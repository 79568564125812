import { determineCurrency } from "config/currency";
import { isUndefined } from "core/helpers/functions";
import { MDBBox } from "mdbreact";
import PriceRender from "./render";
import { useMediaQuery } from "react-responsive";

const Price = (props) => {
  let {
    currency,
    price,
    type,
    onlyValues = false,
    strike = false,
    product = null,
    layout = "standard",
  } = props;
  const isMobile = useMediaQuery({ maxWidth: "992px" });

  if (
    product?.price_range === undefined ||
    product?.price_range?.minimum_price?.final_price?.value === null
  )
    return <></>;

  if (isUndefined(currency)) {
    currency = product.price_range.minimum_price?.regular_price?.currency;
  }
  if (isUndefined(type)) {
    type = "regular";
  }
  let symbol = determineCurrency(currency);

  if (type === "fixed") {
    price = number_format(price, 2);
  }
  if (type === "regular") {
    price = number_format(price);
  }
  if (onlyValues) {
    return `${symbol + price}`;
  }

  const formattedFinalPrice = number_format(
    product.price_range.minimum_price?.final_price?.value,
    2
  );
  const formattedRegularPrice = number_format(
    product.price_range.minimum_price.regular_price.value,
    2
  );
  const formattedEachPrice =
    product.each_price !== undefined &&
    product.each_price !== null &&
    product.each_price !== 0
      ? number_format(product.each_price, 2)
      : false;

  const formattedSpecialEachPrice =
    product.each_special_price !== undefined &&
    product.each_special_price !== null &&
    product.each_special_price !== 0
      ? number_format(product.each_special_price, 2)
      : false;
  const discountApplied =
    product?.price_range?.minimum_price?.discount?.amount_off;

  const unitQty = parseInt(product.unit_qty);

  if (layout === "pdp") {
    return (
      <MDBBox
        className={`d-flex price-component pdp-price ${
          discountApplied ? "flex-column" : "flex-row"
        }`}
      >
        <MDBBox className={`main-prices`}>
          <MDBBox className={`d-flex mr-3`}>
            {discountApplied ? (
              <PriceRender
                discount={discountApplied}
                value={formattedFinalPrice}
                symbol={symbol}
                currency={currency}
                special={true}
              />
            ) : (
              <></>
            )}
            <PriceRender
              discount={discountApplied ? false : true}
              value={formattedRegularPrice}
              symbol={symbol}
              currency={currency}
              special={false}
            />
            {unitQty > 1 && <span> set of {unitQty}</span>}
            {product.special_savings ? (
              <MDBBox className={`save-label ml-3`}>
                {product.special_savings}
              </MDBBox>
            ) : (
              <MDBBox></MDBBox>
            )}
          </MDBBox>
        </MDBBox>
        {unitQty > 1 ? (
          <MDBBox className={`d-flex ${discountApplied ? "mt-3" : ""}`}>
            {formattedEachPrice ? (
              formattedSpecialEachPrice ? (
                <>
                  <MDBBox className={`discount`}>
                    <span>{symbol}</span>
                    <span className={`mr-2`}>
                      {formattedSpecialEachPrice !== null
                        ? formattedSpecialEachPrice
                        : formattedEachPrice}
                    </span>
                  </MDBBox>
                  <MDBBox className={`normal`}>
                    <del>
                      <span>{symbol}</span>
                      <span className={`mr-2`}>{formattedEachPrice}</span>
                    </del>
                  </MDBBox>
                  <span>each</span>
                </>
              ) : (
                <MDBBox>
                  <span>{symbol}</span>
                  <span className={`mr-2`}>{formattedEachPrice}</span>
                  <span>each</span>
                </MDBBox>
              )
            ) : (
              <MDBBox></MDBBox>
            )}
          </MDBBox>
        ) : (
          <></>
        )}
      </MDBBox>
    );
  }

  if (layout === "widget") {
    return (
      <MDBBox
        className={`d-flex ${
          discountApplied || isMobile ? "flex-column" : "flex-row"
        } price-component animated fadeIn price 
        `}
      >
        <MDBBox className={`d-flex justify-content-start align-items-center`}>
          {product.price_range.minimum_price.final_price && discountApplied ? (
            <MDBBox className={`main-price discount mx-2`}>
              <span
                itemProp={`priceCurrency`}
                content={currency}
                className="price-symbol"
              >
                {symbol}
              </span>
              <span itemProp={`price`}>{formattedFinalPrice}</span>
            </MDBBox>
          ) : (
            <></>
          )}
          <MDBBox className={`d-flex flex-row`}>
            {discountApplied ? (
              <MDBBox className={`main-price mx-2`}>
                {/* <del> */}
                <span
                  itemProp={`priceCurrency`}
                  content={currency}
                  className="price-symbol"
                >
                  {symbol}
                </span>
                <span itemProp={`oldPrice`}>{formattedRegularPrice}</span>
                {/* </del> */}
              </MDBBox>
            ) : (
              <MDBBox className={`main-price mx-2`}>
                <span
                  itemProp={`priceCurrency`}
                  content={currency}
                  className="price-symbol"
                >
                  {symbol}
                </span>
                <span itemProp={`price`}>{formattedRegularPrice}</span>
              </MDBBox>
            )}
            {unitQty > 1 ? (
              <MDBBox className={``}>
                <span> set of {unitQty}</span>
              </MDBBox>
            ) : (
              <></>
            )}
          </MDBBox>
          {product.special_savings && !isMobile ? (
            <MDBBox className={`save-label mx-3`}>
              {product.special_savings}
            </MDBBox>
          ) : (
            <></>
          )}
        </MDBBox>
        <div
          className={`d-flex align-items-center justify-content-center flex-${
            formattedEachPrice || formattedSpecialEachPrice
              ? "column"
              : "column-reverse"
          }`}
        >
          <MDBBox
            className={`${
              discountApplied ? "mt-2" : "mt-2"
            } d-flex justify-content-center each-price`}
          >
            {formattedEachPrice ? (
              formattedSpecialEachPrice ? (
                <>
                  <MDBBox className={`discount mx-2`}>
                    <span>{symbol}</span>
                    <span className={`mr-2`}>
                      {formattedSpecialEachPrice !== null
                        ? formattedSpecialEachPrice
                        : formattedEachPrice}
                    </span>
                  </MDBBox>
                  <MDBBox className={`normal`}>
                    <del>
                      <span>{symbol}</span>
                      <span className={`mr-2`}>{formattedEachPrice}</span>
                    </del>
                  </MDBBox>
                  <span>each</span>
                </>
              ) : (
                <MDBBox>
                  <span>{symbol}</span>
                  <span className={`mr-2`}>{formattedEachPrice}</span>
                  <span>each</span>
                </MDBBox>
              )
            ) : (
              <MDBBox className={`no-price`}></MDBBox>
            )}
          </MDBBox>
          {product.special_savings && isMobile ? (
            <MDBBox className={`save-label mx-3`}>
              {product.special_savings}
            </MDBBox>
          ) : (
            <></>
          )}
        </div>
      </MDBBox>
    );
  }

  return (
    <>
      <span className={`price price-component ${props.className}`}>
        {strike ? (
          <del>
            <span
              itemProp={`priceCurrency`}
              content={currency}
              className="price-symbol main-price"
            >
              {symbol}
            </span>
            <span itemProp={`oldPrice`}>{price}</span>
          </del>
        ) : (
          <>
            <span
              itemProp={`priceCurrency`}
              content={currency}
              className="price-symbol"
            >
              {symbol}
            </span>
            <span className={`main-price`} itemProp={`price`}>
              {price}
            </span>
          </>
        )}
      </span>
    </>
  );
};

export const getOnlyPrice = (props) => {
  let { currency, price, type } = props;
  if (isUndefined(currency)) {
    currency = "USD";
  }
  if (isUndefined(type)) {
    type = "regular";
  }
  let symbol = determineCurrency(currency);
  if (type === "fixed") {
    price = number_format(price, 2);
  }
  if (type === "regular") {
    price = number_format(price);
  }
  return `${symbol + price}`;
};

function number_format(number, decimals, dec_point, thousands_sep) {
  // Strip all characters but numerical ones.
  number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
    dec = typeof dec_point === "undefined" ? "." : dec_point,
    s = "",
    toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec);
      return "" + Math.round(n * k) / k;
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
}

export default Price;
