import apolloClient from "app/graphql/apolloClient";
import queryLoader from "app/graphql/queryLoader";
import messagesActions from "core/state/redux/data/messages/actions";
import commonActions from "../../common/actions";
import { customerActions } from "../index";
import initialState from "../initialState";
import { loadState } from "core/state/redux/localStorage";

export default async (store, action) => {
  let loadedState = loadState();
  if (action.token) {
    store.dispatch(
      messagesActions.addMessage(
        "Unable to logout, token is present.",
        "danger"
      )
    );
    store.dispatch(commonActions.unlock());
    return;
  }

  try {
    // if(store.getState().state.customer && 'id' in store.getState().state.customer && store.getState().state.customer.id === false) return;

    const { data } = await apolloClient.mutate({
      mutation: queryLoader("userLogout"),
      variables: action,
    });

    if (!data.revokeCustomerToken && !data.revokeCustomerToken.result) {
      console.log("GraphQL response", data);
      throw new Error("Unable to revoke customer token.");
    }

    if (data.revokeCustomerToken.result) {
      store.dispatch(customerActions._reduceCustomer(initialState));
      store.dispatch(customerActions.getCartInformation());
      store.dispatch(commonActions.unlock());
      if (!action.hideMessage) {
        store.dispatch(
          messagesActions.addMessage("You have logged out.", "success")
        );
      }
    }
  } catch (error) {
    let token = loadedState.customer.data.token;
    if (!token) return;

    store.dispatch(
      customerActions._reduceCustomer({ data: { token: false, email: "" } })
    );
    // if (error.message !== "The current customer isn't authorized.") {
    //   setTimeout(() => {
    //     store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    //   }, 300);
    // }

    store.dispatch(commonActions.unlock());
    return;
  }
};
