import { MDBModalBody, MDBCol, MDBModal } from "mdbreact";

const AddedToWishlistModal = ({
  addedToWishlistModalToggle,
  modalToggleSecond,
  addedToWishlistModal,
}) => {
  return (
    <>
      <MDBModal
        isOpen={addedToWishlistModal}
        toggle={() => {
          addedToWishlistModalToggle();
        }}
        className={`wishlist-modal wishlist-wrapper wishlist-added`}
        centered
      >
        <MDBModalBody className="d-flex align-items-center addtowslpop">
          <MDBCol className="wbr-icon-heart-full">
            <span className="heartxt">Your Favorites And Projects</span>
          </MDBCol>
          <MDBCol className="text-right viewpr">
            <a
              onClick={(e) => {
                e.preventDefault();
                addedToWishlistModalToggle();
                modalToggleSecond();
              }}
              href={`/`}
            >
              Create new project
            </a>
          </MDBCol>
        </MDBModalBody>
      </MDBModal>
    </>
  );
};

export default AddedToWishlistModal;
