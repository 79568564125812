import { useState } from "react";
import Header from "./header";

const HeaderStateContainer = (props) => {
  let { data } = props;
  let menu = data;
  const [toggle, setToggle] = useState(false);
  const [hoveredDropdown, setHoveredDropdown] = useState(true);
  const [position, setPosition] = useState(0);
  const [firstPosition, setFirstPosition] = useState(0);

  const changeToggle = () => {
    setToggle(!toggle);
  };
  let newMenu =
    menu &&
    menu.length > 0 &&
    menu.map((item) => {
      if (
        item.catalog_category !== null &&
        item.catalog_category !== undefined &&
        item.catalog_category
      ) {
        // adjusting catalog object to navigational menu objects
        item = {
          ...item,
          id: item.id,
          name: item.catalog_category.name,
          url_key: item.catalog_category.url_path,
          children: item.catalog_category.children,
          category_id: item.catalog_category.id,
          sideCategories: item.children,
        };
      }
      return item; //changed key name
    });

  return (
    <Header
      menu={newMenu}
      setMenu={() => {}}
      changeToggle={changeToggle}
      toggle={toggle}
      hoveredDropdown={hoveredDropdown}
      setHoveredDropdown={setHoveredDropdown}
      position={position}
      setPosition={setPosition}
      firstPosition={firstPosition}
      setFirstPosition={setFirstPosition}
    />
  );
};

export default HeaderStateContainer;
