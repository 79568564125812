// Action Creators
import types from "./types";

const isValidMessageType = (messageType) => {
  const validMessageTypes = [
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
    "error",
  ];

  return validMessageTypes.includes(messageType);
};

const addMessage = (
  messageText,
  messageType = "info",
  messageTarget = "global",
  messageCloseTime = 4000,
  options = null
) => {
  if (!isValidMessageType(messageType)) {
    throw new Error("Invalid message type: " + messageType);
  }

  if (messageType === "error") {
    messageType = "danger";
  }

  return {
    type: types.ADD_MESSAGE,
    messageText: messageText,
    messageType: messageType,
    messageTarget: messageTarget,
    messageCloseTime: messageCloseTime,
    options,
  };
};

const removeMessage = (
  messageText,
  messageType = "info",
  messageCloseTime = 4000
) => {
  if (!isValidMessageType(messageType)) {
    throw new Error("Invalid message type: " + messageType);
  }

  return {
    type: types.REMOVE_MESSAGE,
    messageText: messageText,
    messageType: messageType,
    messageCloseTime: messageCloseTime,
  };
};

export default {
  addMessage,
  removeMessage,
};
