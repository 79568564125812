import { useContext, useState } from "react";
import { DownloadContext } from "../provider";

export const useDownloader = (image) => {
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useContext(DownloadContext);
  const isAdded = images.find((x) => x.file_url === image);

  const addImage = (group_id, type, is_primary) => {
    if (!isAdded) {
      setImages((images) => [
        ...images,
        { file_url: image, group_id, type, is_primary, remove_flag: "NO" },
      ]);
    }
  };
  const removeImage = () => {
    if (isAdded) {
      setImages((images) => {
        return images.filter((item) => item.file_url !== image);
      });
    }
  };

  const removeAllImages = () => {
    setImages([]);
  };

  return {
    isAdded,
    addImage,
    removeImage,
    removeAllImages,
    loading,
    setLoading,
  };
};
