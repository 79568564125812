import { convertNodeToElement } from "react-html-parser";
import Image from "app/layout/image";
import { isUndefined } from "lodash";
import { MDBLink, MDBBox } from "mdbreact";
import { ModalConsumer } from "core/components/modal/ModalContext";
import Login from "app/pages/customer/login";
import BannerImageForCmsBlocks from "app/layout/bannerImageForCmsBlocks";
import OverlapCardsComponentForCmsBlock from "app/layout/overlapCardsComponentForCmsBlock";
import OfferItemComponent from "app/layout/offerItemComponent";
import ProjectForm from "app/pages/static/hospitality/projectForm";
import Testimonials from "app/pages/static/hospitality/testimonials";

export function transform(node, index, token, history, store) {
  let stylesArray = [];

  if (node.type === "tag" && node.name === "a") {
    if (typeof node.attribs.style === "undefined") {
      node.attribs.style = {};
    } else {
      let parsedStyle = [];
      stylesArray = node.attribs.style.split(";");

      stylesArray.forEach((element) => {
        let miniArray = element.split(":");
        let newSanitizedArray = [];
        miniArray.forEach((piece, index) => {
          let sanitizedPiece = piece.replace(/^ /, "");
          sanitizedPiece =
            "'" +
            sanitizedPiece
              .replace("border-style", "borderStyle")
              .replace("border-color", "borderColor") +
            "'";
          newSanitizedArray[index] = sanitizedPiece;
        });

        if (typeof newSanitizedArray[1] !== "undefined") {
          parsedStyle[newSanitizedArray[0]] = newSanitizedArray[1].replace(
            /"/g,
            ""
          );
        }
      });
      parsedStyle.join(",");
      let finalStyle = JSON.parse(
        JSON.stringify({ ...parsedStyle })
          .replace(/'/g, '"')
          .replace(/""/g, '"')
      );

      node.attribs.style = { ...finalStyle };
    }

    let scrollVar = !isUndefined(node.attribs["data-scroll"])
      ? node.attribs["data-scroll"]
      : undefined;

    if (!isUndefined(node.attribs["data-user"])) {
      return (
        <ModalConsumer value={{ useWrapper: false }}>
          {({ showModal, hideModal }) => (
            <MDBBox
              className="data-user-login-action"
              onClick={() => {
                showModal(() => {
                  return (
                    <Login
                      hideModal={() => {
                        hideModal();
                      }}
                      redirect={node.attribs.href}
                      history={history}
                    />
                  );
                });
              }}
            >
              <MDBBox
                onClick={(e) => {
                  e.preventDefault();
                  if (token) {
                    history.push({
                      pathname: `${node.attribs.href}`,
                      state: { scrollTo: scrollVar },
                    });
                  }
                }}
              >
                {convertNodeToElement(node, index, (node, index) =>
                  transform(node, index, token, history)
                )}
              </MDBBox>
            </MDBBox>
          )}
        </ModalConsumer>
      );
    }

    if (!isUndefined(node.attribs.href) && node.attribs.href.includes("http")) {
      return convertNodeToElement(node, index, (node, index) =>
        transform(node, index, token, history)
      );
    }

    if (typeof node.attribs.class !== "undefined") {
      node.attribs.className = node.attribs.class;
      delete node.attribs.class;
    }
    if (
      typeof node.attribs.href !== "undefined" &&
      (node.attribs.href.includes("tel") ||
        node.attribs.href.includes("mailto"))
    ) {
      return (
        <a {...node.attribs} key={`anchor-${{ ...node.attribs }}`}>
          {node.children.map((item) => {
            return convertNodeToElement(item, index, (node, index) =>
              transform(node, index, token, history)
            );
          })}
        </a>
      );
    }

    let linkComponent = (
      <MDBLink
        key={`parsed-link-${index}`}
        to={{
          pathname: `${node.attribs.href}`,
          state: { scrollTo: scrollVar },
        }}
        {...node.attribs}
      >
        {node.children.map((item) => {
          let icon = {
            type: "tag",
            name: "i",
            attribs: { class: "fas fa-arrow-right" },
            children: [],
            next: null,
          };
          if (item.name === "button") {
            if (
              item.children.length === 1 &&
              item.attribs.class.includes("show-arrow")
            ) {
              item.children.push(icon);
            }
          }
          return convertNodeToElement(item, index, (node, index) =>
            transform(node, index, token, history)
          );
        })}
      </MDBLink>
    );
    return linkComponent;
  }

  if (node.type === "tag" && node.name === "img") {
    if (
      node.attribs.src.includes("https") ||
      node.attribs.src.includes("http")
    ) {
      return (
        <Image
          key={`node-attribs-` + node.type + node.name + index}
          source={node.attribs.src}
          alt={node.attribs.alt}
          preload={false}
        />
      );
    }
    let imageSource = node.attribs.src.split('"');
    return (
      <Image
        source={
          process.env.REACT_APP_MAIN_STORE +
          '/pub/media' +
          "/" +
          imageSource[1]
        }
        alt={node.attribs.alt}
        preload={false}
      />
    );
  }

  if (
    node.type === "tag" &&
    node.attribs &&
    node.attribs["data-banner-section"]
  ) {
    let data = convertNodeToElement(node, index, transform);
    return (
      <BannerImageForCmsBlocks
        key={`banner-images-forcms-${index}`}
        data={data}
      />
    );
  }

  if (
    node.type === "tag" &&
    node.attribs &&
    node.attribs["data-overlap-cards"]
  ) {
    let data = convertNodeToElement(node, index, transform);
    return (
      <OverlapCardsComponentForCmsBlock
        key={`overlap-cards-${index}`}
        data={data}
      />
    );
  }

  if (node.type === "tag" && node.attribs && node.attribs["data-offer-item"]) {
    let data = convertNodeToElement(node, index, transform);
    return <OfferItemComponent key={`offer-item-${index}`} data={data} />;
  }

  if (
    node.type === "tag" &&
    node.attribs &&
    node.attribs["data-project-form"]
  ) {
    let data = convertNodeToElement(node, index, transform);
    return <ProjectForm key={`project-form-${index}`} data={data} />;
  }

  if (
    node.type === "tag" &&
    node.attribs &&
    node.attribs["data-testimonials"]
  ) {
    let data = convertNodeToElement(node, index, transform);
    return <Testimonials key={`testimonials-${index}`} data={data} />;
  }

  return convertNodeToElement(node, index, (node, index) =>
    transform(node, index, token, history)
  );
}
