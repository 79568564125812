import { Fragment, useEffect } from "react";

const Gatco = ({ setStyle }) => {
  useEffect(() => {
    setStyle();
  }, [setStyle]);

  return <Fragment></Fragment>;
};

export default Gatco;
