import { connect } from "react-redux";
import CustomerStateContainer from "./customer-state-container";
import { isUndefined } from "core/helpers/functions";
import { Redirect } from "react-router-dom";
import { customerActions } from "core/state/redux/data/customer";

const CustomerReduxContainer = (props) => {
  if (isUndefined(props.customer) || !props.customer.token)
    return <Redirect to={"/"} />;
  return <CustomerStateContainer {...props} />;
};

const mapStateToProps = (state) => ({
  customer: state.customer.data,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logoutCustomer: () => {
      dispatch(customerActions.logoutCustomer());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerReduxContainer);
