import { memo } from "react";
import { MDBNavLink, MDBBox } from "mdbreact";
import { UncontrolledDropdown } from "reactstrap";
import HeaderDropdown from "../../dropdown";
import useStore from "app/state/hooks/storeHooks/useStore";
import { isEqual } from "lodash";

const MenuItem = memo(
  ({
    currentMenuItem,
    index,
    setHoveredDropdown,
    setShowDropdown,
    hoveredDropdown,
    showDropdown,
    activeMenuItems,
    position,
    setPosition,
    hoveredDropdownId,
    onMouseOverFn,
    hasDropdownMenu,
  }) => {
    const { getStoreCode } = useStore();
    const store = getStoreCode();
    let urlPath = currentMenuItem.url_path
      ? currentMenuItem.url_path
      : currentMenuItem.url_key
      ? currentMenuItem.url_key
      : "";
    urlPath = urlPath[0] === "/" ? urlPath : `/${urlPath}`;

    return (
      <li onMouseOver={onMouseOverFn}>
        <UncontrolledDropdown key={`uncontrolled-nav-dropdown-${index}`}>
          <MDBNavLink
            to={`${urlPath}`}
            className={`${store} text-uppercase text-decoration-none dropdown-toggle underline-animation ${
              hoveredDropdown &&
              hasDropdownMenu &&
              showDropdown &&
              "active-item"
            }`}
            onClick={(e) => {
              if (
                currentMenuItem.name === "Product Types" ||
                currentMenuItem.name === "Collections"
              ) {
                e.preventDefault();
              }
              if (hasDropdownMenu) {
                setHoveredDropdown(false);
                setShowDropdown(false);
              }
            }}
          >
            <MDBBox className={`menu-item hover`} id={`menu-item-${index}`}>
              {currentMenuItem.name}
            </MDBBox>
          </MDBNavLink>
          {/* only for simple menu layout*/}
          {activeMenuItems &&
            !activeMenuItems.complex_menu &&
            currentMenuItem.children &&
            hoveredDropdown && (
              <HeaderDropdown
                hoveredDropdown={hoveredDropdown}
                setHoveredDropdown={setHoveredDropdown}
                activeMenuItems={activeMenuItems}
                position={position}
                setPosition={setPosition}
                showDropdown={showDropdown}
                setShowDropdown={setShowDropdown}
                hoveredDropdownId={hoveredDropdownId}
              />
            )}
        </UncontrolledDropdown>
      </li>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default MenuItem;
