import apolloClient from "app/graphql/apolloClient";
import customerActions from "../../actions";
import commonActions from "../../../common/actions";
import messagesActions from "core/state/redux/data/messages/actions";
import queryActions from "core/state/redux/data/queries/actions";
import { gql } from "graphql.macro";
import { merge } from "lodash";

const APPLY_COUPON_TO_CART_MUTATION = gql`
  mutation ApplyCouponToCart($input: ApplyCouponToCartInput) {
    applyCouponToCart(input: $input) {
      cart {
        id
        # ship_complete
        prices {
          subtotal_including_tax {
            currency
            value
          }
          subtotal_excluding_tax {
            currency
            value
          }
          fee {
            currency
            value
          }
          grand_total {
            currency
            value
          }
          applied_taxes {
            amount {
              currency
              value
            }
            label
          }
          discounts {
            amount {
              value
              currency
            }
            label
          }
        }
        items {
          id
          prices {
            row_total {
              currency
              value
            }
            price {
              currency
              value
            }
            discounts {
              label
              amount {
                currency
                value
              }
            }
          }
          ... on BundleCartItem {
            prices {
              row_total {
                currency
                value
              }
              price {
                currency
                value
              }
              discounts {
                label
                amount {
                  currency
                  value
                }
              }
            }
          }
        }
        applied_coupons {
          code
        }
      }
    }
  }
`;
export default async (store, action) => {
  try {
    let cart_id = store.getState().customer.data.cartToken;
    let input = { cart_id: cart_id, coupon_code: action.coupon };

    const { data } = await apolloClient.mutate({
      mutation: APPLY_COUPON_TO_CART_MUTATION,
      variables: { input: input },
      fetchPolicy: "no-cache",
    });

    if (data.applyCouponToCart) {
      let oldCart = { ...store.getState().customer.data.cart };
      merge(oldCart, data.applyCouponToCart.cart);

      store.dispatch(customerActions.setCartInformation(oldCart));
      store.dispatch(
        messagesActions.addMessage("Updated cart information", "success")
      );
      store.dispatch(commonActions.unlock("success"));
    }
  } catch (error) {
    if (error.message.includes("Could not find a cart with ID")) {
      store.dispatch(customerActions.createEmptyCart());
    } else {
      store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    }
    store.dispatch(commonActions.unlock("fail"));
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
