import { memo } from "react";
import WishlistPlpItem from "../../wishlistPlpItem";

const ProjectsListing = memo(
  ({
    fetchArr,
    product,
    loading,
    setLoading,
    modalToggleSecond,
    collectionProducts,
  }) => {
    return fetchArr.map((list) => {
      let checked = false;
      let foundItem;
      if (collectionProducts?.length > 0) {
        foundItem = list.items.filter((fItem) => {
          return collectionProducts?.map(
            (item) => fItem.product.sku === item?.sku
          );
        });
      } else {
        foundItem = list.items.filter(
          (fItem) => fItem.product.sku === product.sku
        );
      }

      if (
        foundItem.length !== 0 &&
        foundItem.length >= collectionProducts.length
      ) {
        checked = true;
      }

      return (
        <WishlistPlpItem
          key={`wishlist-list-id-${list.id}`}
          loading={loading}
          setLoading={setLoading}
          list={list}
          checked={checked}
          modalToggleSecond={modalToggleSecond}
          sku={product.sku}
          collectionProducts={collectionProducts}
        />
      );
    });
  }
);

export default ProjectsListing;
