import React, { useMemo } from "react";
import HeaderStateContainer from "./header-state-container";
import useStore from "app/state/hooks/storeHooks/useStore";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";

const HeaderDataContainer = (props) => {
  const { getStore } = useStore();
  const { isLoggedIn } = useCustomer();
  const loggedIn = !!isLoggedIn();
  let data = getStore();
  data =
    data &&
    data.navigational_menu &&
    data.navigational_menu[0] &&
    data.navigational_menu[0].parent_categories;

  const navigation = useMemo(() => {
    return data
      ?.filter(
        (item) =>
          (loggedIn && item.visibility !== "Logged out") ||
          (!loggedIn && item.visibility !== "Logged in")
      )
      .map((item) => ({
        ...item,
        children: item.children
          ? item.children.filter(
              (child) =>
                (loggedIn && child.visibility !== "Logged out") ||
                (!loggedIn && child.visibility !== "Logged in")
            )
          : [],
      }));
  }, [loggedIn, data]);

  // const navigationForLoggedIn = data?.map((item) => {
  //   let filteredChildren = item?.children?.filter((child) => {
  //     return child.visibility !== "Logged out";
  //   });
  //   let filteredItem = { ...item, children: filteredChildren };
  //   return item.visibility !== "Logged out" ? filteredItem : null;
  // });
  // const navigationForLoggedOut = data?.map((item) => {
  //   let filteredChildren = item?.children?.filter((child) => {
  //     return child.visibility !== "Logged in";
  //   });
  //   let filteredItem = { ...item, children: filteredChildren };

  //   return item.visibility !== "Logged in" ? filteredItem : null;
  // });

  // const navigation = isLoggedIn()
  //   ? navigationForLoggedIn
  //   : navigationForLoggedOut;

  return (
    <HeaderStateContainer {...props} data={navigation ? navigation : {}} />
  );
};

export default HeaderDataContainer;
