import apolloClient from "app/graphql/apolloClient";
import customerActions from "../actions";
import commonActions from "../../common/actions";
import messagesActions from "core/state/redux/data/messages/actions";
import { gql } from "graphql.macro";

const EDIT_WISHLIST_QUERY = gql`
  mutation EditWishlist($id: Int, $name: String) {
    editWishlist(input: { id: $id, name: $name }) {
      id
      items
      main
      name
    }
  }
`;
export default async (store, action) => {
  try {
    let wishlists = { ...store.getState().customer.data.wishlist };
    if (parseInt(wishlists.id) !== action.id) {
      const { data } = await apolloClient.mutate({
        mutation: EDIT_WISHLIST_QUERY,
        variables: action,
      });

      if (data && data.editWishlist !== null) {
        let filtered = wishlists.multiwishlist_data.findIndex(
          (x) => x.id === action.id
        );
        wishlists.multiwishlist_data[filtered] = { ...data.editWishlist };
        store.dispatch(customerActions._reduceWishlist(wishlists));
        store.dispatch(
          messagesActions.addMessage(`successfully renamed project `, "success")
        );
      }
      store.dispatch(commonActions.unlock("success"));
    } else {
      store.dispatch(
        messagesActions.addMessage("unable to rename main wishlist", "danger")
      );
    }
  } catch (error) {
    store.dispatch(
      messagesActions.addMessage(error.graphQLErrors[0].debugMessage, "danger")
    );
    store.dispatch(commonActions.unlock("fail"));
  }
};
