import apolloClient from "app/graphql/apolloClient";
import queryLoader from "app/graphql/queryLoader";
import { messagesActions } from "core/state/redux/data/messages";
import { isArray, isObject, merge } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "app/state/redux/data/common";
import { customerActions } from "app/state/redux/data/customer";
import {
  SET_GUEST_EMAIL_ON_CART_MUTATION,
  SET_SHIPPING_ADDRESSES_ON_CART,
  SET_BILLING_ADDRESS_ON_CART,
  SET_BILLING_ADDRESS_ON_CART_WITH_SHIPPING,
  MINIMAL_ORDER,
} from "./queries";
import { useContext, useState } from "react";
import { PayContext } from "./PayContext";
import { MiniCartContext } from "./MinicartContext";
import { data } from "app/layout/schemas/searchSchema";

function useCart() {
  const customer = useSelector((state) => state.customer);
  const cart = useSelector((state) => state.customer.data.cart);
  const miniCartContext = useContext(MiniCartContext);

  const getOrderId = () => {
    return customer.order_id;
  };

  const getCart = () => {
    return cart;
  };

  const getCartItems = () => {
    return cart?.items;
  };

  const getCartNumberOfItems = () => {
    return cart?.items.reduce((acc, curr) => acc + curr.quantity, 0);
  };
  //add item
  const addProductToCart = async (request, showNotification = true) => {
    if (request.type === "SimpleProduct") {
      return addSimpleProductToCart(request, showNotification);
    }
    if (request.type === "BundleProduct") {
      return addBundledProductToCart(request, showNotification);
    }
    if (request.type === "ConfigurableProduct") {
      return addConfigurableProductsToCart(request, showNotification);
    }
  };

  //remove item
  const removeItemFromCart = async (productId, showNotification = true) => {
    await dispatch(customerActions.removeProductFromCart(productId));
  };

  //update item
  const updateCartItem = async (cartItems, showNotification = true) => {
    dispatch(customerActions.updateCart(cartItems));
  };

  //clear cart
  const clearItems = async (showNotification = true) => {
    dispatch(customerActions.clearCart());
  };

  const getCartInformation = async () => {
    await dispatch(customerActions.getCartInformation());
  };

  const addSimpleProductToCart = async (request, showNotification) => {
    // creating proper buyRequest
    let buyRequest = [];

    if (isArray(request.data)) {
      buyRequest = [...request.data];
    } else if (typeof request.data === "object") {
      buyRequest = [
        {
          data: { ...request.data },
          customizable_options: { ...request.customizable_options },
        },
      ];
    }

    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("addProduct"),
        variables: {
          items: buyRequest,
          cart_id: cart.id,
        },
      });
      if (data.addSimpleProductsToCart !== null) {
        dispatch(
          customerActions.setCartInformation({
            ...cart,
            ...data.addSimpleProductsToCart.cart,
          })
        );
        if (showNotification) {
          dispatch(
            messagesActions.addMessage(
              "Item successfully added to cart!",
              "success"
            )
          );
        }
        dispatch(commonActions.unlock("success")).then(() => {
          showMiniCartFn();
        });
        return true;
      }
    } catch (err) {
      dispatch(commonActions.unlock("fail"));
      dispatch(
        messagesActions.addMessage(
          "unable to add products to cart, please try again later",
          "danger"
        )
      );
      console.log(err);
      return false;
    }
  };

  const addBundledProductToCart = async (request, showNotification) => {
    // creating proper buyRequest
    let buyRequest = [...generateBundleBuyRequest(request)];

    // query removed
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("addBundleProductsToCart"),
        variables: {
          items: buyRequest,
          cart_id: cart.id,
        },
      });
      if (data.addBundleProductsToCart !== null) {
        dispatch(
          customerActions.setCartInformation({
            ...cart,
            ...data.addBundleProductsToCart.cart,
          })
        );
        if (showNotification) {
          dispatch(
            messagesActions.addMessage(
              "Item successfully added to cart!",
              "success"
            )
          );
        }
        dispatch(commonActions.unlock("success")).then(() => {
          showMiniCartFn();
        });
        return true;
      }
    } catch (err) {
      dispatch(commonActions.unlock("fail"));
      if (!err?.message?.includes("current user")) {
        dispatch(
          messagesActions.addMessage(
            "unable to add products to cart, please try again later",
            "danger"
          )
        );
      }
      return false;
    }
  };
  const addConfigurableProductsToCart = async (request, showNotification) => {
    // creating proper buyRequest
    let buyRequest = [];

    if (isArray(request.data)) {
      buyRequest = [...request.data];
    } else if (typeof request.data === "object") {
      buyRequest = [
        {
          data: { ...request.data },
          customizable_options: { ...request.customizable_options },
        },
      ];
    }
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("addConfigurableProductsToCart"),
        variables: {
          cart_id: cart.id,
          cart_items: [
            {
              parent_sku: request?.input?.cart_items?.parent_sku,
              data: request?.input?.cart_items.data,
            },
          ],
        },
      });
      if (data.addConfigurableProductsToCart !== null) {
        dispatch(
          customerActions.setCartInformation({
            ...cart,
            ...data.addConfigurableProductsToCart.cart,
          })
        );
        if (showNotification) {
          dispatch(
            messagesActions.addMessage(
              "Item successfully added to cart!",
              "success"
            )
          );
        }
        dispatch(commonActions.unlock("success")).then(() => {
          showMiniCartFn();
        });
        return true;
      }
    } catch (err) {
      dispatch(commonActions.unlock("fail"));
      if (!err?.message?.includes("current user")) {
        dispatch(
          messagesActions.addMessage(
            "unable to add products to cart, please try again later",
            "danger"
          )
        );
      }
      return false;
    }
  };

  const saveAdditionalCheckInformation = async (request) => {
    try {
      let finalRequest = { ...request, cart_id: cart.id };
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("setCheckAdditional"),
        variables: { input: finalRequest },
        fetchPolicy: "no-cache",
      });

      dispatch(
        messagesActions.addMessage("Check information updated", "success")
      );
      dispatch(customerActions.setCheckAdditional(data.setCheckAdditional));
      dispatch(commonActions.unlock("success"));
    } catch (err) {
      dispatch(commonActions.unlock("fail"));
      console.log(err.message);
      return;
    }
  };

  const setCartInformation = async (newCart) => {
    dispatch(customerActions.setCartInformation(newCart));
  };

  const getToggleMinicart = () => {
    return customer.toggleMinicart;
  };

  const toggleMiniCart = (modifyHeader = true) => {
    // toggle mini cart at any time

    if (modifyHeader) {
      let headerNav = document.getElementById("header-navigation");
      if (headerNav.style.transform === "translateY(-200px)") {
        headerNav.style.top = "0px";
        headerNav.style.transform = "translateY(0px)";
      }
    }
    miniCartContext.toggleMiniCart();
  };

  const showMiniCartFn = (modifyHeader = true) => {
    if (modifyHeader) {
      let headerNav = document.getElementById("header-navigation");
      let plpSidebar = document.getElementById("sidebar-filter-groups");
      if (headerNav.style.transform === "translateY(-200px)") {
        headerNav.style.top = "0px";
        headerNav.style.transform = "translateY(0px)";
        plpSidebar.classList.add("header-active");
      }
    }
    miniCartContext.showMiniCartFn();
  };

  const setGuestEmailOnCart = async (email) => {
    try {
      let cart_id = customer.data.cartToken;
      let input = { cart_id: cart_id, email: email };
      const { data } = await apolloClient.mutate({
        mutation: SET_GUEST_EMAIL_ON_CART_MUTATION,
        variables: { input: input },
        fetchPolicy: "no-cache",
      });
      if (data.setGuestEmailOnCart) {
        dispatch(
          customerActions.setCartInformation(data.setGuestEmailOnCart.cart)
        );
        dispatch(
          messagesActions.addMessage("Updated cart information", "success")
        );
        dispatch(commonActions.unlock("success"));
      }
    } catch (error) {
      if (error.message.includes("Could not find a cart with ID")) {
        dispatch(customerActions.createEmptyCart());
      } else {
        dispatch(messagesActions.addMessage(error.toString(), "danger"));
      }
      dispatch(commonActions.unlock());
      return;
    }
  };

  const setBillingAddressOnCart = async (billing_address) => {
    try {
      let USED_QUERY = SET_BILLING_ADDRESS_ON_CART;
      let cart_id = cart.id;
      let cartInformation = cart;

      let input = {
        cart_id: cart_id,
        billing_address: { ...billing_address },
      };

      const same_as_shipping = billing_address.same_as_shipping;

      if (same_as_shipping) {
        USED_QUERY = SET_BILLING_ADDRESS_ON_CART_WITH_SHIPPING;
      }

      const { data } = await apolloClient.mutate({
        mutation: USED_QUERY,
        variables: { input: input },
        fetchPolicy: "no-cache",
      });
      if (data) {
        merge(cartInformation, data.setBillingAddressOnCart.cart);
        cartInformation = {
          ...cartInformation,
          billing_address: {
            ...data.setBillingAddressOnCart.cart.billing_address,
          },
        };
        dispatch(customerActions.setCartInformation(cartInformation));
        dispatch(
          messagesActions.addMessage("Updated cart information", "success")
        );
        dispatch(commonActions.unlock("success"));
      }
    } catch (error) {
      if (error.message.includes("Could not find a cart with ID")) {
        dispatch(customerActions.createEmptyCart());
      } else {
        dispatch(messagesActions.addMessage(error.toString(), "danger"));
      }
      dispatch(commonActions.unlock("fail"));
      return;
    }
  };

  const setShippingAddressOnCart = async (shipping_address) => {
    try {
      let cart_id = cart.id;
      let cartInformation = cart;
      let input = {
        cart_id: cart_id,
        shipping_addresses: [{ ...shipping_address }],
      };

      const { data } = await apolloClient.mutate({
        mutation: SET_SHIPPING_ADDRESSES_ON_CART,
        variables: { input: input },
        fetchPolicy: "no-cache",
      });
      if (data) {
        merge(cartInformation, data.setShippingAddressesOnCart.cart);

        cartInformation = {
          ...cartInformation,
          shipping_addresses: [
            ...data.setShippingAddressesOnCart.cart.shipping_addresses,
          ],
        };
        dispatch(customerActions.setCartInformation(cartInformation));
        dispatch(
          messagesActions.addMessage("Updated cart information", "success")
        );
        dispatch(commonActions.unlock("success"));
      }
    } catch (error) {
      if (error.message.includes("Could not find a cart with ID")) {
        dispatch(customerActions.createEmptyCart());
      } else {
        dispatch(messagesActions.addMessage(error.toString(), "danger"));
      }
      dispatch(commonActions.unlock("fail"));
      return;
    }
  };
  const checkForMinimalOrder = async () => {
    try {
      const { data } = await apolloClient.query({
        query: MINIMAL_ORDER,
        fetchPolicy: "network-only",
      });
      if (data) {
        let newData = { ...data.isMinimalOrderFee };
        delete newData.__typename;
        dispatch(
          customerActions.minimalOrderUpdate({
            ...newData,
          })
        );
      }
    } catch (error) {
      // dispatch(messagesActions.addMessage(error.toString(), "danger"));

      // dispatch(commonActions.unlock("fail"));
      return;
    }
  };

  const useCreditCard = () => {
    const initialState = {
      nameOnCard: "",
      number: "",
      date: "",
      expirationDate: "",
      expirationYear: "",
      ccv: "",
      month: "",
      year: "",
      useCard: false,
      saveCard: false,
      hash: undefined,
      address: {
        firstname: "",
        lastname: "",
        telephone: "",
        country_id: "US",
        country_code: "US",
        city: "",
        region: {
          region_code: "AL",
          region: "Alabama",
          region_id: 1,
        },
        street: {
          one: "",
          two: "",
        },
        company: "",
        postcode: "",
      },
    };
    const { pay, setPay } = useContext(PayContext);
    const [card, setCard] = useState(initialState);

    const determineCardType = (number) => {
      // Visa Electron
      var re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
      if (number.match(re) != null) return "VI";

      // visa
      re = new RegExp("^4");
      if (number.match(re) != null) return "VI";

      // Mastercard
      re = new RegExp("^5[1-5]");
      if (number.match(re) != null) return "MC";

      // AMEX
      re = new RegExp("^3[47]");
      if (number.match(re) != null) return "AE";

      // Discover
      re = new RegExp(
        "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
      );
      if (number.match(re) != null) return "DI";

      // JCB
      re = new RegExp("^35(2[89]|[3-8][0-9])");
      if (number.match(re) != null) return "JCB";

      return "OT";
    };

    const resetPayment = () => {
      setCard({ ...initialState, hash: false, useCard: true });
      setPay({ ...pay, ...initialState, hash: false, useCard: true });
    };

    const changeWithSavedCard = (savedCard) => {
      let _savedCard = { ...savedCard };
      if (_savedCard.__typename) {
        delete _savedCard.label;
        delete _savedCard.value;
        delete _savedCard.__typename;
        delete _savedCard.additional.__typename;
        delete _savedCard.address.__typename;
        delete _savedCard.address.region.__typename;
        setCard({ ...card, ..._savedCard, authData: card.authData });
        setPay({ ...pay, ..._savedCard, authData: card.authData });
      } else {
        setCard({ ...card, ...initialState, authData: card.authData });
        setPay({ ...pay, ...initialState, authData: card.authData });
      }
    };

    const handleChangeCard = (key, value) => {
      let cardType = pay.type ? pay.type : "OT";
      if (key === "number") {
        cardType = determineCardType(value);
      }
      if (key.includes(".")) {
        let keyParts = key.split(/\./g);
        if (keyParts[2]) {
          setCard({
            ...card,
            [keyParts[0]]: {
              ...card[keyParts[0]],
              [keyParts[1]]: {
                ...card[keyParts[0]][keyParts[1]],
                [keyParts[2]]: value,
              },
            },
          });
          setPay({
            ...pay,
            [keyParts[0]]: {
              ...pay[keyParts[0]],
              [keyParts[1]]: {
                ...pay[keyParts[0]][keyParts[1]],
                [keyParts[2]]: value,
              },
            },
          });
        } else {
          setCard({
            ...card,
            [keyParts[0]]: { ...card[keyParts[0]], [keyParts[1]]: value },
          });
          setPay({
            ...pay,
            [keyParts[0]]: { ...pay[keyParts[0]], [keyParts[1]]: value },
          });
        }
      } else {
        setCard({ ...card, [key]: value, type: cardType });
        setPay({ ...pay, [key]: value, type: cardType });
      }
    };

    /**
     * Callback is the handle function exported out of Authorize.net container component
     * @param {*} value
     * @param {*} callback
     */
    const dateValidationAndChangeCard = (value) => {
      let finalMonths = 0,
        numbers = value.split("/"),
        months = numbers[0],
        selectedYears = numbers[1];

      if (parseInt(months) > 12) {
        months = "12";
      }
      if (parseInt(months) < 0) {
        months = "0";
      }

      finalMonths = months;

      let newFinalCardDate = finalMonths + "/" + selectedYears;
      setCard({
        ...card,
        date: newFinalCardDate,
        month: finalMonths,
        year: selectedYears,
      });
      setPay({
        ...pay,
        date: newFinalCardDate,
        month: finalMonths,
        year: selectedYears,
      });
    };

    return {
      card,
      handleChangeCard,
      dateValidationAndChangeCard,
      changeWithSavedCard,
      resetPayment,
    };
  };

  const useAuthData = () => {
    const [authData, setAuthData] = useState({});

    const handleSetAuthData = (_authData) => {
      setAuthData(_authData);
    };

    return { authData, handleSetAuthData };
  };

  let dispatch = useDispatch();

  return {
    getCart,
    getCartItems,
    getCartNumberOfItems,
    removeItemFromCart,
    updateCartItem,
    clearItems,
    saveAdditionalCheckInformation,
    addProductToCart,
    toggleMiniCart,
    getToggleMinicart,
    setGuestEmailOnCart,
    useCreditCard,
    useAuthData,
    getOrderId,
    setBillingAddressOnCart,
    setShippingAddressOnCart,
    setCartInformation,
    getCartInformation,
    checkForMinimalOrder,
  };
}

// helper functions
const generateBundleBuyRequest = (request) => {
  let buyRequest = [];
  let bundle_options = [];

  Object.keys(request.data.bundle_options).map((item) => {
    let bundle = request.data.bundle_options[item];
    if (isArray(bundle)) {
      // write logic if multiple bundle items are sent through array
      console.log("this is an array");
    }
    if (isObject(bundle)) {
      Object.keys(bundle).map((bundle_key) => {
        let bundle_item = { ...bundle[bundle_key] };
        if (typeof bundle_item.id === "undefined") return "";
        if (typeof bundle_item.value === "undefined") {
          bundle_item["value"] = bundle_item.id;
        }

        let sanitized_bundle_item = {
          id: bundle_item.option_id,
          value: [bundle_item.value + ""],
          quantity: parseFloat(bundle_item.quantity),
        };
        return bundle_options.push(sanitized_bundle_item);
      });
    }
    return "";
  });
  if (typeof request.data === "object") {
    if (typeof request.customizable_options === "undefined") {
      request["customizable_options"] = [];
    }
    let item_request = {
      sku: request.data.sku,
      quantity: parseFloat(request.data.quantity),
    };
    buyRequest = [
      {
        data: item_request,
        customizable_options: [...request.customizable_options],
        bundle_options: bundle_options,
      },
    ];
  } else if (isArray(request.data)) {
    buyRequest = [...request.data];
  }

  return buyRequest;
};
export { useCart };
