import { MDBCol, MDBRow, MDBTypography } from "mdbreact";
import Price from "core/components/price";

const MiniCartSubtotal = ({ subtotal }) => {
  return (
    <MDBRow className={`minicart-bottom-wrapper`}>
      <MDBCol size="12">
        <MDBRow className={`minicart-subtotal pt-2 align-items-center`}>
          <MDBCol>
            <MDBTypography tag="h5" className={`montserrat-bold f13`}>
              Subtotal
            </MDBTypography>
          </MDBCol>
          <MDBCol className="d-flex justify-content-end align-self-end">
            <MDBTypography tag="p" className={`text-primary notoserif-bold`}>
              {
                <Price
                  price={typeof subtotal !== "undefined" ? subtotal : ""}
                  type={`fixed`}
                />
              }
            </MDBTypography>
          </MDBCol>
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default MiniCartSubtotal;
