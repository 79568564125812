import { useCallback, useState } from "react";
import Menu from "./menu";

const MenuStateContainer = (props) => {
  let { data } = props;

  let menu = data;
  const [position, setPosition] = useState(1);
  const [hoveredDropdown, setHoveredDropdown] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [collapseID, setCollapseID] = useState(false);
  const [hoveredDropdownId, setHoveredDropdownId] = useState(0);
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const [categoryIndex, setCategoryIndex] = useState(undefined);

  const openMobileDropdown = (index) => {
    if (categoryIndex === index) {
      if (showMobileDropdown === true) {
        setShowMobileDropdown(false);
      } else {
        setShowMobileDropdown(true);
      }
    } else {
      setCategoryIndex(index);
      setShowMobileDropdown(true);
    }
  };

  const setHoveredDropdownIdFn = useCallback((value) => {
    setHoveredDropdownId(value);
  }, []);
  const setHoveredDropdownFn = useCallback((value) => {
    setHoveredDropdown(value);
  }, []);
  const setShowDropdownFn = useCallback((value) => {
    setShowDropdown(value);
  }, []);
  const setCollapseIDFn = useCallback((value) => {
    setCollapseID(value);
  }, []);
  const setPositionFn = useCallback((value) => {
    setPosition(value);
  }, []);

  return (
    <Menu
      menu={menu}
      setMenu={() => {}}
      menuItems={menu}
      position={position}
      setPosition={setPositionFn}
      hoveredDropdown={hoveredDropdown}
      setHoveredDropdown={setHoveredDropdownFn}
      showDropdown={showDropdown}
      setShowDropdown={setShowDropdownFn}
      collapseID={collapseID}
      setCollapseID={setCollapseIDFn}
      openMobileDropdown={openMobileDropdown}
      showMobileDropdown={showMobileDropdown}
      setShowMobileDropdown={setShowMobileDropdown}
      categoryIndex={categoryIndex}
      setCategoryIndex={setCategoryIndex}
      hoveredDropdownId={hoveredDropdownId}
      setHoveredDropdownId={setHoveredDropdownIdFn}
    />
  );
};

export default MenuStateContainer;
