import { gql } from "graphql.macro";

export const PRODUCTS = gql`
  query Products(
    $filter: ProductAttributeFilterInput
    $pageSize: Int
    $sort: ProductAttributeSortInput
  ) {
    products(filter: $filter, pageSize: $pageSize, sort: $sort) {
      items {
        stock_status
        categories {
          uid
          page_layout
        }
        lifestyle_gallery
        uid
        id
        sku
        name
        related_collection_categories
        small_image {
          url
          label
        }
        image {
          url
          label
        }
        thumbnail {
          url
          label
        }
        url_rewrites {
          url
        }
        media_gallery {
          disabled
          label
          position
          url
          ... on ProductVideo {
            video_content {
              media_type
              video_provider
              video_url
              video_title
              video_description
              video_metadata
            }
          }
        }
        url_key
        ... on SimpleProduct {
          weight
        }
        description {
          html
        }
        related_products {
          name
          sku
          url_rewrites {
            parameters {
              name
              value
            }
            url
          }
          description {
            html
          }
          small_image {
            label
            url
          }
          thumbnail {
            label
            url
          }
        }
        ... on ConfigurableProduct {
          configurable_options {
            id
            attribute_id_v2
            label
            position
            use_default
            attribute_code
            values {
              value_index
              label
              swatch_data {
                value
              }
            }
            product_id
          }
          variants {
            product {
              id
              name
              sku
              stock_status
              msrp {
                value
                currency
              }
              small_image {
                url
                label
              }
              thumbnail {
                url
                label
              }
              media_gallery {
                disabled
                label
                position
                url
                ... on ProductVideo {
                  video_content {
                    media_type
                    video_provider
                    video_url
                    video_title
                    video_description
                    video_metadata
                  }
                }
              }
              attribute_set_id
              ... on PhysicalProductInterface {
                weight
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                }
              }
            }
            attributes {
              uid
              label
              code
              value_index
            }
          }
        }
      }
    }
  }
`;

export const PRODUCTS_CUSTOMERS = gql`
  query ProductsCustomers(
    $filter: ProductAttributeFilterInput
    $pageSize: Int
    $sort: ProductAttributeSortInput
  ) {
    products(filter: $filter, pageSize: $pageSize, sort: $sort) {
      items {
        stock_status
        categories {
          uid
          page_layout
        }
        lifestyle_gallery
        uid
        sku
        id
        name
        related_collection_categories
        url_rewrites {
          url
        }
        image {
          url
          label
        }
        small_image {
          url
          label
        }
        thumbnail {
          url
          label
        }
        media_gallery {
          disabled
          label
          position
          url
          ... on ProductVideo {
            video_content {
              media_type
              video_provider
              video_url
              video_title
              video_description
              video_metadata
            }
          }
        }
        url_key
        ... on SimpleProduct {
          weight
        }
        description {
          html
        }
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
        related_products {
          name
          sku
          url_rewrites {
            parameters {
              name
              value
            }
            url
          }
          description {
            html
          }
          small_image {
            label
            url
          }
          thumbnail {
            label
            url
          }
          price_range {
            minimum_price {
              discount {
                amount_off
                percent_off
              }
              final_price {
                currency
                value
              }
              regular_price {
                currency
                value
              }
            }
          }
        }
        ... on ConfigurableProduct {
          configurable_options {
            id
            attribute_id_v2
            label
            position
            use_default
            attribute_code
            values {
              value_index
              label
              swatch_data {
                value
              }
            }
            product_id
          }
          variants {
            product {
              id
              name
              sku
              msrp {
                value
                currency
              }
              small_image {
                url
                label
              }
              thumbnail {
                url
                label
              }
              media_gallery {
                disabled
                label
                position
                url
                ... on ProductVideo {
                  video_content {
                    media_type
                    video_provider
                    video_url
                    video_title
                    video_description
                    video_metadata
                  }
                }
              }
              stock_status
              attribute_set_id
              ... on PhysicalProductInterface {
                weight
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                }
              }
            }
            attributes {
              uid
              label
              code
              value_index
            }
          }
        }
      }
    }
  }
`;
