import customerActions from "../actions";
import messagesActions from "core/state/redux/data/messages/actions";
import commonActions from "../../common/actions";

export default async (store, action) => {
  try {
    store
      .dispatch(
        customerActions.getCustomerInformation(
          action.token,
          false,
          "",
          action.cartToken
        )
      )
      .then(() => {
        customerActions.getCartInformation(action.cartToken);
      });
    store.dispatch(commonActions.unlock());
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock());
    return;
  }
};
