import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import globalPages, {
  DefaultPage,
  ErrorPage,
  TemporaryPage,
} from "core/config/routing/pages/globalPages";
import customerPages from "core/config/routing/pages/customerPages";
import guestPages from "core/config/routing/pages/guestPages";
import DynamicRouter from "app/overrides/router/dynamic-content-router";
// import DynamicGraphQlWrapperQueryContainer from "app/overrides/dynamicGraphQlWrapper";
import queryLoader from "app/config/routing/loader";
import { isUndefined } from "core/helpers/functions";
import { loadState } from "core/state/redux/localStorage";
import { Suspense, lazy } from "react";
import useGenerated from "app/state/hooks/generatedHooks";
import ErrorBoundary from "app/pages/errorBoundry";
import Analytics from "react-router-ga";
import useStore from "app/state/hooks/storeHooks/useStore";

const DynamicGraphQlWrapperQueryContainer = lazy(() =>
  import(
    "app/overrides/dynamicGraphQlWrapper" /*webpackChunkName: "dynamicResolution"*/
  )
);
const CmsPageGenerated = lazy(() =>
  import("app/pages/cmsGenerated" /*webpackChunkName: "generatedCmsPage" */)
);

const checkRepeat = function (str) {
  var repeats = /(\/)\1/;
  return repeats.test(str);
};
const Router = (props) => {
  const { getStoreCode } = useStore();
  const store = getStoreCode();
  const { GetCmsPages } = useGenerated();
  const cmsPagesList = GetCmsPages();
  let globalProps = props;

  const history = useHistory();

  let state = loadState();
  if (history?.location?.pathname) {
    const thisPathname = history?.location?.pathname;

    let repeatedSlash = checkRepeat(thisPathname);
    if (repeatedSlash) {
      const explodedString = thisPathname.split(/\//g);
      let finalString = "";
      explodedString.map((x) => {
        if (x !== "") {
          finalString = finalString + "/" + x;
        }
        return x;
      });
      return <Redirect to={finalString} />;
    }
  }

  let generatedCmsPagesRoutes = cmsPagesList ? (
    Object.keys(cmsPagesList).map((key) => {
      let page = cmsPagesList[key];
      return (
        <Route
          key={key + "-cmspage-generated"}
          path={"/" + page.url_key}
          render={(props) => {
            return (
              <ErrorBoundary>
                <Suspense fallback={<TemporaryPage />}>
                  <CmsPageGenerated {...props} data={page} />
                </Suspense>
              </ErrorBoundary>
            );
          }}
        />
      );
    })
  ) : (
    <></>
  );

  const globalPagesRouters = Object.keys(globalPages).map((key) => {
    let Component = globalPages[key].component;
    return (
      <Route
        key={key}
        path={"/" + key}
        exact={globalPages[key].exact}
        render={(innerProps) => (
          <ErrorBoundary>
            <Suspense fallback={<div style={{ background: "#000" }}></div>}>
              <Component {...innerProps} variables={{ url: key }} />
            </Suspense>
          </ErrorBoundary>
        )}
      />
    );
  });

  const dynamicPagesRouter = (
    <Route
      path={"/:url"}
      render={(props) => {
        return (
          <ErrorBoundary>
            <Suspense fallback={<TemporaryPage />}>
              <DynamicGraphQlWrapperQueryContainer
                query={queryLoader("urlResolver")}
                queryIdentifier={"urlResolver"}
                variables={{ url: props.location.pathname.substr(1) }}
                loader={false}
                data={globalProps.data}
                loadingComponent={() => {
                  return (
                    <>
                      <TemporaryPage />
                    </>
                  );
                }}
              >
                <DynamicRouter
                  {...globalProps}
                  variables={{ url: props.location.pathname.substr(1) }}
                />
              </DynamicGraphQlWrapperQueryContainer>
            </Suspense>
          </ErrorBoundary>
        );
      }}
    />
  );

  if (
    !isUndefined(state) &&
    !isUndefined(state.customer) &&
    !isUndefined(state.customer.data) &&
    !isUndefined(state.customer.data.token) &&
    state.customer.data.token !== false
  ) {
    return (
      <Analytics
        id={`${store === "paragon" ? "UA-6778120-1" : "UA-6776878-1"}`}
        debug
      >
        <Switch>
          <Route key={"index"} path="/" exact component={DefaultPage} />
          {Object.keys(customerPages).map((key) => {
            let Component = customerPages[key].component;
            return (
              <Route
                key={key}
                path={"/" + key}
                exact={customerPages[key].exact}
                render={(innerProps) => (
                  <Suspense
                    fallback={<div style={{ background: "#000" }}></div>}
                  >
                    <Component {...innerProps} variables={{ url: key }} />
                  </Suspense>
                )}
              />
            );
          })}
          {globalPagesRouters}
          {generatedCmsPagesRoutes}
          {dynamicPagesRouter}
        </Switch>
      </Analytics>
    );
  } else {
    return (
      <Analytics
        id={`${store === "paragon" ? "UA-6778120-1" : "UA-6776878-1"}`}
        debug
      >
        <Switch>
          <Route key={"index"} path="/" exact component={DefaultPage} />
          {Object.keys(guestPages).map((key) => {
            let Component = guestPages[key].component;
            return (
              <Route
                key={key}
                path={"/" + key}
                exact={guestPages[key].exact}
                render={(innerProps) => (
                  <Suspense
                    fallback={<div style={{ background: "#000" }}></div>}
                  >
                    <Component {...innerProps} variables={{ url: key }} />
                  </Suspense>
                )}
              />
            );
          })}
          {globalPagesRouters}
          {generatedCmsPagesRoutes}
          {dynamicPagesRouter}
          {/* If you want to redirect all guests to specific url if page is not available or found */}
          {/* <Redirect key={"redirection"} to="/" component={DefaultPage} /> */}

          <Route key={"not-found"} component={ErrorPage} />
        </Switch>
      </Analytics>
    );
  }
};

export default Router;
