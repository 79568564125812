import { Fragment, memo } from "react";
import { MDBBox, MDBView, MDBMask } from "mdbreact";
import Image from "app/layout/image";

import DiscoverItemInformation from "./discoverItemInformation";
import DiscoveryPlaceholder from "app/assets/images/discovery-placeholder.png";

const DiscoverItem = ({
  category,
  isActive,
  activeCategory,
  onMouseEnter,
  onMouseLeave,
}) => {
  const webpImage =
    category.homepage_image === null
      ? false
      : process.env.REACT_APP_MAIN_STORE +
        category?.homepage_image?.replace(/.png|.jpg/g, ".webp");

  return category ? (
    <MDBBox
      className={`discover-item ${
        isActive ? (activeCategory === category.id ? "active" : "inactive") : ""
      }`}
      onMouseEnter={() => {
        onMouseEnter(category);
      }}
      onMouseLeave={() => {
        onMouseLeave();
      }}
    >
      <MDBView className={`discover-item-view`}>
        <Image
          webpImage={webpImage}
          source={
            category.homepage_image === null
              ? DiscoveryPlaceholder
              : process.env.REACT_APP_MAIN_STORE + category.homepage_image
          }
          alt={`discover-${category.name && category.name}`}
        />

        <MDBMask className={`discover-item-mask`}>
          <DiscoverItemInformation category={category} />
        </MDBMask>
      </MDBView>
    </MDBBox>
  ) : (
    <Fragment />
  );
};

export default memo(DiscoverItem);
