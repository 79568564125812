import { storeActions } from "app/state/redux/data/store";
import { useSelector, useDispatch } from "react-redux";

/**
 * useStore contains all functions for manipulating the store information
 */
const useStore = () => {
  const store = useSelector((state) => state.store);
  const dispatch = useDispatch();

  const getStore = () => {
    return { ...store };
  };

  const getStoreCode = () => {
    return store.store_code;
  };

  const getMediaUrl = () => {
    return store.base_media_url;
  };

  const setStore = async (payload) => {
    await dispatch(storeActions.setStore(payload)).then(() => {});

    return true;
  };

  return { store, getStore, setStore, getStoreCode, getMediaUrl };
};

export default useStore;
