import { MDBBox } from "mdbreact";
import Icon from "app/assets/icon/icon";

const QuotationComponent = ({ data }) => {
  return (
    <MDBBox className={`quotation-component`}>
      <MDBBox className={`quotes`}>
        <Icon icon={`quotes_icon`} />
      </MDBBox>
      {data}
    </MDBBox>
  );
};

export default QuotationComponent;
