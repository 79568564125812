import { MDBModal, MDBModalBody } from "mdbreact";
import Icon from "app/assets/icon/icon";
import ModalPopupGallery from "./modalContent";

const ModalGallery = ({
  productItem,
  popupGallery,
  setPopupGallery,
  activeImage,
  setActiveImage,
  image_gallery,
  activeImageIndex,
  setActiveImageIndex,
}) => {
  return (
    <MDBModal
      className={`popup-gallery-modal`}
      isOpen={popupGallery}
      toggle={() => setPopupGallery(!popupGallery)}
      centered
      id={`product-gallery-popup`}
    >
      <div className={`icon-container`}>
        <Icon
          icon="closeIconWhite"
          onClick={() => setPopupGallery(!popupGallery)}
        />
      </div>
      <MDBModalBody
        onClick={(e) => {
          setPopupGallery(!popupGallery);
        }}
      >
        <ModalPopupGallery
          productItem={productItem}
          popupGallery={popupGallery}
          setPopupGallery={setPopupGallery}
          activeImage={activeImage}
          setActiveImage={setActiveImage}
          image_gallery={image_gallery}
          activeImageIndex={activeImageIndex}
          setActiveImageIndex={setActiveImageIndex}
        />
      </MDBModalBody>
    </MDBModal>
  );
};

export default ModalGallery;
