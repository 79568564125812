import Homepage from "app/pages/home";
import Information from "app/pages/customer/account/pages/information";
import Addressbook from "app/pages/customer/account/pages/addressbook";
import Newsletter from "app/pages/customer/account/pages/newsletter";
import { lazy } from "react";
import logout from "app/pages/customer/logout";
// import { ImageDownloaderPage } from "core/components/imageDownloader";

const Dashboard = lazy(() =>
  import(
    "app/pages/customer/account/pages/dashboard" /*webpackChunkName: "customer"*/
  )
);

const AddNewShippingAddress = lazy(() =>
  import(
    "app/pages/customer/account/pages/addressbook/shippingAddressesActions/addNewShippingAddress" /*webpackChunkName: "customer"*/
  )
);
const EditShippingAddress = lazy(() =>
  import(
    "app/pages/customer/account/pages/addressbook/shippingAddressesActions/editShippingAddress" /*webpackChunkName: "customer"*/
  )
);
const BillingAddressForm = lazy(() =>
  import(
    "app/pages/customer/account/pages/addressbook/defaultAddresses/defaultBillingAddress/billingAddressForm" /*webpackChunkName: "customer"*/
  )
);

const Favorites = lazy(() =>
  import(
    "app/pages/customer/account/pages/wishlistCore" /*webpackChunkName: "customer"*/
  )
);

const CustomerPages = [
  {
    url: "customer",
    component: Dashboard,
    exact: true,
    inSidebar: true,
    dropdownText: "my account",
    menuText: "My Account",
    inDropdown: true,
  },
  {
    url: "customer/information",
    component: Information,
    exact: true,
    inSidebar: true,
    dropdownText: "information",
    menuText: "Account Information",
    inDropdown: false,
    separated: true,
  },
  {
    url: "customer/addresses",
    component: Addressbook,
    exact: true,
    inSidebar: true,
    menuText: "Address Book",
    dropdownText: "Address Book",
    inDropdown: false,
    children: [
      "customer/addresses/edit",
      "customer/addresses/additional-address",
    ],
    separated: true,
  },
  {
    url: "customer/addresses/edit",
    component: BillingAddressForm,
    exact: false,
    inSidebar: false,
    menuText: "Address Book",
    inDropdown: false,
  },
  {
    url: "customer/addresses/additional-address",
    component: AddNewShippingAddress,
    exact: true,
    inSidebar: false,
    menuText: "Address Book",
    inDropdown: false,
  },

  {
    url: "customer/favorites",
    component: Favorites,
    separated: false,
    exact: true,
    inSidebar: true,
    dropdownText: "My Favorites",
    menuText: "My Favorites",
    inDropdown: true,
    disabled: false,
    showSidebar: false,
  },
  {
    url: "customer/projects",
    component: Favorites,
    separated: false,
    exact: true,
    inSidebar: true,
    dropdownText: "My Projects",
    menuText: "My Projects",
    inDropdown: true,
    disabled: false,
    showSidebar: false,
  },
  {
    url: "customer/newsletter",
    component: Newsletter,
    exact: true,
    inSidebar: true,
    menuText: "Newsletter",
    inDropdown: false,
  },
  // {
  //   url: "customer/downloads",
  //   component: Favorites,
  //   separated: true,
  //   exact: true,
  //   inSidebar: false,
  //   dropdownText: "my downloads",
  //   menuText: "my downloads",
  //   inDropdown: false,
  //   disabled: false,
  //   showSidebar: false,
  // },
  {
    url: "logout",
    component: logout,
    separated: true,
    exact: true,
    inSidebar: false,
    dropdownText: "sign out",
    menuText: "sign out",
    inDropdown: true,
    function: "logoutCustomer",
  },
];

export default CustomerPages;

export const pagesConfig = {
  sidebarTitle: "my account",
  mainTitle: "dashboard",
};

export { default as ErrorPage } from "app/pages/error";

export const DefaultPage = Homepage;
