import Discover from "./discover";
import useGenerated from "app/state/hooks/generatedHooks";
import useStore from "app/state/hooks/storeHooks/useStore";
import { findCategoryByAttribute } from "core/helpers/category";

const DiscoverQueryContainer = () => {
  const { getStore } = useStore();
  const { GetCategories } = useGenerated();
  let data = GetCategories();

  const discoverCategories = getStore()?.discover;

  if (discoverCategories === undefined) return <></>;
  let discoverCategoriesData = discoverCategories.map((category) =>
    findCategoryByAttribute(category.id, data.children, "id")
  );

  //TO DO: replace dummy with data from storeConfig generated
  if (discoverCategoriesData && discoverCategoriesData.length > 0) {
    return <Discover discoverCategories={discoverCategoriesData} />;
  }
  return <></>;
};

export default DiscoverQueryContainer;
