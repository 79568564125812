import { MDBContainer } from "mdbreact";
import useStore from "app/state/hooks/storeHooks/useStore";

const BannerImageForCmsBlocks = ({ data }) => {
  const { getStoreCode } = useStore();
  const store = getStoreCode();
  return (
    <MDBContainer fluid className={`banner-image ${store + "-website"}`}>
      <MDBContainer className={`page-full-width`}>
        <MDBContainer className={`inner-content px-0`}>
          <div className={`image-overlay`}></div>
          {data}
        </MDBContainer>
      </MDBContainer>
    </MDBContainer>
  );
};

export default BannerImageForCmsBlocks;
