const { useQuery } = require("react-apollo-hooks");
const { GET_PRODUCT_IMAGES } = require("../queries");

const useGetProductImagesByFilter = (filter) => {
  let formattedData = undefined;
  const { data, loading, error, refetch } = useQuery(GET_PRODUCT_IMAGES, {
    variables: { filter: filter },
    fetchPolicy: "cache-and-network",
  });

  if (data !== undefined) {
    formattedData =
      typeof data.products !== "undefined" &&
      typeof data.products.items[0] !== "undefined"
        ? data.products.items[0].media_gallery
        : undefined;
  }

  return { data, formattedData, loading, error, refetch };
};

export default useGetProductImagesByFilter;
