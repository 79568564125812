import { useState } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Icon from "app/assets/icon/icon";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import CompareItem from "./compareItem";

const CompareSidebar = ({
  toggleCompareMenu,
  compareMenu,
  data,
  removeCompareMethod,
}) => {
  const { isLoggedIn, getCustomer } = useCustomer();
  const loggedIn = !!isLoggedIn();
  const customer = getCustomer();
  return (
    <div className={`compare-sidebar ${compareMenu ? "open" : "closed"}`}>
      {/* <div className="arrow-part" onClick={toggleCompareMenu}>
        <Icon icon={`${compareMenu ? "arrowDownBlue" : "arrowUpBlue"}`} />
      </div> */}
      <div className="bottom-part">
        <div className="inner d-flex align-items-center">
          <p className="mr-3">
            <b>Compare List</b> (
            {`${data?.length} item${data?.length > 1 ? "s" : ""}`})
          </p>
          <Icon
            icon={`${compareMenu ? "arrowDownBlue" : "arrowUpBlue"}`}
            onClick={toggleCompareMenu}
            className="cursor-pointer"
          />
        </div>
      </div>
      <MDBContainer className={`mt-50 ${data?.length > 3 ? "scroll-bar" : ""}`}>
        <MDBRow>
          {data.map((item) => (
            <MDBCol lg="4" md="6" key={item.product.id}>
              <CompareItem
                productItem={item}
                loggedIn={loggedIn}
                customer={customer}
                removeCompareMethod={removeCompareMethod}
              />
            </MDBCol>
          ))}
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

export default CompareSidebar;
