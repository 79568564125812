import { MDBContainer, MDBBox, MDBRow, MDBCol } from "mdbreact";
import Icon from "app/assets/icon/icon";

const OfferItemComponent = ({ data }) => {
  let elementName = data?.props?.children?.find((item) => {
    return item.type === "h5";
  });
  elementName = elementName.props?.children[0].toLowerCase();
  let icon = elementName.includes("design")
    ? "contract_design"
    : elementName.includes("components")
    ? "contract_components"
    : elementName.includes("mirrors")
    ? "contract_mirrors"
    : elementName.includes("volume capability")
    ? "contract_volume"
    : elementName.includes("customer service")
    ? "contract_customer_service"
    : "";

  return (
    <MDBContainer fluid className={`offer-item-component px-0`}>
      <MDBRow className={`offer-item-inner w-100`}>
        <MDBCol lg={`6`} className={`offer-item `}>
          <MDBBox className={`icon-container`}>
            <Icon icon={icon} />
          </MDBBox>
          {data}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default OfferItemComponent;
