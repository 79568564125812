import {
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBTypography,
  MDBBox,
  MDBBtn,
} from "mdbreact";
import ReCAPTCHA from "react-google-recaptcha";
import InputField from "core/components/inputField";
import { shouldMarkError } from "../validation";

const FirstStep = ({
  errors,
  touched,
  setTouched,
  email,
  setEmailFn,
  forgotPassword,
  loading,
  setLoading,
  recaptchaRef,
  siteKeyVal,
  onChangeRecaptcha,
  recaptchaValue,
}) => {
  return (
    <>
      <div className="forgot-password">
        <MDBContainer fluid>
          <MDBRow className={`mb-90`}>
            <MDBBox className="title-with-background w-100">
              <MDBContainer>
                <MDBTypography tag="h1" className="page-title">
                  Forgot Your Password
                </MDBTypography>
              </MDBContainer>
            </MDBBox>
          </MDBRow>
        </MDBContainer>
        <MDBContainer className={"page-main-content forgot-password first"}>
          <MDBRow className="d-flex justify-content-center">
            <MDBCol sm="8" xl="6" className="text-center">
              <p className="mb-5">
                Please enter your email address below to receive a password
                reset.
              </p>
            </MDBCol>
          </MDBRow>
          <MDBRow className="input-btn-content">
            <MDBCol sm="12" className="input-field-content">
              <MDBRow>
                <MDBCol>
                  <MDBBox className={`input-container`}>
                    <InputField
                      hint="Email"
                      placeholder={"Required"}
                      className={`custom-input ${
                        shouldMarkError("email", errors, touched)
                          ? "validation-error"
                          : ""
                      }`}
                      // label={`Email *`}
                      onBlur={(e) => {
                        setTouched({ ...touched, email: true });
                      }}
                      group
                      type="email"
                      validate
                      error="wrong"
                      success="right"
                      value={email !== undefined ? email : ""}
                      onChange={setEmailFn}
                    />
                    <MDBTypography tag="p" className={`error-label`}>
                      {shouldMarkError("email", errors, touched)
                        ? email.length === 0
                          ? "Email is required *"
                          : "Email is invalid *"
                        : ""}
                    </MDBTypography>
                  </MDBBox>
                  <MDBContainer
                    className={`d-flex justify-content-center recaptcha-btn-wrapper p-0 mt-5`}
                  >
                    <div className="recaptcha">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={siteKeyVal}
                        onChange={onChangeRecaptcha}
                      />
                      {recaptchaValue === null && (
                        <div className={"recaptcha-error"}>
                          reCAPTCHA is required *
                        </div>
                      )}
                    </div>
                  </MDBContainer>
                  <div className="clickable-button">
                    <MDBBtn
                      color={"primary"}
                      disabled={loading || recaptchaValue === null}
                      outline
                      className={"btn-with-icon btn-black-text"}
                      onClick={(e) => {
                        setLoading(true);
                        forgotPassword(email);
                      }}
                      children={
                        <>
                          <MDBBox tag="span">{`${
                            loading ? "Sending..." : "Reset My Password"
                          }`}</MDBBox>
                          <MDBBox className="circle-icon" />
                        </>
                      }
                    />
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
      <MDBRow className={"w-100 spacer"}></MDBRow>
    </>
  );
};

export default FirstStep;
