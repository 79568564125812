import types from "./types";

const setStore = (payload) => {
  return {
    type: types.SET_STORE,
    payload: payload,
  };
};

const allActions = { setStore };

export default allActions;
