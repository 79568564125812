import { MDBCol, MDBView, MDBMask, MDBBox } from "mdbreact";
import Image from "app/layout/image";
import DiscoveryPlaceholder from "app/assets/images/discovery-placeholder.png";

const DiscoverItemMobile = ({ category, store }) => {
  const webpImage =
    category.homepage_image === null
      ? false
      : category?.homepage_image?.replace(/.png|.jpg/g, ".webp");
  return category?.url_path ? (
    <MDBCol className={`discover-item-mobile px-0`}>
      <MDBView className={`discover-item-view`}>
        <Image
          wrapperClass={`imageloader`}
          webpImage={webpImage}
          source={
            category.homepage_image === null
              ? DiscoveryPlaceholder
              : category.homepage_image
          }
          alt={`discover-${category.name && category.name}`}
        />

        <MDBMask className={`discover-item-mask`}>
          <MDBBox className={`discover-item-mobile-text ${store + "-website"}`}>
            <h5 className={`category-name`}>{category.name}</h5>
            <p className={`category-product-count`}>
              {category.product_count} Products
            </p>
          </MDBBox>
        </MDBMask>
      </MDBView>
    </MDBCol>
  ) : (
    <></>
  );
};

export default DiscoverItemMobile;
