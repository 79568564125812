
import lodash from "lodash";
import React, { memo } from "react";
import ImageLoader from "react-load-image";
// import ReactLoader from "app/layout/loader";
import Skeleton from "react-loading-skeleton";
import ImageContainer from "./imageContainer";

// function Preloader(props) {
//   return (
//     <div style={{ width: "100%" }}>
//       <ReactLoader />
//     </div>
//   );
// }

const Image = memo(
  (props) => {
    let source = "";
    if (typeof props.source === "undefined") return "";
    if (!lodash.isUndefined(props.backend) && props.backend === true) {
      if (props.backend_public) {
        source =
          process.env.REACT_APP_MAIN_STORE +
          '/pub/media' +
          props.source;
      } else {
        source = process.env.REACT_APP_MAIN_STORE + props.source;
      }
    } else {
      source = props?.source?.includes("http") ? props.source : props.source;
    }

    return (
      <ImageContainer {...props}>
        {/* <img
          src={source}
          style={props.style}
          alt={props.image_alt}
          loading={"lazy"}
          className={props.className}
          effect="blur"
          style={{ width: "100%" }}
          placeholderSrc={props.placeholderimage}
        /> */}
        <ImageLoader src={source} className={props.className}>
          <img className={props.className} alt={""} />
          <div>Error!</div>
          <Skeleton
            className={`skeleton-img ${
              typeof props.attributes !== "undefined" &&
              typeof props.attributes[`data-skeleton-class`] !== "undefined"
                ? props.attributes[`data-skeleton-class`]
                : ""
            }`}
            width={`100%`}
            height={`auto`}
          />
        </ImageLoader>
      </ImageContainer>
    );
  },
  (prevProps, nextProps) => {
    if (lodash.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default Image;
