import { MDBCol } from "mdbreact";
import React from "react";
import { default as ReactLoadingSkeleton } from "react-loading-skeleton";

const Skeleton = ({ columnProps }) => {
  return (
    <>
      <MDBCol {...columnProps}>
        <ReactLoadingSkeleton height={`14.3rem`} width={`14.3rem`} />
      </MDBCol>
      <MDBCol {...columnProps}>
        <ReactLoadingSkeleton height={`14.3rem`} width={`14.3rem`} />
      </MDBCol>
      <MDBCol {...columnProps}>
        <ReactLoadingSkeleton height={`14.3rem`} width={`14.3rem`} />
      </MDBCol>
    </>
  );
};

export default Skeleton;
