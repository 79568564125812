import { MDBRow } from "mdbreact";
import CategoriesList from "../dropdownLayoutComponents/categoriesList";

const SimpleDropdownLayout = (props) => {
  let {
    activeMenuItems,
    activeCategory,
    setActiveCategory,
    setShowDropdown,
    store,
  } = props;

  return (
    <MDBRow className="w-100 mx-0">
      <CategoriesList
        complexMenuLayout={false}
        activeMenuItems={activeMenuItems}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        setShowDropdown={setShowDropdown}
        store={store}
      />
    </MDBRow>
  );
};

export default SimpleDropdownLayout;
