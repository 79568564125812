import useApplication from "app/state/hooks/applicationHooks/useApplication";
import { useEffect } from "react";

const OfflineBar = () => {
  const { getOnlineStatus, setIsOnline } = useApplication();

  /**
   * We use this effect to set real browser network status on initial render
   */
  useEffect(() => {
    setIsOnline();
  }, []);

  useEffect(function addOnlineListener() {
    const setOnlineTrue = () => {
      setIsOnline(true);
    };
    window.addEventListener("online", setOnlineTrue);
    return () => {
      window.removeEventListener("online", setOnlineTrue);
    };
  }, []);

  useEffect(function addOfflineListener() {
    const setOnlineFalse = () => {
      setIsOnline(false);
    };
    window.addEventListener("offline", setOnlineFalse);
    return () => {
      window.removeEventListener("offline", setOnlineFalse);
    };
  }, []);

  const isOnline = getOnlineStatus();
  return !isOnline ? (
    <div
      id={`offline-notice`}
      fluid
      className={`container-fluid bg-primary text-white text-uppercase text-center p-1 position-fixed z-modal`}
    >
      You are currently browsing in offline mode
    </div>
  ) : (
    <></>
  );
};

export default OfflineBar;
