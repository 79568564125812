import apolloClient from "core/graphql/apolloClient";
const { ADD_FILES_FOR_DOWNLOAD } = require("../queries");

const useFilesForDownload = () => {
  /**
   *
   * @param {[*]} files Array of files that contain the following (file_url (Url to the file), group_id (SKU or unique identifier), is_primary (1 or 0), remove_flag (YES or NO), type (PRODUCT or LIFESTYLE)
   */
  const addFilesForDownload = async (files) => {
    try {
      const { data, error } = await apolloClient.mutate({
        mutation: ADD_FILES_FOR_DOWNLOAD,
        variables: { input: files },
      });
      if (error) {
        throw new Error(error);
      }
      if (data) {
        return data;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  return { addFilesForDownload };
};

export default useFilesForDownload;
