import { MDBCol, MDBTypography, MDBBox } from "mdbreact";
import { Controller } from "react-hook-form";
import InputField from "core/components/inputField/inputField";
import Select from "app/layout/select";
import useValidationPatterns from "app/state/hooks/validationPatternHooks/useValidationPatterns";

const LeftSide = ({
  control,
  trigger,
  errors,
  typesOfInquiry,
  industries,
  customer,
}) => {
  const { emailPattern } = useValidationPatterns();
  return (
    <MDBCol lg={`6`} className={`text-left`}>
      <Controller
        control={control}
        name="type_of_inquiry"
        defaultValue=""
        rules={{
          required: true,
        }}
        render={({ onChange, value, name, ref }) => (
          <MDBBox className={`input-container`}>
            <Select
              onChange={onChange}
              // onBlur={() => trigger(name)}
              innerRef={ref}
              name={name}
              placeholder={`Required`}
              options={typesOfInquiry}
              value={value}
              isSearchable={false}
              className={`custom-input ${
                errors[name] ? "validation-error" : ""
              }`}
              label={`Type of Inquiry *`}
              filled={value && value.length !== 0 ? true : false}
            />
            <MDBTypography tag="p" className={`error-label`}>
              {`${errors[name] ? "Please select one of the options." : ""}`}
            </MDBTypography>
          </MDBBox>
        )}
      />

      <Controller
        control={control}
        name="industry"
        defaultValue=""
        rules={{
          required: true,
        }}
        render={({ onChange, value, name, ref }) => (
          <MDBBox className={`input-container`}>
            <Select
              onChange={onChange}
              // onBlur={() => trigger(name)}
              innerRef={ref}
              isSearchable={false}
              name={name}
              placeholder={`Required`}
              options={industries}
              value={value}
              className={`custom-input ${
                errors[name] ? "validation-error" : ""
              }`}
              label={`Industry *`}
              filled={value && value.length !== 0 ? true : false}
            />
            <MDBTypography tag="p" className={`error-label`}>
              {`${errors[name] ? "Please select one of the options." : ""}`}
            </MDBTypography>
          </MDBBox>
        )}
      />

      <Controller
        control={control}
        name="firstname"
        defaultValue=""
        rules={{ required: true }}
        render={({ onChange, value, name, ref }) => (
          <MDBBox className={`input-container`}>
            <InputField
              // onBlur={() => trigger(name)}
              innerRef={ref}
              placeholder={"Required"}
              name={name}
              value={value}
              onChange={onChange}
              className={`custom-input ${
                errors[name] ? "validation-error" : ""
              }`}
              label={`First Name *`}
              filled={value && value.length !== 0 ? true : false}
            />
            <MDBTypography tag="p" className={`error-label`}>
              {`${errors[name] ? "First Name is required *" : ""}`}
            </MDBTypography>
          </MDBBox>
        )}
      />
      <Controller
        control={control}
        name="lastname"
        defaultValue=""
        rules={{ required: true }}
        render={({ onChange, value, name, ref }) => (
          <MDBBox className={`input-container`}>
            <InputField
              // onBlur={() => trigger(name)}
              innerRef={ref}
              placeholder={"Required"}
              name={name}
              value={value}
              onChange={onChange}
              className={`custom-input ${
                errors[name] ? "validation-error" : ""
              }`}
              label={`Last Name *`}
              filled={value && value.length !== 0 ? true : false}
            />
            <MDBTypography tag="p" className={`error-label`}>
              {`${errors[name] ? "Last Name is required *" : ""}`}
            </MDBTypography>
          </MDBBox>
        )}
      />
      <Controller
        control={control}
        name="email"
        defaultValue={customer?.email ? customer.email : ""}
        rules={{
          required: true,
          pattern: emailPattern(),
        }}
        render={({ onChange, value, name, ref }) => (
          <MDBBox className={`input-container`}>
            <InputField
              // onBlur={() => trigger(name)}
              innerRef={ref}
              placeholder={"Required"}
              name={name}
              value={value}
              onChange={onChange}
              className={`custom-input ${
                errors[name] ? "validation-error" : ""
              }`}
              label={`Email Address *`}
              filled={value && value.length !== 0 ? true : false}
            />
            <MDBTypography tag="p" className={`error-label`}>
              {`${
                errors[name]
                  ? errors[name] && errors[name].type === "pattern"
                    ? "Email Address is invalid *"
                    : "Email Address is required *"
                  : ""
              }`}
            </MDBTypography>
          </MDBBox>
        )}
      />
    </MDBCol>
  );
};

export default LeftSide;
