export default (state, action) => {
  if (action.payload === null) {
    return state;
  }
  let filteredOutList = state.data.wishlistItems.filter(
    (wishlist) => wishlist.id !== action.payload
  );

  return Object.assign({}, state, {
    ...state,
    data: {
      ...state.data,
      wishlistItems: [...filteredOutList],
    },
  });
};
