import { useState } from "react";
import MaskJson from "./mask.json";
import countries from "config/data/countries";
import { getCountries, getRegionData } from "config/data/dataMapping";
import { filterByCountryId } from "core/helpers/customer";
/*
Pattern Hook for validating form input
*/

const useValidationPatterns = () => {
  let countriesData = [];
  let regionsData = [];
  const _countries = countries.countries;
  const [preselectCode, setPreselectCode] = useState("US");
  const [selectedCountry, setSelectedCountry] = useState(
    filterByCountryId(preselectCode, countriesData)
  );
  const [selectedRegion, setSelectedRegion] = useState("");
  const emailPattern = () => {
    let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern;
  };

  const zipCodeMask = (selectedCountry) => {
    let mask = false;

    if (selectedCountry === "US") {
      mask = [/\d/, /\d/, /\d/, /\d/, /\d/];
    }
    if (selectedCountry === "CA") {
      mask = [/[A-Z]/i, /\d/, /[A-Z]/i, " ", /\d/, /[A-Z]/i, /\d/];
    }
    return mask;
  };

  const websiteUrlPattern = () => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return pattern;
  };

  const fullNamePattern = () => {
    var pattern = new RegExp("^[A-Z][a-zA-Z]{2,}(?: [A-Z][a-zA-Z]*){0,2}$");
    return pattern;
  };

  const findMaskByCountry = (country) => {
    if (country === "CA" || country === "US") {
      return MaskJson.filter((mask) => {
        return mask.cc === country || mask.cc.includes(country);
      })[0];
    }
    return false;
  };

  const generateSanitizedMaskArray = (country) => {
    let object = findMaskByCountry(country);
    object = object.mask ? object.mask.split("") : false;

    if (!object) return object;

    return Object.keys(object).map((key) => {
      let regexChar = object[key];
      if (object[key] === "+") regexChar = "+";
      if (object[key] === "#") regexChar = /\d/;
      return regexChar;
    });
  };

  const getCountriesArray = () => {
    let countryDataObject = getCountries(_countries);
    countriesData = Object.keys(countryDataObject).map((key) => {
      return countryDataObject[key];
    });
    return countriesData;
  };

  const getRegionsArray = (preselectedCountry) => {
    if (preselectedCountry && preselectedCountry.value) {
      regionsData = getRegionData(countriesData, preselectedCountry);
    } else {
      if (selectedCountry && selectedCountry.value) {
        regionsData = getRegionData(countriesData, selectedCountry);
      }
    }
    regionsData = regionsData.filter((item) => {
      return item !== null;
    });
    return regionsData;
  };

  const getSelectedCountry = () => {
    return selectedCountry;
  };

  const getSelectedRegion = () => {
    return selectedRegion;
  };
  const setRegion = (region) => {
    return setSelectedRegion(region);
  };

  const setCountry = (code) => {
    let country = filterByCountryId(code, countriesData);
    setSelectedCountry(country);
    regionsData = getRegionsArray();
    setRegion("");
    return country;
  };

  return {
    emailPattern,
    zipCodeMask,
    websiteUrlPattern,
    fullNamePattern,
    findMaskByCountry,
    generateSanitizedMaskArray,
    getCountriesArray,
    getRegionsArray,
    setCountry,
    getSelectedCountry,
    getSelectedRegion,
    setRegion,
  };
};

export default useValidationPatterns;
