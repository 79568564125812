import { Suspense, useState } from "react";
import { MDBBox } from "mdbreact";
import Icon from "app/assets/icon/icon";
import { lazy } from "react";
import "react-datepicker/dist/react-datepicker.css";

const DatePicker = lazy(() =>
  import("react-datepicker" /*webpackChunkName: "datepicker"*/)
);

const CustomDatePicker = ({
  startDate,
  setStartDate,
  placeholder,
  disabled,
  onClickHandler,
  minDate,
  maxDate,
}) => {
  const [openCalendar, setOpenCalendar] = useState(false);

  return (
    <MDBBox className={`custom-date-picker ${startDate === null && "error"}`}>
      <Suspense fallback={<></>}>
        <DatePicker
          placeholderText={placeholder}
          selected={startDate ? new Date(startDate) : startDate}
          onChange={(date) => {
            setStartDate(date);
            onClickHandler(date);
          }}
          disabled={disabled}
          open={openCalendar}
          onFocus={() => setOpenCalendar(!openCalendar)}
          minDate={minDate}
          maxDate={maxDate}
        />
      </Suspense>

      <MDBBox
        className={`date-picker-icon ${disabled && "disabled"} `}
        onClick={() => {
          setOpenCalendar(!openCalendar);
        }}
      >
        <Icon icon={`calendar`} />
      </MDBBox>
    </MDBBox>
  );
};

export default CustomDatePicker;
