import { connect } from "react-redux";
import CmsPageQueryContainer from "./cms-query-container";
import { default as queriesActions } from "app/state/redux/data/queries/actions";
import { customerIsLoggedIn } from "core/helpers/customer";
import useCache from "app/state/hooks/cacheHooks/useCache";

const CmsPageReduxContainer = (props) => {
  const { getCacheEntry } = useCache();
  let token = false;
  let data = false;
  let isCached =
    typeof props.variables !== "undefined"
      ? getCacheEntry(props.variables.url)
      : false;
  // isCached = isQueryCached(props);
  token = customerIsLoggedIn(props.customer);
  let loading = !(typeof props.data !== "undefined" && !props.data[props.id]);

  if (
    typeof props.data !== "undefined" &&
    typeof props.data[props.id] !== "undefined"
  )
    data = props.data[props.id].data;
  // if(typeof props.isCached !== "undefined" && typeof props.isCached.urlResolver !== 'undefined' && typeof props.isCached.urlResolver[props]) isCached = props.isCached.urlResolver[props.url];

  if (data || isCached) loading = false;
  if (isCached) data = isCached;

  return (
    <CmsPageQueryContainer
      {...props}
      token={token}
      loading={loading}
      data={data}
      isCached={isCached}
    />
  );
};

const mapStateToProps = (state) => ({
  thisState: state.customer,
  customer: state.customer.customer,
  modal: state.customer.modal,
  timestamp: state.common.timestamp,
  data: state.queries.data,
  isCached: state.queries,
});

const mapDispatchToProps = (dispatch) => {
  return {
    addQueryData: (data, identifier, key) => {
      dispatch(queriesActions.addQueryData(data, identifier, key));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CmsPageReduxContainer);
