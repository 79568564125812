import { useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideAlerter = (ref, displaySearch, setDisplaySearch) => {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (
      ref.current &&
      !ref.current?.contains(event.target) &&
      event.target.tagName !== "svg" &&
      event.target.parentElement?.id !== "search-text" &&
      event.target.tagName !== "path"
    ) {
      if (displaySearch) {
        setDisplaySearch(!displaySearch);
      }
    }
  }
  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};
