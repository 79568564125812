/**
 * Persisting state is very important in any PWA application that strives to have offline capabilities.
 * To achieve this, we are going to implement a subscribe on the instance of the store.
 * We are using lodash.throttle to "throttle" the data every second or so (depending on the configuration below)
 */

import _ from "lodash";
import {
  getCustomerState,
  getQueriesState,
  getCacheState,
  getSearchState,
  getProductState,
  getCompareState,
  getWishlistState,
} from "./initialStates";
import { saveState } from "./localStorage";
import { generateAppPersistedState } from "app/state/redux/persistedState";

export function allStates(store = {}) {
  let storeState =
    typeof store === "object" && typeof store.getState === "function"
      ? store.getState()
      : {};

  return {
    customer: getCustomerState(storeState),
    queries: getQueriesState(storeState),
    cache: getCacheState(storeState),
    search: getSearchState(storeState),
    product: getProductState(storeState),
    compare: getCompareState(storeState),
    wishlist: getWishlistState(storeState),
    ...generateAppPersistedState(storeState),
  };
}

const subscribeDataPersistor = (store) => {
  store.subscribe(() => {
    let storeState = store.getState();
    let allStatesData = allStates(store);
    saveState({
      ...allStatesData,
      ...generateAppPersistedState(storeState),
    });
  });
};

export default subscribeDataPersistor;
