import apolloClient from "app/graphql/apolloClient";
import customerActions from "../actions";
import commonActions from "../../common/actions";
import queryActions from "core/state/redux/data/queries/actions";
import queryLoader from "app/graphql/queryLoader";
import messagesActions from "core/state/redux/data/messages/actions";
// import messagesActionTypes from "core/state/redux/data/messages/types";
// import { pendingTask, end } from "react-redux-spinner";

// const ADD_MESSAGE = messagesActionTypes.ADD_MESSAGE;

export default async (store, action) => {
  if (!action.token || action.token === "") {
    store.dispatch(
      messagesActions.addMessage(
        "You are missing the reset password token. Please send another reset password request",
        "danger"
      )
    );
    store.dispatch(commonActions.unlock());
    return;
  }

  if (
    !action.password ||
    action.password === "" ||
    !action.confirmPassword ||
    action.confirmPassword === "" ||
    action.password !== action.confirmPassword
  ) {
    store.dispatch(
      messagesActions.addMessage("Please confirm your password.", "danger")
    );
    store.dispatch(commonActions.unlock());
    return;
  }

  try {
    let request = {
      email: action.email,
      resetPasswordToken: action.token,
      newPassword: action.password,
      input: {
        token: action.token,
        password: action.password,
        password_confirm: action.confirmPassword,
      },
    };

    console.log(request);
    const { data } = await apolloClient.mutate({
      mutation: queryLoader("resetCustomerPassword"),
      variables: request,
    });
    if (data.resetPassword.code !== "error") {
      // dispatch error codes from graphql
      store.dispatch(customerActions.resetCustomerPasswordSuccess(true));
      store.dispatch(
        messagesActions.addMessage(
          data.resetPassword.message,
          data.resetPassword.code
        )
      );
      store.dispatch(commonActions.unlock("success"));
    } else {
      throw Error(data.resetPassword.message);
    }
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock("fail"));
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
