import apolloClient from "app/graphql/apolloClient";
import queryLoader from "app/graphql/queryLoader";
import messagesActions from "core/state/redux/data/messages/actions.js";
import commonActions from "core/state/redux/data/common/actions";

const NEWSLETTER_CUSTOMER_SUBSCRIBE = "newsletterCustomerSubscribe";

export default async (store, action) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: queryLoader(NEWSLETTER_CUSTOMER_SUBSCRIBE),
      variables: {
        email: action.email,
        first_name: action.first_name,
        last_name: action.first_name,
        what_describes_you: action.what_describes_you,
        company: action.company,
        state: action.state,
        country: action.country,
        zip: action.zip,
      },
    });

    if (data.subscriber) {
      store.dispatch(
        messagesActions.addMessage(
          "you have succesfully subscribed.",
          "success"
        )
      );
    }
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock());
    return;
  }
};
