import { applicationActions } from "app/state/redux/data/application";
import { useDispatch, useSelector } from "react-redux";

const useApplication = () => {
  const application = useSelector((state) => state.application);
  const dispatch = useDispatch();

  const getOnlineStatus = () => {
    return application?.online;
  };

  const setIsOnline = () => {
    const status = window.navigator.onLine;
    dispatch(applicationActions.setIsOnline(status));
  };

  return { getOnlineStatus, setIsOnline };
};

export default useApplication;
