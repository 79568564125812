import { useState } from "react";
import { MDBCol, MDBBox, MDBTypography } from "mdbreact";
import { Controller } from "react-hook-form";
import MaskedInput from "react-text-mask";
import InputField from "core/components/inputField";
import useValidationPatterns from "app/state/hooks/validationPatternHooks/useValidationPatterns";
import CustomDatePicker from "app/layout/datePicker";

const RightSide = ({ control, trigger, errors, setShipDate, shipDate }) => {
  const { getSelectedCountry, generateSanitizedMaskArray } =
    useValidationPatterns();
  let selectedCountry = getSelectedCountry();
  let countryForMask = selectedCountry
    ? selectedCountry.value
      ? selectedCountry.value
      : selectedCountry
    : "US";

  const maskSanitizedArray = generateSanitizedMaskArray(countryForMask);
  const todaysDate = new Date();
  const [count, setCount] = useState(0);

  const handleSelectDate = (date) => {
    setShipDate(date);
  };

  return (
    <MDBCol lg={`6`} className={`d-flex flex-column right-side text-left`}>
      <Controller
        control={control}
        name="phone"
        defaultValue=""
        rules={{
          required: true,
        }}
        render={({ onChange, value, name, ref }) => (
          <MaskedInput
            error="wrong"
            success="right"
            mask={maskSanitizedArray}
            name={name}
            value={value}
            onChange={onChange}
            innerRef={ref}
            // onBlur={() => trigger(name)}
            render={(ref, inputProps, onChange, onBlur) => {
              return (
                <MDBBox
                  className={`w-100 masked-input-container input-container`}
                >
                  <label htmlFor={name}>Phone Number *</label>
                  <input
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={"Required"}
                    innerRef={ref}
                    {...inputProps}
                    ref={ref}
                    className={`form-control masked-input validate default-input-field ${
                      value !== "" && !errors.phone && "filled"
                    } ${errors.phone ? "validation-error" : ""} `}
                  />
                  <MDBTypography tag="p" className={`error-label`}>
                    {errors[name] && <>Phone Number is required *</>}
                  </MDBTypography>
                </MDBBox>
              );
            }}
          />
        )}
      />
      <Controller
        control={control}
        name="project_name"
        defaultValue=""
        rules={{ required: true }}
        render={({ onChange, value, name, ref }) => (
          <MDBBox className={`input-container`}>
            <InputField
              // onBlur={() => trigger(name)}
              innerRef={ref}
              placeholder={"Required"}
              name={name}
              value={value}
              onChange={onChange}
              className={`custom-input ${
                errors[name] ? "validation-error" : ""
              }`}
              label={`Project Name *`}
              filled={value && value.length !== 0 ? true : false}
            />
            <MDBTypography tag="p" className={`error-label`}>
              {`${errors[name] ? "Project Name is required *" : ""}`}
            </MDBTypography>
          </MDBBox>
        )}
      />
      <MDBBox className={`input-container`}>
        <label className={`date-picker-label`}>Estimated Project Date *</label>
        <CustomDatePicker
          startDate={shipDate}
          setStartDate={setShipDate}
          placeholder={`Required`}
          onClickHandler={handleSelectDate}
          minDate={todaysDate}
        />
        <MDBTypography tag="p" className={`error-label`}>
          {`${shipDate === null ? "Estimated Project Date is required *" : ""}`}
        </MDBTypography>
      </MDBBox>
      <Controller
        control={control}
        name="questions_or_comments"
        defaultValue=""
        rules={{ required: true }}
        render={({ onChange, value, name, ref }) => (
          <MDBBox className={`input-container textarea`}>
            <InputField
              // onBlur={() => trigger(name)}
              innerRef={ref}
              type={`textarea`}
              placeholder={"Required"}
              name={name}
              value={value}
              onChange={(e) => {
                setCount(e.target.value.length);
                onChange(e);
              }}
              className={`custom-input ${
                errors[name] ? "validation-error" : ""
              }`}
              label={`Questions or comments *`}
              filled={value && value.length !== 0 ? true : false}
              rows="5"
              maxLength={150}
            />
            <p className="limit">{`Characters: ${count}/150`}</p>
            <MDBTypography tag="p" className={`error-label`}>
              {`${errors[name] ? "Questions or comments is required *" : ""}`}
            </MDBTypography>
          </MDBBox>
        )}
      />
    </MDBCol>
  );
};

export default RightSide;
