import { memo } from "react";
import PropTypes from "prop-types";
import { isEqual, isUndefined } from "lodash";
import Helmet from "react-helmet";
import { OrganizationSchema } from "../schemas/organizationSchema";
import useStore from "app/state/hooks/storeHooks/useStore";

/**
 * SEO Helmet component
 * @param {string} title
 * @param {string} url
 * @param {string} description
 * @param {string} image
 * @param {string} card
 * @param {string} useAppname
 * @param {string} customSchema
 */
const SeoHelmet = memo(
  ({
    title,
    url,
    description,
    image,
    card,
    useAppName,
    customSchema,
    status,
  }) => {
    const { getStore, getStoreCode } = useStore();
    const store = getStore();

    if (isUndefined(title) || isUndefined(url)) return <></>;

    let realTitle =
      title !== ""
        ? typeof useAppName !== "undefined" && useAppName === true
          ? title + " - " + store.default_title
          : title + " - " + store.default_title
        : store.default_title;

    let finalDescription =
      description && description.length > 0
        ? description
        : store.default_description;

    let finalUrl = window.location.href;

    // create breadcrumbs
    let explodedUrl = url.split(/\//g).filter((x) => x.length !== 0);
    let currentExplosion = finalUrl.split(window.location.origin);

    let breadcrumbs = explodedUrl.map((item, index) => {
      let explode = currentExplosion[1].split(/\//g).filter((x) => x !== "");
      let findIndex = explode.findIndex((x) => x === item);
      let finalDestination = explode
        .map((_item, _index) => {
          return _index <= index ? _item : "";
        })
        .filter((x) => x !== "");

      return {
        name: item.toUpperCase().replace(/-/g, " "),
        item:
          process.env.REACT_APP_URL + findIndex === 0
            ? item
            : finalDestination
                .map((_item, _index) => {
                  return _index <= index ? _item : "";
                })
                .join("/"),
      };
    });

    let organizationSchema = OrganizationSchema(
      { breadcrumbs: breadcrumbs },
      getStoreCode()
    );

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{realTitle}</title>
          <meta name="description" content={finalDescription} />
          {status && <meta name="prerender-status-code" content={status} />}
          {/** facebook */}
          <meta property="og:title" content={realTitle} />
          <meta property="og:description" content={finalDescription} />
          <meta property="og:image" content={image} />
          <meta property="og:url" content={finalUrl} />

          {/** twitter */}
          {/* <meta name="twitter:title" content={realTitle} />
          <meta name="twitter:description" content={finalDescription} />
          <meta name="twitter:image" content={image} />
          <meta
            name="twitter:card"
            content={typeof card === "undefined" ? "summary_large_image" : card}
          /> */}

          <link rel="canonical" href={finalUrl} />
          <script type="application/ld+json">{organizationSchema}</script>
        </Helmet>
      </>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

SeoHelmet.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
};

export default SeoHelmet;
