import Icon from "app/assets/icon/icon";
import { MDBBox } from "mdbreact";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import Items from "./items";

const ToggleMenu = (props) => {
  let {
    items,
    menuItem,
    isOpen,
    setIsOpen,
    open,
    setOpen,
    closeBurger,
    collapseId,
    setCollapseId,
    sideItems,
  } = props;
  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  const clickOnLink = useCallback(
    (e) => {
      if (
        collapseId.includes(menuItem.id) &&
        collapseId[collapseId.length - 1] !== menuItem.id
      ) {
        e.preventDefault();
      } else {
        if (!collapseId.includes(menuItem.id)) {
          e.preventDefault();
          setCollapseId([...collapseId, menuItem.id]);
        } else {
          closeBurger();
        }
      }
    },
    [closeBurger, collapseId, menuItem.id, setCollapseId]
  );
  const hasChildren = useMemo(() => {
    return (
      typeof menuItem !== "undefined" &&
      typeof menuItem.children !== "undefined" &&
      menuItem.children.length > 0
    );
  }, [menuItem]);

  if (menuItem.product_count === 0 && menuItem.children.length < 0) {
    return null;
  }
  let urlPath = menuItem.url_path
    ? menuItem.url_path
    : menuItem.url_key
    ? menuItem.url_key
    : "";
  urlPath = urlPath[0] === "/" ? urlPath : `/${urlPath}`;

  const openOrCloseFunction = (e) => {
    if (!collapseId.includes(menuItem.id)) {
      e.preventDefault();
      setCollapseId([...collapseId, menuItem.id]);
    } else {
      closeBurger();
    }
  };

  return (
    <div className={`toggle-menu-item`}>
      <div
        className="menu-item text-capitalize"
        id={`menu-item-${menuItem.name}`}
        onClick={toggle}
        onKeyUp={toggle}
        tabIndex={0}
        role="button"
      >
        <div className="dropdown-toggle nav-link static-link">
          {menuItem.icon && (
            <span className="menu-item-icon">
              <Icon icon={menuItem.icon} />
            </span>
          )}
          <Link
            to={urlPath}
            className={`link-mobile text-decoration-none`}
            key={`nav-item-not-uncontrolled-nav-${menuItem.url_path}`}
            onClick={clickOnLink}
          >
            <span>{menuItem.name}</span>
          </Link>
          {hasChildren ? (
            <span className="arrow-container" onClick={openOrCloseFunction}>
              <Icon icon="arrowDown" />
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
      {typeof items !== "undefined" && items.length > 0 && (
        <MDBBox
          className={`toggle-dropdown p-0 ${
            collapseId.includes(menuItem.id) ? "active" : "not-active"
          } ${
            collapseId.includes(menuItem.id) &&
            collapseId[collapseId.length - 1] === menuItem.id
              ? "last-active"
              : ""
          }`}
        >
          <Items
            menuItem={menuItem}
            items={items}
            closeBurger={closeBurger}
            open={open}
            setOpen={setOpen}
            collapseId={collapseId}
            setCollapseId={setCollapseId}
            sideItems={sideItems}
          />
        </MDBBox>
      )}
    </div>
  );
};

export default ToggleMenu;
