// Action Creators
import types from "./types";

const setIsOnline = (payload) => {
  return {
    type: types.SET_IS_ONLINE,
    payload: payload,
  };
};

const ACTIONS = {
  setIsOnline,
};

export default ACTIONS;
