import Details from "./details";

const Content = ({
  data,
  loading,
  storeCode,
  mediaGallery,
  setSelectedSwatch,
  setSelectedSize,
  selectedSize,
  selectedSwatch,
  setActiveGalleryImage,
  addCompareMethod,
  productVariant,
  activeGalleryImage,
}) => {
  return (
    <Details
      data={data}
      loading={loading}
      storeCode={storeCode}
      mediaGallery={mediaGallery}
      setSelectedSwatch={setSelectedSwatch}
      setSelectedSize={setSelectedSize}
      selectedSwatch={selectedSwatch}
      selectedSize={selectedSize}
      activeGalleryImage={activeGalleryImage}
      setActiveGalleryImage={setActiveGalleryImage}
      addCompareMethod={addCompareMethod}
      productVariant={productVariant}
    />
  );
};

export default Content;
