// Action Creators
import types from "./types";

const addToCompare = (payload) => {
  return {
    type: types.ADD_TO_COMPARE,
    payload: payload,
  };
};
const removeCompare = (payload) => {
  return {
    type: types.REMOVE_COMPARE,
    payload: payload,
  };
};
const clearCompare = (payload) => {
  return {
    type: types.CLEAR_COMPARE,
    payload: payload,
  };
};
const setCompare = (payload) => {
  return {
    type: types.SET_COMPARE,
    payload: payload,
  };
};
const setOpenState = (payload) => {
  return {
    type: types.SET_OPEN_STATE,
    payload: payload,
  };
};

export default {
  addToCompare,
  removeCompare,
  clearCompare,
  setCompare,
  setOpenState,
};
