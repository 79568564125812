import { useState } from "react";
import { MDBTypography, MDBBtn, MDBBox } from "mdbreact";
import CheckButton from "core/components/checkButton";
import { isUndefined } from "core/helpers/functions";
import SeoHelmet from "app/layout/seoHelmet";

const Newsletter = (props) => {
  let [newsletter, setNewsletter] = useState(props.customer.is_subscribed);

  return (
    <div fluid className="account-information-container mb-5">
      <SeoHelmet
        url={`/customer/newsletter`}
        title={`Newsletter Subscription`}
      />
      <div className="px-lg-4 w-100">
        <MDBTypography
          tag="h3"
          className="mb-5 d-none d-lg-block border-bottom pb-2"
        >
          Subscription Option
        </MDBTypography>
        <div>
          <CheckButton
            containerClass={"checkbox-container check-button"}
            label={"General Subscription"}
            checked={newsletter}
            wrapperClass={`checkmark`}
            type="checkbox"
            onChange={() => {
              setNewsletter(!newsletter);
            }}
          />
        </div>
        <div className="account-newsletter-button">
          <MDBBtn
            type="submit"
            color="primary"
            className={`btn-with-icon btn-black-text`}
            outline
            disabled={props.locked}
            children={
              <>
                <MDBBox tag="span">
                  {props.locked ? "Saving..." : "Save"}
                </MDBBox>
                <MDBBox className="circle-icon" />
              </>
            }
            onClick={(e) => {
              e.preventDefault();
              if (isUndefined(props.locked) || props.locked === false) {
                props.onSubmit({
                  is_subscribed: newsletter,
                });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
