export const handleContactUsSubmit = (
  data,
  reset,
  submitContactUs,
  addMessage
) => {
  if (data) {
    submitContactUs({ variables: { input: data } }).then(() => {
      reset();
      addMessage("Request successfully submitted.", "success");
    });
  }
};
