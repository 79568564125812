import types from "./types";
import { customerTypes } from "../customer";
import addToWishlist from "./reducers/addToWishlist";
import removeFromWishlist from "./reducers/removeFromWishlist";
import setWishlist from "./reducers/setWishlist";
import moveWishlist from "./reducers/moveWishlist";
import editWishlist from "./reducers/editWishlist";
import addToCartWishlist from "./reducers/addToCartWishlist";
import setWishlistLoading from "./reducers/setWishlistLoading";
import clearWishlist from "./reducers/clearWishlist";
import removeWishlist from "./reducers/removeWishlist";
import createWishlist from "./reducers/createWishlist";
import markAsVisited from "./reducers/markAsVisited";

// Main reducer
const wishlistReducer = (state, action) => {
  if (state === undefined) {
    return {};
  }
  if (action.type === types.ADD_TO_WISHLIST) {
    return addToWishlist(state, action);
  }
  if (action.type === types.REMOVE_FROM_WISHLIST) {
    return removeFromWishlist(state, action);
  }
  if (action.type === types.SET_WISHLIST) {
    return setWishlist(state, action);
  }
  if (action.type === types.MOVE_WISHLIST) {
    return moveWishlist(state, action);
  }
  if (action.type === types.EDIT_WISHLIST) {
    return editWishlist(state, action);
  }
  if (action.type === types.ADD_TO_CART_WISHLIST) {
    return addToCartWishlist(state, action);
  }
  if (
    action.type === customerTypes.LOGOUT_CUSTOMER ||
    action.type === types.CLEAR_WISHLIST_DATA
  ) {
    return clearWishlist();
  }
  if (action.type === types.SET_WISHLIST_LOADING) {
    return setWishlistLoading(state, action);
  }

  if (action.type === types.REMOVE_WISHLIST) {
    return removeWishlist(state, action);
  }

  if (action.type === types.CREATE_WISHLIST) {
    return createWishlist(state, action);
  }

  if (action.type === types.MARK_AS_VISITED) {
    return markAsVisited(state, action);
  }

  return state;
};

export default wishlistReducer;
