import { useState } from "react";
import { Controller } from "react-hook-form";
import { MDBBox, MDBTypography } from "mdbreact";
import InputField from "core/components/inputField/inputField";
// import { participateInWhereToBuy } from "config/data/customProjectData";
// import Select2 from "app/layout/select";
import useValidationPatterns from "app/state/hooks/validationPatternHooks/useValidationPatterns";

const RightSide = ({ control, trigger, errors }) => {
  const { emailPattern } = useValidationPatterns();
  const [count, setCount] = useState(0);
  return (
    <div className={`d-flex flex-column col-lg-6`}>
      <Controller
        control={control}
        name="email"
        defaultValue={""}
        rules={{
          required: true,
          pattern: emailPattern(),
        }}
        render={({ onChange, value, name, ref }) => (
          <MDBBox className="input-container">
            <InputField
              // onBlur={() => trigger(name)}
              innerRef={ref}
              placeholder={"Required"}
              name={name}
              value={value}
              onChange={onChange}
              className={`custom-input ${
                errors[name] ? "validation-error" : ""
              }`}
              label={`Email *`}
              filled={value && value.length !== 0 ? true : false}
            />
            {errors[name] && (
              <MDBTypography tag="p" className="error-label">
                {errors[name] && errors[name].type === "pattern"
                  ? "Email Address is invalid"
                  : "Email Address is required"}
              </MDBTypography>
            )}
          </MDBBox>
        )}
      />
      <Controller
        control={control}
        name="subject"
        rules={{
          required: true,
        }}
        render={({ onChange, value, name, ref }) => (
          <MDBBox className="input-container">
            <InputField
              // onBlur={() => trigger(name)}
              innerRef={ref}
              placeholder={"Requested"}
              name={name}
              value={value}
              onChange={onChange}
              className={`custom-input ${
                errors[name] ? "validation-error" : ""
              }`}
              label={`Subject *`}
              filled={value && value.length !== 0 ? true : false}
            />
            {errors[name] && (
              <MDBTypography tag="p" className="error-label">
                Subject is requested
              </MDBTypography>
            )}
          </MDBBox>
        )}
      />
      {/* <Controller
        control={control}
        name="subject"
        render={({ onChange, value, name, ref }) => (
          <Select2
            isSearchable={false}
            wrapperClass={`inner-select`}
            onBlur={() => trigger(name)}
            innerRef={ref}
            name={name}
            label="Subject"
            value={value}
            onChange={onChange}
            filled={value && value.length !== 0 ? true : false}
            options={participateInWhereToBuy}
            placeholder={"Requested"}
          />
        )}
      /> */}
      <Controller
        control={control}
        name="message"
        defaultValue={""}
        rules={{ required: true }}
        render={({ onChange, value, name, ref }) => (
          <MDBBox className={`input-container textarea`}>
            <InputField
              placeholder={`Required`}
              type={`textarea`}
              // onBlur={() => trigger(name)}
              innerRef={ref}
              name={name}
              maxLength={150}
              value={value}
              onChange={(e) => {
                setCount(e.target.value.length);
                onChange(e);
              }}
              filled={value && value.length !== 0 ? true : false}
              className={`custom-input ${
                errors[name] ? "validation-error" : ""
              }`}
              label={`Message *`}
            />
            <p className="limit">{`Characters: ${count}/150`}</p>
            {errors[name] && (
              <MDBTypography tag="p" className="error-label">
                Message is required
              </MDBTypography>
            )}
          </MDBBox>
        )}
      />
    </div>
  );
};

export default RightSide;
