import Image from "app/layout/image";
import Icon from "app/assets/icon/icon";
import { useLocation } from "react-router-dom";

const SalesRepWidget = ({
  salesRep,
  showFax = true,
  showAddress = true,
  infoGrid = 2,
  title = false,
  layout = "default",
}) => {
  let location = useLocation();
  let webpImage = salesRep.image;
  if (webpImage) {
    webpImage = webpImage
      .replace(/.png/, ".webp")
      .replace(/.jpg/, ".webp")
      .replace(/.webp/, ".webp")
      .replace(/.jpeg/, ".webp");
  }

  const phone = location.pathname.includes("find-a-rep")
    ? salesRep.phone
    : salesRep.telephone;
  const cell = location.pathname.includes("find-a-rep")
    ? salesRep.cell
    : salesRep.mobile;

  const maskPhone = (phone) => {
    if (!phone) return "No Fax";
    phone = phone.replace(/[^\d]/g, "");
    if (phone.length == 10)
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, " +1($1)$2-$3");
    return null;
  };

  return (
    <div className={`sales-rep-widget`}>
      {title && <h5 className={`text-uppercase mb-3`}>{title}</h5>}
      <div className={`card-inner ${layout}`}>
        <div className={`image-container px-0`}>
          {salesRep.image ? (
            <Image
              source={salesRep.image}
              webpImage={webpImage}
              alt={`sales-rep-${salesRep.firstname}`}
            />
          ) : (
            <img
              src="https://via.placeholder.com/259x259.png"
              alt="sales-rep-placeholder"
            />
          )}
        </div>
        <div className={`card-content `}>
          <div className={`rep-name-container`}>
            {salesRep.firstname && salesRep.lastname !== null && (
              <h4 className={`rep-name`}>
                {salesRep.firstname + " " + salesRep.lastname}
              </h4>
            )}
            {salesRep.agency_name !== null && (
              <p className={`rep-industry`}> {salesRep.agency_name}</p>
            )}
          </div>
          <div
            className={`rep-info`}
            style={{ gridTemplateColumns: `repeat(${infoGrid}, 1fr)` }}
          >
            {(phone && (
              <div className={`info-with-icon`}>
                <Icon icon={`phone_icon`} />
                <p>{maskPhone(phone)} </p>
              </div>
            )) || (
              <div className={`info-with-icon`}>
                <Icon icon={`phone_icon`} /> <p>No Phone</p>
              </div>
            )}
            {(cell !== null && cell !== undefined && (
              <div className={`info-with-icon`}>
                <Icon icon={`mobile_icon`} />{" "}
                <p>
                  <a href={`tel:${cell}`}>{maskPhone(cell)}</a>
                </p>
              </div>
            )) || (
              <div className={`info-with-icon`}>
                <Icon icon={`mobile_icon`} /> <p>No Cell</p>
              </div>
            )}
            {showFax && salesRep?.fax !== null && salesRep?.fax !== undefined && (
              <div className={`info-with-icon`}>
                <Icon icon={`fax_icon`} />
                <p>
                  <a href={`tel:${salesRep.fax}`}>{maskPhone(salesRep.fax)}</a>
                </p>
              </div>
            )}
            {salesRep.email !== null && (
              <div className={`info-with-icon`}>
                <Icon icon={`email_icon`} />{" "}
                <p>
                  <a href={`mailto:${salesRep.email}`}>{salesRep.email}</a>
                </p>
              </div>
            )}

            {showAddress &&
              (salesRep.location_state !== null || salesRep.city !== null) && (
                <div className={`info-with-icon`}>
                  {!salesRep.city && !salesRep.location_state ? null : (
                    <Icon icon={`location_icon`} />
                  )}
                  <p>
                    {salesRep.city}{" "}
                    {salesRep.location_state && `, ${salesRep.location_state}`}
                  </p>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SalesRepWidget;
