import Slider from "react-slick";
import Image from "app/layout/image/image";
import { useEffect, useState, useCallback } from "react";
import Icon from "app/assets/icon/icon";
import useStore from "app/state/hooks/storeHooks/useStore";

const MainSlider = ({
  sliderNav2,
  activeImageIndex,
  image_gallery,
  productItem,
  setSliderNav1,
  setActiveImageIndex,
}) => {
  let slider1 = null;
  const { getStoreCode } = useStore();
  const store = getStoreCode();

  useEffect(() => {
    setSliderNav1(slider1);
  }, [slider1]);

  const handlePrev = () => {
    return slider1.slickPrev();
  };

  const handleNext = () => {
    return slider1.slickNext();
  };
  const activateFullScreen = useCallback(() => {
    let el = document.getElementById("product-gallery-popup");
    if (el) {
      setIsFullScreen(true);
      el.requestFullscreen();
      el.style.background = "#ffffff";
    }
  }, []);

  const deActivateFullScreen = useCallback(() => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement
    ) {
      setIsFullScreen(false);
      document?.exitFullscreen();
    }
  }, []);

  const [isFullScreen, setIsFullScreen] = useState(false);
  return (
    <div
      className={`main-slider-container ${
        isFullScreen && "full-screen"
      } ${store}-website`}
    >
      <div
        className={`slider-arrow prev ${activeImageIndex === 0 && "disabled"}`}
        onClick={handlePrev}
      >
        <Icon icon={"slick_arrow_left_big"} />
      </div>
      <div className={`slider-container`}>
        <Slider
          className="popup-slider"
          asNavFor={sliderNav2}
          ref={(slider) => (slider1 = slider)}
          {...{
            dots: false,
            infinite: false,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: activeImageIndex,
            afterChange: (index) => setActiveImageIndex(index),
          }}
        >
          {image_gallery &&
            image_gallery.map((item, index) => {
              const explodedImageSource = item.url.split(/\//g);
              const webpImage = explodedImageSource[
                explodedImageSource.length - 1
              ]
                .replace(/.png/, ".webp")
                .replace(/.jpg/, ".webp")
                .replace(/.webp/, ".webp");

              const webpImageDirectory =
                "catalog/product/webp/" + productItem?.sku + "/";
              return (
                <div
                  className={`image-container`}
                  key={`product-image-${index}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Image
                    source={item.url}
                    wrapperClass={`imageloader`}
                    webpImage={webpImageDirectory + webpImage}
                    alt={`product-image-${item.label}`}
                    backend={true}
                    backend_public={true}
                    mediaLink={true}
                  />
                  <div className={`full-screen-controls ${store}-website`}>
                    <p onClick={activateFullScreen}>+</p>
                    <p onClick={deActivateFullScreen}>-</p>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
      <div
        className={`slider-arrow next ${
          activeImageIndex === image_gallery?.length - 1 && "disabled"
        }`}
        onClick={handleNext}
      >
        <Icon icon={"slick_arrow_right_big"} />
      </div>
    </div>
  );
};

export default MainSlider;
