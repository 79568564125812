// Action Creators
import types from "./types";

const addToWishlist = (payload) => {
  return {
    type: types.ADD_TO_WISHLIST,
    payload: payload,
  };
};
const removeFromWishlist = (payload) => {
  return {
    type: types.REMOVE_FROM_WISHLIST,
    payload: payload,
  };
};
const setWishlist = (payload) => {
  return {
    type: types.SET_WISHLIST,
    payload: payload,
  };
};

const moveWishlist = (payload) => {
  return {
    type: types.MOVE_WISHLIST,
    payload: payload,
  };
};
const editWishlist = (payload) => {
  return {
    type: types.EDIT_WISHLIST,
    payload: payload,
  };
};
const addToCartWishlist = (payload) => {
  return {
    type: types.ADD_TO_CART_WISHLIST,
    payload: payload,
  };
};

const checkEmail = (payload) => {
  return {
    type: types.CHECK_EMAIL,
    payload: payload,
  };
};

const setWishlistLoading = (payload) => {
  return {
    type: types.SET_WISHLIST_LOADING,
    payload: payload,
  };
};

const clearWishlistData = () => {
  return {
    type: types.CLEAR_WISHLIST_DATA,
  };
};

const removeWishlist = (payload) => {
  return {
    type: types.REMOVE_WISHLIST,
    payload: payload,
  };
};

const createWishlist = (payload) => {
  return {
    type: types.CREATE_WISHLIST,
    payload: payload,
  };
};

const markAsVisited = (payload) => {
  return {
    type: types.MARK_AS_VISITED,
    payload: payload,
  };
};

export default {
  addToWishlist,
  removeFromWishlist,
  setWishlist,
  moveWishlist,
  editWishlist,
  addToCartWishlist,
  checkEmail,
  setWishlistLoading,
  clearWishlistData,
  removeWishlist,
  createWishlist,
  markAsVisited,
};
