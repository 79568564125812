export const getProductDetailsImage = (sku, mediaGallery) => {
  let productDetailImage = null;
  let filteredGallery = mediaGallery.filter((image) => {
    return image.url.includes("02") || image.url.includes("03");
  });

  filteredGallery.map((image) => {
    const url = image.url;

    //finding index of product's sku in the url string
    let skuIndex = url.indexOf(sku);
    //removing _1.jpg or _1.png
    let modifiedUrl = url.slice(skuIndex, url.length - 6);

    //extracting the number between two underscores
    let firstUnderscore = modifiedUrl.indexOf("_");
    let lastUnderscore = modifiedUrl.lastIndexOf("_");
    modifiedUrl = modifiedUrl.slice(firstUnderscore + 1, lastUnderscore);

    //setting product detail image
    if (modifiedUrl === "02") return (productDetailImage = image);
    if (modifiedUrl === "03") return (productDetailImage = image);
    return false;
  });

  return productDetailImage;
};

export const removeImageWithRole = (mediaGallery) => {
  let filteredImages = mediaGallery?.filter((image) => {
    let showImage = false;
    //if one of these roles is not found, image will be displayed
    // showImage = image.types?.find((type) => {
    //   return type === "thumbnail" || type === "small_image";
    // });
    // if (showImage === undefined) return image;
    // return false;
    if (!image?.video_content) {
      return image;
    }
  });
  return filteredImages;
};
