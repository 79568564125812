import Icon from "app/assets/icon/icon";
import { MDBCol } from "mdbreact";
import React from "react";

const Image = ({ image, columnProps, selected, toggleImage, key }) => {
  return (
    <MDBCol
      key={`${key}`}
      {...columnProps}
      className={`p-3 download-image ${
        selected !== undefined ? "selected" : "not-selected"
      }`}
      onClick={() => {
        toggleImage(image);
      }}
    >
      <Icon
        className={`download-image-icon`}
        icon={`${selected !== undefined ? "downloadBlack" : "downloadImage"}`}
      />
      <img src={image.url} alt={image.label} className={`img-fluid`} />
    </MDBCol>
  );
};

export default Image;
