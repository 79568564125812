/**
 * Redux template file, used to export reducers, middlewares, inital state and action/creators.
 *
 * Customer Redux
 **/

import customerReducerNew from "./reducer";
export { default as customerMiddleware } from "./middleware";
export { default as customerTypes } from "./types";
export { default as customerActions } from "./actions";
export { default as customerInitialState } from "./initialState";
export default customerReducerNew;
