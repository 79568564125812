import { useState } from "react";
import { MDBModal, MDBModalBody } from "mdbreact";
import Icon from "app/assets/icon/icon";
import Image from "app/layout/image";
import DownloadTearsheet from "app/layout/downloadTearsheet";

const Attributes = ({
  attributes,
  data,
  context,
  selectedSize,
  selectedSwatch,
  pdpSidebarImage,
  productVariant,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  //   function downloadImage(source) {
  //     var el = document.createElement("a");
  //     el.setAttribute("href", source);
  //     el.setAttribute("download", "img");
  //     document.body.appendChild(el);
  //     el.click();
  //     el.remove();
  //   }

  return (
    <>
      <div className="attributes mt-5 mb-5">
        {attributes?.map((attribute, index) => (
          <div
            className="attribute border-bottom d-flex justify-content-between"
            key={index}
          >
            <p>{attribute?.title}</p>
            <div
              onClick={() => {
                if (attribute?.title === "Tear Sheets") {
                  context.showModal(() => {
                    return (
                      <DownloadTearsheet
                        product={data}
                        hideModal={context.hideModal}
                        selectedSwatch={selectedSwatch}
                        selectedSize={selectedSize}
                        pdpSidebarImage={pdpSidebarImage}
                        productVariant={productVariant}
                      />
                    );
                  });
                } else if (attribute?.title === "Installations") {
                  window.open("/resources?index=2", "_blank");
                }
              }}
            >
              <div className="d-flex">
                {attribute?.text && (
                  <p
                    className="text-underline text-right"
                    onClick={() => {
                      if (attribute?.text === "View") {
                        setIsOpen(true);
                      }
                    }}
                  >
                    {attribute?.text}
                  </p>
                )}
                {attribute?.icon && (
                  <div
                    className="ml-5"
                    onMouseOver={(e) => e.currentTarget.classList.add("active")}
                    onMouseLeave={(e) =>
                      e.currentTarget.classList.remove("active")
                    }
                  >
                    <Icon
                      icon="downloadSelectedIcon"
                      onClick={() => {
                        if (attribute?.title === "Specifications") {
                          window.open(pdpSidebarImage?.url, "_blank");
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <MDBModal
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
        centered
        id={`popup-modal`}
      >
        <MDBModalBody
          onClick={(e) => {
            if (!e.target?.classList?.contains("specification-image")) {
              setIsOpen(false);
            }
          }}
        >
          <div className="content-holder">
            <Icon
              className="cursor-pointer"
              icon="closeIconBlue"
              onClick={() => setIsOpen(!isOpen)}
            />
            <Image
              source={pdpSidebarImage?.url}
              alt="specification-image"
              className="specification-image"
            />
          </div>
        </MDBModalBody>
      </MDBModal>
    </>
  );
};

export default Attributes;
