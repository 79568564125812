import { MDBBox } from "mdbreact";
import Icon from "app/assets/icon/icon";
import { Link } from "react-router-dom";
import MiniCart from "./miniCart";
import { useCart } from "app/state/hooks/cartHooks/useCart";
import useLocking from "core/state/hooks/commonHooks/useLocking";

const CartAndWishlist = ({
  displayMiniCart,
  showMiniCart,
  toggleMiniCart,
  hideMiniCart,
  customer,
}) => {
  const { getCart, updateCartItem, getCartNumberOfItems } = useCart();
  const cart = getCart();
  const [lock] = useLocking();
  let numberOfItems = getCartNumberOfItems();
  // let showMiniCart = true;
  return (
    <MDBBox className={`quote-and-wishlist`}>
      <Link
        to={`/customer/favorites`}
        className="wishlist text-decoration-none wishlist-icon"
      >
        {/* {wishlistCount !== 0 && <MDBBox tag="p">{wishlistCount}</MDBBox>} */}
        <Icon icon="favorite_icon" />
        {/* wishlist count should go here */}
        {/* <label className="wishlist-count"></label> */}
      </Link>
      {customer?.show_price !== false ? (
        <MDBBox
          className={`mini-quote-container`}
          onMouseEnter={displayMiniCart}
          onMouseLeave={hideMiniCart}
        >
          <Link to={"/cart"} className={`quote-icon text-decoration-none`}>
            <Icon icon="quoteIconWhite" />
            {numberOfItems > 0 && (
              <label className="quote-count">{numberOfItems}</label>
            )}
          </Link>
          {showMiniCart ? (
            <MiniCart
              updateCart={updateCartItem}
              showMiniCart={showMiniCart}
              toggleMiniCart={toggleMiniCart}
              displayMiniCart={displayMiniCart}
              hideMiniCart={hideMiniCart}
              cart={cart}
              lock={lock}
              numberOfItems={numberOfItems}
            />
          ) : (
            <></>
          )}
        </MDBBox>
      ) : null}
    </MDBBox>
  );
};
export default CartAndWishlist;
