export const logicallyHandleHeaderOnScroll = (
  prevPos,
  currPos,
  headerRef,
  setShowMiniCart,
  minicartToggle,
  isMobile
) => {
  let scrollPosition = window.scrollY;
  let headerNav = headerRef;
  if (scrollPosition <= 550) {
    headerNav.current.classList.remove("moved");
    headerNav.current.classList.add("initial");
    return;
  }
  if (!isMobile) {
    let footerBoundingRectangle = document
      .getElementById("footer")
      ?.getBoundingClientRect();
    const body = document.getElementsByTagName("BODY")[0];

    if (body.classList?.contains("search-open")) {
      return;
    }

    if (footerBoundingRectangle === undefined) return;
    let plpSidebar = document.getElementById("sidebar-filter-groups");

    if (currPos.y > -100) {
      if (headerNav.current.className.indexOf("moved") !== -1) {
        if (plpSidebar) {
          plpSidebar.classList.add("header-active");
        }
        headerNav.current.classList.remove("moved");
        headerNav.current.classList.add("initial");
      }
    } else if (currPos.y < -100) {
      if (
        prevPos.y < currPos.y &&
        footerBoundingRectangle.bottom >
          (window.innerHeight + 300 ||
            document.documentElement.clientHeight + 300)
      ) {
        if (headerNav.current.className.indexOf("moved") !== -1) {
          if (plpSidebar) {
            plpSidebar.classList.add("header-active");
          }
          headerNav.current.classList.remove("moved");
          headerNav.current.classList.add("initial");
        }
      } else {
        if (
          footerBoundingRectangle.top >= 0 &&
          footerBoundingRectangle.bottom <=
            (window.innerHeight + 300 ||
              document.documentElement.clientHeight + 300)
        ) {
        } else {
          if (headerNav.current.className.indexOf("initial") !== -1) {
            if (plpSidebar) {
              plpSidebar.classList.remove("header-active");
            }
            if (minicartToggle) {
              setShowMiniCart(false, false);
            }
            headerNav.current.classList.remove("initial");
            headerNav.current.classList.add("moved");
          }
        }
      }
    }
  }
};
