import { useState } from "react";
import { MDBView, MDBBox } from "mdbreact";
import { isUndefined } from "core/helpers/functions";
import HotspotCard from "./hotspotCard";
import useStore from "app/state/hooks/storeHooks/useStore";
import { useMediaQuery } from "react-responsive";
import Image from "../image/image";
import { useCallback } from "react";

const HotspotRender = (props) => {
  const { getStore } = useStore();
  const isTablet = useMediaQuery({ maxWidth: "992px" });
  const isMobile = useMediaQuery({ maxWidth: "580px" });
  let image = isUndefined(props.contentArray.image)
    ? props.contentArray.hotspot_image || props.image
    : props.contentArray.image;

  //TO DO: replace string with variable from themeConfig
  const [hotspotBackground] = useState(getStore().base_link_url + image);

  const [isVisible, setVisible] = useState(false);
  const showProductInfo = (dotSku, isDotVisible) => {
    let dotParams = props.dots;
    dotParams.map((newDot) => {
      if (newDot.sku === dotSku) {
        if (isDotVisible === true) {
          newDot.isVisible = false;
          setVisible(!isVisible);
        } else {
          newDot.isVisible = true;
          setVisible(!isVisible);
        }
      } else {
        newDot.isVisible = false;
        setVisible(!isVisible);
      }
      return newDot;
    });
  };
  const realImage = hotspotBackground;

  const webpImage =
    realImage && !realImage.includes(".png")
      ? realImage.replace(/.png|.jpg/g, ".webp")
      : "";

  const closeModal = useCallback(() => {
    props.hideModal();
  }, []);

  return (
    <MDBBox className={`hotspot-content-container`}>
      <MDBView className={`background-image`}>
        <Image
          source={realImage}
          webpImage={webpImage}
          wrapperClass={`img-slider`}
        />
        {props.dots.map((dot, index) => {
          return (
            <HotspotCard
              key={`card-comp-` + index}
              dot={dot}
              dots={props.dots}
              isHome={props.isHome}
              customer={props.customer}
              showProductInfo={showProductInfo}
              toggleModal={props.toggleModal}
              closeModal={closeModal}
            />
          );
        })}
      </MDBView>
    </MDBBox>
  );
};

export default HotspotRender;
