import { lazy, Suspense, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { findCategoryByAttribute } from "core/helpers/category";
import { TemporaryPage } from "app/config/routing/pages/globalPages";
import useGenerated from "app/state/hooks/generatedHooks";
import useCompare from "app/state/hooks/compareHook";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import { compareActions } from "app/state/redux/data/compare";
import CompareSidebar from "./compareSidebar";

const Collections = lazy(() =>
  import("./collections" /*webpackChunkName: "collections"*/)
);
const CollectionsDetail = lazy(() =>
  import("./collectionsDetail" /*webpackChunkName: "collections detail"*/)
);
const CategoryContainer = lazy(() =>
  import("./category-container" /*webpackChunkName: "category"*/)
);

const Category = () => {
  const { GetCategories } = useGenerated();
  let dispatch = useDispatch();
  const {
    getCompareData,
    getCompareListId,
    addCompareFunc,
    removeCompareFunc,
  } = useCompare();

  const { getCustomer } = useCustomer();
  const customer = getCustomer();

  const categories = GetCategories();
  const location = useLocation();
  let url = location?.pathname?.substring(1);
  const [compareMenu, setCompareMenu] = useState(false);
  const [compareData, setCompareData] = useState([]);

  const toggleCompareMenu = () => {
    setCompareMenu(!compareMenu);
  };

  const addCompareMethod = async (data) =>
    addCompareFunc(
      data,
      getCompareListId,
      setCompareData,
      setCompareMenu,
      customer
    );

  const removeCompareMethod = async (data) =>
    removeCompareFunc(data, getCompareListId, setCompareData, customer);

  useEffect(async () => {
    let compareItems;
    const compareData = getCompareData();
    if (customer?.compare_list?.uid) {
      compareItems = await getCompareListId(customer?.compare_list?.uid);
    } else {
      if (getCompareData()?.data?.compareItems?.listCleared === false) {
        dispatch(
          compareActions.setCompare({ compareItems: [], listCleared: true })
        );
        compareItems = [];
      } else {
        compareItems = await getCompareListId(
          compareData?.data?.compareItems?.uid
        );
      }
    }
    setCompareData(compareItems?.items);
  }, [customer]);

  let category = undefined;

  if (typeof categories === "undefined") return <></>;
  if (typeof categories.children === "undefined") return <></>;
  if (typeof categories.children !== "undefined") {
    category = findCategoryByAttribute(url, categories.children, "url_path");
  }

  if (category === undefined) return <CategoryContainer search={true} />;

  if (category.page_layout === `collections-layout`)
    return (
      <Suspense fallback={<TemporaryPage />}>
        <Collections category={category} />
      </Suspense>
    );
  if (category.page_layout === `collections-detail-layout`)
    return (
      <Suspense fallback={<TemporaryPage />}>
        <CollectionsDetail
          category={category}
          addCompareMethod={addCompareMethod}
        />
        {compareData?.length > 0 && (
          <CompareSidebar
            toggleCompareMenu={toggleCompareMenu}
            compareMenu={compareMenu}
            data={compareData}
            removeCompareMethod={removeCompareMethod}
          />
        )}
      </Suspense>
    );
  return (
    <Suspense fallback={<TemporaryPage />}>
      <CategoryContainer
        category={category}
        search={false}
        designer={
          category?.page_layout?.includes(`designer-layout`) ? true : false
        }
        url={url}
        addCompareMethod={addCompareMethod}
      />
      {compareData?.length > 0 && (
        <CompareSidebar
          toggleCompareMenu={toggleCompareMenu}
          compareMenu={compareMenu}
          data={compareData}
          removeCompareMethod={removeCompareMethod}
        />
      )}
    </Suspense>
  );
};

export default Category;
