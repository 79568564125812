import { useEffect, Suspense, useState, memo, useCallback } from "react";
import useGenerated from "app/state/hooks/generatedHooks";
import useStore from "app/state/hooks/storeHooks/useStore";
import GatcoTheme from "../../themes/gatco";
import { isEqual, reduce } from "lodash";

const StoreWrapper = memo(({ children }) => {
  const { GetGeneratedStore, determineStore, changeCurrentStore } =
    useGenerated();
  const { getStore } = useStore();
  const [style, setStyle] = useState(0);
  const [thisStore, setThisStore] = useState(getStore());
  const [updated, setUpdated] = useState(false);

  const changeStyle = useCallback(function handleStyle() {
    setStyle((prevCount) => prevCount + 1);
  }, []);

  const numberOfStores = 1;

  let currentStore = thisStore;
  let store = GetGeneratedStore(currentStore);

  let oneDiff = reduce(
    currentStore,
    function (result, value, key) {
      return isEqual(value, store[key])
        ? result
        : result.concat({ [key]: value });
    },
    []
  );

  let secondDiff = reduce(
    store,
    function (result, value, key) {
      return isEqual(value, currentStore[key])
        ? result
        : result.concat({ [key]: value });
    },
    []
  );

  useEffect(() => {
    if (store?.store_code !== currentStore.store_code) {
      determineStore();
      setThisStore(getStore());
    } else {
      if (
        (oneDiff.length > 0 || secondDiff.length > 0) &&
        !updated &&
        store?.store_code === currentStore.store_code
      ) {
        setUpdated(true);
        changeCurrentStore(store);
      }
    }
  }, [
    determineStore,
    store,
    currentStore,
    oneDiff.length,
    secondDiff.length,
    updated,
    changeCurrentStore,
    getStore,
  ]);

  if (currentStore.base_link_url === undefined) return <></>;

  // we use currentStore.store_code to load styles, due to big differences in themes
  return (
    <>
      {/* {window.location.hostname === "localhost" ? (
        <StoreSwitcher store={store} />
      ) : (
        <></>
      )} */}
      <Suspense fallback={<></>}>
        <GatcoTheme setStyle={changeStyle} />
      </Suspense>

      {style >= numberOfStores && currentStore.store_code && children}
    </>
  );
});

export default StoreWrapper;
