import { MDBContainer, MDBRow, MDBCol, MDBTypography, MDBBox } from "mdbreact";
import CmsBlock from "core/components/cmsBlock";
import Breadcrumbs from "app/layout/breadcrumbs";
import SeoHelmet from "app/layout/seoHelmet";
import ContactForm from "./contactForm";

const Contact = () => {
  return (
    <MDBBox className={`contact-us-page`}>
      <SeoHelmet url={`/contact`} title={`Contact Us`} />
      <MDBContainer fluid>
        <MDBRow>
          <CmsBlock dangHtml={true} id="contact-us-banner" />
        </MDBRow>
      </MDBContainer>
      <MDBContainer fluid>
        <MDBRow className={`mb-90 contact-title-wrapper`}>
          <MDBBox className="title-with-background w-100">
            <MDBContainer>
              <MDBTypography tag="h1" className="page-title">
                Contact Us
              </MDBTypography>
            </MDBContainer>
          </MDBBox>
        </MDBRow>
        <MDBRow className={``}>
          <MDBContainer>
            <Breadcrumbs />
          </MDBContainer>
        </MDBRow>
        <MDBRow className={`mb-100 contact-text-wrapper`}>
          <MDBContainer>
            <CmsBlock dangHtml={true} id="contact-us-text" />
          </MDBContainer>
        </MDBRow>
        <MDBRow className={`mb-100 contact-info-wrapper`}>
          <MDBContainer>
            <CmsBlock dangHtml={true} id="contact-us-info" />
          </MDBContainer>
        </MDBRow>
        <MDBRow>
          <MDBContainer className={`page-full-width`}>
            <MDBRow className="contact-form">
              <MDBCol size="12">
                <MDBContainer className="contact-form-wrapper">
                  <MDBTypography tag="h3" className={`mb-40`}>
                    Let’s have a chat
                  </MDBTypography>
                  <ContactForm />
                </MDBContainer>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>
      </MDBContainer>
    </MDBBox>
  );
};

export default Contact;
