export default (state, action) => {
  let { data } = action;
  let customerState = state;
  let foundAddress = false;
  customerState.data.addresses.map((address, index) => {
    if (address.id === data.id) {
      foundAddress = true;
      return (customerState.data.addresses[index] = data);
    }
    return address;
  });
  if (!foundAddress) {
    const newAddresses = customerState.data.addresses;
    newAddresses.push(action.data);
    customerState.data.addresses = newAddresses;
  }
  return Object.assign({}, state, {
    ...customerState,
  });
};
