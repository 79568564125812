import { MDBBox, MDBBtn, MDBCol, MDBRow } from "mdbreact";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import Modal from "./modal";

const Heading = ({
  data,
  itemCount,
  showBackButton,
  headingClassName,
  refetch,
  showTitle = true,
}) => {
  const { goBack } = useHistory();

  const [modal, setModal] = useState(false);
  const [action, setAction] = useState(false);

  const toggleModal = (newAction = false) => {
    setModal(!modal);
    setAction(newAction);
  };

  return (
    <>
      <MDBBox className={`my-downloads-heading ${headingClassName}`}>
        {showBackButton ? (
          <MDBRow
            className={`mb-5`}
            onClick={(e) => {
              goBack();
            }}
          >
            Back
          </MDBRow>
        ) : (
          <></>
        )}
        <MDBRow className={`mb-5`}>
          <MDBCol className={`d-flex p-0 align-items-center`}>
            {showTitle ? (
              <h2 className={`d-flex align-items-center`}>
                My Downloads
                {itemCount ? (
                  <span className={`my-downloads-product-count pt-2`}>
                    {itemCount} item(s)
                  </span>
                ) : (
                  <></>
                )}
              </h2>
            ) : (
              <></>
            )}
          </MDBCol>
          {data && data.length > 0 ? (
            <MDBCol lg={`3`} md={`4`} xs={`12`}>
              <MDBRow className={`justify-content-between align-items-center`}>
                <MDBBox
                  role={`button`}
                  onClick={(e) => {
                    toggleModal("REMOVE");
                  }}
                >
                  <u>Remove all</u>
                </MDBBox>
                <MDBBtn
                  onClick={(e) => {
                    toggleModal("DOWNLOAD_ALL");
                  }}
                >
                  Download All
                </MDBBtn>
              </MDBRow>
            </MDBCol>
          ) : (
            <></>
          )}
        </MDBRow>
      </MDBBox>
      <Modal
        modal={modal}
        toggleModal={toggleModal}
        action={action}
        data={data}
        refetch={refetch}
      />
    </>
  );
};

export default Heading;
