import { useCallback, useState } from "react";
import { MDBModal, MDBModalBody, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { useForm } from "react-hook-form";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import { useWishlist } from "app/state/hooks/wishlistHooks/wishlistHookAddRemove";
import Icon from "app/assets/icon/icon";
import useStore from "app/state/hooks/storeHooks/useStore";
import LoginRegister from "./loginRegister";
import SingIn from "./singIn";

const Login = ({
  email,
  setEmail,
  userEmail,
  setUserEmail,
  showLoginRegister,
  setShowLoginRegister,
  showPasswordContent,
  setShowPasswordContent,
  loading,
  setLoading,
  toggleView,
  setToggleView,
  errorMessage,
  setErrorMessage,
  ...props
}) => {
  const { checkEmail } = useWishlist();
  const [userName, setUserName] = useState("");

  const { login, getCustomer } = useCustomer();
  const { getStoreCode } = useStore();
  let storeCode = getStoreCode();
  let customer = getCustomer();

  const toggle = useCallback(() => {
    setUserName("");
    setToggleView(false);
  }, []);
  const setEmailFn = (e) => {
    if (errorMessage.type) {
      setErrorMessage({
        type: undefined,
        message: undefined,
      });
    }
    setEmail(e.target.value);
  };
  const mode = "onBlur";
  const { register, handleSubmit, errors } = useForm({
    mode: mode,
    reValidateMode: mode,
  });

  const onSubmit = async (data) => {
    if (data.email !== undefined) {
      setUserEmail(data.email);
    }

    setLoading("login-register");

    if (data.email !== "" && userName === "") {
      await checkEmail(data.email).then((response) => {
        setLoading(false);
        if (response.error === undefined) {
          setUserName(response);
          setErrorMessage({
            type: undefined,
            message: undefined,
          });
          setShowPasswordContent(!showPasswordContent);
          setShowLoginRegister(!showLoginRegister);
        } else {
          setErrorMessage({
            type: "login-register",
            message: response.message,
          });
        }
      });
    }
  };

  const onSubmitLogin = async (data) => {
    if (userEmail && data.password === "") {
      setLoading(false);
    } else {
      setLoading("logging-in");
      await login(userEmail, data.password).then((response) => {
        setLoading(false);
        if (response.error === undefined) {
          setLoading(false);
          setErrorMessage({
            type: undefined,
            message: undefined,
          });
          setShowLoginRegister(false);
          data.password && setToggleView(false);
        } else {
          setErrorMessage({
            type: "sign-in",
            message: response.message,
          });
        }
      });
    }
  };
  const handleBackArrow = () => {
    setUserName("");
    setShowLoginRegister(true);
    setShowPasswordContent(false);
  };
  const handleClose = () => {
    toggle();
    if (props.hideModal) {
      props.hideModal();
    }
  };

  return (
    <MDBModal
      wrapClassName={`login-modal`}
      isOpen={toggleView}
      side
      position={`right`}
      fullHeight={true}
      animation="right"
      fade={true}
      className={`login-modal-wrapper ${storeCode}`}
      toggle={() => {
        toggle();
        props.hideModal();
      }}
    >
      <MDBModalBody className="p-0 d-flex flex-column">
        <MDBContainer>
          <MDBRow>
            <div
              className={`d-flex justify-content-between w-100 align-items-center modal-title`}
            >
              <div
                className={`cursor-pointer account-icon-login`}
                onClick={handleClose}
              >
                <Icon icon="accountIcon" />
              </div>
              <div className={`d-flex align-items-center`}>
                {!showLoginRegister && (
                  <div
                    className={`modal-back-arrow cursor-pointer`}
                    onClick={handleBackArrow}
                  >
                    <Icon icon="arrowDown" />
                  </div>
                )}
                <h3>
                  {showLoginRegister && "Welcome"}
                  {showPasswordContent && `Welcome back, ${userName}`}
                </h3>
              </div>
              <div className={`cursor-pointer close-btn`} onClick={handleClose}>
                <Icon icon="closeIconBlue" />
              </div>
            </div>
          </MDBRow>
          <MDBRow>
            <MDBCol lg="12">
              <div className="line"></div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        {showLoginRegister && (
          <LoginRegister
            email={email}
            errors={errors}
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            register={register}
            setEmailFn={setEmailFn}
            showLoginRegister={showLoginRegister}
            setShowLoginRegister={setShowLoginRegister}
            showPasswordContent={showPasswordContent}
            setShowPasswordContent={setShowPasswordContent}
            toggle={toggle}
            storeCode={storeCode}
            loading={loading}
            errorMessage={errorMessage}
            userName={userName}
          />
        )}
        {showPasswordContent && (
          <SingIn
            onSubmit={onSubmitLogin}
            handleSubmit={handleSubmit}
            register={register}
            errors={errors}
            showLoginRegister={showLoginRegister}
            setShowLoginRegister={setShowLoginRegister}
            showPasswordContent={showPasswordContent}
            setShowPasswordContent={setShowPasswordContent}
            toggle={toggle}
            storeCode={storeCode}
            setToggleView={setToggleView}
            userName={userName}
            setUserName={setUserName}
            userEmail={userEmail}
            email={email}
            errorMessage={errorMessage}
            customer={customer}
            loading={loading}
            setErrorMessage={setErrorMessage}
          />
        )}
      </MDBModalBody>
    </MDBModal>
  );
};
export default Login;
