import { MDBCardText, MDBCardBody, MDBCard } from "mdbreact";

const TextCard = ({ dot, containerClassName }) => {
  return (
    <MDBCard className={`${containerClassName} hotspot-card text-card`}>
      <MDBCardBody>
        <MDBCardText>
          {dot.display_type === "text" ? dot.addInfo : <></>}
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>
  );
};

export default TextCard;
