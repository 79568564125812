import apolloClient from "app/graphql/apolloClient";
import customerActions from "../../actions";
import commonActions from "../../../common/actions";
import messagesActions from "core/state/redux/data/messages/actions";
import queryLoader from "app/graphql/queryLoader";

export default async (store, action) => {
  let cart_id = store.getState().customer.data.cart.id;
  let cart = { ...store.getState().customer.data.cart };
  try {
    const { data } = await apolloClient.query({
      query: queryLoader("setPaymentMethod"),
      variables: {
        input: {
          cart_id,
          payment_method: action.payment_method,
        },
      },
      fetchPolicy: "no-cache",
    });
    if (data.setPaymentMethodOnCart !== null) {
      let newCart = {
        ...cart,
        prices: { ...data.setPaymentMethodOnCart.cart.prices },
        available_payment_methods: [
          ...data.setPaymentMethodOnCart.cart.available_payment_methods,
        ],
        selected_payment_method: {
          ...data.setPaymentMethodOnCart.cart.selected_payment_method,
        },
      };
      store.dispatch(customerActions.setCartInformation(newCart));
    }
    store.dispatch(commonActions.unlock());
  } catch (error) {
    store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    store.dispatch(commonActions.unlock());
    return;
  }
};
