export const loadState = (key) => {
  if (!key) key = process.env.REACT_APP_STORAGE_KEY;
  return JSON.parse(localStorage.getItem(key));
};

export const saveState = (state, key = process.env.REACT_APP_STORAGE_KEY) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch {
    // ignore write errors
  }
};
