import { isEqual } from "lodash";
import { MDBBox, MDBBtn, MDBCol, MDBRow, MDBTypography } from "mdbreact";
import { memo } from "react";
import { useHistory } from "react-router";

const RegistrationOnLoginModal = memo(
  ({ toggle }) => {
    const history = useHistory();
    return (
      <MDBRow className={`register-section`}>
        <MDBCol size="12">
          <MDBBox>
            <MDBTypography
              tag="p"
              className={`modal-subtitle text-uppercase mb-3`}
            >
              Wholesale Customers
            </MDBTypography>

            <MDBTypography tag="p" className={`modal-text mb-5`}>
              Business customers may apply to get online access to pricing,
              inventory, and to submit a quote request
            </MDBTypography>
            <MDBBtn
              color={"primary"}
              className={"btn-without-icon"}
              onClick={() => {
                history.push("/register");
                toggle();
              }}
              children={
                <>
                  <MDBBox tag="span">Request Access</MDBBox>
                </>
              }
            />
          </MDBBox>
        </MDBCol>
      </MDBRow>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default RegistrationOnLoginModal;
