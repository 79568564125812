import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import ContentDataContainer from "./content-data-container";
import CustomLoader from "app/layout/customLoader";

const REP_LOCATORS_WITH_FILTERS = gql`
  query getRepLocators($filters: RepLocatorFilterInput) {
    getRepLocators(filter: $filters) {
      country
      state
      industry
      fax
      firstname
      lastname
      phone
      cell
      email
      country
      state
      city
      image
      industry
      position
      show_in_grid
      notes
      state
      location_state
      agency_name
    }
  }
`;

const ContentQueryContainer = (props) => {
  let { filterData } = props;

  const { data, loading } = useQuery(REP_LOCATORS_WITH_FILTERS, {
    variables: {
      filters: {
        country: filterData.country.value,
        state: filterData.state.code,
        zip: filterData.zip,
        industry: filterData.industry.value,
      },
    },
    fetchPolicy: "cache-first",
  });

  if (loading) return <CustomLoader />;
  return (
    <ContentDataContainer
      data={data}
      selectState={props.selectState}
      industrySelect={props.industrySelect}
    />
  );
};

export default ContentQueryContainer;
