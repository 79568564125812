import { Fragment, useState } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import DiscoverItem from "./discoverItem";
import DiscoverItemMobile from "./discoverItemMobile";
import DiscoverTitle from "./discoverTitle";

const Discover = ({ discoverCategories }) => {
  const isMobileOrTablet = useMediaQuery({ maxWidth: "992px" });

  //isActive is used to determine if any item is hovered
  const [isActive, setIsActive] = useState(false);
  //activeCategory is used to determine which category is hovered
  const [activeCategory, setActiveCategory] = useState(false);

  const onMouseEnter = (category) => {
    setIsActive(true);
    setActiveCategory(category.id);
  };
  const onMouseLeave = () => {
    setIsActive(false);
    setActiveCategory(false);
  };
  return (
    <MDBContainer fluid className={`discover-container`}>
      <MDBRow className={`discover-title`}>
        <DiscoverTitle isMobileOrTablet={isMobileOrTablet} />
      </MDBRow>
      <MDBRow className={`discover-items-wrapper justify-content-between`}>
        {discoverCategories.map((category, index) => {
          if (category === undefined)
            return <Fragment key={`discover-fragment-${index}`} />;
          return (
            <Fragment key={`discover-${category.id}-${index}`}>
              <MDBCol className={`px-3`}>
                <Link to={`/collections?index=${index}`}>
                  {isMobileOrTablet ? (
                    <DiscoverItemMobile
                      key={`discover-item-mobile-${index}`}
                      category={category}
                    />
                  ) : (
                    <DiscoverItem
                      key={`discover-item-${index}`}
                      index={index}
                      category={category}
                      isActive={isActive}
                      setIsActive={setIsActive}
                      activeCategory={activeCategory}
                      setActiveCategory={setActiveCategory}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                    />
                  )}
                </Link>
              </MDBCol>
            </Fragment>
          );
        })}
      </MDBRow>
    </MDBContainer>
  );
};

export default Discover;
