import Icon from "app/assets/icon/icon";
import "./togglePassword.scss";

const TogglePasswordContainer = ({ children, show = false, toggle }) => {
  return (
    <div className="position-relative">
      {children}
      <Icon
        icon={show ? "visibilty_icon" : "hiddenIcon"}
        onClick={toggle}
        className="icon-password"
      />
    </div>
  );
};

export default TogglePasswordContainer;
