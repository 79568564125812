import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import WishlistDataContainer from "./wishlist-data-container";

const WishlistReduxContainer = (props) => {
  return <WishlistDataContainer {...props} />;
};

const mapStateToProps = (state) => ({
  customer: state.customer.data.token,
  wishlistMessages: state.messages.items,
});

const mapDispatchToProps = (dispatch) => {
  return {
    lock: (callbackFunction) => {
      dispatch(commonActions.lock(callbackFunction));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WishlistReduxContainer);
