import { useMediaQuery } from "react-responsive";
import { MDBCol, MDBRow, MDBBox } from "mdbreact";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import Heading from "./heading";
import ActionsLeft from "./actionsLeft";
import ActionsRight from "./actionsRight";

const Details = ({
  data,
  storeCode,
  mediaGallery,
  setSelectedSwatch,
  setSelectedSize,
  selectedSize,
  selectedSwatch,
  activeGalleryImage,
  setActiveGalleryImage,
  addCompareMethod = { addCompareMethod },
  productVariant,
}) => {
  const isTablet = useMediaQuery({ maxWidth: 992 });
  const isMobile = useMediaQuery({ maxWidth: 575.98 });
  const { isLoggedIn, getCustomer } = useCustomer();
  let loggedIn = isLoggedIn();
  const customer = getCustomer();
  let variations = [];

  return (
    <MDBBox className={`product-details ${storeCode}`}>
      <Heading
        isMobile={isMobile}
        data={data}
        storeCode={storeCode}
        isTablet={isTablet}
        mediaGallery={mediaGallery}
        customer={customer}
        activeGalleryImage={activeGalleryImage}
        setActiveGalleryImage={setActiveGalleryImage}
        productVariant={productVariant}
        loggedIn={loggedIn}
      />
      <MDBRow className={`${!isTablet && "border-top pt-5"}`}>
        <MDBCol lg="6" className={``}>
          <ActionsLeft
            data={data}
            loggedIn={loggedIn}
            storeCode={storeCode}
            selectedSwatch={selectedSwatch}
            selectedSize={selectedSize}
            setSelectedSwatch={setSelectedSwatch}
            setSelectedSize={setSelectedSize}
          />
        </MDBCol>
        <MDBCol lg="6" className={``}>
          <ActionsRight
            data={data}
            loggedIn={loggedIn}
            storeCode={storeCode}
            customer={customer}
            selectedSwatch={selectedSwatch}
            selectedSize={selectedSize}
            variations={variations}
            addCompareMethod={addCompareMethod}
            productVariant={productVariant}
          />
        </MDBCol>
      </MDBRow>
    </MDBBox>
  );
};

export default Details;
