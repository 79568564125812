import { MDBContainer, MDBRow, MDBBox, MDBTypography } from "mdbreact";
import CmsPage from "app/pages/cms/";
import SeoHelmet from "app/layout/seoHelmet/seoHelmet";
import Breadcrumbs from "app/layout/breadcrumbs";

const PrivacyPolicy = (props) => {
  return (
    <>
      <SeoHelmet url={`/privacy-policy`} title={`Privacy Policy`} />
      <MDBContainer fluid className={` privacy-policy-wrapper`}>
        <MDBRow className="mb-90">
          {/* <MDBCol>
              <Breadcrumbs />
            </MDBCol> */}
          <MDBBox className="title-with-background w-100">
            <MDBContainer>
              <MDBTypography tag="h1" className="page-title">
                Privacy Policy
              </MDBTypography>
            </MDBContainer>
          </MDBBox>
        </MDBRow>
        <MDBRow className={``}>
          <MDBBox className="w-100">
            <MDBContainer>
              <Breadcrumbs />
            </MDBContainer>
          </MDBBox>
        </MDBRow>
        <MDBContainer className="content">
          <CmsPage
            dangHtml={false}
            identifier={`privacy-policy`}
            url={`privacy-policy`}
            variables={{ url: "privacy-policy" }}
            breadcrumbs
          />
        </MDBContainer>
      </MDBContainer>
    </>
  );
};

export default PrivacyPolicy;
