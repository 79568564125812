import { memo, useEffect } from "react";
import { Link } from "react-router-dom";
import { MDBCol, MDBBox, MDBRow, MDBTypography } from "mdbreact";
import { isEqual } from "lodash";
import Price from "core/components/price";
import useProducts from "app/state/hooks/productsHooks/useProducts";
import { useCart } from "app/state/hooks/cartHooks/useCart";
import Icon from "app/assets/icon/icon";
import Image from "app/layout/image/image";
import QtyContainer from "./qtyContainer";

const MiniCartItem = memo(
  ({
    item,
    hideMiniCart,
    quantity,
    setQuantity,
    updateCart,
    lock,
    loading,
    setLoading,
  }) => {
    const { getProductLinkComponent } = useProducts();
    const { removeItemFromCart } = useCart();
    useEffect(() => {
      if (item.quantity !== quantity) {
        setQuantity(item.quantity);
      }
    }, [item, quantity, setQuantity]);

    const webpDirectory = "catalog/product/webp/" + item.product.name + "/";

    const explodedImageSource = item?.product?.small_image?.url?.split(/\//g);
    const webpImage = explodedImageSource[explodedImageSource?.length - 1]
      ?.replace(/.png/, ".webp")
      ?.replace(/.jpg/, ".webp")
      ?.replace(/.webp/, ".webp");
    return (
      <>
        <MDBCol size={`3`} className="minicart-item">
          <MDBBox className="minicart-item-image">
            <Link
              to={getProductLinkComponent(item.product, { onlyLink: true })}
            >
              <Image
                source={item.product.small_image.url}
                webpImage={webpDirectory + webpImage}
                backend={true}
                backend_public={true}
                mediaLink={true}
              />
            </Link>
          </MDBBox>
        </MDBCol>
        <MDBCol
          size="9"
          className="minicart-item-content d-flex flex-column justify-content-between"
        >
          <MDBRow>
            <MDBCol className={`d-flex align-items-start`}>
              <Link
                onClick={hideMiniCart}
                className="minicart-item-name text-default"
                to={getProductLinkComponent(item.product, { onlyLink: true })}
              >
                <MDBTypography tag="p" className={`notoserif`}>
                  {item.product.name}
                </MDBTypography>
              </Link>
              <MDBBox
                onClick={() => {
                  if (!loading) {
                    setLoading(`loading-${item.id}`);
                    removeItemFromCart(item.id).then(() => {
                      setLoading(false);
                    });
                  }
                }}
              >
                <Icon icon="trashIcon" />
              </MDBBox>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            {/* <QtyContainer
              quantity={quantity}
              setQuantity={setQuantity}
              loading={loading}
              setLoading={setLoading}
              item={item}
              updateCart={updateCart}
              lock={lock}
            /> */}
            <MDBCol size="7">
              {item?.configurable_options?.map((option, index) => {
                return (
                  <MDBTypography
                    tag="p"
                    className="item-option f13 montserrat-medium text-capitalize"
                    key={`option-${item.id}`}
                  >
                    {option.value_label}
                  </MDBTypography>
                );
              })}
            </MDBCol>
            <MDBCol
              size="5"
              className="minicart-item-price d-flex align-items-end justify-content-end"
            >
              <MDBTypography tag="p" className={`text-primary notoserif-bold`}>
                <Price
                  price={
                    item.product.price_range.minimum_price.regular_price.value *
                    item.quantity
                  }
                  type={`fixed`}
                />
              </MDBTypography>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default MiniCartItem;
