export const storeConfig = import(
  "./storeConfig.json" /*webpackChunkName: "storeConfig" */
);

export const categories = import(
  "./categories.json" /*webpackChunkName: "categories" */
);

export const cmsBlocks = import(
  "./cmsBlocks.json" /*webpackChunkName: "cmsBlocks" */
);

export const galleries = import(
  "./galleries.json" /*webpackChunkName: "galleries" */
);

// export const testimonials = import(
//   "./testimonials.json" /*webpackChunkName: "testimonials" */
// );

export const testimonials = [];

export const catalogsdata = import(
  "./catalogsdata.json" /*webpackChunkName: "catalogsdata" */
);

// export const careers = import(
//   "./careers.json" /*webpackChunkName: "careers" */
// );

export const careers = [];

export const cmsPages = import(
  "./cmsPages.json" /*webpackChunkName: "cmsPages" */
);
