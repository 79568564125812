import { useMemo } from "react";
import { MDBContainer, MDBCollapse, MDBBox } from "mdbreact";
import { MobileNavigation } from "./mobileNavigation";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";

const MobileMenu = ({
  open,
  setOpen,
  menuItems,
  props,
  toggleHamburger,
  closeBurger,
  setCollapseID,
  collapseID,
  store,
}) => {
  let navigation = MobileNavigation(
    menuItems,
    closeBurger,
    toggleHamburger,
    setOpen,
    open,
    collapseID,
    setCollapseID,
    props
  );

  let { isLoggedIn } = useCustomer();
  let token = isLoggedIn();

  /**
   * This does not work as expected, memoization does not work accordingly (due to props that are required for re-rendering the menu items)
   */
  const MemoizedNavigation = useMemo(() => {
    return navigation[0];
  }, [navigation]);
  return (
    <MDBContainer
      fluid
      id={`mobile-navigation`}
      className={`align-items-start ${
        (open && `is-open`) || `is-closed`
      } px-0 ${store}`}
    >
      <MDBCollapse
        isOpen={open}
        navbar
        className={`menu-inner ${store} ${token && "logged-in"}`}
      >
        {MemoizedNavigation}
      </MDBCollapse>
    </MDBContainer>
  );
};
export default MobileMenu;
