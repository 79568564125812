import { MDBRow, MDBContainer } from "mdbreact";
import Button from "core/components/button";
import { ModalConsumer } from "core/components/modal/ModalContext";
import Login from "app/pages/customer/login";

const ResetPassSuccess = (props) => {
  return (
    <>
      <>
        <MDBContainer className={"page-main-content forgot-password fourth"}>
          <h1 className="mb-5">Your Password Has Been Successfully Changed</h1>
          <ModalConsumer value={{ useWrapper: false }}>
            {({ showModal, hideModal }) => (
              <Button
                to={`/`}
                color="primary"
                className={"big m-auto"}
                direction={"mr-2"}
                children={<span>Back to Login</span>}
                onClick={() => {
                  showModal(() => {
                    return <Login {...props} hideModal={hideModal} />;
                  });
                }}
              />
            )}
          </ModalConsumer>
        </MDBContainer>
        <MDBRow className={"w-100 spacer"}></MDBRow>
      </>
      )
    </>
  );
};

export default ResetPassSuccess;
