import { MDBContainer } from "mdbreact";
import React from "react";
import useDownloads from "../hooks/useDownloads";
import Heading from "./heading";
import Listing from "./listing";

/**
 *
 * @param {boolean} showBackButton If true, header will include back button
 * @param {string} headingClassName Additional classes for heading
 * @param {string} listingClassName Additional classes for listing
 */
export const ImageDownloaderPage = ({
  showBackButton = true,
  showBackButtonInFooter = false,
  headingClassName = "",
  listingClassName = "",
}) => {
  const { formattedData, loading, error, refetch } = useDownloads();

  let itemCount = formattedData !== undefined ? formattedData.length : null;
  return (
    <MDBContainer
      id={`my-downloads-page`}
      className={`mt-4 ${headingClassName}`}
    >
      <Heading
        itemCount={itemCount}
        showBackButton={showBackButton}
        data={formattedData}
        refetch={refetch}
      />
      <Listing
        className={listingClassName}
        data={formattedData}
        loading={loading}
        error={error}
        refetch={refetch}
        itemCount={itemCount}
      />
      <Heading
        itemCount={itemCount}
        showBackButton={showBackButtonInFooter}
        data={formattedData}
        refetch={refetch}
        showTitle={false}
      />
    </MDBContainer>
  );
};
