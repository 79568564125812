import { MDBTypography } from "mdbreact";
import Price from "app/layout/price";

const ProductPrice = ({ product, storeCode }) => {
  return (
    <MDBTypography tag="p" className={`pdp-price ${storeCode}`}>
      {product.price_range !== undefined ? (
        <Price product={product} layout={`pdp`} />
      ) : (
        <></>
      )}
    </MDBTypography>
  );
};
export default ProductPrice;
