import { useMemo, Suspense, lazy, useState, useEffect } from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { isEmpty } from "core/helpers/functions";
import useProducts from "app/state/hooks/productsHooks/useProducts";
import useStore from "app/state/hooks/storeHooks/useStore";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import { ErrorPage } from "app/config/routing/pages/guestPages";
import Breadcrumbs from "app/layout/breadcrumbs";
import { compareActions } from "app/state/redux/data/compare";
import useCompare from "app/state/hooks/compareHook";
import { ProductSchema } from "app/schemas/productSchema";
import Content from "./content";
import MediaSlider from "./mediaSlider";
import { PRODUCTS, PRODUCTS_CUSTOMERS } from "./queries";
import ProductSEO from "./seo";
import ProductDetails from "./productDetails";
import { getProductDetailsImage, removeImageWithRole } from "./helper";
import Skeleton from "./skeleton";
import CompareSidebar from "../category/compareSidebar";

const RelatedProducts = lazy(() =>
  import("./relatedProducts" /*webpackChunkName: "plp"*/)
);
const ProductDesigner = lazy(() =>
  import("./designer" /*webpackChunkName: "plp"*/)
);
const RecentlyViewedProducts = lazy(() =>
  import("./recentlyProducts" /*webpackChunkName: "plp"*/)
);
const ProductHotspot = lazy(() =>
  import("./productHotspot" /*webpackChunkName: "plp"*/)
);

// const ProductSidebar = lazy(() =>
//   import("./sidebar" /*webpackChunkName: "plp"*/)
// );

const RelatedCollections = lazy(() =>
  import("./relatedCollections" /*webpackChunkName: "plp"*/)
);

const ProductPage = (props) => {
  const { state } = useLocation();

  const { GetProducts } = useProducts();
  let dispatch = useDispatch();
  const { isLoggedIn, getCustomer } = useCustomer();
  const [selectedSwatch, setSelectedSwatch] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [mediaGallery, setMediaGallery] = useState(false);
  const [baseImage, setBaseImage] = useState(null);
  const [productVariant, setProductVariant] = useState(null);
  const [compareMenu, setCompareMenu] = useState(false);
  const [compareData, setCompareData] = useState([]);
  const [activeGalleryImage, setActiveGalleryImage] = useState(1);
  let token = isLoggedIn();
  const customer = getCustomer();
  const isMobile = useMediaQuery({ maxWidth: 992 });

  const {
    addCompareFunc,
    getCompareData,
    createCompareList,
    getCompareListId,
    removeCompareFunc,
  } = useCompare();

  const toggleCompareMenu = () => {
    setCompareMenu(!compareMenu);
  };

  const toggleSidebarMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const addCompareMethod = async (data) =>
    addCompareFunc(
      data,
      getCompareListId,
      setCompareData,
      setCompareMenu,
      customer
    );

  const removeCompareMethod = async (data) =>
    removeCompareFunc(data, getCompareListId, setCompareData, customer);

  useEffect(async () => {
    let compareItems;
    const compareData = getCompareData();
    if (customer?.compare_list?.uid) {
      compareItems = await getCompareListId(customer?.compare_list?.uid);
    } else {
      if (getCompareData()?.data?.compareItems?.listCleared === false) {
        dispatch(
          compareActions.setCompare({ compareItems: [], listCleared: true })
        );
        compareItems = [];
      } else {
        compareItems = await getCompareListId(
          compareData?.data?.compareItems?.uid
        );
      }
    }
    setCompareData(compareItems?.items);
  }, [customer]);

  const [selectedSize, setSelectedSize] = useState(null);
  const { getStoreCode } = useStore();
  let formattedData = state?.item ? { ...state.item } : {};
  let storeCode = getStoreCode();
  const { data, loading, error } = GetProducts(
    token ? PRODUCTS_CUSTOMERS : PRODUCTS,
    {
      filter: { sku: { eq: props.data.urlResolver.sku } },
      pageSize: 1,
    }
  );

  if (data !== undefined && loading === false && error === undefined) {
    formattedData = { ...data?.products?.items[0] };
  }

  useEffect(() => {
    setBaseImage(formattedData.image);
    let findMediaGallery = formattedData?.variants?.find((data) => {
      if (data.attributes.length > 1 && selectedSize) {
        return (
          data.attributes?.[0]?.label === selectedSwatch?.label &&
          data.attributes?.[1]?.label === selectedSize
        );
      }
      return data.attributes?.[0]?.label === selectedSwatch?.label;
    })?.product?.media_gallery;

    //for removing image with roles such as thumbnail and small image
    findMediaGallery = removeImageWithRole(findMediaGallery);

    //sorting image by position
    findMediaGallery = findMediaGallery?.sort((a, b) => {
      return a.url - b.url;
    });

    // let findSwatchImage = selectedSwatch?.image.split("/");
    findMediaGallery = findMediaGallery?.filter(
      (mediaGallery) => !mediaGallery?.url.includes(".topp")
    );

    setMediaGallery(findMediaGallery);
  }, [selectedSwatch, selectedSize]);

  useEffect(() => {
    if (!selectedSwatch && !selectedSize) return;
    let variantCombination = {
      swatchLabel: selectedSwatch?.label,
      sizeLabel: selectedSize,
    };

    let variant = formattedData.variants.find((variant) => {
      if (variantCombination?.sizeLabel && variant.attributes?.length > 1) {
        return (
          variant.attributes?.[0]?.label === variantCombination?.swatchLabel &&
          variant.attributes?.[1]?.label === variantCombination?.sizeLabel
        );
      } else {
        return (
          variant.attributes?.[0]?.label === variantCombination?.swatchLabel
        );
      }
    });

    if (variant) {
      variant["configurable_options"] = formattedData?.configurable_options;
    }
    setProductVariant(variant);
  }, [selectedSwatch, selectedSize]);

  let currentlyViewedProduct = useMemo(
    () => ({
      ...(data !== undefined &&
      data.products !== undefined &&
      data.products.items !== undefined
        ? data.products.items[0]
        : []),
    }),
    [data]
  );

  if (data !== undefined && isEmpty(data?.products?.items)) {
    return <ErrorPage />;
  }

  let productDetailsImage = null;

  if (loading) return <Skeleton token={token} />;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <script type="application/ld+json">
          {typeof data !== "undefined" && ProductSchema(data, getStoreCode())}
        </script>
      </Helmet>
      <div id={"product"}>
        <MDBContainer className={`product-page page-full-width ${storeCode}`}>
          <ProductSEO product={formattedData} />
          <MDBRow>
            <Breadcrumbs />
            <MDBCol lg="12" className={`${!isMobile && "px-0"}`}>
              <MediaSlider
                mediaGallery={mediaGallery}
                loading={loading}
                storeCode={storeCode}
                productItem={formattedData}
                activeGalleryImage={activeGalleryImage}
                baseImage={baseImage}
              />
            </MDBCol>
            <MDBCol lg="12" className={`${!isMobile && "px-0"}`}>
              <Content
                data={formattedData}
                loading={loading}
                storeCode={storeCode}
                mediaGallery={mediaGallery}
                setSelectedSwatch={setSelectedSwatch}
                setSelectedSize={setSelectedSize}
                activeGalleryImage={activeGalleryImage}
                setActiveGalleryImage={setActiveGalleryImage}
                selectedSwatch={selectedSwatch}
                selectedSize={selectedSize}
                addCompareMethod={addCompareMethod}
                productVariant={productVariant}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <ProductDetails
          data={formattedData}
          loading={loading}
          storeCode={storeCode}
          productDetailsImage={productDetailsImage}
          selectedSwatch={selectedSwatch}
          selectedSize={selectedSize}
          toggleSidebarMenu={toggleSidebarMenu}
          productVariant={productVariant}
        />
        <Suspense fallback={<></>}>
          <ProductHotspot productData={formattedData} storeCode={storeCode} />
        </Suspense>
        <Suspense fallback={<></>}>
          <RelatedProducts
            loading={loading}
            storeCode={storeCode}
            token={token}
            data={formattedData}
            sku={formattedData?.sku}
          />
        </Suspense>
        <Suspense fallback={<></>}>
          <ProductDesigner
            categories={formattedData.categories}
            storeCode={storeCode}
            data={formattedData}
          />
        </Suspense>
        <Suspense fallback={<></>}>
          <RecentlyViewedProducts
            storeCode={storeCode}
            currentlyViewedProduct={currentlyViewedProduct}
            addCompareMethod={addCompareMethod}
          />
        </Suspense>
        {formattedData?.related_collection_categories && (
          <Suspense fallback={<></>}>
            <RelatedCollections data={formattedData} />
          </Suspense>
        )}
        {/* <ProductSidebar
          toggleSidebarMenu={toggleSidebarMenu}
          menuOpen={menuOpen}
          productVariant={productVariant}
        /> */}
        {compareData?.length > 0 && (
          <CompareSidebar
            toggleCompareMenu={toggleCompareMenu}
            compareMenu={compareMenu}
            data={compareData}
            removeCompareMethod={removeCompareMethod}
          />
        )}
      </div>
    </>
  );
};

export default ProductPage;
