import { MDBBox } from "mdbreact";
// --------------------------------------------------------------------------------------------------------------------------------------------------------
// This is the default modal wrapper, change it to comply with the modals in your design
// --------------------------------------------------------------------------------------------------------------------------------------------------------
const ModalWrapper = (props) => {
  return (
    <MDBBox className="pwa-modal">
      <MDBBox className="content">{props.children}</MDBBox>
    </MDBBox>
  );
};

export default ModalWrapper;
