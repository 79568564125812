import {
  MDBContainer,
  MDBRow,
  MDBTypography,
  MDBBox,
  MDBLink,
  MDBCol,
} from "mdbreact";
import CmsBlock from "core/components/cmsBlock";
import SeoHelmet from "app/layout/seoHelmet";

const ErrorPage = () => {
  return (
    <MDBBox className={`error-page`}>
      <SeoHelmet url={`/error`} title={`Error Page`} status={`404`} />
      <MDBContainer fluid>
        <MDBRow>
          <CmsBlock dangHtml={true} id="error-page-banner" />
        </MDBRow>
      </MDBContainer>
      <MDBContainer fluid>
        <MDBRow className={`mb-90 error-title-wrapper`}>
          <MDBBox className="title-with-background w-100">
            <MDBContainer>
              <MDBTypography tag="h1" className="page-title">
                Oops!
              </MDBTypography>
            </MDBContainer>
          </MDBBox>
        </MDBRow>
        <MDBRow className={`mb-100 error-text-wrapper`}>
          <MDBContainer>
            <MDBRow>
              <MDBCol size="12">
                <MDBTypography tag="p" className={`mb-5`}>
                  We can’t seem to find the page you are looking for
                </MDBTypography>
              </MDBCol>
              <MDBCol size="12">
                <MDBTypography tag="p" className={`text-primary mb-4`}>
                  Here are some helpful links
                </MDBTypography>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size="12" className={`d-flex`}>
                <MDBLink to={`/`} className={`black-link link mr-5`}>
                  <MDBTypography>Home</MDBTypography>
                </MDBLink>
                <MDBLink
                  to={`/contact`}
                  tag="span"
                  className={`black-link link`}
                >
                  <MDBTypography>Contact</MDBTypography>
                </MDBLink>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>
      </MDBContainer>
    </MDBBox>
  );
};

export default ErrorPage;
