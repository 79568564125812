import { MDBBox } from "mdbreact";
import BarLoader from "react-spinners/BarLoader";
import ClipLoader from "react-spinners/ClipLoader";
import "./loader.scss";

const ReactLoader = (props) => {
  let loading = typeof props.loading === "undefined" ? true : props.loading;
  const loader = {
    color: "#11415a",
    height: 100,
    width: 100,
    type: "MutatingDots",
  };
  if (props.type === "bar") {
    return (
      <MDBBox className={"loading-container"}>
        <BarLoader
          loading={loading}
          color={
            typeof props.color !== "undefined"
              ? props.color
              : loader.color
          }
          height={
            typeof props.height === "undefined"
              ? loader.height
              : props.height
          }
          width={
            typeof props.width === "undefined"
              ? loader.width
              : props.width
          }
        />
      </MDBBox>
    );
  }
  return (
    <MDBBox className={"loading-container"}>
      <ClipLoader
        loading={loading}
        size={typeof props.size !== "undefined" ? props.size : 50}
        color={
          typeof props.color !== "undefined"
            ? props.color
            : loader.color
        }
        height={
          typeof props.height === "undefined"
            ? loader.height
            : props.height
        }
        width={
          typeof props.width === "undefined"
            ? loader.width
            : props.width
        }
      />
    </MDBBox>
  );
};

export default ReactLoader;
