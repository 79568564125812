import { useState, createContext } from "react";

const PayContext = createContext({
  pay: {
    address: {
      firstname: "",
      lastname: "",
    },
  },
  selectedPaymentMethod: {
    method: "",
  },
  setPay: () => {},
  setSelectedPaymentMethod: () => {},
});

function PayProvider(props) {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({
    method: "terms",
  });
  const [pay, setPay] = useState({
    hash: false,
    address: {
      firstname: "",
      lastname: "",
      telephone: "",
      country_id: "US",
      country_code: "US",
      city: "",
      region: {
        region_code: "AL",
        region: "Alabama",
        region_id: 1,
      },
      street: {
        one: "",
        two: "",
      },
      company: "",
      postcode: "",
    },
  });

  return (
    <PayContext.Provider
      value={{ pay, setPay, selectedPaymentMethod, setSelectedPaymentMethod }}
    >
      {props.children}
    </PayContext.Provider>
  );
}

export { PayContext, PayProvider };
