import { MDBCol, MDBRow } from "mdbreact";
import React from "react";
import { default as ReactLoadingSkeleton } from "react-loading-skeleton";

const Skeleton = () => {
  return (
    <MDBCol style={{ minHeight: "300px" }}>
      <MDBRow className={`h-100`}>
        <MDBCol>
          <ReactLoadingSkeleton height={`100%`} />
        </MDBCol>
        <MDBCol>
          <ReactLoadingSkeleton height={`100%`} />
        </MDBCol>
        <MDBCol>
          <ReactLoadingSkeleton height={`100%`} />
        </MDBCol>
        <MDBCol>
          <ReactLoadingSkeleton height={`100%`} />
        </MDBCol>
      </MDBRow>
    </MDBCol>
  );
};

export default Skeleton;
