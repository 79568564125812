import { MDBCol, MDBBox } from "mdbreact";
import { Link } from "react-router-dom";

const SubcategoriesList = ({
  subcategories,
  setShowDropdown,
  setActiveCategoryImage,
  store,
  activeCategory,
  activeMenuItems,
}) => {
  let slicedSubcategories =
    subcategories &&
    subcategories?.filter((x) => x.product_count > 0)?.slice(0, 22);

  return (
    <MDBCol size="3" className={`subcategories-container  ${store}`}>
      {/* splitting subcategories into two column when there are more than 11 subcategories in list */}
      <MDBBox
        className={`subcategories-list
        ${
          typeof slicedSubcategories !== "undefined" &&
          slicedSubcategories.length > 10 &&
          "two-columns-layout"
        }`}
      >
        {slicedSubcategories &&
          slicedSubcategories?.map((subcategory) => {
            let urlPath = subcategory.url_path
              ? subcategory.url_path
              : subcategory.url_key
              ? subcategory.url_key
              : "";
            urlPath = urlPath.includes("/") ? urlPath : "/" + urlPath;
            return (
              <span
                key={`subcategory-item-${subcategory.name && subcategory.name}`}
                className={`subcategory-item`}
              >
                <Link
                  to={"/" + urlPath}
                  className="line-animation"
                  onClick={() => setShowDropdown(false)}
                  onMouseOver={() => {
                    if (subcategory.menu_image) {
                      setActiveCategoryImage(
                        subcategory.menu_image ? subcategory.menu_image : false
                      );
                    }
                  }}
                  onMouseLeave={() => {
                    setActiveCategoryImage(
                      activeMenuItems.children[activeCategory].menu_image
                        ? activeMenuItems.children[activeCategory].menu_image
                        : false
                    );
                  }}
                >
                  <span className="hover">
                    {subcategory.name && subcategory.name}
                  </span>
                </Link>
              </span>
            );
          })}
      </MDBBox>
    </MDBCol>
  );
};
export default SubcategoriesList;
