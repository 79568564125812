import { useSelector, useDispatch } from "react-redux";
import messagesActions from "core/state/redux/data/messages/actions";

/**
 * useMessages contains all functions for manipulating with UI notifications
 */
const useMessages = () => {
  const messages = useSelector((state) => state.messages);
  const dispatch = useDispatch();
  // write a getter for queries (cmsBlocks, cmsPages)

  /**
   * By default we retrieve all messages that are global and type success, to fetch all messages, you just need to set key as "all"
   * @param {string} key
   * @param {string} type
   */
  const getMessages = (key = "global", type = "success") => {
    if (Object.keys(messages).length === 0) return [];

    if (key === "all") return [...messages.items];
    let selectedMessages = messages.items.filter(
      (message) => message.target === key && message.type === type
    );

    return selectedMessages;
  };

  const addMessage = async (message, type = "success", key = "global") => {
    await dispatch(messagesActions.addMessage(message.toString(), type, key));
  };

  return { getMessages, addMessage };
};

export default useMessages;
