/* Used on:
    Account Dashboard - Add New Shipping Address
*/
export const addressType = [
  { value: "Commercial", label: "Commercial" },
  { value: "Residential", label: "Residential" },
];

/* Used on:
    Participate In Where To Buy Component
*/
export const businessType = [
  { value: 1, label: "Retailer" },
  { value: 2, label: "Int Design" },
  { value: 3, label: "Hospitality" },
];

/* Used on:
    Participate In Where To Buy Component
*/
export const participateInWhereToBuy = [
  { value: "1", label: "Yes" },
  { value: "0", label: "No" },
];

/* Used on Express Registration Page

*/
export const businessTypeOptions = [
  { value: 1, label: "Retailer" },
  { value: 2, label: "Int Design" },
  { value: 3, label: "Hospitality" },
];

export const tearsheetMarkupOptions = [
  { value: 1.5, label: "1.5" },
  { value: 2, label: "2" },
  { value: 2.5, label: "2.5" },
  { value: 3, label: "3" },
];
