import { MDBBox, MDBBtn, MDBCol, MDBRow, MDBTypography } from "mdbreact";
import { memo } from "react";
import { isEqual } from "lodash";
import { useHistory } from "react-router";

const ExpressRegistrationOnLoginModal = memo(
  ({ toggle }) => {
    const history = useHistory();
    return (
      <MDBRow>
        <MDBCol size="12">
          <MDBBox className={`modal-part`}>
            <MDBTypography
              tag="p"
              className={`modal-subtitle text-uppercase mb-3`}
            >
              Guest Registration
            </MDBTypography>
            {/* <MDBTypography tag="p" className={`modal-text mb-2`}>
              Add items to your wishlist/projects
            </MDBTypography>
            <MDBTypography tag="p" className={`modal-text mb-5`}>
              Compare Products
            </MDBTypography> */}
            <MDBBtn
              color={"primary"}
              className={"btn-without-icon"}
              onClick={() => {
                toggle();
                history.push("/quick-register");
              }}
              children={
                <>
                  <MDBBox tag="span">Register Now</MDBBox>
                </>
              }
              type="submit"
            />
          </MDBBox>
        </MDBCol>
      </MDBRow>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default ExpressRegistrationOnLoginModal;
