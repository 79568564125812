import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import CheckButton from "core/components/checkButton";
import { messagesActions } from "core/state/redux/data/messages";
import Icon from "app/assets/icon/icon";
import { useWishlist } from "app/state/hooks/wishlistHooks/wishlistHookAddRemove";

const WishlistPlpItem = (props) => {
  const { addToWishlist, removeFromWishlist } = useWishlist();
  const history = useHistory();
  let dispatch = useDispatch();
  let { list, checked } = props;
  const [loading, setLoading] = useState(false);
  const [collectionData, setCollectionData] = useState([]);
  useEffect(() => {
    let input = [];
    props.collectionProducts?.map((item) => input.push(item.sku));
    setCollectionData(input);
  }, []);
  let longPojectName = "";
  longPojectName = list.name.substring(0, 18);

  return (
    <MDBContainer className="choose-project-wrapper">
      <MDBRow className="choose-project align-items-center">
        {list.name === "My Favorites" && "Favorites" ? (
          <>
            <MDBCol
              size="2"
              className="d-flex favorite-icons align-items-center justify-content-center"
            >
              {/* {checked ? (
                <Icon icon="passionIcon" />
              ) : (
                <Icon icon="passionIcon" />
              )} */}
              <Icon icon="passionIcon" />
            </MDBCol>
            <MDBCol size="8" className="my-4 p-0">
              <MDBRow className={"text-left"}>
                <MDBCol size="12" className={`project-name`}>
                  {(list.name === "My Favorites" && "Favorites") ||
                    (list.name.length > 18 && longPojectName + "...") ||
                    list.name}
                </MDBCol>
                <MDBCol size="12" className={`number-of-items`}>
                  {list.items_count} Items
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol
              size="2"
              className={`my-4 d-flex align-items-center justify-content-end check-wishlist-wrapper ${
                loading ? "loading-check" : ""
              }`}
            >
              <CheckButton
                containerClass={"checkbox-container check-button multiselect"}
                checked={checked}
                wrapperClass={`${checked ? "checkmark" : "checkmark-selected"}`}
                onChange={() => {
                  setLoading(true);
                  if (checked) {
                    let removeWishlistPlp = {
                      wishlist: list.name,
                      skus:
                        collectionData?.length > 0
                          ? collectionData
                          : [props.sku],
                    };
                    removeFromWishlist(
                      removeWishlistPlp,
                      "wishlist-modal"
                    ).then(() => {
                      setLoading(false);
                    });
                    // modalToggleSecond();
                  } else {
                    let addWishlistPlp = {
                      products:
                        collectionData?.length > 0
                          ? collectionData
                          : [{ sku: props.sku }],
                      wishlists: [{ name: list.name }],
                    };
                    addToWishlist(addWishlistPlp, "wishlist-modal").then(() => {
                      setLoading(false);
                    });
                  }
                }}
                type={"checkbox"}
              />
            </MDBCol>
          </>
        ) : (
          <>
            <MDBCol size="10" className="my-4">
              <MDBRow
                className={`text-left ${checked ? "checked-project" : ""}`}
              >
                <MDBCol
                  size="12"
                  className={`project-name not-favorite`}
                  onClick={() => {
                    history.push(
                      `/customer/projects?project_id=${list.id}`
                    );
                  }}
                >
                  {(list.name.length > 18 && longPojectName + "...") ||
                    list.name}
                </MDBCol>
                <MDBCol size="12" className={`number-of-items`}>
                  {list.items_count} Items
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol
              size="2"
              className={`my-4 d-flex align-items-center justify-content-end check-wishlist-wrapper ${
                loading ? "loading-check" : ""
              }`}
            >
              <CheckButton
                containerClass={
                  "checkbox-container wishlist-plp check-button multiselect"
                }
                checked={checked}
                wrapperClass={`${checked ? "checkmark" : "checkmark-selected"}`}
                onChange={() => {
                  setLoading(true);
                  if (checked) {
                    let removeWishlistPlp = {
                      wishlist: list.name,
                      skus:
                        collectionData?.length > 0
                          ? collectionData
                          : [props.sku],
                    };
                    removeFromWishlist(
                      removeWishlistPlp,
                      "wishlist-modal"
                    ).then(() => {
                      setLoading(false);
                    });
                    // modalToggleSecond();
                  } else {
                    let input = [];
                    collectionData?.map((data) => input.push({ sku: data }));
                    let addWishlistPlp = {
                      products: input.length > 0 ? input : [{ sku: props.sku }],
                      wishlists: [{ name: list.name }],
                    };
                    addToWishlist(addWishlistPlp, "wishlist-modal").then(() => {
                      setLoading(false);
                      if (props.collectionProducts?.length > 0) {
                        dispatch(
                          messagesActions.addMessage(
                            "Collection successfully added to your project.",
                            "success"
                          )
                        );
                      }
                    });
                  }
                }}
                type={"checkbox"}
              />
            </MDBCol>
          </>
        )}
      </MDBRow>
    </MDBContainer>
  );
};

export default WishlistPlpItem;
