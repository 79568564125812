import { useState } from "react";
import Customer from "./customer";

const CustomerStateContainer = (props) => {
  let currentLocation = props.location.pathname;
  let currentUrl = currentLocation.substring(1);
  const [selectedLink, setSelectedLink] = useState(currentUrl);
  if (selectedLink !== currentUrl) {
    setSelectedLink(currentUrl);
  }
  return (
    <Customer
      {...props}
      currentLocation={currentUrl}
      selectedLink={selectedLink}
      setSelectedLink={setSelectedLink}
    />
  );
};

export default CustomerStateContainer;
