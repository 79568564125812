import Pages from "./pages";
import customerPages from "core/config/routing/pages/customerPages";
import Dashboard from "./dashboard";

const PagesStateContainer = (props) => {
  let { currentLocation } = props;
  let selectedPage = Dashboard;

  customerPages.sort((a, b) => (a.url > b.url ? 1 : -1));
  let filteredPage = customerPages.find((page) => {
    return (
      (page.exact && page.url.includes(currentLocation)) ||
      (page.exact === false && currentLocation.includes(page.url))
    );
  });

  if (typeof filteredPage !== "undefined") {
    selectedPage = filteredPage.component;
  }

  return <Pages {...props} selectedPage={selectedPage} page={filteredPage} />;
};

export default PagesStateContainer;
