import { MDBContainer, MDBRow, MDBTypography, MDBBox, MDBBtn } from "mdbreact";
import { useCallback } from "react";
import CmsBlock from "core/components/cmsBlock";
import SeoHelmet from "app/layout/seoHelmet";

const CartSubmit = (props) => {
  const retry = useCallback(() => {
    props.refetch();
  }, [props]);
  return (
    <MDBBox className={`quote-thank-you-wrapper`}>
      <SeoHelmet url={`/quote-thank-you`} title={`Quote Thank Page`} />
      <MDBContainer fluid>
        <MDBRow>
          <CmsBlock dangHtml={true} id="thank-you-image" />
        </MDBRow>
      </MDBContainer>
      <MDBContainer fluid>
        <MDBRow className={`mb-40 quote-thank-you-title-wrapper`}>
          <MDBBox className="title-with-background w-100">
            <MDBContainer>
              <MDBTypography tag="h1" className="page-title">
                Thank you
              </MDBTypography>
            </MDBContainer>
          </MDBBox>
        </MDBRow>
        <MDBRow className={`mb-100 quote-thank-you-text-wrapper`}>
          <MDBContainer>
            <CmsBlock dangHtml={true} id="quote-thank-you-text" />
            <MDBBtn
              color="primary"
              outline
              href="/"
              onClick={retry}
              className="btn-with-icon btn-black-text"
              children={
                <>
                  <MDBBox tag="span">Homepage</MDBBox>
                  <MDBBox className="circle-icon" />
                </>
              }
            />
          </MDBContainer>
        </MDBRow>
      </MDBContainer>
    </MDBBox>
  );
};

export default CartSubmit;
