import { useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { MDBTooltip, MDBBox, MDBBtn } from "mdbreact";
// import { ImageDownloadIcon } from "core/components/imageDownloader";
import { ModalConsumer } from "core/components/modal/ModalContext";
import Input from "core/components/input/input";
import WishlistIcon from "app/layout/wishlistIcon";
import Icon from "app/assets/icon/icon";
// import DownloadTearsheet from "app/layout/downloadTearsheet";
import ShareProduct from "app/layout/shareProduct";
import Login from "app/pages/customer/login";
import { useCart } from "app/state/hooks/cartHooks/useCart";

const ActionsRight = ({
  data,
  loggedIn,
  storeCode,
  customer,
  selectedSwatch,
  selectedSize,
  variations,
  addCompareMethod,
  productVariant,
}) => {
  const [showDropdownOptions, setShowDropdownOptions] = useState(false);
  const [qty, setQty] = useState(1);
  const [loading, setLoading] = useState(false);
  const { addProductToCart } = useCart();
  const cartId = useSelector((state) => state.customer?.data?.cart?.id);
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const addQty = () => {
    setQty((prev) => prev + 1);
  };
  const minusQty = () => {
    setQty((prev) => (prev !== 1 ? prev - 1 : prev));
  };

  const changeQty = (e) => {
    let value = parseInt(e.target.value);
    if (value < 1) {
      setQty(1);
    } else {
      setQty(value);
    }
  };
  let productSkuWithVariants;

  selectedSize && selectedSize !== null
    ? variations.push(
        { label: selectedSwatch?.label },
        { label: selectedSize ? selectedSize : "" }
      )
    : variations.push({ label: selectedSwatch?.label });

  data.variants.filter((item) => {
    if (
      item.attributes[0]?.label === variations[0]?.label &&
      item.attributes[1]?.label === variations[1]?.label
    ) {
      return (productSkuWithVariants = item.product.sku);
    }
  });

  const addProductToCartFn = () => {
    setLoading(true);
    addProductToCart({
      input: {
        cart_id: cartId,
        cart_items: {
          parent_sku: data.sku,
          data: {
            quantity: qty,
            sku: productSkuWithVariants
              ? productSkuWithVariants
              : productVariant?.product.sku,
          },
        },
      },
      type: data.__typename,
    }).then(() => {
      setLoading(false);
    });
  };

  let downloadOptions = [
    {
      value: "Download Tearsheet",
      label: "Download Tearsheet",
    },
    //faze 2
    // {
    //   value: "Download Images",
    //   label: "Download Images",
    // },
  ];

  const button =
    customer?.show_price !== false
      ? (loggedIn && (
          <MDBBtn
            color="primary"
            className={`btn-with-icon btn-black-text mr-3 ${
              isMobile && "w-100"
            }`}
            outline
            onClick={addProductToCartFn}
            children={
              <>
                <MDBBox tag="span">
                  {" "}
                  <MDBBox tag="span">
                    {loading
                      ? "Adding to quote request..."
                      : "Add to quote request"}
                  </MDBBox>
                </MDBBox>
                <MDBBox className="circle-icon" />
              </>
            }
          />
        )) || (
          <ModalConsumer value={{ useWrapper: false }}>
            {({ showModal, hideModal }) => (
              <MDBBtn
                color="primary"
                className={`btn-with-icon btn-black-text mr-3 ${
                  isMobile && "w-100"
                }`}
                outline
                onClick={(e) => {
                  showModal(() => {
                    return <Login hideModal={hideModal} />;
                  });
                }}
                children={
                  <>
                    <MDBBox tag="span"> Login to send a quote request</MDBBox>
                    <MDBBox className="circle-icon" />
                  </>
                }
              />
            )}
          </ModalConsumer>
        )
      : null;

  return (
    <div className={`d-flex pdp-actions-right ${storeCode}`}>
      <MDBBox className={`d-flex`}>
        {loggedIn && customer?.show_price !== false ? (
          <div className={`controls`}>
            <Input
              type={"number"}
              wrapperClass={"quantity-field"}
              className={"qty-control-wrapper"}
              value={qty}
              plusClick={addQty}
              minusClick={minusQty}
              onChange={changeQty}
            />
          </div>
        ) : (
          <></>
        )}
        {!isMobile ? button : null}
      </MDBBox>
      <div className="action-buttons-holder d-flex">
        {isMobile ? button : null}
        <WishlistIcon
          product={data}
          productVariant={productVariant}
          className={`d-flex`}
          label={true}
          actions={{ showPopupOnAdd: true }}
          button={true}
          // actions={{
          //   removeOnClick: true,
          //   removeWishlist: "My Favorites",
          // }}
          isPdp={true}
        />

        <MDBBtn
          color="default"
          outline
          className={"compare-btn"}
          onClick={() => addCompareMethod(data?.id)}
          children={
            <MDBTooltip domElement placement="top" tag="span">
              <Icon icon="compare" />
              <span>Compare</span>
            </MDBTooltip>
          }
        />
      </div>
    </div>
  );
};
export default ActionsRight;
