import types from "./types";
import registerB2CCustomer from "./reducers/registerB2CCustomer";
import registerB2BCustomer from "./reducers/registerB2BCustomer";
import registerCustomerSuccess from "./reducers/registerCustomerSuccess";
import getCustomerInformationReducer from "./reducers/getCustomerInformation";
import setCustomerInformation from "./reducers/setCustomerInformation";
import _reduceCustomer from "./reducers/_reduceCustomer";
import _setCustomerToken from "./reducers/_setCustomerToken";
import createEmptyCart from "./reducers/createEmptyCart";
import setCartTokenReducer from "./reducers/setCartToken";
import setCartInformationReducer from "./reducers/setCartData";
import addItemToCartReducer from "./reducers/addItemToCart";
import removeItemfromCartReducer from "./reducers/removeItemFromCart";
import updateCartReducer from "./reducers/updateCart";
import clearCartReducer from "./reducers/clearCart";
import _reduceWishlist from "./reducers/_reduceWishlist";
import editCustomerAddress from "./reducers/editCustomerAddress.js";
import _reduceCustomerAddress from "./reducers/_reduceCustomerAddress";
import resetCustomerPasswordSuccess from "./reducers/resetCustomerPasswordSuccess";
import placeOrderSuccess from "./reducers/placeOrderSuccess";
import updateItemReducer from "./reducers/updateItemReducer";
import setCheckAdditional from "./reducers/setCheckAdditional";
import _updateCustomerData from "./reducers/_updateCustomerData";
import _toggleMinicart from "./reducers/_toggleMinicart";
import _reduceRecentlyViewed from "./reducers/_reduceRecentlyViewed";
import minimalOrderUpdate from "./reducers/minimalOrderUpdate";
import deleteCustomerAddressReducer from "./reducers/_deleteCustomerAddress";

// Main reducer
const customerReducerNew = (state, action) => {
  if (state === undefined) {
    return {};
  }

  if (action.type === types.REGISTER_B2C_CUSTOMER) {
    return registerB2CCustomer(state, action);
  }

  if (action.type === types.REGISTER_B2B_CUSTOMER) {
    return registerB2BCustomer(state, action);
  }

  if (action.type === types.REGISTER_CUSTOMER_SUCCESS) {
    return registerCustomerSuccess(state, action);
  }

  if (action.type === types.GET_CUSTOMER_INFORMATION) {
    return getCustomerInformationReducer(state, action);
  }

  if (action.type === types.SET_CUSTOMER_INFORMATION) {
    return setCustomerInformation(state, action);
  }

  if (action.type === types._SET_CUSTOMER_TOKEN) {
    return _setCustomerToken(state, action);
  }

  if (action.type === types._REDUCE_CUSTOMER) {
    return _reduceCustomer(state, action);
  }

  if (action.type === types.CREATE_EMPTY_CART) {
    return createEmptyCart(state, action);
  }

  if (action.type === types.SET_CART_INFORMATION) {
    return setCartInformationReducer(state, action);
  }

  if (action.type === types.SET_CART_TOKEN) {
    return setCartTokenReducer(state, action);
  }

  if (action.type === types.ADD_PRODUCT_TO_CART) {
    return addItemToCartReducer(state, action);
  }

  if (action.type === types.REMOVE_PRODUCT_FROM_CART) {
    return removeItemfromCartReducer(state, action);
  }

  if (action.type === types.UPDATE_CART) {
    return updateCartReducer(state, action);
  }

  if (action.type === types.CLEAR_CART) {
    return clearCartReducer(state, action);
  }

  if (action.type === types._REDUCE_WISHLIST) {
    return _reduceWishlist(state, action);
  }

  if (action.type === types.EDIT_CUSTOMER_ADDRESS) {
    return editCustomerAddress(state, action);
  }

  if (action.type === types._REDUCE_CUSTOMER_ADDRESS) {
    return _reduceCustomerAddress(state, action);
  }
  if (action.type === types.RESET_CUSTOMER_PASSWORD_SUCCESS) {
    return resetCustomerPasswordSuccess(state, action);
  }

  if (action.type === types.PLACE_ORDER_SUCCESS) {
    return placeOrderSuccess(state, action);
  }

  if (action.type === types.UPDATE_ITEM_REDUCER) {
    return updateItemReducer(state, action);
  }
  if (action.type === types.SET_CHECK_ADDITIONAL) {
    return setCheckAdditional(state, action);
  }

  if (action.type === types.UPDATE_CUSTOMER_DATA) {
    return _updateCustomerData(state, action);
  }

  if (action.type === types.TOGGLE_MINICART) {
    return _toggleMinicart(state, action);
  }

  if (action.type === types._REDUCE_RECENTLY_VIEWED) {
    return _reduceRecentlyViewed(state, action);
  }

  if (action.type === types.MINIMAL_ORDER_UPDATE) {
    return minimalOrderUpdate(state, action);
  }

  if (action.type === types.DELETE_CUSTOMER_ADDRESS) {
    return deleteCustomerAddressReducer(state, action);
  }

  return state;
};

export default customerReducerNew;
