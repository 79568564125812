import apolloClient from "app/graphql/apolloClient";
import wishlistActions from "app/state/redux/data/wishlist/actions";
import queryLoader from "app/graphql/queryLoader";
import { messagesActions } from "core/state/redux/data/messages";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "app/state/redux/data/common";
import useErrorHandling from "../coreHooks/useErrorHandling";
import { apolloClientMutation } from "app/graphql/apolloClient";

function useWishlist() {
  const wishlist = useSelector((state) => state.wishlist);
  const myWishlists = useSelector((state) => state.wishlist.data);
  const customer = useSelector((state) => state.customer);
  const { sanitizeGraphQLMessage } = useErrorHandling();
  let dispatch = useDispatch();

  const addToWishlist = async (
    request,
    target = "global",
    notification = true
  ) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("addItemsToWishlist"),
        variables: { input: request },
        fetchPolicy: "no-cache",
      });
      if (data.addItemsToWishlist !== null) {
        if (wishlist.data.length === 0) {
          const fetchWishlistAgain = await apolloClientMutation.query({
            query: queryLoader("getWishlists"),
            fetchPolicy: "no-cache",
          });
          if (fetchWishlistAgain.data.getWishlists !== null) {
            dispatch(
              wishlistActions.setWishlist(fetchWishlistAgain.data.getWishlists)
            );
            dispatch(wishlistActions.addToWishlist(...data.addItemsToWishlist));
          }
        } else {
          dispatch(wishlistActions.addToWishlist(...data.addItemsToWishlist));
        }

        if (notification) {
          let wishlistName = request.wishlists[0].name;
          dispatch(
            messagesActions.addMessage(
              `Item successfully added to ${
                wishlistName === "My Favorites" ? "favorites" : "project"
              }`,
              "success",
              target,
              3000
            )
          );
        }
        return true;
      }
    } catch (err) {
      if (HandleAuthorizationError(err, clearWishlistData)) {
        dispatch(
          messagesActions.addMessage(
            sanitizeGraphQLMessage(err.message),
            "danger",
            "global",
            3000
          )
        );
      }
      return false;
    }
  };
  const createWishlist = async (request, target = "global") => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("addNewWishlist"),
        variables: { input: request },
        fetchPolicy: "no-cache",
      });
      if (data.addNewWishlist !== null) {
        return dispatch(
          wishlistActions.createWishlist(data.addNewWishlist)
        ).then(() => {
          dispatch(
            messagesActions.addMessage(
              "New project successfully created",
              "success",
              target,
              3000
            )
          );
          return data.addNewWishlist;
        });
      }
    } catch (err) {
      if (err.message.includes("A wish list already exists")) {
        dispatch(
          messagesActions.addMessage(
            "A project with this name already exists.",
            "danger",
            target
          )
        );
      } else {
        if (HandleAuthorizationError(err, clearWishlistData)) {
          dispatch(
            messagesActions.addMessage(
              sanitizeGraphQLMessage(err.message),
              "danger",
              target
            )
          );
        }
      }

      return false;
    }
  };

  const removeFromWishlist = async (request, target = "global") => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("deleteItemsFromWishlist"),
        variables: { input: request },
        fetchPolicy: "no-cache",
      });
      if (data.deleteItemsFromWishlist !== null) {
        dispatch(
          wishlistActions.removeFromWishlist(data.deleteItemsFromWishlist)
        );
        dispatch(
          messagesActions.addMessage(
            `Item successfully removed from ${
              request?.wishlist === "My Favorites" ? "favorites" : "a project"
            }`,
            "success",
            target
          )
        );
        return true;
      }
    } catch (error) {
      if (HandleAuthorizationError(error, clearWishlistData)) {
        dispatch(
          messagesActions.addMessage(
            sanitizeGraphQLMessage(error.message),
            "danger",
            target
          )
        );
      }

      return false;
    }
  };
  const fetchWishlistData = async (force = false) => {
    if (force === false) {
      if (false) {
        // Have a customer
        console.log("havecustomer");
        if (
          typeof wishlist !== "undefined" &&
          typeof wishlist.data !== "undefined" &&
          typeof wishlist.data.wishlistItems !== "undefined"
        ) {
          // have a wishlist
          return wishlist.data.wishlistItems;
        } else {
          const { data } = await apolloClient.query({
            query: queryLoader("getWishlists"),
            fetchPolicy: "no-cache",
          });
          if (data.getWishlists !== null) {
            dispatch(wishlistActions.setWishlist(data.getWishlists));
          }

          return data;
        }
      } else {
        if (wishlist?.data?.length > 0) {
          dispatch(wishlistActions.clearWishlistData());
        }
      }
    } else {
      try {
        if (
          typeof customer !== "undefined" &&
          typeof customer.data !== "undefined" &&
          typeof customer.data.token !== "undefined" &&
          customer.data.token !== false &&
          typeof customer.data.name !== "undefined" &&
          customer.data.name !== ""
        ) {
          const { data } = await apolloClientMutation.query({
            query: queryLoader("getWishlists"),
            fetchPolicy: "no-cache",
          });
          if (data.getWishlists !== null) {
            dispatch(wishlistActions.setWishlist(data.getWishlists));
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getWishlistItems = () => {
    if (
      typeof wishlist.data !== "undefined" &&
      typeof wishlist.data.wishlistItems !== "undefined"
    ) {
      return wishlist.data.wishlistItems;
    }
    return wishlist.data;
  };
  const moveItemToWishlist = async (request) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("moveItemToWishlist"),
        variables: request,
        fetchPolicy: "no-cache",
      });
      if (data !== null) {
        fetchWishlistData(true);
        dispatch(
          messagesActions.addMessage(
            " moved to project",
            "success",
            "global",
            3000
          )
        );
      }
    } catch (err) {
      if (HandleAuthorizationError(err, clearWishlistData)) {
        dispatch(
          messagesActions.addMessage(
            sanitizeGraphQLMessage(err.message),
            "danger",
            "global",
            3000
          )
        );
      }

      return;
    }
  };
  const editWishlist = async (request) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("editWishlist"),
        variables: { input: request },
        fetchPolicy: "no-cache",
      });

      if (data.editWishlist !== null) {
        const { data } = await apolloClientMutation.query({
          query: queryLoader("getWishlists"),
          fetchPolicy: "no-cache",
        });

        if (data.getWishlists !== null) {
          dispatch(wishlistActions.setWishlist(data.getWishlists));
        }

        dispatch(
          messagesActions.addMessage(
            "Project name successfully changed",
            "success",
            "global",
            3000
          )
        );
      }
    } catch (error) {
      console.log(error.message);
      return;
    }
  };
  const deleteWishlist = async (request) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("deleteWishlist"),
        variables: { input: request },
        fetchPolicy: "no-cache",
      });
      if (data.deleteWishlist !== null) {
        dispatch(wishlistActions.removeWishlist(data.deleteWishlist.id)).then(
          () => {
            dispatch(
              messagesActions.addMessage(
                "Project successfully removed",
                "success",
                "global",
                3000
              )
            );
          }
        );
      }
    } catch (error) {
      console.log(error.message);
      return;
    }
  };
  const checkEmail = async (request) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("checkEmail"),
        variables: { email: request },
        fetchPolicy: "no-cache",
      });

      if (data.checkEmail !== null && data.checkEmail.username !== null) {
        dispatch(commonActions.unlock("success"));
        return data.checkEmail.username;
      }
    } catch (error) {
      dispatch(commonActions.unlock("fail"));
      return { error: true, message: sanitizeGraphQLMessage(error.message) };
    }
  };
  const isItemInProject = (sku) => {
    if (
      typeof wishlist.data !== "undefined" &&
      typeof wishlist.data.wishlistItems !== "undefined"
    ) {
      wishlist.data.wishlistItems.map((project) => {
        project.items.map((item) => {
          if (item.product.sku === sku) {
            return true;
          }
          return false;
        });
        return false;
      });
    }
    return false;
  };
  const itemsInProjects = () => {
    if (
      typeof wishlist.data !== "undefined" &&
      typeof wishlist.data.wishlistItems !== "undefined"
    ) {
      return wishlist.data.wishlistItems.map((project) => {
        if (project.items_count > 0) {
          return true;
        }
        return false;
      });
    }
    return false;
  };

  const sendWishlistEmail = async (request) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("sendWishlistEmail"),
        variables: { ...request },
        fetchPolicy: "no-cache",
      });

      if (data.sendWishlistEmail === "Success") {
        dispatch(
          messagesActions.addMessage("Project successfully shared", "success")
        );
        return true;
      } else {
        throw Error(data.sendWishlistEmail);
      }
    } catch (error) {
      dispatch(messagesActions.addMessage(error.message, "danger"));
      return false;
    }
  };

  const clearWishlistData = () => {
    dispatch(wishlistActions.clearWishlistData());
  };

  const addToVisitedProducts = (payload) => {
    dispatch(wishlistActions.markAsVisited(payload));

    return true;
  };
  const getFromVisitedProducts = (payload = false) => {
    if (payload) {
      let foundInVisited =
        typeof wishlist.visited !== "undefined"
          ? wishlist.visited.find((x) => payload === x)
          : false;

      return foundInVisited;
    }
    return wishlist.visited;
  };

  return {
    addToWishlist,
    removeFromWishlist,
    fetchWishlistData,
    getWishlistItems,
    moveItemToWishlist,
    editWishlist,
    wishlist,
    myWishlists,
    createWishlist,
    deleteWishlist,
    checkEmail,
    isItemInProject,
    itemsInProjects,
    sendWishlistEmail,
    addToVisitedProducts,
    getFromVisitedProducts,
  };
}
export { useWishlist };

const HandleAuthorizationError = (err, callback = () => {}) => {
  if (
    err.message ===
    "GraphQL error: The current user cannot perform operations on wish list"
  ) {
    callback();
    return false;
  }

  return err;
};
