import { Link } from "react-router-dom";
const Breadcrumbs = (props) => {
  let links = props.links;
  if (typeof props.links === "undefined") {
    links = props.backupLinks;
  }

  const getLink = (linkUrl) => {
    switch (linkUrl) {
      case "/collections/minimalist":
        return "/collections?index=0";
      case "/collections/casual":
        return "/collections?index=1";
      case "/collections/traditional":
        return "/collections?index=2";
      case "/collections/solid-brass":
        return "/collections?index=3";
      default:
        return linkUrl;
    }
  };

  return (
    <div className={`breadcrumbs`}>
      <ul>
        <li>
          {typeof links !== "undefined"
            ? links.map((link) => (
                <Link key={link.url} to={getLink(link.url)}>
                  <span>{link.title}</span>
                </Link>
              ))
            : ""}
        </li>
      </ul>
    </div>
  );
};

export default Breadcrumbs;
