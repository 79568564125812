import { useState, memo } from "react";
import { useWishlist } from "app/state/hooks/wishlistHooks/wishlistHookAddRemove";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBContainer,
} from "mdbreact";
import Button from "core/components/button";
import InputField from "core/components/inputField";
import Messages from "core/components/sessionMessages";
import useMessages from "app/state/hooks/messageHooks/useMessages";
import Icon from "app/assets/icon/icon";
import Image from "app/layout/image/image";
import Price from "app/layout/price";
import ProjectsListing from "./projectsListing";

const LoggedInModal = memo(
  ({
    modalWishlistSecond,
    modalToggleSecond,
    setLoading,
    loading,
    fetchArr,
    product,
    loggedIn,
    collectionProducts,
  }) => {
    const { getMessages } = useMessages();
    const { createWishlist, addToWishlist } = useWishlist();
    const [createLink, setCreateLink] = useState(false);
    const [project, setProject] = useState("");
    let collectionProductsSku = [
      ...collectionProducts.map((product) => {
        return { sku: product.sku };
      }),
    ];

    let wishlistMessages = getMessages(`wishlist-modal`);
    return (
      <>
        <MDBModal
          isOpen={modalWishlistSecond}
          toggle={(e) => {
            modalToggleSecond();
          }}
          className={"wishlist-modal modal-wrapper"}
          centered
        >
          <MDBModalHeader className="d-flex p-0 modal-custom-header w-100">
            <h4 className="text-center">Added to Projects</h4>
            <div className="close-btn">
              <div
                onClick={() => {
                  modalToggleSecond();
                }}
              >
                <Icon icon="closeIconBlue" />
              </div>
            </div>
          </MDBModalHeader>
          <div
            id="session-messages-container-wishlist"
            className={`wishlist-messages-container ${
              wishlistMessages.length > 0 ? "has-messages" : "no-messages"
            }`}
          >
            <Messages target={"wishlist-modal"} />
          </div>

          <MDBModalBody className="d-flex w-100 flex-column">
            {product?.name ? (
              <MDBRow className={`flex-column align-items-center mt-5 mb-3`}>
                <MDBCol>
                  <Image
                    source={
                      product?.small_image?.url
                        ? product.small_image.url
                        : product?.thumbnail?.url
                        ? product.thumbnail.url
                        : ""
                    }
                  />
                </MDBCol>
                <MDBCol
                  className={`d-flex product-name-title align-items-center justify-content-center mt-3`}
                >
                  <MDBRow className="text-center">
                    <MDBCol size={"12"}>
                      <h5>{product.name}</h5>
                    </MDBCol>
                    {(loggedIn && (
                      <MDBCol size={"12"}>
                        <div
                          className={
                            "product-price d-flex justify-content-center"
                          }
                        >
                          <Price product={product} layout={`widget`} />
                        </div>
                      </MDBCol>
                    )) || <></>}
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            ) : null}
            {createLink ? (
              <div className="add-project-name modal-title-wrapper">
                <div>
                  <InputField
                    counter={50}
                    // hint={
                    //   project.length === 0
                    //     ? "Enter project name"
                    //     : "Project name"
                    // }
                    placeholder="Create new project"
                    group
                    type="text"
                    value={project}
                    onChange={(e) => {
                      if (e.target.value.length <= 24) {
                        setProject(e.target.value);
                      }
                    }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        setLoading(true);
                        createWishlist(project, "wishlist-modal").then(
                          (response) => {
                            setLoading(false);
                            setProject("");
                            setCreateLink(false);
                            let addWishlistPlp = {
                              products:
                                collectionProducts?.length > 0
                                  ? collectionProductsSku
                                  : [{ sku: product.sku }],
                              wishlists: [
                                {
                                  name: response[response?.length - 1]?.name,
                                },
                              ],
                            };
                            addToWishlist(
                              addWishlistPlp,
                              "wishlist-modal"
                            ).then(() => {
                              setLoading(false);
                            });
                          }
                        );
                      }
                    }}
                  />
                  <Button
                    color="primary"
                    className={"btn-with-icon w-100 my-5"}
                    disabled={loading || project.length === 0}
                    direction={"mr-2"}
                    leftIcon={true}
                    children={
                      <>
                        <span>add</span>
                      </>
                    }
                    onClick={(e) => {
                      setLoading(true);
                      createWishlist(project, "wishlist-modal").then(
                        (response) => {
                          setLoading(false);
                          setProject("");
                          setCreateLink(false);
                          if (response) {
                            let addWishlistPlp = {
                              products:
                                collectionProducts?.length > 0
                                  ? collectionProductsSku
                                  : [{ sku: product.sku }],
                              wishlists: [{ name: response.name }],
                            };
                            addToWishlist(
                              addWishlistPlp,
                              "wishlist-modal"
                            ).then(() => {
                              setLoading(false);
                            });
                          }
                        }
                      );
                    }}
                  />
                </div>
              </div>
            ) : (
              <MDBContainer>
                <MDBRow className="modal-title-wrapper">
                  <Button
                    color="primary"
                    disabled={loading}
                    className={"btn-with-icon w-100 my-5"}
                    direction={"mr-2"}
                    leftIcon={true}
                    onClick={(e) => {
                      setCreateLink(!createLink);
                    }}
                    id={"addFavorite"}
                    children={
                      <>
                        <Icon icon="arrowRightWhite" />
                        <span>create new project</span>
                      </>
                    }
                  />
                </MDBRow>
              </MDBContainer>
            )}
            <ProjectsListing
              fetchArr={fetchArr}
              product={product}
              loading={loading}
              setLoading={setLoading}
              modalToggleSecond={modalToggleSecond}
              collectionProducts={collectionProducts}
            />
          </MDBModalBody>
        </MDBModal>
      </>
    );
  }
);

export default LoggedInModal;
