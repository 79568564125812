import axios from "axios";


/**
 * @param {*} images Images array which must have a mandatory "file_url" key
 * @param {*} customerToken Customer Token, used for authorization.
 * @param {*} setLoading Set loading state.
 * @param {*} removeAllImages Remove all images function.
 * @param {*} callback Callback called after success, you can close model or do anything else.
 * @param {*} failCallback Callback called after fail, you can dispatch messages, update state or do anything else.
 */
export const downloadImages = async (
  images,
  customerToken = false,
  loadingKey = true,
  setLoading = () => {},
  successCallback = () => {},
  failCallback = () => {}
) => {
  setLoading(loadingKey);
  await _downloadImages(images, customerToken).then((res) => {
    if (res) {
      successCallback();
    } else {
      failCallback();
    }
    setLoading(false);
  });
};

/**
 * @param {*} urls Array of image objects that include the file_url key.
 * @param {*} customerToken Customer token used for authorization.
 */
const _downloadImages = async (urls, customerToken = false) => {
  const url =
    process.env.REACT_APP_MAIN_STORE +
    "rest/V1/jola-filedownloader/downloadFiles";
  var filesArray = [];
  urls.map((item, index) => {
    return (filesArray[index] = {
      url: item.file_url,
    });
  });
  var rawRequest = JSON.stringify({ type: "images", files: filesArray });
  var config = {
    method: "post",
    url: url,
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${customerToken}`,
    },
    data: rawRequest,
  };

  return axios(config)
    .then((data) => {
      if (data.status === 200 && urls.length > 1) {
        window.open(data.data, "_blank");
      } else {
        CreateImageLink(data.data);
      }
      return true;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
};

/**
 * Generates request for adding or removing files from the FileDownloader module, it always re
 * @param {*} data Can be a single FileForDownload entity, or an array of FileForDownload entities
 * @param {*} removeFlag Can be "YES" or "NO"
 *
 * @returns Array(FileForDownload)
 */
export const generateRequestForAddingOrRemovingFileForDownload = (
  data,
  removeFlag
) => {
  if (typeof data.file_url !== "undefined")
    return [
      {
        file_url: data.file_url,
        group_id: data.group_id,
        is_primary: data.is_primary,
        remove_flag: removeFlag,
        type: data.type,
      },
    ];

  let request = data.map((item) => ({
    file_url: item.file_url,
    group_id: item.group_id,
    is_primary: item.is_primary,
    remove_flag: removeFlag,
    type: item.type,
  }));

  return request;
};

/**
 * @param {*} data
 */
const CreateImageLink = (data) => {
  var json_data = JSON.parse(data);
  var a = document.createElement("a");
  a.href = json_data.base64;
  a.download = json_data.image_name;
  a.click();
};
