import { MDBMask, MDBBox } from "mdbreact";
import TextCard from "./textCard";
import ProductCard from "./productCard";
import { useMediaQuery } from "react-responsive";

const HotspotCard = (props) => {
  const isMobile = useMediaQuery({ maxWidth: "992px" });
  let containerClassName = "card-container ";
  let maskClassName = "hotspot-mask ";

  if (props.dot.top > 45 && props.dot.left > 50) {
    containerClassName += " bottom-right";
    maskClassName += " bottom-right";
  }
  if (props.dot.top < 45 && props.dot.left > 50) {
    containerClassName += " right";
    maskClassName += " right";
  }
  if (props.dot.top > 45) {
    containerClassName += " bottom";
    maskClassName += " bottom";
  }
  if (props.dot.left > 45) {
    containerClassName += " right";
    maskClassName += " right";
  }

  return (
    <>
      <MDBMask
        style={{
          top: props.dot.top + "%",
          left:
            props.dot.left > 50
              ? `calc(${props.dot.left}% - 4rem)`
              : props.dot.left + "%",
        }}
        onClick={() =>
          isMobile && props.showProductInfo(props.dot.sku, props.dot.isVisible)
        }
        onMouseEnter={() => {
          !isMobile &&
            props.showProductInfo(props.dot.sku, props.dot.isVisible);
        }}
        onMouseLeave={() => {
          !isMobile &&
            props.showProductInfo(props.dot.sku, props.dot.isVisible);
        }}
        className={`hotSpot ${maskClassName} ${
          (props.dot.isVisible && "hotspot-active") || "hotspot-inactive"
        }`}
      >
        {props.dot.isVisible && props.dot.display_type === "product" ? (
          <ProductCard
            dot={props.dot}
            containerClassName={containerClassName}
            closeModal={props.closeModal}
          />
        ) : (
          props.dot.isVisible && (
            <TextCard dot={props.dot} containerClassName={containerClassName} />
          )
        )}
        <MDBBox className={`hotspot-circle ${maskClassName}`}>
          <p>{props.dot.isVisible ? "-" : "+"}</p>
        </MDBBox>
      </MDBMask>
    </>
  );
};

export default HotspotCard;
