import { useState } from "react";
import { MDBCol } from "mdbreact";
import Toolbar from "./toolbar";
import Content from "./content";

const FindARepComponentContainer = (props) => {
  const [filterData, setFilterData] = useState(false);

  const setFilterDataFn = (data) => {
    return setFilterData(data);
  };

  return (
    <>
      <MDBCol size="12" className={`pb-5 px-0`}>
        <Toolbar {...props} setFilterData={setFilterDataFn} />
      </MDBCol>
      <MDBCol size="12" className={`px-0`}>
        <Content filterData={filterData} />
      </MDBCol>
    </>
  );
};

export default FindARepComponentContainer;
