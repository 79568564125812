import { MDBCol } from "mdbreact";
import Image from "app/layout/image";
import { Link } from "react-router-dom";

const CategoryImage = ({
  activeCategory,
  image,
  activeMenuItems,
  setShowDropdown,
}) => {
  let urlPath = activeMenuItems?.children[activeCategory]?.url_path
    ? activeMenuItems?.children[activeCategory]?.url_path
    : activeMenuItems?.children[activeCategory]?.url_key
    ? activeMenuItems?.children[activeCategory]?.url_key
    : "";
  urlPath = urlPath.includes("/") ? urlPath : "/" + urlPath;

  const webpImage =
    image && image?.replace(/.png/, ".webp")?.replace(/.jpg/, ".webp");

  return (
    <MDBCol
      size="5"
      className="category-image px-0"
      onClick={() => setShowDropdown(false)}
    >
      <Link to={urlPath}>
        <Image
          source={
            image
              ? image
              : "https://www.naem.org/images/default-source/default-album/default-album/700x500.png?sfvrsn=df8f03c2_0"
          }
          webpImage={webpImage}
          wrapperClass={`imageloader`}
          alt={"menu-category"}
          backend={false}
        />
      </Link>
    </MDBCol>
  );
};
export default CategoryImage;
