import apolloClient from "app/graphql/apolloClient";
import customerActions from "../actions";
import commonActions from "../../common/actions";
import queryLoader from "app/graphql/queryLoader";
import messagesActions from "core/state/redux/data/messages/actions";
import queryActions from "core/state/redux/data/queries/actions";

export default async (store, action) => {
  let cart_id = store.getState().customer.data.cartToken;
  let cart = { ...store.getState().customer.data.cart };
  let items = action.items.map((item) => ({
    cart_item_id: item.cart_item_id,
    quantity: item.quantity,
    customizable_options: item.customizable_options,
  }));
  try {
    const { data } = await apolloClient.mutate({
      mutation: queryLoader("updateCart"),
      variables: {
        cart_id,
        items,
      },
    });

    if (data.updateCartItems !== null) {
      let newCart = {
        ...cart,
        items: [...data.updateCartItems.cart.items],
        prices: { ...data.updateCartItems.cart.prices },
        shipping_addresses: [...data.updateCartItems.cart.shipping_addresses],
      };
      store.dispatch(customerActions.updateItemReducer(newCart));
      store.dispatch(commonActions.unlock("success"));
      store.dispatch(
        messagesActions.addMessage("Cart successfully updated", "success")
      );
    }
  } catch (error) {
    if (error.message.includes("Could not find a cart with ID")) {
      store.dispatch(customerActions.createEmptyCart());
      store.dispatch(customerActions.updateCart(action.items));
    } else {
      store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    }
    store.dispatch(commonActions.unlock());
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
