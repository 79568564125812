import { MDBBox } from "mdbreact";

const RadioButtons = ({
  groupName,
  options,
  setChecked,
  checked,
  layout = "column",
  innerRef,
  onChangeHandle,
}) => {
  const handleChange = (value) => {
    if (onChangeHandle) {
      value === "0" ? onChangeHandle(false) : onChangeHandle(true);
    }
    return setChecked(value);
  };

  return (
    <MDBBox className={`radio-buttons d-flex flex-${layout}`}>
      {options &&
        options.length > 0 &&
        options.map((option, index) => {
          return (
            <MDBBox
              key={`radio-button-option-${index}`}
              className={`radio-button-container`}
            >
              <div class="radio_input">
                <input
                  inputRef={innerRef}
                  type="radio"
                  id={option.value}
                  name={groupName}
                  value={option.value}
                  checked={checked === option.value}
                  onChange={() => {}}
                />
                <span
                  class="radio_control"
                  onClick={() => handleChange(option.value)}
                ></span>
                <label htmlFor={option.id} className={`radio-button-label`}>
                  {option.label}
                </label>
              </div>
            </MDBBox>
          );
        })}
    </MDBBox>
  );
};

export default RadioButtons;
