import SeoHelmet from "app/layout/seoHelmet";
import { lazy } from "react";

const DashboardLayout = lazy(() => import("./dashboardLayout"));
const DefaultLayout = lazy(() => import("./defaultLayout"));

const AddressBook = (props) => {
  let { dashboardLayout = false } = props;
  return (
    <div
      className={`addressbook-container ${
        dashboardLayout && "dashboard-section"
      }`}
    >
      <SeoHelmet url={`/customer/addresses`} title={`Address Book`} />
      {dashboardLayout ? (
        <DashboardLayout {...props} />
      ) : (
        <DefaultLayout {...props} />
      )}
    </div>
  );
};

export default AddressBook;
