import { MDBContainer } from "mdbreact";
import ReactLoader from "app/layout/loader";

const Temporary = () => {
  return (
    <MDBContainer
      id={`temporary`}
      className={`d-flex flex-column align-items-center justify-content-center center`}
    >
      <ReactLoader />
    </MDBContainer>
  );
};

export default Temporary;
