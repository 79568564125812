import { MDBContainer } from "mdbreact";
import { isEmpty } from "lodash";
import InitialBlock from "./initialBlock";
import NoResults from "./noResults";
import SalesRepWidget from "app/layout/salesRepWidget";

const Content = ({ repLocators }) => {
  if (typeof repLocators === "undefined") {
    return <InitialBlock />;
  } else if (isEmpty(repLocators)) {
    return <NoResults />;
  } else {
    return (
      <MDBContainer className={`find-rep-content find-rep-results`}>
        {repLocators.map((repLocators) => {
          return <SalesRepWidget salesRep={repLocators} />;
        })}
      </MDBContainer>
    );
  }
};

export default Content;
