import {
  MDBContainer,
  MDBRow,
  MDBTypography,
  MDBBox,
  MDBCol,
  MDBBtn,
} from "mdbreact";
import InputField from "core/components/inputField";
import { memo, useCallback } from "react";
import ExpressRegistrationOnLoginModal from "./expressRegistration";
import RegistrationOnLoginModal from "./registration";

const LoginRegister = memo(
  ({
    loading,
    onSubmit,
    handleSubmit,
    setEmailFn,
    register,
    errors,
    // showLoginRegister,
    // setShowLoginRegister,
    toggle,
    storeCode,
    errorMessage,
  }) => {
    // const handleActivateNewUserClick = useCallback(
    //   function toggleModalsOnNewUserClick() {
    //     setShowExpressRegistration(!showExpressRegistration);
    //     setShowLoginRegister(!showLoginRegister);
    //   },
    //   [
    //     setShowExpressRegistration,
    //     setShowLoginRegister,
    //     showLoginRegister,
    //     showExpressRegistration,
    //   ]
    // );
    const handleToggle = useCallback(
      function handleToggleFn() {
        toggle();
      },
      [toggle]
    );
    return (
      <MDBContainer className={`login-modal-content`}>
        <MDBRow>
          <MDBBox className={`modal-inner-content w-100`}>
            <MDBRow>
              <MDBCol size="12">
                <MDBBox className={`modal-part`}>
                  <MDBTypography
                    tag="p"
                    className={`text-uppercase mb-1 modal-subtitle mb-3`}
                  >
                    Log in
                  </MDBTypography>
                  <MDBTypography tag="p" className={`modal-text`}>
                    If you are already registered with the site, please login
                    below.
                  </MDBTypography>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <MDBBox className={`input-container`}>
                      <InputField
                        wrapperClass={`mb-5`}
                        placeholder={"Enter your email*"}
                        className={`custom-input m-0 ${
                          errors.email ||
                          typeof errorMessage.message !== "undefined"
                            ? "validation-error"
                            : ""
                        }`}
                        label={``}
                        inputRef={register({
                          required: true,
                          pattern:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        })}
                        group
                        name="email"
                        type="email"
                        error="wrong"
                        success="right"
                        onChange={setEmailFn}
                        filled={
                          (typeof errors.email !== "undefined" &&
                            errors.email.ref.value.length === 0 &&
                            false) ||
                          true
                        }
                      />
                      <MDBTypography tag="p" className={`error-label`}>
                        {typeof errors.email !== "undefined"
                          ? errors.email.ref.value.length === 0
                            ? "Email is required*"
                            : "Email is invalid*"
                          : errors.email ||
                            typeof errorMessage.message !== "undefined"
                          ? "Email is invalid"
                          : ""}
                      </MDBTypography>
                    </MDBBox>
                    <MDBBtn
                      disabled={loading === "login-register"}
                      color={"primary"}
                      className={`btn-without-icon ${
                        (errors.email ||
                          typeof errorMessage.message !== "undefined") &&
                        "mt-5"
                      }`}
                      children={
                        <>
                          <MDBBox tag="span">Continue</MDBBox>
                        </>
                      }
                      type="submit"
                    />
                  </form>
                </MDBBox>
              </MDBCol>
            </MDBRow>
            <ExpressRegistrationOnLoginModal
              storeCode={storeCode}
              toggle={handleToggle}
            />
            <RegistrationOnLoginModal
              toggle={handleToggle}
              storeCode={storeCode}
            />
          </MDBBox>
        </MDBRow>
      </MDBContainer>
    );
  }
);
export default LoginRegister;
