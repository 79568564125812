import { TemporaryPage } from "app/config/routing/pages/globalPages";
import queryLoader from "app/graphql/queryLoader";
import CmsPage from "./cms";
import { lazy, Suspense } from "react";
const DynamicGraphQlWrapperQueryContainer = lazy(() =>
  import(
    "app/overrides/dynamicGraphQlWrapper" /*webpackChunkName: "dynamicResolution"*/
  )
);

const query = queryLoader("cmsPage");

const CmsPageQueryContainer = (props) => {
  return (
    <>
      {props.isCached && (
        <CmsPage
          {...props}
          dangHtml={props.dangHtml}
          title={props.title}
          cssClass={props.scssClass}
          id={props.id}
          data={props.isCached}
          isCached={props.isCached}
        />
      )}
      {props.data && !props.isCached && (
        <CmsPage
          {...props}
          dangHtml={props.data.dangHtml}
          title={props.data.title}
          cssClass={props.data.scssClass}
          id={props.data.id}
          data={props.data}
          isCached={props.isCached}
        />
      )}
      {!props.data && !props.isCached && (
        <Suspense fallback={<TemporaryPage />}>
          <DynamicGraphQlWrapperQueryContainer
            query={query}
            variables={{ ...props.variables, identifier: props.variables.url }}
            url={props.url}
            loader={true}
          >
            <CmsPage
              {...props}
              dangHtml={props.dangHtml}
              title={props.title}
              cssClass={props.scssClass}
              id={props.id}
              isCached={props.isCached}
            />
          </DynamicGraphQlWrapperQueryContainer>
        </Suspense>
      )}
    </>
  );
};

export default CmsPageQueryContainer;
