import { Link } from "react-router-dom";

const SearchViewAll = ({ total_count, search, setDisplaySearch }) => {
  return total_count > 2 ? (
    <div
      className={`search-view-all w-100 d-flex justify-content-center align-items-center`}
    >
      <Link
        to={{
          pathname: `/search-results`,
          search: `search=${encodeURI(search)}`,
          state: { search_total_count: total_count },
        }}
        onClick={(e) => {
          setDisplaySearch(false);
        }}
        className={"underline-animation-from-middle view-all"}
      >
        <p className={`hover`}>View All ({total_count})</p>
      </Link>
    </div>
  ) : (
    <></>
  );
};

export default SearchViewAll;
