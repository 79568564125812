import { MDBContainer, MDBTypography, MDBBox, MDBRow } from "mdbreact";
import Slider from "react-slick";
import { isEmpty } from "lodash";
import { withRouter } from "react-router-dom";
import useGenerated from "app/state/hooks/generatedHooks";
import Hotspot from "app/layout/hotspot";
import Icon from "app/assets/icon/icon";

const GalleryDetails = ({ history }) => {
  const { GetGalleries } = useGenerated();
  let data = GetGalleries();

  //hiding homepage category fot lifestyle gallery landing page
  let filteredData =
    !isEmpty(data) &&
    data.filter((item) => {
      return !item.name.includes("Homepage");
    });

  // getting all galleries
  let allGalleries = [];
  !isEmpty(filteredData) &&
    filteredData.map((category, index) => {
      category.galleries?.map((gallery) => {
        return allGalleries.push({ ...gallery, category: index + 1 });
      });
      return category;
    });

  const galleries = allGalleries;
  let location = window.location.search;
  const urlParams = new URLSearchParams(location);
  let clickedGalleryIndex = urlParams.get("index");
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    variableWidth: false,
    slidesToScroll: 1,
  };
  return (
    <MDBBox className={`gallery-detail`}>
      <Slider {...settings} currentSlide={clickedGalleryIndex}>
        {typeof galleries !== "undefined" ? (
          galleries?.map((galleryItem) => {
            galleryItem.hotspot.map((ht, i) => {
              ht.content = JSON.parse(ht.content);
              ht.content[0]["image"] = ht.image;
              ht.content = JSON.stringify(ht.content);
            });
            return (
              <MDBContainer fluid key={galleryItem.category}>
                <MDBRow>
                  <MDBBox className={`title-with-background w-100`}>
                    <MDBContainer>
                      {galleryItem.name ? (
                        <MDBTypography tag="h1" className="page-title">
                          {galleryItem.name}
                        </MDBTypography>
                      ) : (
                        <MDBTypography tag="h1" className="page-title">
                          {galleryItem.title}
                        </MDBTypography>
                      )}
                    </MDBContainer>
                  </MDBBox>
                </MDBRow>
                <MDBRow>
                  <MDBContainer>
                    <div
                      onClick={() => history.goBack()}
                      className="back-button d-flex align-items-center mb-5"
                    >
                      <Icon icon="backArrowIcon" />
                      Back
                    </div>
                    <MDBTypography tag="h4" className="gallery-title mb-4">
                      {galleryItem.title}
                    </MDBTypography>
                    <MDBTypography tah="h3" className={`gallery-description`}>
                      {galleryItem.description}
                    </MDBTypography>
                    <Hotspot
                      contentArray={galleryItem.hotspot.reduce(
                        (acc, spot) => acc.concat(JSON.parse(spot.content)),
                        []
                      )}
                      image={galleryItem?.image.replace(/.png|.jpg/g, ".webp")}
                    />
                  </MDBContainer>
                </MDBRow>
              </MDBContainer>
            );
          })
        ) : (
          <></>
        )}
      </Slider>
    </MDBBox>
  );
};

export default withRouter(GalleryDetails);
