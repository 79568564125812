import { connect } from "react-redux";
import { customerActions } from "core/state/redux/data/customer";
import { Redirect } from "react-router-dom";

const Logout = (props) => {
  if (props.customer.data.token !== false) {
    props.logoutCustomer();
  }

  return <Redirect to={`/`} />;
};
const mapStateToProps = (state) => ({
  customer: state.customer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logoutCustomer: () => {
      dispatch(customerActions.logoutCustomer());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
