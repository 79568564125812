import { MDBCol, MDBRow } from "mdbreact";
import React from "react";
import Skeleton from "./skeleton";
import Item from "./item";

/**
 *
 * @param {*} data List of FileForDownload items
 * @param {boolean} loading Loading state
 * @param {*} error Error object if query is not successful
 * @param {function} refetch Refetch function that can be triggered once something is updated
 * @param {string} listingClassName Additional classes for listing
 */
const Listing = ({
  data,
  loading,
  error,
  refetch,
  listingClassName,
  itemCount,
}) => {
  return (
    <MDBRow
      id={`my-downloads-listing`}
      className={`my-downloads-listing ${listingClassName} ${
        itemCount === null || itemCount === 0 ? `no-items` : "has-items"
      }`}
    >
      {data === undefined && loading ? (
        <Skeleton />
      ) : error === undefined && !loading ? (
        generateItemList(data, refetch)
      ) : error && data === undefined ? (
        <MDBCol className={`p-0`}>
          <h4>We are unable to retrieve your downloads at this moment.</h4>
          <p>Please try again later.</p>
        </MDBCol>
      ) : (
        generateItemList(data, refetch)
      )}
    </MDBRow>
  );
};

const generateItemList = (data, refetch = () => {}) => {
  return data.length > 0 ? (
    data.map((item, index) => {
      return (
        <Item
          key={`item-${index}`}
          thisKey={`item-${index}`}
          data={item}
          refetch={refetch}
        />
      );
    })
  ) : (
    <MDBCol className={`p-0`}>
      <h4>Your downloads folder is empty.</h4>
      <p>Add a product for download to get started.</p>
    </MDBCol>
  );
};

export default Listing;
