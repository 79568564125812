import types from "./types";
import setStore from "./reducers/setStore";

const storeReducer = (state, action) => {
  if (state === undefined) return {};
  if (action.type === types.SET_STORE) return setStore(state, action);

  return state;
};

export default storeReducer;
