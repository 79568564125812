// Action types

const ADD_TO_WISHLIST = "jola/wishlist/ADD_TO_WISHLIST";
const REMOVE_FROM_WISHLIST = "jola/wishlist/REMOVE_FROM_WISHLIST";
const SET_WISHLIST = "jola/wishlist/SET_WISHLIST";
const MOVE_WISHLIST = "jola/wishlist/MOVE_WISHLIST";
const EDIT_WISHLIST = "jola/wishlist/EDIT_WISHLIST";
const ADD_TO_CART_WISHLIST = "jola/wishlist/ADD_TO_CART_WISHLIST";
const CHECK_EMAIL = "jola/wishlist/CHECK_EMAIL";
const SET_WISHLIST_LOADING = "jola/wishlist/SET_WISHLIST_LOADING";
const CLEAR_WISHLIST_DATA = "jola/wishlist/CLEAR_WISHLIST_DATA";
const REMOVE_WISHLIST = "jola/wishlist/REMOVE_WISHLIST";
const CREATE_WISHLIST = "jola/wishlist/CREATE_WISHLIST";
const MARK_AS_VISITED = "jola/wishlist/MARK_AS_VISITED";

export default {
  ADD_TO_WISHLIST,
  REMOVE_FROM_WISHLIST,
  SET_WISHLIST,
  MOVE_WISHLIST,
  EDIT_WISHLIST,
  ADD_TO_CART_WISHLIST,
  CHECK_EMAIL,
  SET_WISHLIST_LOADING,
  CLEAR_WISHLIST_DATA,
  REMOVE_WISHLIST,
  CREATE_WISHLIST,
  MARK_AS_VISITED,
};
