import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import FindARepComponentContainer from "./find-a-rep-component-container";
import SeoHelmet from "app/layout/seoHelmet/seoHelmet";

const FindRep = (props) => {
  return (
    <>
      <SeoHelmet
        url={`find-a-rep`}
        title={`Find A Rep`}
        description={`Find a rep near you.`}
        image={""}
      />
      <MDBContainer fluid className={"page-full-width find-rep-container"}>
        <MDBRow className={`find-a-rep-title`}>
          <MDBCol className="text-center" size="12">
            <h1>Find A Sales Rep</h1>
          </MDBCol>
        </MDBRow>
        <FindARepComponentContainer {...props} />
      </MDBContainer>
    </>
  );
};

export default FindRep;
