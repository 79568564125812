import { MDBCol, MDBRow } from "mdbreact";
import Image from "app/layout/image/image";
import Icon from "app/assets/icon/icon";

const MediaGalleryForTwoImages = ({
  mediaGallery,
  productItem,
  handleImageClick,
  isMobile,
  baseImage,
  lifestyleImage,
  filteredGallery,
  activeGalleryImage
}) => {
  const minimizedImge = (image) => {
    if (!image) return;
    const explodedImageSource = image?.url.split(/\//g);
    const webpImage = explodedImageSource[explodedImageSource.length - 1]
      .replace(/.png/, ".webp")
      .replace(/.jpg/, ".webp")
      .replace(/.webp/, ".webp");

    const webpImageDirectory = "catalog/product/webp/" + productItem?.sku + "/";
    return webpImage + webpImageDirectory;
  };
  return mediaGallery && mediaGallery.length > 0 ? (
    <div
      className={`media-gallery-container ${
        mediaGallery.length === 1 && "single-image"
      }`}
    >
      <MDBRow className="">
        {baseImage && !activeGalleryImage && (
          <MDBCol
            size={`${
              mediaGallery.length === 1 && !lifestyleImage ? "12" : "6"
            }`}
          >
            <div className={`product-image-container`}>
              <Image
                source={baseImage.url}
                wrapperClass={`imageloader`}
                webpImage={minimizedImge(baseImage)}
                alt={`product-image-${baseImage.label}`}
                backend={true}
                backend_public={true}
                mediaLink={true}
              />
                <div
                  onClick={() =>
                    handleImageClick(
                      baseImage,
                      mediaGallery.findIndex(
                        (element) => element.url === baseImage.url
                      )
                    )
                  }
                  className={`enlarge-icon`}
                >
                  <Icon icon={"enlarge_icon"} />
                </div>
            </div>
          </MDBCol>
        )}
        {activeGalleryImage &&
          filteredGallery?.map((image, index) => {
            return (
              <MDBCol
                size={`${
                  mediaGallery.length === 1 && !lifestyleImage ? "12" : "6"
                }`}
                key={index}
              >
                <div
                  className={`product-image-container`}
                  key={`product-image-${index}`}
                >
                  <Image
                    source={image.url}
                    wrapperClass={`imageloader`}
                    webpImage={minimizedImge(image)}
                    alt={`product-image-${image.label}`}
                    backend={true}
                    backend_public={true}
                    mediaLink={true}
                  />
                  {!image.url.toLowerCase().includes("ingr") && (
                    <div
                      onClick={() => handleImageClick(image, index)}
                      className={`enlarge-icon`}
                    >
                      <Icon icon={"enlarge_icon"} />
                    </div>
                  )}
                </div>
              </MDBCol>
            );
          })}
        {lifestyleImage && (
          <MDBCol size="6" className="">
            <div className={`product-image-container`}>
              <Image
                source={lifestyleImage?.url}
                wrapperClass={`imageloader`}
                webpImage={minimizedImge(lifestyleImage)}
                alt={`product-image-${lifestyleImage?.label}`}
                backend={true}
                backend_public={true}
                mediaLink={true}
              />
            </div>
          </MDBCol>
        )}
      </MDBRow>
    </div>
  ) : (
    <div className={`media-gallery-container`}>
      <img src={productItem.small_image.url} alt={`pdp-placeholder`} />
      {!isMobile && (
        <img src={productItem.small_image.url} alt={`pdp-placeholder`} />
      )}
    </div>
  );
};

export default MediaGalleryForTwoImages;
