import { ErrorPage } from "core/config/routing/pages/globalPages";
import { default as PageTypes } from "core/config/routing/pageTypes";
import { isUndefined } from "core/helpers/functions";
import { Suspense } from "react";
import { Redirect } from "react-router";

const DynamicRouter = (props) => {
  if (!props.data || !props.data.urlResolver) {
    if (!props.isCached) return <div>{/* <ReactLoader /> */}</div>;
  }

  if (!isUndefined(props.data) && !isUndefined(props.data.urlResolver)) {
    const urlResolver = props.data.urlResolver;

    if (urlResolver !== null && PageTypes.hasOwnProperty(urlResolver.type)) {
      const Page = PageTypes[urlResolver.type];
      return (
        <Suspense fallback={<></>}>
          <Page
            {...props}
            id={urlResolver.uid}
            url={props.variables.url}
            variables={{ id: urlResolver.uid, url: props.variables.url }}
          />
        </Suspense>
      );
    }
  }
  if (isUndefined(props.data)) {
    return <>Loading...</>;
  }

  // return <Redirect to={`/`} />;
  return <ErrorPage />;
};

export default DynamicRouter;
