import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import useFilters from "app/state/hooks/filtersHooks/useFilters";
import { useWishlist } from "app/state/hooks/wishlistHooks/wishlistHookAddRemove";
import { validate } from "../wishlist/validation";
import useBrowser from "app/state/hooks/coreHooks/useBrowser";
import { createContext, useState, useEffect } from "react";
import useMessages from "app/state/hooks/messageHooks/useMessages";

export const WishlistContext = createContext({
  component: null,
  item: {},
  props: {},
  renderCount: 0,
  setItem: () => {},
  getWishlistItems: () => {},
  handleLoginCheck: () => {},
  onClickFn: () => {},
  isInWishlist: () => {},
  setIconLoading: false,
});

export const WishlistProvider = (props) => {
  const { isLoggedIn, login } = useCustomer();
  const { addMessage } = useMessages();
  let customer = isLoggedIn();
  const {
    addToWishlist,
    removeFromWishlist,
    fetchWishlistData,
    checkEmail,
    getFromVisitedProducts,
    addToVisitedProducts,
    getWishlistItems,
  } = useWishlist();
  let fetchArr = [...getWishlistItems()];
  let browser = useBrowser();
  fetchArr = fetchArr.sort((a, b) => {
    if (
      a.name.toLowerCase() === "my favorites" ||
      b.name.toLowerCase() === "my favorites"
    ) {
      return browser === "Firefox" ? -1 : 1;
    }
    if (parseInt(a.id) > parseInt(b.id)) {
      return -1;
    } else {
      return 1;
    }
  });

  useEffect(() => {
    const fetchFn = () => {
      fetchWishlistData();
    };
    fetchFn();
  }, [customer, fetchWishlistData]);

  const { dispatchLoading } = useFilters();

  useEffect(() => {
    fetchWishlistData();
  }, [fetchWishlistData]);
  const [item, setItem] = useState({});
  const [collectionProducts, setCollectionProducts] = useState({});

  const [modalWishlist, setModalWishlist] = useState(false);
  const [modalWishlistSecond, setModalWishlistSecond] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState(false);
  const [error, setError] = useState(false);
  const [addedToWishlistModal, setAddedToWishlistModal] = useState(false);
  const [iconLoading, setIconLoading] = useState(false);
  const [addedPopUp, setAddedPopUp] = useState(false);
  const [someFn, setSomeFn] = useState({});

  const addedToWishlistModalToggle = () => {
    setAddedToWishlistModal(!addedToWishlistModal);
  };

  let errors = validate(email, password);
  const [touched, setTouched] = useState({
    email: false,
    password: false,
  });
  const validateInput = (e) => {
    e.preventDefault();
    let isTrue = true;
    setTouched({
      email: true,
      password: true,
    });
    Object.keys(errors).map((item) => {
      let error = errors[item];
      if (isTrue) {
        if (error === true) {
          isTrue = false;
        }
      }
      return item;
    });

    return isTrue;
  };
  const modalToggle = () => {
    setUsername(false);
    setEmail("");
    setModalWishlist(!modalWishlist);
  };
  const modalToggleSecond = () => {
    setModalWishlistSecond(!modalWishlistSecond);
  };
  let loggedIn = false;
  if (typeof customer !== "undefined" && customer) {
    loggedIn = true;
  }
  // determine if product is added to any wishlist
  const isInWishlist = (sku, wishlistName = "My Favorites") => {
    if (typeof sku === "undefined" || typeof wishlistName === "undefined")
      return false;
    if (typeof fetchArr[0] === "undefined") return false;
    let searchedWishlist = fetchArr.find(
      (x) => x.name.toLowerCase() === wishlistName.toLowerCase()
    );
    if (typeof searchedWishlist === "undefined") return false;
    return searchedWishlist.items.find((item) => item.product.sku === sku)
      ? true
      : false;
  };

  const checkIfProductIsInProject = (sku) => {
    if (typeof sku === "undefined") return false;
    if (typeof fetchArr[0] === "undefined") return false;
    let projects = fetchArr.filter((x) => x.name !== "My Favorites");
    return projects.find(({ items }) => {
      return items.some((item) => item?.product?.sku === sku);
    });
  };

  const handleLoginCheck = (product, collectionProducts = []) => {
    let { sku } = product;
    if (username === false) {
      setLoading(true);
      setError(false);
      if (!errors.email) {
        checkEmail(email).then((response) => {
          if (response?.error) {
            setError(true);
          } else {
            setUsername(response);
          }
          setLoading(false);
        });
      } else {
        setError(true);
        setLoading(false);
      }
    } else {
      // login logic
      setLoading(true);
      setError(false);
      if (password === "") {
        setLoading(false);
        setError(true);
      } else {
        someFn.setLoading(true);
        login(email, password).then((response) => {
          if (typeof response.error === "undefined") {
            setLoading(false);
            setModalWishlist(false);
            if (isInWishlist(sku)) {
              setModalWishlistSecond(true);
            } else {
              setIconLoading(true);
              let skus = collectionProducts?.map((product) => ({
                sku: product.sku,
              }));
              console.log(skus, collectionProducts, "heree");
              addToWishlist(
                {
                  products:
                    collectionProducts?.length > 0 ? skus : [{ sku: sku }],
                  wishlists: [{ name: "My Favorites" }],
                },
                "wishlist-modal",
                true
              ).then((response) => {
                setLoading(false);
                setModalWishlistSecond(true);
                setAddedToWishlistModal(false);
                setIconLoading(false);
                dispatchLoading(false);
                addToVisitedProducts(sku);
                someFn.setLoading(false);
              });
            }
          } else {
            addMessage("response.message", "danger", `wishlist-login`);
            setLoading(false);
            setError(true);
          }
        });
      }
    }
  };

  let removeSuccessCallback =
    typeof props.removeSuccessCallback === "undefined"
      ? () => {}
      : props.removeSuccessCallback;

  const onClickFn = (
    product,
    additional = {},
    setLoading = () => {},
    productVariant = null,
    products = []
  ) => {
    let super_attribute = [];
    if (productVariant) {
      productVariant?.attributes.map((attribute) => {
        productVariant?.configurable_options.map((config) => {
          config?.values.map((configValue) => {
            if (configValue.value_index === attribute.value_index) {
              super_attribute.push({
                id: config.attribute_id_v2,
                value: configValue.value_index,
              });
            }
          });
        });
      });
    }
    setCollectionProducts(products);
    let sku;
    if (product) {
      sku = product?.sku;
      setItem(product);
      setIconLoading(`loading_${sku}`);
    } else {
      setItem({});
    }
    if (!loggedIn) {
      setSomeFn({
        setLoading: setLoading,
      });
      modalToggle();
      setIconLoading(false);
    } else {
      setLoading(true);
      if (isInWishlist(sku)) {
        if (!checkIfProductIsInProject(sku)) {
          let removeRequest = {
            wishlist: "My Favorites",
            skus: [sku],
          };
          removeFromWishlist(removeRequest, true).then((response) => {
            setIconLoading(false);
            removeSuccessCallback();
            setLoading(false);
            addMessage(
              `Item has been successfully removed from favorites`,
              "success"
            );
          });
          return;
        }
        if (typeof additional.removeCartItemFromFavorites !== "undefined") {
          addToWishlist(
            {
              products: [{ sku: sku, super_attribute: super_attribute }],
              wishlists: [{ name: "My Favorites" }],
            },
            "wishlist-modal",
            false
          ).then((response) => {
            setLoading(false);

            if (typeof additional.removeCartItemFromFavorites !== "undefined") {
              additional.removeItem(additional.itemId, () => {
                setLoading(false);
              });
            } else {
              // response should be true, if false it means call failed
              if (response) {
                if (!getFromVisitedProducts(sku)) {
                  addToVisitedProducts(sku);
                }
                if (additional.showPopupOnAdd) {
                  setModalWishlistSecond(true);
                }
              }
              setLoading(false);
              setIconLoading(false);
            }
          });
        } else {
          if (additional?.removeOnClick) {
            let removeRequest = {
              wishlist: additional.removeWishlist,
              skus: [sku],
            };
            removeFromWishlist(removeRequest).then((response) => {
              addMessage(
                "Item has been successfully removed from favorites",
                "success"
              );
              setIconLoading(false);
              removeSuccessCallback();
              setLoading(false);
            });
          } else {
            modalToggleSecond();
            setIconLoading(false);
            setLoading(false);
          }
        }
      } else {
        let loggedInStatus = isLoggedIn();
        if (loggedInStatus) {
          if (additional?.sentFromProject) {
            if (additional?.removeOnClick) {
              let removeRequest = {
                wishlist: additional.removeWishlist,
                skus: [sku],
              };
              removeFromWishlist(removeRequest).then((response) => {
                setIconLoading(false);
                removeSuccessCallback();
                addToVisitedProducts(sku);
                setLoading(false);
              });
            } else {
              modalToggleSecond();
              setIconLoading(false);
              addToVisitedProducts(sku);
              setLoading(false);
            }
          } else {
            // when adding product to project (logged in)
            let wishlistInput = [];
            products?.map((product) =>
              wishlistInput.push({ sku: product.sku })
            );

            addToWishlist(
              {
                products:
                  wishlistInput.length > 0
                    ? wishlistInput
                    : [
                        {
                          sku: sku,
                          super_attribute: super_attribute,
                        },
                      ],
                wishlists: [{ name: "My Favorites" }],
              },
              "wishlist-modal",
              false
            ).then((response) => {
              setLoading(false);

              if (
                typeof additional.removeCartItemFromFavorites !== "undefined"
              ) {
                additional.removeItem(additional.itemId, () => {
                  setLoading(false);
                });
              } else {
                // response should be true, if false it means call failed
                if (response) {
                  if (!getFromVisitedProducts(sku)) {
                    addToVisitedProducts(sku);
                  }
                  // if (additional.showPopupOnAdd) {
                  //   setModalWishlistSecond(true);
                  // }
                  // setModalWishlistSecond(true);
                  setAddedPopUp(true);
                  addMessage(
                    `${
                      wishlistInput.length > 0 ? "Items" : "Item"
                    } has been successfully added to favorites`,
                    "success"
                  );
                }
                setLoading(false);
                setIconLoading(false);
              }
            });
          }
        }
      }
    }
  };

  return (
    <WishlistContext.Provider
      value={{
        username: username,
        setUsername: setUsername,
        password: password,
        setPassword: setPassword,
        email: email,
        setEmail: setEmail,
        error: error,
        setError: setError,
        errors: errors,
        touched: touched,
        setTouched: setTouched,
        validateInput: validateInput,
        getWishlistItems: getWishlistItems,
        item: item,
        setItem: setItem,
        handleLoginCheck: handleLoginCheck,
        onClickFn: onClickFn,
        isInWishlist: isInWishlist,
        wishlistData: fetchArr,
        iconLoading: iconLoading,
        setIconLoading: setIconLoading,
        modalWishlist: modalWishlist,
        setModalWishlist: setModalWishlist,
        modalToggleSecond: modalToggleSecond,
        modalToggle: modalToggle,
        modalWishlistSecond: modalWishlistSecond,
        loading: loading,
        setLoading: setLoading,
        addedToWishlistModalToggle: addedToWishlistModalToggle,
        setModalWishlistSecond: setModalWishlistSecond,
        addedPopUp: addedPopUp,
        setAddedPopUp: setAddedPopUp,
        collectionProducts: collectionProducts,
      }}
    >
      <>{props.children}</>
    </WishlistContext.Provider>
  );
};

export const WishlistConsumer = WishlistContext.Consumer;

export default WishlistContext;
