export default (state, action) => {
  let payload = { ...action.payload };
  let findWishlist = state.data.wishlistItems.findIndex(
    (wishlist) => wishlist.name === payload.name
  );

  let newState = { ...state };
  newState.data.wishlistItems[findWishlist] = {
    ...payload,
    id: newState.data.wishlistItems[findWishlist].id,
  };

  return Object.assign({}, state, { ...newState });
};
