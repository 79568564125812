import { lazy, useState, Suspense } from "react";
import { isUndefined } from "core/helpers/functions";

const Information = lazy(() =>
  import("./information" /*webpackChunkName: "information"*/)
);

const InformationStateContainer = (props) => {
  let { customer } = props;

  const [changeEmail, setChangeEmail] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [firstname, setFirstname] = useState(
    isUndefined(customer.firstname) ? "" : customer.firstname
  );
  const [lastname, setLastname] = useState(
    isUndefined(customer.lastname) ? "" : customer.lastname
  );
  const [email, setEmail] = useState(
    isUndefined(customer.email) ? "" : customer.email
  );
  const [password, setPassword] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [newpasswordconf, setNewpasswordconf] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [loading, setLoading] = useState(false);
  let secondTitle = "confirm password";
  if (changePassword) {
    secondTitle = "Change Password";
  }
  if (changeEmail) {
    secondTitle = "Change Email";
  }
  if (changePassword && changeEmail) {
    secondTitle = "Change Email And Password";
  }

  return (
    <Suspense fallback={<></>}>
      <Information
        {...props}
        isUndefined={isUndefined}
        customer={customer}
        // Getters
        changeEmail={changeEmail}
        firstname={firstname}
        changePassword={changePassword}
        lastname={lastname}
        email={email}
        password={password}
        newpassword={newpassword}
        newpasswordconf={newpasswordconf}
        secondTitle={secondTitle}
        loading={loading}
        // Setters
        setChangeEmail={setChangeEmail}
        setChangePassword={setChangePassword}
        setFirstname={setFirstname}
        setLastname={setLastname}
        setEmail={setEmail}
        setPassword={setPassword}
        setNewpassword={setNewpassword}
        setNewpasswordconf={setNewpasswordconf}
        currentPassword={currentPassword}
        setLoading={setLoading}
        setCurrentPassword={setCurrentPassword}
      />
    </Suspense>
  );
};

export default InformationStateContainer;
