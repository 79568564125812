import { isEqual } from "lodash";
import { useState, useEffect, memo } from "react";
import HeaderDropdown from "./dropdown";

const HeaderDropdownDataContainer = memo(
  (props) => {
    let { activeMenuItems } = props;

    let complexMenuLayout = activeMenuItems.complex_menu;
    //initial active category
    const [activeCategory, setActiveCategory] = useState(0);
    //initial active subcategory
    const [activeSubcategory, setActiveSubcategory] = useState(0);

    //and initial active subcategories
    let initialSubcategories =
      activeMenuItems && activeMenuItems.children
        ? activeMenuItems.children[activeCategory] &&
          activeMenuItems.children[activeCategory].children
        : false;

    let initialImage =
      activeMenuItems && activeMenuItems.children
        ? activeMenuItems.children[activeCategory] &&
          activeMenuItems.children[activeCategory].menu_image
        : false;
    //active subcategories
    const [subcategories, setSubcategories] = useState(initialSubcategories);
    const [activeCategoryImage, setActiveCategoryImage] = useState(
      initialImage
    );

    useEffect(() => {
      setActiveCategory(0);
      setActiveSubcategory(0);
      setSubcategories(initialSubcategories);
    }, [activeMenuItems]);
    return (
      <HeaderDropdown
        {...props}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        subcategories={subcategories}
        setSubcategories={setSubcategories}
        activeCategoryImage={activeCategoryImage}
        setActiveCategoryImage={setActiveCategoryImage}
        complexMenuLayout={complexMenuLayout}
      />
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default HeaderDropdownDataContainer;
