import Icon from "app/assets/icon/icon";
import { MDBCol, MDBContainer, MDBNavItem, MDBRow } from "mdbreact";
import ToggleMenu from "..";
import { Link } from "react-router-dom";

const Items = (props) => {
  const { menuItem, items, closeBurger, collapseId, setCollapseId, sideItems } =
    props;
  let urlPath = menuItem.url_path
    ? menuItem.url_path
    : menuItem.url_key
    ? menuItem.url_key
    : "";
  urlPath = urlPath[0] === "/" ? urlPath : `/${urlPath}`;
  return (
    <>
      <MDBContainer fluid className="mobile-menu-back-wrapper">
        <MDBRow
          className={`d-flex m-0 toggle-dropdown-heading w-100 align-center align-items-center`}
        >
          <MDBCol className="heading-text">
            <span>{menuItem.name}</span>
          </MDBCol>
          <div
            className=" arrow-container"
            onClick={(e) => {
              let newCollapseId = [...collapseId];
              newCollapseId.pop();
              setCollapseId([...newCollapseId]);
            }}
          >
            <span className="back-arrow">
              <Icon icon="arrowLeftBlue" />
              <span className="ml-2">Back</span>
            </span>
          </div>
        </MDBRow>
      </MDBContainer>

      <div className={`mobile-menu-items-list`}>
        {Object.keys(items).map((element, i) => {
          let urlPath = items[i].url_path
            ? items[i].url_path
            : items[i].url_key
            ? items[i].url_key
            : "";

          urlPath = urlPath[0] === "/" ? urlPath : `/${urlPath}`;
          return (
            <MDBNavItem
              key={`mobile-navigation-item-${items[i].id}-${items[i].url_path}`}
            >
              {typeof items[i] !== "undefined" &&
              typeof items[i].children === "undefined" ? (
                <Link
                  className={`text-decoration-none link-mobile`}
                  to={
                    menuItem?.name.toLowerCase() === "styles"
                      ? `/collections?index=${i}`
                      : urlPath
                  }
                  onClick={(e) => {
                    if (
                      collapseId.includes(menuItem.id) &&
                      collapseId[collapseId.length - 1] !== menuItem.id
                    ) {
                      e.preventDefault();
                    } else {
                      closeBurger();
                      setCollapseId([]);
                    }
                  }}
                >
                  <span>{items[i].name}</span>
                </Link>
              ) : (
                <></>
              )}
              {typeof items[i] !== "undefined" &&
              typeof items[i].children !== "undefined" &&
              items[i].children.length === 0 ? (
                <Link
                  className={`text-decoration-none text-uppercase link-mobile`}
                  to={`${urlPath}`}
                  onClick={(e) => {
                    if (
                      collapseId.includes(menuItem.id) &&
                      collapseId[collapseId.length - 1] !== menuItem.id
                    ) {
                      e.preventDefault();
                    } else {
                      closeBurger();
                      setCollapseId([]);
                    }
                  }}
                >
                  <span>{items[i].name}</span>
                </Link>
              ) : (
                <></>
              )}
              {typeof items[i] !== "undefined" &&
              typeof items[i].children !== "undefined" &&
              items[i].children.length > 0 ? (
                <ToggleMenu
                  items={items[i].children}
                  menuItem={items[i]}
                  closeBurger={closeBurger}
                  setCollapseId={setCollapseId}
                  collapseId={collapseId}
                />
              ) : (
                <></>
              )}
            </MDBNavItem>
          );
        })}

        {sideItems &&
          sideItems.length > 0 &&
          Object.keys(sideItems).map((element, i) => {
            const item = sideItems[element];
            return (
              <MDBNavItem
                className={`pr-0`}
                key={`mobile-navigation-item-${item.id}-${item.url_path}`}
              >
                <Link
                  className={`text-decoration-none text-uppercase link-mobile`}
                  to={`${item.url_path}`}
                  onClick={(e) => {
                    closeBurger();
                    setCollapseId([]);
                  }}
                >
                  <span>{item.name}</span>
                </Link>
              </MDBNavItem>
            );
          })}

        {menuItem.complex_menu && (
          <Link
            className="view-all-mobile"
            to={`${urlPath}`}
            onClick={() => {
              closeBurger();
              setCollapseId([]);
            }}
          >
            <span className="hover">View All</span>
          </Link>
        )}
      </div>
    </>
  );
};

export default Items;
