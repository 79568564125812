import apolloClient from "app/graphql/apolloClient";
import queryLoader from "app/graphql/queryLoader";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "../../redux/data/common";
import { customerActions } from "../../redux/data/customer";
import { messagesActions } from "core/state/redux/data/messages";
import gql from "graphql-tag";
import useErrorHandling from "../coreHooks/useErrorHandling";
import { apolloClientMutation } from "app/graphql/apolloClient";
import { wishlistActions } from "app/state/redux/data/wishlist";
import { compareActions } from "app/state/redux/data/compare";
import { useCallback } from "react";
import { useMutation } from "react-apollo-hooks";

// re-apply

const useCustomer = () => {
  const customerObject = useSelector((state) => state.customer);
  const customer = useSelector((state) => state.customer.data);
  const { sanitizeGraphQLMessage } = useErrorHandling();
  let dispatch = useDispatch();

  const getCustomer = () => {
    // logic to return customer information from redux
    return customer;
  };

  const getMiniCartToggle = () => {
    return customerObject.toggleMinicart;
  };

  const isLoggedIn = () => {
    if (customer.token === false) {
      return false;
    }

    return customer.token;
  };

  const logoutCustomer = async () => {
    try {
      if (customer.token) {
        dispatch(customerActions.logoutCustomer());
        dispatch(
          compareActions.setCompare({ compareItems: [], listCleared: true })
        );
      }
    } catch (error) {
      dispatch(commonActions.unlock("fail"));
      dispatch(messagesActions.addMessage(error.toString(), "danger", "login"));
      // dispatch(customerActions._reduceCustomer());
      return false;
    }
  };

  const invalidCustomerToken = async () => {
    try {
      dispatch(customerActions.logoutCustomer(true));
      setTimeout(() => {
        dispatch(
          messagesActions.addMessage(
            "Your session has expired. Please login to resume.",
            "danger"
          )
        );
      }, 300);
    } catch (error) {
      dispatch(commonActions.unlock("fail"));
      dispatch(messagesActions.addMessage(error.toString(), "danger", "login"));
    }
  };

  const login = async (email, password, cartToken) => {
    try {
      if (!email || !password) {
        throw new Error("Invalid email or password");
      }

      const { data } = await apolloClient.mutate({
        mutation: queryLoader("userLogin"),
        variables: { email: email, password: password },
        fetchPolicy: "no-cache",
      });

      if (!data.generateCustomerToken || !data.generateCustomerToken.token) {
        console.log("GraphQL response", data);
        throw new Error("No customer token received");
      }
      dispatch(
        customerActions._setCustomerToken(
          data.generateCustomerToken.token,
          cartToken
        )
      ).then(async () => {
        const wishlist = await apolloClientMutation.query({
          query: queryLoader("getWishlists"),
          fetchPolicy: "no-cache",
        });
        if (wishlist.data.getWishlists.length === 0) {
          const createWishlist = await apolloClient.mutate({
            mutation: queryLoader("addNewWishlist"),
            variables: { input: "My Favorites" },
            fetchPolicy: "no-cache",
          });

          if (createWishlist.data.addNewWishlist !== null) {
            dispatch(
              wishlistActions.setWishlist([createWishlist.data.addNewWishlist])
            );
          }
        } else {
          dispatch(wishlistActions.setWishlist(wishlist.data.getWishlists));
        }
      });
      return data.generateCustomerToken;
    } catch (error) {
      dispatch(commonActions.unlock("fail"));
      dispatch(messagesActions.addMessage(error.toString(), "danger", "login"));
      // dispatch(customerActions._reduceCustomer());
      return { error: true, message: sanitizeGraphQLMessage(error.message) };
    }
  };

  const registerCustomer = async (input, showSuccessPage) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("registerCustomer"),
        variables: {
          input: input,
        },
        fetchPolicy: "no-cache",
      });

      if (!data.createCustomer || !data.createCustomer.customer) {
        console.log("GraphQL response", data);
        throw new Error("No customer information retrieved.");
      }
      dispatch(
        messagesActions.addMessage(
          "You have successfully registered.",
          "success",
          "register"
        )
      );
      showSuccessPage(true);
    } catch (error) {
      dispatch(commonActions.unlock("fail"));
      dispatch(
        messagesActions.addMessage(error.toString(), "danger", "register")
      );
      return error?.message;
    }
  };

  const updateCustomerEmail = async (payload) => {
    const updateCustomerEmailMutation = gql`
      mutation UpdateCustomerEmail($email: String!, $password: String!) {
        updateCustomerEmail(email: $email, password: $password) {
          customer {
            email
          }
        }
      }
    `;
    try {
      if (!payload.email || !payload.password) {
        throw new Error("Email or password is missing.");
      }
      const { data } = await apolloClient.mutate({
        mutation: updateCustomerEmailMutation,
        variables: payload,
        fetchPolicy: "no-cache",
      });

      if (data.updateCustomerEmail && data.updateCustomerEmail.customer) {
        dispatch(
          customerActions.updateCustomerData(data.updateCustomerEmail)
        ).then(() => {
          dispatch(
            messagesActions.addMessage(
              "Successfully updated customer email",
              "success"
            )
          );
        });
      }

      return true;
    } catch (error) {
      dispatch(messagesActions.addMessage(error.message, "danger"));
      return false;
    }
  };

  const updateCustomerInformation = async (payload) => {
    const updateCustomerInformationMutation = gql`
      mutation UpdateCustomerV2($payload: CustomerUpdateInput!) {
        updateCustomerV2(input: $payload) {
          customer {
            firstname
            lastname
          }
        }
      }
    `;
    try {
      if (!payload) {
        throw new Error("Missing information.");
      }
      const { data } = await apolloClient.mutate({
        mutation: updateCustomerInformationMutation,
        variables: { payload: payload },
        fetchPolicy: "no-cache",
      });

      if (data.updateCustomerV2 && data.updateCustomerV2.customer) {
        dispatch(
          customerActions.updateCustomerData(data.updateCustomerV2)
        ).then(() => {
          dispatch(
            messagesActions.addMessage(
              "Successfully updated customer information",
              "success"
            )
          );
        });
      }

      return true;
    } catch (error) {
      dispatch(messagesActions.addMessage(error.message, "danger"));
      return false;
    }
  };

  const updateCustomerPassword = async (payload) => {
    try {
      if (!payload) {
        throw new Error("Missing information.");
      }
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("changeCustomerPassword"),
        variables: payload,
        fetchPolicy: "no-cache",
      });

      if (data.changeCustomerPassword) {
        dispatch(
          messagesActions.addMessage(
            "Successfully updated customer information",
            "success"
          )
        );
      }

      return true;
    } catch (error) {
      dispatch(messagesActions.addMessage(error.message, "danger"));
      return false;
    }
  };

  const updateCustomerState = (newData) => {
    let oldCustomer = { ...customer };

    dispatch(
      customerActions.setCustomerInformation({ ...oldCustomer, ...newData })
    );
  };

  const getMinimalOrderInformation = () => {
    return {
      added: customerObject.added,
      response: customerObject.response,
      can_checkout: customerObject.can_checkout,
    };
  };
  return {
    getCustomer,
    isLoggedIn,
    logoutCustomer,
    login,
    registerCustomer,
    updateCustomerEmail,
    updateCustomerInformation,
    updateCustomerPassword,
    getMiniCartToggle,
    updateCustomerState,
    getMinimalOrderInformation,
    invalidCustomerToken,
  };
};

export default useCustomer;
