import { MDBBox } from "mdbreact";
import Button from "core/components/button";
import Select from "app/layout/select";
import MaskedInput from "react-text-mask";
import { useForm, Controller } from "react-hook-form";

const Form = ({
  zipCodeMask,
  selectedCountry,
  setFilterData,
  setSelectCountryFn,
  countriesData,
  regionsData,
  industries,
  loading,
}) => {
  const {
    handleSubmit,
    formState,
    control,
    trigger,
    getValues,
    setValue,
  } = useForm();

  const { errors } = formState;

  const { country } = getValues();

  const zipMask = zipCodeMask(country ? country.value : "US");

  let zipPlaceholder = country ? country?.value : "US";
  zipPlaceholder = loading
    ? "Zip "
    : zipPlaceholder === "US"
    ? " Zip "
    : " Postal ";

  let statePlaceholder = country ? country?.value : "US";
  statePlaceholder = loading
    ? "State "
    : statePlaceholder === "US"
    ? " State "
    : " Province ";

  const onSearch = (data) => {
    if (data) {
      setFilterData(data);
    }
  };

  return (
    <form id={`register-form`} onSubmit={handleSubmit(onSearch)}>
      <MDBBox className={`align-items-center find-rep-toolbar mb-3 `}>
        <Controller
          control={control}
          name="country"
          defaultValue={selectedCountry}
          rules={{
            required: true,
          }}
          render={({ onChange, value, name, ref }) => (
            <Select
              onChange={(e) => {
                onChange(e);
                setSelectCountryFn(e);
                setValue(`state`, "");
                setValue(`zip`, "");
              }}
              isDisabled={loading}
              onBlur={() => trigger(name)}
              innerRef={ref}
              name={name}
              placeholder={`Select Country *`}
              options={countriesData}
              value={value}
              className={`custom-input ${
                errors.business_type ? "validation-error" : ""
              }`}
              label={`${
                errors.business_type && " Please select one of the options."
              }`}
              filled={value && value.length !== 0 ? true : false}
            />
          )}
        />
        <Controller
          control={control}
          name="state"
          defaultValue={""}
          rules={{
            required: country?.value === "US" ? true : false,
          }}
          render={({ onChange, value, name, ref }) => (
            <Select
              isDisabled={loading || !country}
              onChange={onChange}
              onBlur={() => trigger(name)}
              innerRef={ref}
              name={name}
              placeholder={`Select ${statePlaceholder} ${
                country?.value === "US" ? "*" : ""
              }`}
              options={regionsData}
              value={value}
              className={`custom-input ${
                country?.value === "US" && errors[name]
                  ? "validation-error"
                  : ""
              }`}
              label={`${
                country?.value === "US" && errors[name]
                  ? "Please select one of the options."
                  : ""
              }`}
              filled={value && value.length !== 0 ? true : false}
            />
          )}
        />
        <Controller
          control={control}
          name="zip"
          defaultValue={""}
          rules={{
            // change when we have all data for countries
            // required: country?.value === "US" ? true : false,
            pattern:
              country?.value === "US" || country?.value === "CA"
                ? zipMask
                : null,
            minLength:
              country?.value === "US" || country?.value === "CA"
                ? zipMask.length
                : null,
          }}
          render={({ onChange, value, name, ref }) => (
            <MaskedInput
              mask={zipMask}
              name={name}
              value={value}
              onChange={onChange}
              innerRef={ref}
              onBlur={() => {
                trigger(name);
              }}
              render={(ref, inputProps, onChange, onBlur) => {
                return (
                  <MDBBox className={`w-100 masked-input-container`}>
                    <input
                      onBlur={onBlur}
                      onChange={onChange}
                      placeholder={`${zipPlaceholder} Code ${
                        country?.value === "US" ? "*" : !country ? "*" : ""
                      }`}
                      innerRef={ref}
                      {...inputProps}
                      disabled={loading || !country || errors.country}
                      ref={ref}
                      className={`form-control masked-input validate default-input-field ${
                        value !== "" && !errors[name] && "filled"
                      } ${
                        country?.value === "US" && errors[name]
                          ? "validation-error"
                          : ""
                      } `}
                    />
                    {country?.value === "US" && errors[name] && (
                      <p className={`error-label`}>
                        {zipPlaceholder} Code is required *
                      </p>
                    )}
                  </MDBBox>
                );
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="industry"
          defaultValue={""}
          rules={{
            required: true,
          }}
          render={({ onChange, value, name, ref }) => (
            <Select
              onChange={onChange}
              onBlur={() => trigger(name)}
              innerRef={ref}
              name={name}
              placeholder={`Select Industry *`}
              options={industries}
              value={value}
              className={`custom-input ${
                errors[name] ? "validation-error" : ""
              }`}
              isDisabled={loading || !country}
              label={`${errors[name] && "Please select one of the options."}`}
              filled={value && value.length !== 0 ? true : false}
            />
          )}
        />
        <Button
          disabled={!country}
          color="primary"
          className={"w-100"}
          children={<MDBBox tag="span">{"Search"}</MDBBox>}
          type={`submit`}
        />
      </MDBBox>
    </form>
  );
};

export default Form;
