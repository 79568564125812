import SearchInput from "./searchInput";
import { MDBBox, MDBTypography } from "mdbreact";

const SearchToolbar = (props) => {
  return (
    <div className={`search-toolbar w-100`}>
      <div className={`input-container w-100 text-center`}>
        <MDBBox className="my-5">
          <MDBTypography tag="h1" className="page-title text-center">
            Search
          </MDBTypography>
          <MDBTypography tag="p" className="text-center">
            Can we help you find what you need?
          </MDBTypography>
        </MDBBox>
        <MDBBox>
          <SearchInput {...props} />
        </MDBBox>
      </div>
    </div>
  );
};

export default SearchToolbar;
