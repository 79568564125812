import { useState, useEffect } from "react";
import Content from "./content";

const ContentDataContainer = (props) => {
  let { data, selectState, industrySelect } = props;
  let getRepLocators = data?.getRepLocators;
  const [repLocatorsData, setRepLocatorsData] = useState(getRepLocators);
  const [selectedStateData, setSelectedStateData] = useState(selectState);
  const [selectedIndustryData, setSelectedIndustryData] = useState(
    industrySelect
  );

  useEffect(() => {
    if (
      typeof repLocatorsData === "undefined" &&
      typeof getRepLocators !== "undefined"
    ) {
      setRepLocatorsData(getRepLocators);
      setSelectedStateData(selectState);
      setSelectedIndustryData(industrySelect);
    }
    if (
      typeof repLocatorsData !== "undefined" &&
      typeof getRepLocators !== "undefined"
    ) {
      setRepLocatorsData(getRepLocators);
      setSelectedStateData(selectState);
      setSelectedIndustryData(industrySelect);
    }
  }, [data, getRepLocators, industrySelect, repLocatorsData, selectState]);
  let repLocators = repLocatorsData;

  return (
    <Content
      repLocators={repLocators}
      selectState={selectedStateData}
      industrySelect={selectedIndustryData}
    />
  );
};
export default ContentDataContainer;
