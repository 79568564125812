import React, { useState } from "react";
import MainSlider from "./mainSlider";
// import NavSlider from "./navSlider";

const ModalPopupGallery = (props) => {
  let { productItem, image_gallery, activeImageIndex, setActiveImageIndex } =
    props;

  const [sliderNav1, setSliderNav1] = useState(null);
  const [sliderNav2, setSliderNav2] = useState(null);

  return (
    <div className="popup-modal">
      <MainSlider
        sliderNav2={sliderNav2}
        activeImageIndex={activeImageIndex}
        image_gallery={image_gallery}
        productItem={productItem}
        setSliderNav1={setSliderNav1}
        setActiveImageIndex={setActiveImageIndex}
      />
      {/* <NavSlider
        sliderNav1={sliderNav1}
        activeImageIndex={activeImageIndex}
        image_gallery={image_gallery}
        productItem={productItem}
        setSliderNav2={setSliderNav2}
      /> */}
    </div>
  );
};

export default ModalPopupGallery;
