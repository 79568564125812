import { memo, useMemo } from "react";
import { MDBBox } from "mdbreact";
import ImageContainer from "./imageContainer";
import { isUndefined, isEqual } from "lodash";
import ImageLoader from "react-load-image";
import ReactLoader from "app/layout/loader";

function Preloader(props) {
  return (
    <MDBBox
      style={{
        width: "100%",
        height: "100%",
      }}
      className={`loading-container`}
    >
      <ReactLoader {...props} type={props.type} />
    </MDBBox>
  );
}

const Image = memo(
  (props) => {
    const preload = props.preload !== undefined ? props.preload : true;
    const webpImage = useMemo(
      () =>
        !props.webpImage
          ? !props.webpImage
          : props.webpImage.includes("http") ||
            props.webpImage.includes("data:image")
          ? props.webpImage
          : props.webpImage.includes(process.env.REACT_APP_MAIN_STORE)
          ? `https://${props.webpImage}`
          : `https://${process.env.REACT_APP_MAIN_STORE}/${props.webpImage}`,
      [props.webpImage]
    );
    let source = "";
    if (typeof props.source === "undefined") return "";
    if (!isUndefined(props.backend) && props.backend === true) {
      if (props.backend_public) {
        // const url = props.mediaLink
        //   ? store.base_media_url
        //   : store.base_link_url;

        source =
          props.source.includes("http") || props.source.includes("data:image")
            ? props.source
            : props.source.includes(process.env.REACT_APP_MAIN_STORE)
            ? `https://${props.source}`
            : `https://${process.env.REACT_APP_MAIN_STORE}/${props.source}`;
      } else {
        source = process.env.REACT_APP_MAIN_STORE + props.source;
      }
    } else {
      if (props.source) {
        source =
          props.source.includes("http") || props.source.includes("data:image")
            ? props.source
            : props.source.includes(process.env.REACT_APP_MAIN_STORE)
            ? `https://${props.source}`
            : `https://${process.env.REACT_APP_MAIN_STORE}/${props.source}`;
      }
    }

    const imgProps = {
      //necessary in order to pass hyphenated data-src to props
      "data-src": source,
    };

    if (webpImage) {
      return (
        <ImageContainer {...props}>
          {preload ? (
            <link
              rel={"preload"}
              as="image"
              href={webpImage ? webpImage : ""}
              className={props.className}
              alt={""}
            />
          ) : (
            <></>
          )}
          <div className={`${props.wrapperClass}`}>
            <picture>
              {webpImage ? (
                <source srcSet={webpImage} type="image/webp" />
              ) : (
                <></>
              )}
              <source
                srcSet={`${
                  source === null
                    ? "https://www.naem.org/images/default-source/default-album/default-album/700x500.png?sfvrsn=df8f03c2_0"
                    : source
                }`}
                type={`${
                  source.includes(".jpg")
                    ? "image/jpeg"
                    : source.includes(".png")
                    ? "image/png"
                    : "image/png"
                }`}
              />
              <img
                className={`${props.className} lazyload`}
                {...imgProps}
                src={
                  source === null
                    ? "https://www.naem.org/images/default-source/default-album/default-album/700x500.png?sfvrsn=df8f03c2_0"
                    : source
                }
                width={"400px"}
                height={"400px"}
                alt={props.alt}
              />
            </picture>
          </div>
        </ImageContainer>
      );
    }

    return (
      <>
        <link
          rel={"preload"}
          as="image"
          href={source}
          className={props.className}
          alt={""}
        />
        <ImageContainer {...props}>
          <ImageLoader src={source} onError={(e) => console.log(e)}>
            <img
              src={source}
              {...imgProps}
              className={`${props.className} lazyload`}
              alt={props.alt}
              width={`400px`}
              height={`400px`}
            />
            <MDBBox>Error!</MDBBox>
            <Preloader props={props} />
          </ImageLoader>
        </ImageContainer>
      </>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default Image;
