import { useState, useRef, useContext, Suspense } from "react";
import CustomerAccountActions from "./customerAccountActions";
import CartAndWishlist from "./cartAndWishlist";
import { useOutsideAlerter } from "./useOutsideAlerter";
import Icon from "app/assets/icon/icon";
import { MiniCartContext } from "app/state/hooks/cartHooks/MinicartContext";
import useUtility from "app/state/hooks/useUtility";

const Right = (props) => {
  let { customer, token, store } = props;
  const { lazyWithPreload } = useUtility();
  const miniCartContext = useContext(MiniCartContext);
  const [LazySearch, setLazySearch] = useState(false);
  const { showMiniCart, displayMiniCart, hideMiniCart, toggleMiniCart } =
    miniCartContext;
  let loggedIn = token;
  let [searchVal, setSearchVal] = useState("");
  let [displaySearch, setDisplaySearch] = useState(false);

  let searchRef = useRef(null);

  const outsideClick = () => {
    setDisplaySearch(false);
    const body = document.getElementsByTagName("BODY")[0];
    body.classList.remove("search-open");
  };
  useOutsideAlerter(searchRef, displaySearch, outsideClick);

  const toggleSearch = (e) => {
    const body = document.getElementsByTagName("BODY")[0];
    setSearchVal("");
    setDisplaySearch(!displaySearch);
    if (body.classList?.contains("search-open")) {
      body.classList.remove("search-open");
    } else {
      body.classList.add("search-open");
    }
    const SearchComponent = lazyWithPreload(() =>
      import("../../search" /*webpackChunkName: "lazy-search" */)
    );
    setLazySearch(SearchComponent);
  };

  // let wishlistCount = 0;
  return (
    <div className="menu-right-container">
      <CustomerAccountActions
        token={token}
        props={props}
        hideMiniCart={hideMiniCart}
        customer={customer}
        store={store}
      />
      <div
        ref={searchRef}
        className=" pl-1 d-flex justify-content-center align-items-center"
      >
        <p
          className={`search-icon  ${loggedIn ? "logged-in" : ""} ${
            displaySearch && "close-search-icon"
          } ${store}`}
          id="search-text"
          onClick={toggleSearch}
        >
          <Icon
            icon={`searchIcon`}
            className={`${displaySearch ? "icon-blue" : ""}`}
          />
        </p>
      </div>
      {token && (
        <CartAndWishlist
          // wishlistCount={wishlistCount}
          displayMiniCart={displayMiniCart}
          showMiniCart={showMiniCart}
          toggleMiniCart={toggleMiniCart}
          hideMiniCart={hideMiniCart}
          customer={customer}
        />
      )}
      <div ref={searchRef}>
        {LazySearch ? (
          <Suspense fallback={<></>}>
            <LazySearch
              open={displaySearch}
              searchVal={searchVal}
              setSearchVal={setSearchVal}
              setDisplaySearch={setDisplaySearch}
              displaySearch={displaySearch}
              toggleSearch={toggleSearch}
            />
          </Suspense>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Right;
