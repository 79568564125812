import { useLocation } from "react-router";
import { memo, useState } from "react";
import { MDBBox, MDBBtn, MDBTooltip } from "mdbreact";
import Button from "core/components/button/button";
import Icon from "app/assets/icon/icon";
import { WishlistConsumer } from "../wishlistContext/WishlistContext";

/**
 * Render a wishlist icon.
 * @param {object} product A product object, the only required parameter are the sku and image.
 * @param {object} actions Additional actions used when dispatching onClick events.
 * Possible parameters:
 * @example {
 * showPopupOnAdd: true,
 * removeOnClick: true,
 * removeWishlist: "Wish List"
 * }
 * @param {boolean} button Used to change the render of the component into a button. Default: false
 * @param {boolean} label Used to show label next to the icon (applicable only in button mode)
 * @param {string} labelText Text used next to the icon (if button mode is on). Default: "Favorites"
 * @param {boolean} hideIcon Hides the icon
 */
const WishlistIcon = memo(
  ({
    product,
    actions,
    collections = null,
    label = false,
    button = false,
    labelText = "",
    wishlistName = "My Favorites",
    hideIcon = false,
    className = "",
    loadingText = "Saving...",
    isPdp = false,
    productVariant = null,
  }) => {
    // console.log(selectedSwatch, selectedSize);
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    if (typeof product?.sku === "undefined" && !collections) return <></>;
    return (
      <WishlistConsumer>
        {({ onClickFn, isInWishlist }) => {
          // let foundItem = isInWishlist(product?.sku, wishlistName);
          let foundItem = isInWishlist(product?.sku, wishlistName);
          if (!productVariant && product?.variants?.length > 0) {
            productVariant = product?.variants[0];
            productVariant["configurable_options"] =
              product?.configurable_options;
          }
          let onClickFunction = (collections) => {
            if (!loading) {
              if (!collections) {
                onClickFn(product, actions, setLoading, productVariant);
              } else {
                onClickFn(product, actions, setLoading, null, collections);
              }
            }
          };
          return (
            <>
              <div
                className={`wishlist-icon-container ${className} ${
                  loading ? "loading" : ""
                }`}
                style={{
                  opacity:
                    location.pathname.includes("/customer/favorites") && "1",
                }}
                onClick={(e) => {
                  onClickFunction(collections);
                }}
              >
                {!hideIcon ? (
                  !collections && (
                    <MDBBtn
                      color="default"
                      outline
                      className={"wishlist-pdp-btn"}
                      children={
                        isPdp ? (
                          <MDBTooltip domElement placement="top" tag="span">
                            <Icon
                              icon={
                                foundItem ? `wishlistPdp` : `wishlistPlpEmpty`
                              }
                            />
                            <span>Wishlist</span>
                          </MDBTooltip>
                        ) : (
                          <Icon
                            icon={
                              foundItem ? `wishlistPdp` : `wishlistPlpEmpty`
                            }
                          />
                        )
                      }
                    />
                  )
                ) : (
                  // <Icon
                  //   icon={foundItem ? `wishlistPlpFull` : `wishlistPlpEmpty`}
                  // />
                  <></>
                )}
                {label && button ? (
                  !collections ? (
                    <WishlistLabel
                      labelText={labelText}
                      loading={loading}
                      loadingText={loadingText}
                    />
                  ) : (
                    <Button
                      className={
                        "w-100 collection-to-wishlist-btn btn-primary d-flex justify-content-between"
                      }
                      children={
                        <>
                          <h1 className="text-left">
                            Add this collection to your favorites
                          </h1>
                          <div className="circle-holder">
                            <MDBBox className={`circle`}>
                              <p>{"+"}</p>
                            </MDBBox>
                          </div>
                        </>
                      }
                    />
                  )
                ) : (
                  <></>
                )}
              </div>
              {label && !button ? (
                <WishlistLabel
                  labelText={labelText}
                  onClickFn={() => {
                    onClickFunction();
                  }}
                  loading={loading}
                  loadingText={loadingText}
                />
              ) : (
                <></>
              )}
            </>
          );
        }}
      </WishlistConsumer>
    );
  }
);

const WishlistLabel = ({
  onClickFn = () => {},
  labelText,
  loadingText,
  loading,
}) => {
  return (
    <span
      onClick={() => {
        onClickFn();
      }}
    >
      {loading ? loadingText : labelText}
    </span>
  );
};

export default WishlistIcon;
