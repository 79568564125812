/**
 * filter integrations with Redux
 */

import searchReducer from "./reducer";
export { default as searchTypes } from "./types";
// export { default as searchMiddleware } from "./middleware";
export { default as searchActions } from "./actions";
export { default as searchInitialState } from "./initialState";
export default searchReducer;

// End
