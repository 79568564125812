import apolloClient from "app/graphql/apolloClient";
import customerActions from "../../actions";
import commonActions from "../../../common/actions";
import messagesActions from "core/state/redux/data/messages/actions";
import queryActions from "core/state/redux/data/queries/actions";
import { gql } from "graphql.macro";

const APPLY_COUPON_TO_CART_MUTATION = gql`
  mutation RemoveCouponFromCart($input: RemoveCouponFromCartInput) {
    removeCouponFromCart(input: $input) {
      cart {
        id
        # ship_complete
        prices {
          subtotal_including_tax {
            currency
            value
          }
          subtotal_excluding_tax {
            currency
            value
          }
          fee {
            currency
            value
          }
          grand_total {
            currency
            value
          }
          applied_taxes {
            amount {
              currency
              value
            }
            label
          }
          discounts {
            amount {
              value
              currency
            }
            label
          }
        }
        items {
          id
          prices {
            discounts {
              amount {
                currency
                value
              }
              label
            }
            price {
              currency
              value
            }
            row_total {
              currency
              value
            }
            row_total_including_tax {
              currency
              value
            }
            total_item_discount {
              currency
              value
            }
          }
          ... on SimpleCartItem {
            simple_customizable: customizable_options {
              label
              values {
                label
                value
                price {
                  type
                  units
                  value
                }
              }
            }
          }
          ... on BundleCartItem {
            bundle_customizable: customizable_options {
              label
              sort_order
              values {
                label
                value
                price {
                  type
                  units
                  value
                }
              }
            }
            bundle_options {
              id
              label
              type
              values {
                id
                label
                price
                quantity
              }
            }
          }
          product {
            url_key
            name
            sku
            stock_status
            small_image {
              url
            }
            size
            price_range {
              minimum_price {
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  currency
                  value
                }
                fixed_product_taxes {
                  amount {
                    currency
                    value
                  }
                  label
                }
                regular_price {
                  currency
                  value
                }
              }
              maximum_price {
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  currency
                  value
                }
                fixed_product_taxes {
                  amount {
                    currency
                    value
                  }
                  label
                }
                regular_price {
                  currency
                  value
                }
              }
            }
          }
          quantity
        }
        applied_coupons {
          code
        }
      }
    }
  }
`;
export default async (store, action) => {
  try {
    let cart_id = store.getState().customer.data.cartToken;
    let input = { cart_id: cart_id };

    const { data } = await apolloClient.mutate({
      mutation: APPLY_COUPON_TO_CART_MUTATION,
      variables: { input: input },
      fetchPolicy: "no-cache",
    });
    if (data.removeCouponFromCart) {
      let newCart = {
        ...store.getState().customer.data.cart,
        ...data.removeCouponFromCart.cart,
      };
      store.dispatch(customerActions.setCartInformation(newCart));
      store.dispatch(
        messagesActions.addMessage("removed all active coupons", "success")
      );
      store.dispatch(commonActions.unlock("success"));
    }
  } catch (error) {
    if (error.message.includes("Could not find a cart with ID")) {
      store.dispatch(customerActions.createEmptyCart());
    } else {
      store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    }
    store.dispatch(commonActions.unlock("fail"));
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
