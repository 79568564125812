import gql from "graphql-tag";

export const COUNTRIES_V2 = gql`
  query CountriesV2 {
    countriesV2 {
      value: id
      label: full_name_english
      regions: available_regions {
        code
        id
        name
      }
    }
  }
`;
