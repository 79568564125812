import { MDBContainer, MDBRow, MDBTypography, MDBBox, MDBBtn } from "mdbreact";
import CmsBlock from "core/components/cmsBlock";
import { useHistory } from "react-router";

const AccountCreationThankYouPage = () => {
  const history = useHistory();
  return (
    <div className={"account-creation-success"}>
      <MDBContainer fluid>
        <MDBRow>
          <CmsBlock dangHtml={true} id="thank-you-image" />
        </MDBRow>
      </MDBContainer>
      <MDBContainer fluid>
        <MDBRow className={`mb-90`}>
          <MDBBox className="title-with-background w-100">
            <MDBContainer>
              <MDBTypography tag="h1" className="page-title">
                Thank You!
              </MDBTypography>
            </MDBContainer>
          </MDBBox>
        </MDBRow>
        <MDBRow>
          <MDBContainer>
            <CmsBlock dangHtml={true} id="account-creation-thank-you-text" />
            <MDBBtn
              color="primary"
              outline
              onClick={() => history.push("/")}
              className={"btn-with-icon btn-black-text mt-50 mb-100"}
              children={
                <>
                  <MDBBox tag="span">Homepage</MDBBox>
                  <MDBBox className="circle-icon" />
                </>
              }
            />
          </MDBContainer>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

export default AccountCreationThankYouPage;
