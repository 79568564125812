import { isEmpty } from "core/helpers/functions";
import HotspotRender from "./render";
import HotspotQueryLoader from "./hotspotQueryLoader";
import useGenerated from "app/state/hooks/generatedHooks";

const Hotspot = (props) => {
  const { GetGeneratedStore } = useGenerated();
  const store = GetGeneratedStore();
  const base_url = store?.secure_base_media_url?.split("media/")[0];
  let dots = [];
  let skuArray = [];
  if (!base_url) return <></>;
  let contentArray = props.contentArray;

  if (contentArray !== null && contentArray !== undefined) {
    contentArray.map((dot) => {
      if (dot.sku !== "") skuArray.push(dot.sku);
      dots.push({
        top: dot.top,
        left: dot.left,
        display_type: dot.display_type,
        sku: dot.sku,
        addInfo: dot.addInfo,
        isVisible: false,
      });
      return dots;
    });
  }
  if (!isEmpty(skuArray))
    return (
      <HotspotQueryLoader
        dots={dots}
        skuArray={skuArray}
        skeletonHeight={"60rem"}
        {...props}
      />
    );
  return <HotspotRender dots={dots} {...props} />;
};

export default Hotspot;
