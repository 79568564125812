import { MDBContainer } from "mdbreact";
import { lazy, Suspense } from "react";
import SeoHelmet from "app/layout/seoHelmet";
import useStore from "app/state/hooks/storeHooks/useStore";
import Discover from "./discover";

const DesignerCatalogSection = lazy(() =>
  import(
    "./designerCatalogSection" /*webpackChunkName: "desginerCatalogSectionHomepage" */
  )
);
const LifestyleGallery = lazy(() =>
  import("./lifestyleGallery" /*webpackChunkName: "lifestyleGalleryHomepage" */)
);

const FeaturedSection = lazy(() =>
  import("./featuredSection" /*webpackChunkName: "featuredSectionHomepage" */)
);

const HomeSlider = lazy(() =>
  import("./homeSlider" /*webpackChunkName: "homeSlider" */)
);

const Home = () => {
  const { getStore } = useStore();

  return (
    <>
      <SeoHelmet title={"Home Page"} url={"/"} />
      <MDBContainer fluid id="home" className="px-0">
        <Suspense fallback={<></>}>
          <HomeSlider />
        </Suspense>
        <Suspense fallback={<></>}>
          <FeaturedSection />
        </Suspense>
        <Discover />
        <Suspense fallback={<></>}>
          <DesignerCatalogSection />
        </Suspense>
        <Suspense fallback={<></>}>
          <LifestyleGallery />
        </Suspense>
      </MDBContainer>
    </>
  );
};

export default Home;
