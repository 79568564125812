import { Component } from "react";
import PdfProductDetails from "./pdfProductDetails";
import PdfProductFeatures from "./pdfProductFeatures";
import PdfProductSpecifications from "./pdfProductSpecifications";
import "./_pdf.scss";
import Logo from "app/layout/logo";
import GatcoBlueLogo from "app/assets/logo/GatcoLogoBlue.svg";

class Pdf extends Component {
  constructor(props) {
    super(props);
    this.state = { image: "" };
  }
  componentDidMount() {
    let image = new Image();
    image.src = this.product?.image.url;
  }

  render() {
    const store = this.props.store;
    const pricing = this.props.pricing;
    const remarks = this.props.remarks;
    const product = this.props.product;
    const selectedSwatch = this.props.selectedSwatch;
    const selectedSize = this.props.selectedSize;
    const customer = this.props.customer;
    const pdpSidebarImage = this.props.pdpSidebarImage;
    const productVariant = this.props.productVariant;

    return (
      <div className={`pdf-container ${store + "-website"}`}>
        <img src={GatcoBlueLogo} alt="logo" className="logo" />
        <div className="border-bottom mb-5 mt-5"></div>
        <PdfProductDetails
          product={product}
          pricing={pricing}
          selectedSwatch={selectedSwatch}
          selectedSize={selectedSize}
          customer={customer}
          productVariant={productVariant}
          remarks={remarks}
        />
        {/* {pdpSidebarImage && (
          <div className="img-holder">
            <img
              src={pdpSidebarImage?.url}
              alt="pdp-sidebar-image"
              className="mb-5 topp-image"
            />
          </div>
        )} */}
        {/* <PdfProductFeatures feature={product.feature} /> */}
        {/* <PdfProductSpecifications product={product} /> */}
        <div className={`pdf-footer`}>
          <p>
            ©Copyright {new Date().getFullYear()} <b>Gatco inc</b>.
          </p>
        </div>
      </div>
    );
  }
}

export default Pdf;
