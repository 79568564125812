/**
 * Here we map initial state for each Redux state (if necessary)
 * The function name is mappped in'persistedState.js', where the key is the name of the reducer
 * This can be readjusted to possibly use one function to try and find the initial state
 */

import { testInitialState } from "./data/test";
import { storeInitialState } from "./data/store";
import { customerInitialState } from "./data/customer";
import { applicationInitialState } from "./data/application";
import { compareInitialState } from "./data/compare";
import { isEmpty } from "core/helpers/functions";

export const getTestState = (storeState) => {
  let testState = {};
  if (!isEmpty(storeState) && !isEmpty(storeState.test)) {
    testState = storeState.test;
  } else {
    testState = testInitialState;
  }
  return testState;
};

export const getStoreState = (currentStore) => {
  let newStore = {};
  if (!isEmpty(currentStore) && !isEmpty(currentStore.test)) {
    newStore = currentStore.test;
  } else {
    newStore = storeInitialState;
  }
  return newStore;
};

export const getCustomerState = (currentStore) => {
  let newStore = {};
  if (!isEmpty(currentStore) && !isEmpty(currentStore.customer)) {
    newStore = currentStore.customer;
  } else {
    newStore = customerInitialState;
  }
  return newStore;
};

export function getCompareState(storeState) {
  let compareState = {};
  if (!isEmpty(storeState) && !isEmpty(storeState.compare)) {
    compareState = storeState.compare;
  } else {
    compareState = compareInitialState;
  }

  return compareState;
}

export const getApplicationState = (currentStore) => {
  let newStore = {};
  if (!isEmpty(currentStore) && !isEmpty(currentStore.customer)) {
    newStore = currentStore.customer;
  } else {
    newStore = applicationInitialState;
  }
  return newStore;
};
