import { memo } from "react";
import { Link } from "react-router-dom";

import Logo from "app/layout/logo";

const Left = memo(() => {
  return (
    <div className="menu-left">
      <Link
        to="/"
        aria-current="page"
        className="navbar-brand active"
        data-test="navbar-brand"
      >
        <Logo component={"header"} />
      </Link>
    </div>
  );
});

export default Left;
