import { memo } from "react";
import SearchResults from "./searchResults";
import { useQuery } from "react-apollo-hooks";
import { PRODUCTS, PRODUCTS_CUSTOMER } from "./queries";
import { isEqual } from "lodash";

import { apolloClientMutation } from "app/graphql/apolloClient";

const SearchResultsQueryContainer = memo(
  ({
    search,
    allCategories,
    closeSearch,
    mobileonclick,
    changeSearch,
    setDisplaySearch,
    token,
  }) => {
    let finalQuery = null;
    finalQuery = token !== false ? PRODUCTS_CUSTOMER : PRODUCTS;

    const { data, loading, error } = useQuery(finalQuery, {
      variables: {
        search: `${search}`,
        pageSize: "3",
        sort: { relevance: "DESC" },
      },
      fetchPolicy: "cache-first",
      client: apolloClientMutation,
    });

    if (error) return "ERROR";
    return (
      <SearchResults
        data={!loading ? data : { products: { items: [] } }}
        search={search}
        allCategories={allCategories}
        closeSearch={closeSearch}
        loading={loading}
        mobileonclick={mobileonclick}
        changeSearch={changeSearch}
        setDisplaySearch={setDisplaySearch}
      />
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }
    return false;
  }
);

export default SearchResultsQueryContainer;
