import {
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
} from "mdbreact";
import {
  downloadImages,
  generateRequestForAddingOrRemovingFileForDownload,
} from "../../../functions";
import useFilesForDownload from "../../../hooks/useFilesForDownload";
import React, { useState } from "react";
import useCustomer from "core/state/hooks/customerHooks/useCustomer";

const Modal = ({ modal, toggleModal, action, data, refetch }) => {
  const { addFilesForDownload } = useFilesForDownload();
  const [, isLoggedIn] = useCustomer();
  const [loading, setLoading] = useState(false);
  if (action === false) return <></>;

  let allCopies = getOptionsByActionType(
    toggleModal,
    data,
    isLoggedIn(),
    setLoading,
    refetch,
    addFilesForDownload
  );
  return (
    <MDBModal
      isOpen={modal}
      toggle={toggleModal}
      contentClassName={`rounded`}
      wrapClassName={`d-flex align-items-center justify-content-center text-center`}
    >
      <MDBModalHeader className={`py-4 text-center`}>
        {allCopies[action].heading}
      </MDBModalHeader>
      <MDBModalBody className={`p-5`}>
        <MDBRow className={`flex-column px-5`}>
          <MDBCol className={`pb-4`}>{allCopies[action].description}</MDBCol>
          <MDBCol className={`pb-4`}>
            <MDBBtn
              onClick={(e) => {
                allCopies[action].buttonOnClick(action);
              }}
              disabled={loading}
              className={`w-100`}
            >
              {allCopies[action].buttonCopy}
            </MDBBtn>
          </MDBCol>
          <MDBCol className={`pb-4`}>
            <MDBBtn outline className={`w-100`} disabled={loading}>
              Cancel
            </MDBBtn>
          </MDBCol>
          <MDBCol>{allCopies[action].footerCopy}</MDBCol>
        </MDBRow>
      </MDBModalBody>
    </MDBModal>
  );
};

const getOptionsByActionType = (
  toggleModal,
  data,
  customerToken = false,
  setLoading = false,
  refetch = () => {},
  addFilesForDownload
) => {
  const onClickAction = (action) => {
    let request = generateRequestForAddingOrRemovingFileForDownload(
      data,
      "YES"
    );

    if (action === "REMOVE") {
      setLoading(true);
      addFilesForDownload(request).then(() => {
        refetch().then((res) => {
          setLoading(false);
          toggleModal();
        });
      });
    } else {
      setLoading(true);
      downloadImages(data, customerToken, true, setLoading, () => {
        addFilesForDownload(request).then(() => {
          refetch().then((res) => {
            setLoading(false);
            toggleModal();
          });
        });
      });
    }
  };
  return {
    REMOVE: {
      heading: "Remove All",
      description:
        "Are you sure that you want to remove all images from your downloads? This will delete all saved images.",
      buttonCopy: "Remove All",
      buttonOnClick: onClickAction,
      footerCopy: "",
    },
    DOWNLOAD_ALL: {
      heading: "Download Options",
      description:
        "Choose DOWNLOAD MAIN IMAGES to download the primary image for all products selected.",
      buttonCopy: "Download Main Images",
      buttonOnClick: onClickAction,
      footerCopy:
        "Note: Download may be large and take a few minutes depending on the selection of images.",
    },
  };
};

export default Modal;
