import { useState, useEffect, memo } from "react";
import { CSSTransition } from "react-transition-group";
import useGenerated from "app/state/hooks/generatedHooks";
import Icon from "app/assets/icon/icon";

const PromoBanner = memo((props) => {
  const [open, setOpen] = useState(true);
  const { GetCmsBlocks } = useGenerated();
  const promoBanner = GetCmsBlocks("promo-banner-header");
  useEffect(() => {
    document.body.classList.add("promo-shown");
  }, []);
  const handleClose = (e) => {
    setOpen(false);
    document.body.classList.remove("promo-shown");
  };
  return (
    <CSSTransition
      in={open}
      timeout={300}
      classNames={`promotional-item`}
      appear
      unmountOnExit
    >
      <div className={"promo-banner"}>
        {promoBanner}
        <div className={"promo-banner-close"} onClick={handleClose}>
          <Icon icon={"close_icon"} />
        </div>
      </div>
    </CSSTransition>
  );
});

export default PromoBanner;
