import Hotspot from "./render";
import { isEmpty } from "core/helpers/functions";
import Skeleton from "react-loading-skeleton";
import { PRODUCTS, PRODUCTS_WITH_PRICES } from "./queries";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import useProducts from "app/state/hooks/productsHooks/useProducts";

const HotspotQueryLoader = (props) => {
  const { GetProducts } = useProducts();
  let { dots, skuArray } = props;
  const { isLoggedIn } = useCustomer();
  const PRODUCTS_QUERY = isLoggedIn() ? PRODUCTS_WITH_PRICES : PRODUCTS;
  const { data, loading, error } = GetProducts(PRODUCTS_QUERY, {
    filter: {
      sku: { in: skuArray },
    },
  });

  let counter = 0;
  if (loading)
    return (
      <Skeleton height={props.skeletonHeight ? props.skeletonHeight : "auto"} />
    );
  if (error) return <></>;
  if (!isEmpty(skuArray)) {
    if (data !== undefined) {
      data.products.items.map((info) => {
        dots[counter].product = info;
        dots[counter].image = info.small_image.url;
        dots[counter].name = info.name;
        dots[counter].content = info.name;
        dots[counter].url = info.url_key;
        dots[counter].overall_size = info.overall_size;
        if (isLoggedIn()) {
          dots[counter].price =
            info.price_range.minimum_price.final_price.value;
          dots[counter].priceCurrency =
            info.price_range.minimum_price.final_price.currency;
        }
        counter += 1;
        return info;
      });
    }
  }
  return <Hotspot dots={dots} {...props} />;
};

export default HotspotQueryLoader;
