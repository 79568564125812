import { useEffect, useState } from "react";
import { MDBContainer } from "mdbreact";
import LoginModal from "./modals/loginModal";
import AddedMiddleModal from "./modals/addedMiddleModal";
import AddedToWishlistModal from "./modals/addedToWishlistModal";
import LoggedInModal from "./modals/loggedInModal";

const Wishlist = ({
  item,
  username,
  setUsername,
  password,
  setPassword,
  email,
  setEmail,
  touched,
  setTouched,
  error,
  setError,
  errors,
  loading,
  setLoading,
  validateInput,
  modalWishlist,
  addedToWishlistModal,
  addedToWishlistModalToggle,
  modalWishlistSecond,
  fetchArr,
  customer,
  children,
  handleLoginCheck,
  modalToggle,
  modalToggleSecond,
  addedPopUp,
  setAddedPopUp,
  collectionProducts = [],
}) => {
  let loggedIn = false;
  if (typeof customer !== "undefined" && customer) {
    loggedIn = true;
  }
  const [timeId, setTimeId] = useState(0);
  const [autoClose, setAutoClose] = useState(true);
  let time;

  useEffect(() => {
    if (autoClose && addedPopUp) {
      time = setTimeout(() => {
        setAddedPopUp(false);
      }, 4000);
      setTimeId(time);
    } else {
      const clearId = clearInterval(time);
      setTimeId(clearId);
    }
    return () => {
      clearInterval(time);
    };
  }, [autoClose, addedPopUp]);
  return (
    <>
      {children}
      {modalWishlist && (
        <LoginModal
          username={username}
          password={password}
          email={email}
          setUsername={setUsername}
          setPassword={setPassword}
          setEmail={setEmail}
          modalWishlist={modalWishlist}
          modalToggle={modalToggle}
          product={item}
          loggedIn={loggedIn}
          error={error}
          errors={errors}
          touched={touched}
          setTouched={setTouched}
          handleLoginCheck={handleLoginCheck}
          validateInput={validateInput}
          loading={loading}
          setError={setError}
          item={item}
          collectionProducts={collectionProducts}
        />
      )}
      {addedPopUp && loggedIn && (
        <AddedMiddleModal
          modalToggleSecond={modalToggleSecond}
          addedPopUp={addedPopUp}
          setAddedPopUp={setAddedPopUp}
          onMouseEnter={() => setAutoClose(false)}
          onMouseLeave={() => setAutoClose(true)}
        />
      )}
      {modalWishlistSecond && loggedIn && (
        <MDBContainer id={`plp-wishlist-modal`} className={`second-modal`}>
          <LoggedInModal
            modalWishlistSecond={modalWishlistSecond}
            modalToggleSecond={modalToggleSecond}
            setLoading={setLoading}
            loading={loading}
            fetchArr={fetchArr}
            product={item}
            loggedIn={loggedIn}
            collectionProducts={collectionProducts}
          />
        </MDBContainer>
      )}
      {addedToWishlistModal && !modalWishlistSecond && loggedIn && (
        <AddedToWishlistModal
          addedToWishlistModalToggle={addedToWishlistModalToggle}
          modalToggleSecond={modalToggleSecond}
          addedToWishlistModal={addedToWishlistModal}
        />
      )}
    </>
  );
};

export default Wishlist;
