import { memo } from "react";
import { MDBBox } from "mdbreact";
import { withRouter } from "react-router-dom";
import { PageParser } from "app/layout/cmsBlock/Parser";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import SeoHelmet from "app/layout/seoHelmet";
import { transform } from "./functions";
import { isEqual } from "lodash";

const CmsPage = memo(
  (props) => {
    let { id, data, isCached, url, addQueryData, history } = props;

    let cmsPage =
      typeof props.data !== "undefined" &&
      typeof props.data.cmsPage !== "undefined"
        ? props.data.cmsPage
        : props.data;
    const { isLoggedIn } = useCustomer();
    let token = isLoggedIn();

    if (props.isCached) cmsPage = props.isCached.cmsPage;

    if (typeof cmsPage === "undefined") {
      return "undefined";
    }

    if (!props.dangHtml) {
      return (
        <div
          itemScope
          itemType="http://schema.org/WebPageElement"
          itemProp={`mainContentOfPage`}
        >
          <meta itemProp={`cssSelector`} content={`.title`} />
          {data.title && <SeoHelmet title={data.title} url={data.url_key} />}
          <MDBBox
            className={`cms-page page-${cmsPage.url_key} page-main-content px-0 container-fluid animated fadeIn`}
          >
            <MDBBox id={"cmspage-" + props.id}>
              {/* {typeof props.breadcrumbs === "undefined" ? <Breadcrumbs /> : <></>} */}
              <div itemProp={`text`}>
                {props.title && (
                  <MDBBox tag="h3">
                    <span className={`title`} itemProp={`headline`}>
                      {cmsPage.title}
                    </span>
                  </MDBBox>
                )}
                <PageParser
                  {...props}
                  data={{ ...cmsPage }}
                  className={`container-fluid`}
                  transform={(node, index) =>
                    transform(node, index, token, history)
                  }
                />
              </div>
            </MDBBox>
          </MDBBox>
        </div>
      );
    }
    return (
      <div
        itemScope
        itemType="http://schema.org/WebPageElement"
        itemProp={`mainContentOfPage`}
      >
        <div itemProp={`text`}>
          <MDBBox dangerouslySetInnerHTML={{ __html: cmsPage.content }} />
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default withRouter(CmsPage);
