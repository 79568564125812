import { useEffect } from "react";

export const toggleCollapse = (props) => {
  return props.setCollapseID(!props.collapseID);
};

export const toggleHamburger = (props) => {
  return props.setOpen(!props.open);
};

export const useOutsideAlerter = (ref, displaySearch, setDisplaySearch) => {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (
      ref.current &&
      !ref.current?.contains(event.target) &&
      event.target.tagName !== "svg" &&
      event.target.tagName !== "path"
    ) {
      if (displaySearch) {
        setDisplaySearch(!displaySearch);
      }
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};
