import Homepage from "app/pages/home";
import RegisterSuccess from "app/pages/customer/register/thankYouPage";
import ContactPage from "app/pages/static/contact";
import FindRep from "app/pages/static/find-a-rep";
import ForgotPassword from "app/pages/forgotPassword";
import Customer from "app/pages/customer/account";
import TermsOfUse from "app/pages/static/terms-of-use";
import PrivacyPolicy from "app/pages/static/privacy-policy";
import CartSubmit from "app/pages/cart/cartSubmit";
import { SearchResults } from "app/pages/catalog";
import { lazy } from "react";
import OfflinePage from "app/layout/OfflinePage";
import GalleryDetails from "app/pages/static/galleryDetail";
import AccountCreationThankYouPage from "app/pages/customer/quickRegister/thank-you";
import ComingSoon from "app/pages/static/comingSoon";

const Register = lazy(() =>
  import("app/pages/customer/register" /*webpackChunkName: "register"*/)
);

const LifestyleGallery = lazy(() =>
  import(
    "app/pages/static/lifestyleGallery" /*webpackChunkName: "lifestyle-gallery"*/
  )
);

const Catalogs = lazy(() =>
  import("app/pages/static/catalogs" /*webpackChunkName: "catalogs"*/)
);

const CatalogDetail = lazy(() =>
  import(
    "app/pages/static/catalogDetail" /*webpackChunkName: "catalog-detail"*/
  )
);

const Faq = lazy(() =>
  import("app/pages/static/faq" /*webpackChunkName: "faq-page"*/)
);

const Hospitality = lazy(() =>
  import("app/pages/static/hospitality" /*webpackChunkName: "hospitality"*/)
);

const Forms = lazy(() =>
  import("app/pages/forms" /*webpackChunkName: "forms"*/)
);

const Cart = lazy(() =>
  import("app/pages/cart" /*webpackChunkName: "checkout" */)
);

const Typography = lazy(() =>
  import("app/pages/typography" /*webpackChunkName: "typography" */)
);

const AboutUs = lazy(() =>
  import("app/pages/static/about-us" /*webpackChunkName: "about-us" */)
);

const Resources = lazy(() =>
  import("app/pages/static/resources" /*webpackChunkName: "about-us" */)
);

const WhereToBuy = lazy(() =>
  import("app/pages/static/whereToBuy" /*webpackChunkName: "where-to-buy" */)
);

const QuickRegister = lazy(() =>
  import(
    "app/pages/customer/quickRegister" /*webpackChunkName: "quick-register" */
  )
);

const globalPages = {
  home: { component: Homepage, exact: false },
  register: {
    component: Register,
    exact: false,
  },
  "register-success": { component: RegisterSuccess, exact: true },
  "account-creation-success": {
    component: AccountCreationThankYouPage,
    exact: true,
  },
  typography: { component: Typography, exact: false },
  customer: { component: Customer, exact: false },
  "forgot-password": { component: ForgotPassword, exact: false },
  forms: { component: Forms, exact: false },

  //static
  contact: { component: ContactPage, exact: false },
  "find-a-rep": {
    component: FindRep,
    exact: false,
  },
  "quick-register": { component: QuickRegister, exact: true },
  "faq-page": { component: Faq, exact: false },
  cart: { component: Cart, exact: true },
  "search-results": { component: SearchResults, exact: false },
  "quote-thank-you": { component: CartSubmit, exact: true },
  "terms-of-use": { component: TermsOfUse, exact: true },
  "privacy-policy": { component: PrivacyPolicy, exact: true },
  "lifestyle-gallery": { component: LifestyleGallery, exact: true },
  "lifestyle-gallery-detail": { component: GalleryDetails, exact: true },
  catalogs: { component: Catalogs, exact: true },
  "catalog-detail": { component: CatalogDetail, exact: false },
  "about-us": { component: AboutUs, exact: false },
  resources: { component: Resources, exact: false },
  hospitality: { component: Hospitality, exact: true },
  "where-to-buy": { component: WhereToBuy, exact: true },
  "offline-page": { component: OfflinePage, exact: true },
  "coming-soon": { component: ComingSoon, exact: true },
};

export default globalPages;

export { default as ErrorPage } from "app/pages/error";

export { default as TemporaryPage } from "app/pages/temporary";

export const DefaultPage = Homepage;
