const deleteCustomerAddressReducer = (state, action) => {
  let { payload } = action;
  let customerState = state;
  let newAddresses = customerState.data.addresses.filter((address) => {
    return address.id !== payload;
  });

  customerState.data.addresses = newAddresses;
  return Object.assign({}, state, {
    ...customerState,
  });
};

export default deleteCustomerAddressReducer;
