import { MDBCol, MDBTypography } from "mdbreact";
import { memo } from "react";

const DiscoverTitle = memo(({}) => {
  return (
    <MDBCol>
      <MDBTypography tag="h2" className={`text-center`}>
        Discover Styles
      </MDBTypography>
    </MDBCol>
  );
});

export default DiscoverTitle;
