import { loader } from "graphql.macro";

// perhaps this needs to be deprecated?

const mapper = (query) => {
  if (query === "customerOrders") {
    return loader("../graphql/queries/sales/customerOrders.graphql");
  }

  if (query === "category") {
    return loader("../graphql/queries/category.graphql");
  }

  if (query === "categoryProducts") {
    return loader("../graphql/queries/categoryProducts.graphql");
  }

  if (query === "products") {
    return loader("../graphql/queries/products.graphql");
  }

  if (query === "compareList") {
    return loader("../graphql/queries/compareList.graphql");
  }

  if (query === "product") {
    return loader("../graphql/queries/product.graphql");
  }

  if (query === "getGalleryCategory") {
    return loader("../graphql/queries/getGalleryCategory.graphql");
  }

  if (query === "categoryTree") {
    return loader("../graphql/queries/categoryTree.graphql");
  }

  if (query === "cmsPage") {
    return loader("../graphql/queries/cmsPage.graphql");
  }

  if (query === "getProductInfoBySku") {
    return loader("../graphql/queries/getProductInfoBySku.graphql");
  }

  if (query === "addToCompare") {
    return loader("./mutations/compare/addToCompare.graphql");
  }

  if (query === "removeCompare") {
    return loader("./mutations/compare/removeCompare.graphql");
  }

  if (query === "createCompareList") {
    return loader("./mutations/compare/createCompareList.graphql");
  }

  if (query === "clearCompare") {
    return loader("./mutations/compare/deleteCompareList.graphql");
  }

  if (query === "Hotspots") {
    return loader("../graphql/queries/hotspots.graphql");
  }

  if (query === "cmsBlock") {
    return loader("../graphql/queries/cmsBlock.graphql");
  }

  // if (query === "urlResolver") {
  //   return loader("../graphql/queries/urlResolver.graphql");
  // }

  if (query === "userLogin") {
    return loader(
      "../graphql/mutations/customer/session/loginMutation.graphql"
    );
  }

  if (query === "userLogout") {
    return loader(
      "../graphql/mutations/customer/session/logoutMutation.graphql"
    );
  }

  if (query === "getCustomer") {
    return loader("../graphql/queries/customer/customer.graphql");
  }

  if (query === "validateCustomerToken") {
    return loader("../graphql/queries/customer/validateCustomerToken.graphql");
  }

  if (query === "checkCustomerAuthorization") {
    return loader("../graphql/queries/customerCheckToken.graphql");
  }

  if (query === "registerCustomer") {
    return loader(
      "../graphql/mutations/customer/createCustomerMutation.graphql"
    );
  }

  if (query === "createCustomerAddress") {
    return loader(
      "../graphql/mutations/customer/address/createCustomerAddressMutation.graphql"
    );
  }

  if (query === "updateCustomerInformation") {
    return loader(
      "../graphql/mutations/customer/information/updateCustomerInformation.graphql"
    );
  }

  if (query === "newsletterCustomerSubscribe") {
    return loader(
      "../graphql/mutations/customer/subscribe/newsletterSubscribe.graphql"
    );
  }
  if (query === "editCustomerAddress") {
    return loader(
      "../graphql/mutations/customer/address/editCustomerAddress.graphql"
    );
  }

  if (query === "changeCustomerPassword") {
    return loader(
      "../graphql/mutations/customer/information/changeCustomerPassword.graphql"
    );
  }

  if (query === "generateCache") {
    return loader("../graphql/mutations/generateCache.graphql");
  }

  if (query === "generateResetPasswordToken") {
    return loader(
      "../graphql/mutations/customer/generateResetPasswordToken.graphql"
    );
  }

  // if (query === "exportListQuery") {
  //   return loader("../graphql/queries/exportListQuery.graphql");
  // }

  if (query === "resetCustomerPassword") {
    return loader(
      "../graphql/mutations/customer/information/resetPassword.graphql"
    );
  }

  if (query === "sendEmail") {
    return loader("../graphql/mutations/sendEmail.graphql");
  }

  if (query === "createEmptyCart") {
    return loader(
      "../graphql/mutations/customer/cart/customerCreateEmptyCart.graphql"
    );
  }

  if (query === "cartData") {
    return loader("../graphql/queries/customer/cart/cart.graphql");
  }

  if (query === "customerCart") {
    return loader("../graphql/queries/customer/cart/customerCart.graphql");
  }

  if (query === "addProduct") {
    return loader(
      "../graphql/mutations/customer/cart/addProductToCart.graphql"
    );
  }
  if (query === "addConfigurableProductsToCart") {
    return loader(
      "../graphql/mutations/customer/cart/addConfigurableProductsToCart.graphql"
    );
  }

  if (query === "removeProduct") {
    return loader(
      "../graphql/mutations/customer/cart/removeItemsFromCart.graphql"
    );
  }

  if (query === "updateCart") {
    return loader("../graphql/mutations/customer/cart/updateCartItems.graphql");
  }

  if (query === "mergeCarts") {
    return loader("../graphql/mutations/customer/cart/mergeCarts.graphql");
  }

  if (query === "placeOrder") {
    return loader("../graphql/mutations/customer/cart/placeOrder.graphql");
  }

  if (query === "setPaymentMethod") {
    return loader(
      "../graphql/mutations/customer/cart/setPaymentMethodOnCart.graphql"
    );
  }

  if (query === "getWishlists") {
    return loader("../graphql/queries/wishlists/getWishlists.graphql");
  }

  if (query === "setCheckAdditional") {
    return loader(
      "../graphql/mutations/customer/cart/setCheckAdditional.graphql"
    );
  }

  // WISHLIST
  if (query === "getWishlists") {
    return loader("../graphql/queries/wishlists/getWishlists.graphql");
  }
  if (query === "addAllToCart") {
    return loader("../graphql/mutations/wishlists/addAllToCart.graphql");
  }
  if (query === "addItemsToWishlist") {
    return loader("../graphql/mutations/wishlists/addItemsToWishlist.graphql");
  }
  if (query === "addItemToCart") {
    return loader("../graphql/mutations/wishlists/addItemToCart.graphql");
  }
  if (query === "addNewWishlist") {
    return loader("../graphql/mutations/wishlists/addNewWishlist.graphql");
  }
  if (query === "copyItemToWishlist") {
    return loader("../graphql/mutations/wishlists/copyItemToWishlist.graphql");
  }
  if (query === "deleteItemsFromWishlist") {
    return loader(
      "../graphql/mutations/wishlists/deleteItemsFromWishlist.graphql"
    );
  }
  if (query === "deleteItemsFromWishlistQty") {
    return loader(
      "../graphql/mutations/wishlists/deleteItemsFromWishlistQty.graphql"
    );
  }
  if (query === "deleteWishlist") {
    return loader("../graphql/mutations/wishlists/deleteWishlist.graphql");
  }
  if (query === "editWishlist") {
    return loader("../graphql/mutations/wishlists/editWishlist.graphql");
  }
  if (query === "moveItemToWishlist") {
    return loader("../graphql/mutations/wishlists/moveItemToWishlist.graphql");
  }
  if (query === "updateWishlistItem") {
    return loader("../graphql/mutations/wishlists/updateWishlistItem.graphql");
  }
  if (query === "moveItemToWishlist") {
    return loader("../graphql/mutations/wishlists/moveItemToWishlist.graphql");
  }

  if (query === "checkEmail") {
    return loader("../graphql/mutations/wishlists/checkEmail.graphql");
  }

  if (query === "sendWishlistEmail") {
    return loader("../graphql/mutations/wishlists/sendWishlistEmail.graphql");
  }

  if (query === "createTokenBaseCard") {
    return loader(
      "../graphql/mutations/customer/cart/createTokenBaseCard.graphql"
    );
  }

  throw new Error(
    "Unknown query, you probably forgot to define it in state/graphql/queryLoader.js."
  );
};

export default mapper;
