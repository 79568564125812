import { memo, useCallback, useState } from "react";
import Mobile from "./mobile";
const MobileStateContainer = memo((props) => {
  let { menu } = props;

  const [open, setOpen] = useState(false);
  const [collapseID, setCollapseID] = useState([]);
  const [menuToggle, setMenuToggle] = useState(0);
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const [categoryIndex, setCategoryIndex] = useState(undefined);

  const handleCollapseID = useCallback((value) => {
    setCollapseID(value);
  }, []);
  const handleCategoryIndex = useCallback((value) => {
    setCategoryIndex(value);
  }, []);
  const handleShowMobileDropdown = useCallback((value) => {
    setShowMobileDropdown(value);
  }, []);
  const handleMenuToggle = useCallback((value) => {
    setMenuToggle(value);
  }, []);

  const handleOpen = useCallback((value) => {
    setOpen(value);
  }, []);

  const openMobileDropdown = useCallback(
    (index) => {
      if (categoryIndex === index) {
        if (showMobileDropdown === true) {
          handleShowMobileDropdown(false);
        } else {
          handleShowMobileDropdown(true);
        }
      } else {
        handleCategoryIndex(index);
        handleShowMobileDropdown(true);
      }
    },
    [
      categoryIndex,
      showMobileDropdown,
      handleCategoryIndex,
      handleShowMobileDropdown,
    ]
  );

  return (
    <Mobile
      {...props}
      open={open}
      setOpen={handleOpen}
      menuItems={menu}
      categoryIndex={categoryIndex}
      setCategoryIndex={handleCategoryIndex}
      setCollapseID={handleCollapseID}
      collapseID={collapseID}
      openMobileDropdown={openMobileDropdown}
      menuToggle={menuToggle}
      setMenuToggle={handleMenuToggle}
    />
  );
});

export default MobileStateContainer;
