import { MDBContainer } from "mdbreact";
import ProjectForm from "./form";
import useStore from "app/state/hooks/storeHooks/useStore";
import { useMediaQuery } from "react-responsive";

const ProjectFormContainer = ({ data }) => {
  const { getStoreCode } = useStore();
  const store = getStoreCode();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <MDBContainer
      fluid
      className={`project-form text-center mt-5 px-0 ${store + "-website"}`}
    >
      <ProjectForm />
    </MDBContainer>
  );
};

export default ProjectFormContainer;
