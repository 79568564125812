import { commonActions } from "app/state/redux/data/common";
import { customerActions } from "app/state/redux/data/customer";
import { isEqual } from "lodash";
import { useState } from "react";
import { useQuery } from "react-apollo-hooks";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const useProducts = () => {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const GetProducts = (query, filter, isSearch = false) => {
    const [previousData, setPreviousData] = useState(undefined);
    const { loading, error, errors, data, refetch } = useQuery(query, {
      variables: filter,
      fetchPolicy: "cache-first",
      // skip: Object.keys(filter.filter)[0] === undefined,
      errorPolicy: "ignore",
    });

    if (data !== undefined && !isEqual(data, previousData)) {
      setPreviousData({ ...data });
    }
    if (data === undefined && previousData !== undefined)
      return { loading, error, errors, data: previousData, refetch };

    return { loading, error, errors, data, refetch };
  };

  const getProductLinkComponent = (
    item,
    options = { Component: Link },
    config_options = false
  ) => {
    let finalUrl =
      item !== undefined &&
      item.url_rewrites !== undefined &&
      item.url_rewrites[0] !== undefined &&
      item.url_rewrites[0].url;
    const filterUrl = item?.url_rewrites?.filter((x) =>
      options?.categoryId &&
      x.parameters[1] !== undefined &&
      parseInt(x.parameters[1].value) === options?.categoryId
        ? x
        : false
    );
    if (filterUrl !== undefined && filterUrl[0] !== undefined) {
      finalUrl = filterUrl[0]?.url;
    }

    if (options?.onlyLink)
      return {
        pathname: `/${finalUrl}`,
        search: !config_options ? "" : `?${config_options.toLowerCase()}`,
        state: { item: { ...item }, type: "PRODUCT" },
      };

    let Component = options.Component !== undefined ? options.Component : Link;

    return (
      <Component
        className={`${options.className}`}
        to={{
          pathname: `/${finalUrl}`,
          search: !config_options ? "" : `?${config_options.toLowerCase()}`,
          state: { item: { ...item }, type: "PRODUCT" },
        }}
      >
        {options.children}
      </Component>
    );
  };

  const getSwatchesAndSizes = (
    data,
    swatchImagePath,
    selectedSwatch = null,
    selectedSize = null
  ) => {
    let sizesArray = [];
    let swatchesArray = [];

    let colorCombination = [];
    data?.variants?.map(({ attributes }) => {
      if (attributes?.[0]?.label === selectedSwatch?.label) {
        colorCombination.push(attributes?.[1]?.label);
      }
      let sizeAttribute = attributes.find(
        (attribute) => attribute.code !== "color"
      );

      if (sizeAttribute) {
        if (!selectedSwatch && !selectedSize) {
          if (!sizesArray.includes(sizeAttribute?.label)) {
            sizesArray.push(sizeAttribute?.label);
          }
        } else {
          sizesArray = colorCombination;
        }
      }

      attributes.map((attribute) => {
        data.configurable_options.map((config) => {
          config?.values.map((configValue) => {
            if (
              attribute?.code === config?.attribute_code &&
              attribute?.value_index === configValue?.value_index &&
              !swatchImagePath.includes("undefined")
            ) {
              if (
                !configValue?.swatch_data?.value ||
                swatchesArray.filter(
                  (swatch) =>
                    swatch.image ===
                    `${swatchImagePath}/${configValue?.swatch_data?.value}`
                ).length > 0
              )
                return;

              swatchesArray.push({
                image: `${swatchImagePath}/${configValue?.swatch_data?.value}`,
                label: configValue?.label,
              });
            }
          });
        });
      });
    });

    return { sizesArray, swatchesArray };
  };

  // NEED TO SET GET AND ADD FOR RECENTLY
  const addRecentlyViewedProduct = async (product) => {
    if (product.sku === undefined) return;
    try {
      let recentlyViewedList = [
        ...(customer !== undefined && customer.recently_viewed !== undefined
          ? customer.recently_viewed
          : []),
      ];
      let exists = false;

      //stores maximum 20 items in recently viewed array
      if (recentlyViewedList.length > 20) {
        recentlyViewedList.pop();
      }
      recentlyViewedList.map((item) => {
        if (item.sku === product.sku) {
          exists = true;
          item.timestamp = Date.now();
        }
        return item;
      });
      //if item already exists, update timestamp
      if (!exists) {
        if (typeof product.sku !== undefined)
          recentlyViewedList.push({
            ...product,
            timestamp: Date.now(),
          });
      }
      recentlyViewedList.sort(
        (item1, item2) => item2.timestamp - item1.timestamp
      );

      dispatch(customerActions._reduceRecentlyViewed(recentlyViewedList));
      dispatch(commonActions.unlock("success"));
    } catch (error) {
      console.log(error);
      dispatch(commonActions.unlock("fail"));
    }
  };

  const getRecentlyViewedProduct = (sku = undefined) => {
    // if sku is passed, we try to find it in recently_viewed data
    if (
      sku !== undefined &&
      customer !== undefined &&
      customer.recently_viewed !== undefined
    ) {
      let product = customer.recently_viewed.find((x) => x.sku === sku);
      return product !== null ? product : false;
    }

    return customer !== undefined && customer.recently_viewed !== undefined
      ? customer.recently_viewed
      : false;
  };

  const getProductLink = (activeItem, pathname, productLink) => {
    let category =
      typeof activeItem.categories !== "undefined" &&
      typeof activeItem.categories[0] !== "undefined"
        ? activeItem.categories[0]
        : undefined;

    if (activeItem.url_rewrites) {
      let newPathname = activeItem.url_rewrites.filter((rewrite) =>
        rewrite.url.includes(pathname.substr(1) + "/" + activeItem.url_key)
      );
      if (newPathname.length > 0) {
        productLink = newPathname[0].url;
        if (productLink.charAt(0) !== "/") {
          productLink = "/" + productLink;
        }
      } else {
        if (category) {
          if (pathname.includes(category.url_key)) {
            productLink = "/" + category.url_key + "/" + activeItem.url_key;
          } else {
            productLink = "/" + activeItem.url_key;
          }
        } else {
          productLink = "/" + activeItem.url_key;
        }
      }
    }

    return productLink;
  };

  return {
    GetProducts,
    getProductLinkComponent,
    addRecentlyViewedProduct,
    getRecentlyViewedProduct,
    getProductLink,
    getSwatchesAndSizes,
  };
};

export default useProducts;
