import useValidationPatterns from "app/state/hooks/validationPatternHooks/useValidationPatterns";
import { useState } from "react";
import countries from "config/data/countries";
import { getCountries, getRegionData } from "config/data/dataMapping";
import Form from "./form";
import { useQuery } from "react-apollo-hooks";
import { COUNTRIES_V2 } from "./queries";

const Toolbar = (props) => {
  let { industries, setFilterData } = props;

  const countriesQuery = COUNTRIES_V2;
  const { data, loading, error } = useQuery(countriesQuery, {
    fetchPolicy: "cache-first",
  });

  const { zipCodeMask, findMaskByCountry } = useValidationPatterns();

  const generateSanitizedMaskArray = (object) => {
    return Object.keys(object).map((key) => {
      let regexChar = object[key];
      if (object[key] === "+") regexChar = "+";
      if (object[key] === "#") regexChar = /\d/;
      return regexChar;
    });
  };

  const [, setMaskSanitizedArray] = useState(
    generateSanitizedMaskArray(findMaskByCountry("US").mask.split(""))
  );
  const [, setMask] = useState(findMaskByCountry("US"));
  const updateRegion = (value, type) => {
    let data = getRegionData(countriesData, value);
    setRegionsData(data);
  };
  const [regionsData, setRegionsData] = useState([]);

  const setSelectCountryFn = (event, type) => {
    let value = event;
    let mask = [];
    mask = findMaskByCountry(value.value);
    setMask(mask);

    updateRegion(value, type);
    let maskSanitized =
      typeof mask.mask !== "undefined" ? mask.mask.split("") : "";
    setMaskSanitizedArray(generateSanitizedMaskArray(maskSanitized));
  };
  const _countries = countries.countries;
  let countryDataObject = getCountries(_countries);
  if (loading)
    return (
      <Form
        key={`loading-toolbar`}
        countriesData={[]}
        regionsData={[]}
        industries={industries}
        selectedCountry={null}
        setSelectCountryFn={setSelectCountryFn}
        zipCodeMask={zipCodeMask}
        setFilterData={setFilterData}
        loading={true}
      />
    );
  if (error)
    return <>Something went wrong. Please check your internet connection.</>;
  if (data === undefined) return <></>;

  let countriesData;
  countriesData = Object.keys(countryDataObject).map((key) => {
    return countryDataObject[key];
  });

  countriesData = countriesData.filter((item) => {
    return item.value === "US" || item.value === "CA";
  });

  let newCountriesData = [...countriesData];
  let countriesV2WithoutUSandCa = data.countriesV2.filter(
    (x) => x.value !== "US" && x.value !== "CA"
  );

  let finalCountriesData = [...newCountriesData, ...countriesV2WithoutUSandCa];
  let selectedCountry = null;

  return (
    <Form
      key={`loaded-toolbar`}
      countriesData={finalCountriesData}
      regionsData={regionsData}
      industries={industries}
      selectedCountry={selectedCountry}
      setSelectCountryFn={setSelectCountryFn}
      zipCodeMask={zipCodeMask}
      setFilterData={setFilterData}
    />
  );
};

export default Toolbar;
