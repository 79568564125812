import { gql } from "graphql.macro";

export const PRODUCTS = gql`
  query Products(
    $filter: ProductAttributeFilterInput! # $sort: ProductAttributeSortInput # $pageSize: Int # $currentPage: Int
  ) {
    products(
      filter: $filter # sort: $sort # pageSize: $pageSize
    ) # currentPage: $currentPage
    {
      total_count
      items {
        id
        name
        sku
        url_key
        url_rewrites {
          parameters {
            name
            value
          }
          url
        }
        categories {
          id
        }
        small_image {
          label
          url
        }
      }
    }
  }
`;

export const PRODUCTS_WITH_PRICES = gql`
  query Products(
    $filter: ProductAttributeFilterInput! # $sort: ProductAttributeSortInput # $pageSize: Int # $currentPage: Int
  ) {
    products(
      filter: $filter # sort: $sort # pageSize: $pageSize
    ) # currentPage: $currentPage
    {
      total_count
      items {
        id
        name
        sku
        url_key
        url_rewrites {
          parameters {
            name
            value
          }
          url
        }
        categories {
          id
        }
        small_image {
          label
          url
        }
        price_range {
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            fixed_product_taxes {
              amount {
                currency
                value
              }
              label
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            fixed_product_taxes {
              amount {
                currency
                value
              }
              label
            }
            regular_price {
              currency
              value
            }
          }
        }
        price_tiers {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          quantity
        }
      }
    }
  }
`;
