import { useState, Suspense, useRef, useCallback } from "react";
import {
  MDBCol,
  MDBBox,
  MDBHamburgerToggler,
  MDBLink,
  MDBNavbarBrand,
} from "mdbreact";
import Logo from "app/layout/logo";
import Icon from "app/assets/icon/icon";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import { useCart } from "app/state/hooks/cartHooks/useCart";
import useUtility from "app/state/hooks/useUtility";
import CustomerAccountActions from "../mobileMenu/customerActions/customerAccountActions";

const MobileHeaderTop = ({
  props,
  toggleHamburger,
  setMenuToggle,
  closeBurger,
  displaySearch,
  setDisplaySearch,
  store,
  open,
}) => {
  let { isLoggedIn, getCustomer } = useCustomer();
  const customer = getCustomer();
  let { getCartNumberOfItems } = useCart();
  let token = isLoggedIn();
  const { lazyWithPreload } = useUtility();
  const cartNumberOfItems = getCartNumberOfItems();
  const [LazySearch, setLazySearch] = useState(false);
  let [searchVal, setSearchVal] = useState("");

  let searchRef = useRef(null);

  const closeBurgerFn = useCallback(() => {
    setMenuToggle(0);
    closeBurger();
  }, [closeBurger, setMenuToggle]);
  const toggleBurgerFn = useCallback(() => {
    setDisplaySearch(false);
    toggleHamburger(props);
  }, [props, setDisplaySearch, toggleHamburger]);
  const toggleSearchFn = useCallback(() => {
    // props.resetSearch();
    setSearchVal("");
    setDisplaySearch(displaySearch === false ? true : false);
    const SearchComponent = lazyWithPreload(() =>
      import("../../search" /*webpackChunkName: "lazy-search" */)
    );
    setLazySearch(SearchComponent);
    closeBurgerFn();
  }, [closeBurgerFn, displaySearch, lazyWithPreload, setDisplaySearch]);
  return (
    <MDBBox
      display={`flex`}
      flex={`row`}
      justifyContent={`between`}
      id={`mobile-navigation-container`}
      className={`w-100 h-100 mobile-header-top ${open && "collapsed"}`}
      alignItems={`center`}
    >
      <MDBBox id={`logo-container mobile-left`}>
        <MDBCol className={`position-relative px-0`}>
          <MDBNavbarBrand
            href="/"
            onClick={(e) => {
              setDisplaySearch(false);
              closeBurgerFn();
            }}
          >
            <Logo open={open} />
          </MDBNavbarBrand>
        </MDBCol>
      </MDBBox>

      <MDBBox
        className={`mobile-middle align-items-center d-flex justify-content-end`}
      >
        {token && customer?.show_price !== false && (
          <MDBBox id={`mini-cart-icon`} flex={`column`} display={`flex`}>
            <MDBBox className="cart ml-auto text-right" alignSelf={`end`}>
              <MDBLink
                onClick={() => closeBurger()}
                to={"/cart"}
                className="p-0 text-decoration-none cart-icon"
              >
                <Icon icon="quoteIconWhite" />
                <label className="cart-count">{cartNumberOfItems}</label>
              </MDBLink>
            </MDBBox>
          </MDBBox>
        )}
        {!open && (
          <CustomerAccountActions closeBurger={closeBurger} token={token} />
        )}
        {!open && (
          <div ref={searchRef}>
            <MDBBox
              id={`search-icon`}
              flex={`column`}
              display={`flex`}
              onClick={toggleSearchFn}
            >
              <MDBBox className={`${store}`}>
                <Icon
                  icon={`searchIcon`}
                  className={`${displaySearch ? "icon-blue" : ""}`}
                />
              </MDBBox>
            </MDBBox>
          </div>
        )}
      </MDBBox>
      <MDBBox className={`hamburger-menu mobile-right ${open && "collapsed"}`}>
        <MDBHamburgerToggler id="hamburger1" onClick={toggleBurgerFn} />
      </MDBBox>

      {LazySearch ? (
        <Suspense fallback={<></>}>
          <LazySearch
            open={displaySearch}
            searchVal={searchVal}
            setSearchVal={setSearchVal}
            setDisplaySearch={setDisplaySearch}
            toggleSearch={toggleSearchFn}
          />
        </Suspense>
      ) : (
        <></>
      )}
    </MDBBox>
  );
};
export default MobileHeaderTop;
