import configData from "config/schemaConfiguration.json";

export const SearchSchema = (props) => {
  const { store = "gatco" } = props;
  const schemaData = configData[store];
  const searchData = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: schemaData.name,
    url: schemaData.url,
    potentialAction: {
      "@type": "SearchAction",
      target: `${schemaData}/search-results?search={search_term_string}`,
      "query-input": "required name=search_term_string",
    },
  };

  return JSON.stringify(searchData);
};
