const { MDBBox } = require("mdbreact");

/**
 * PriceRender
 * @param {*} value Price
 * @param {*} currency USD/CAD etc.
 * @param {Boolean} discount True/False (boolean, based on condition, you can always forward false to have price without stroke)
 * @param {*} symbol Currency symbol
 * @returns Formatted price
 */
const PriceRender = ({
  value,
  currency,
  discount,
  symbol,
  special = false,
}) => {
  return discount !== false ? (
    <MDBBox className={`${special ? "discount mr-2" : "normal"}`}>
      <span
        itemProp={`priceCurrency`}
        content={currency}
        className="price-symbol"
      >
        {symbol}
      </span>
      <span itemProp={`price`} className={`mr-2`}>
        {value}
      </span>
    </MDBBox>
  ) : (
    <MDBBox className={`${special ? "discount mr-2" : "normal"}`}>
      <del>
        <span
          itemProp={`priceCurrency`}
          content={currency}
          className="price-symbol"
        >
          {symbol}
        </span>
        <span itemProp={`price`} className={`mr-2`}>
          {value}
        </span>
      </del>
    </MDBBox>
  );
};

export default PriceRender;
