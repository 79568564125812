import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";

const ProductSkeleton = ({ token }) => {
  const isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <>
      {/* PRODUCT MEDIA GALLERY AND PRODUCT DETAILS */}
      <div
        className={`container page-full-width`}
        style={{
          marginTop: isMobile ? "5rem" : "10rem",
        }}
      >
        <div className={`row`}>
          <div
            className={`col-lg-8`}
            style={{ marginBottom: isMobile ? "5rem" : 0 }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
                gap: "2rem",
              }}
            >
              <Skeleton height={`51.5rem`} width={`100%`} />
              {!isMobile && <Skeleton height={`51.5rem`} width={`100%`} />}
            </div>
          </div>
          <div className={`col-lg-4`}>
            <Skeleton
              height={`6rem`}
              width={`100%`}
              count={token ? 4 : 3}
              className={`mb-4`}
            />
            <Skeleton height={`20rem`} width={`100%`} className={`mb-4`} />
            <Skeleton height={`7rem`} width={`100%`} className={`mb-4`} />
          </div>
        </div>
      </div>

      {/* MORE IN SERIES */}
      <div>
        <Skeleton
          height={`16rem`}
          width={`100%`}
          style={{ marginTop: isMobile ? "10rem" : "24rem" }}
        />
      </div>

      {/* PRODUCT FEATURES AND SPECIFICATION */}
      <div
        className={`container page-full-width`}
        style={{ marginTop: isMobile ? "10rem" : "20rem" }}
      >
        <Skeleton height={`6rem`} width={`100%`} />
        <div className={`row`} style={{ marginTop: "6rem" }}>
          <div
            className={`col-lg-6`}
            style={{ marginBottom: isMobile ? "5rem" : 0 }}
          >
            <Skeleton height={isMobile ? `40rem` : `60rem`} width={`100%`} />
          </div>
          <div className={`col-lg-6`}>
            <Skeleton height={`15rem`} width={`100%`} />
            <Skeleton
              height={`40rem`}
              width={`100%`}
              style={{ marginTop: "5rem" }}
            />
          </div>
        </div>
      </div>

      {/* RELATED PRODUCTS */}
      <div
        className={`container page-full-width`}
        style={{ marginTop: isMobile ? "10rem" : "20rem" }}
      >
        <Skeleton height={`6rem`} width={`100%`} />
        <div
          style={{
            marginTop: "6rem",
            display: "grid",
            gridTemplateColumns: isMobile ? "1fr 1fr" : "1fr 1fr 1fr 1fr",
            gap: "2rem",
          }}
        >
          <Skeleton height={isMobile ? `40rem` : `60rem`} width={`100%`} />
          <Skeleton height={isMobile ? `40rem` : `60rem`} width={`100%`} />
          {!isMobile && <Skeleton height={`60rem`} width={`100%`} />}
          {!isMobile && <Skeleton height={`60rem`} width={`100%`} />}
        </div>
      </div>

      {/* FEATURED DESIGNER */}
      <div
        className={`container`}
        style={{ marginTop: isMobile ? "10rem" : "20rem" }}
      >
        <Skeleton height={isMobile ? `40rem` : `60rem`} width={`100%`} />
      </div>

      {/* RECENTLY VIEWED */}
      <div
        className={`container page-full-width`}
        style={{
          marginTop: isMobile ? "10rem" : "20rem",
          paddingBottom: "10rem",
        }}
      >
        <Skeleton height={`6rem`} width={`100%`} />
        <div
          style={{
            marginTop: "6rem",
            display: "grid",
            gridTemplateColumns: isMobile ? "1fr 1fr" : "1fr 1fr 1fr 1fr",
            gap: "2rem",
          }}
        >
          <Skeleton height={isMobile ? `40rem` : `60rem`} width={`100%`} />
          <Skeleton height={isMobile ? `40rem` : `60rem`} width={`100%`} />
          {!isMobile && <Skeleton height={`60rem`} width={`100%`} />}
          {!isMobile && <Skeleton height={`60rem`} width={`100%`} />}
        </div>
      </div>
    </>
  );
};

export default ProductSkeleton;
