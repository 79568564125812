import { ApolloClient } from "apollo-client";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import introspectionQueryResultData from "./fragmentTypes.json";
import { createHttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
// redux store
import store from "core/state/redux/store";
import { customerActions } from "core/state/redux/data/customer";

import { customerInitialState } from "app/state/redux/data/customer";

// fetches local storage state and theme configuration
import { loadState } from "core/state/redux/localStorage";

// react used for redirections
import { isUndefined } from "core/helpers/functions";
import { wishlistActions } from "app/state/redux/data/wishlist";
import { messagesActions } from "core/state/redux/data/messages";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});
const cache = new InMemoryCache({ fragmentMatcher });
let token;

const logoutCustomer = () => {
  store.dispatch(customerActions.logoutCustomer(true));
  // store.dispatch(customerActions._reduceCustomer(customerInitialState));
  setTimeout(() => {
    store.dispatch(
      messagesActions.addMessage(
        "Your session has expired. Please login to resume.",
        "danger"
      )
    );
  }, 300);
};

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.

  let loadedState = loadState();
  let storeState = loadedState?.store;
  token =
    loadedState &&
    typeof loadedState.customer !== "undefined" &&
    typeof loadedState.customer.data !== "undefined" &&
    typeof loadedState.customer.data.token !== "undefined"
      ? loadedState.customer.data.token
      : false;

  let siteCode = storeState?.store_code;

  if (token === "false") {
    logoutCustomer();
  }

  if (siteCode !== undefined) {
    // Use the setContext method to set the HTTP headers.
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : "",
        store: siteCode,
      },
    });
  } else {
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : "",
        "cache-control": "no-cache",
      },
    });
  }

  // Call the next link in the middleware chain.
  return forward(operation);
});

const resetToken = onError(({ graphQLErrors, networkError }) => {
  if (networkError === "TypeError: Failed to fetch") {
    console.log("we should try offline mode");
  }
  if (!isUndefined(graphQLErrors)) {
    let error = graphQLErrors[0];
    if (error.extensions.category === "graphql-authorization") {
      if (!token) return;
      token = false;
      logoutCustomer();
    }
  }
});

// implement customerTokenValidation here, in case there is no graphql error
export const apolloClient = new ApolloClient({
  link: authLink.concat(
    ApolloLink.from([
      resetToken,
      onError(({ graphQLErrors, networkError }) => {
        return;
        // if (graphQLErrors) {
        //   graphQLErrors.map(({ message, locations, path, debugMessage }) =>
        //     console.log(
        //       `[GraphQL error]: Message: ${message}, DebugMessage: ${debugMessage}, Location: ${JSON.stringify(
        //         locations
        //       )}, Path: ${path}`
        //     )
        //   );
        // }
        // if (networkError) {
        //   console.log(`[Network error]: ${networkError}`);
        // }
      }),

      createHttpLink({
        uri: "https://" + process.env.REACT_APP_MAIN_STORE + "/graphql",
        useGETForQueries: true,
      }),
    ])
  ),

  cache: cache,
});

export const apolloClientMutation = new ApolloClient({
  link: authLink.concat(
    ApolloLink.from([
      resetToken,
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path, debugMessage }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, DebugMessage: ${debugMessage}, Location: ${JSON.stringify(
                locations
              )}, Path: ${path}`
            )
          );
        }
        if (networkError) console.log(`[Network error]: ${networkError}`);
      }),

      createHttpLink({
        uri: "https://" + process.env.REACT_APP_MAIN_STORE + "/graphql",
      }),
    ])
  ),

  cache: cache,
});

export default apolloClient;
