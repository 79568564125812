import { useState } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBLink,
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBBox,
  MDBBtn,
} from "mdbreact";
import { Link } from "react-router-dom";
import SessionMessages from "core/components/sessionMessages";
import InputField from "core/components/inputField";
import Icon from "app/assets/icon/icon";
import Image from "app/layout/image";
import Price from "app/layout/price";
import TogglePasswordContainer from "app/layout/togglePasswordContainer";

const LoginModal = ({
  modalWishlist,
  modalToggle,
  product,
  loggedIn,
  email,
  setEmail,
  password,
  setPassword,
  username,
  setUsername,
  // errors,
  touched,
  setTouched,
  handleLoginCheck,
  validateInput,
  loading,
  error,
  setError,
  item,
  collectionProducts,
}) => {
  const [togglePassword, setTogglePassword] = useState(false);
  return (
    <>
      <MDBContainer id={`plp-wishlist-modal`} className={`first-modal`}>
        <MDBModal
          isOpen={modalWishlist}
          toggle={(e) => {
            modalToggle();
          }}
          className={"wishlist-modal modal-wrapper"}
          centered
        >
          <MDBModalHeader className="d-flex p-0 modal-custom-header w-100">
            <h4 className="text-center">Add to Favorites</h4>
            <div className="close-btn">
              <div
                onClick={() => {
                  modalToggle();
                }}
                type="button"
              >
                <Icon icon="closeIconBlue" />
              </div>
            </div>
          </MDBModalHeader>
          <MDBModalBody className="align-items-center">
            <MDBContainer fluid>
              <MDBRow className={`border-bottom py-sm-5 py-3`}>
                <MDBCol>
                  {username === false ? (
                    product?.name ? (
                      <MDBRow className={`flex-column align-items-center`}>
                        <MDBCol>
                          <Image
                            source={
                              product?.small_image?.url
                                ? product.small_image.url
                                : product?.thumbnail?.url
                                ? product.thumbnail.url
                                : ""
                            }
                          />
                        </MDBCol>
                        <MDBCol
                          className={`d-flex product-name-title align-items-center justify-content-center mt-3`}
                        >
                          <MDBRow>
                            <MDBCol size={"12"}>
                              <h5>{product.name}</h5>
                            </MDBCol>
                            {(loggedIn && (
                              <MDBCol size={"12"}>
                                <div
                                  className={
                                    "product-price d-flex justify-content-center"
                                  }
                                >
                                  <Price product={product} layout={`widget`} />
                                </div>
                              </MDBCol>
                            )) || <></>}
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>
                    ) : null
                  ) : (
                    <MDBRow>
                      <MDBCol
                        size={`12`}
                        className="text-center wrong-email mx-auto my-5"
                      >
                        <p className="mb-3">{email}</p>
                        <MDBLink
                          tag="span"
                          className={"modal-link"}
                          onClick={(e) => {
                            setEmail("");
                            setUsername(false);
                          }}
                        >
                          Not your email? Go back
                        </MDBLink>
                      </MDBCol>
                    </MDBRow>
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow className={`action-content`}>
                <MDBCol size="12" className={`p-0`}>
                  <MDBRow>
                    <MDBCol className="text-center mx-auto">
                      <p className="text-center my-5 font-weight-bold">
                        {username === false
                          ? "Before you do that..."
                          : `Welcome back, ${username}`}
                      </p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol size="12">
                      {username === false ? (
                        <p className="text-center signin-block">
                          Sign in with your email
                        </p>
                      ) : (
                        <>
                          <p className="text-center mb-5 enter-password w-responsive text-center mx-auto p-0">
                            Great to see you again. Enter your account password
                            to continue.
                          </p>
                        </>
                      )}

                      {username === false ? (
                        <>
                          <MDBBox
                            className={`email-container input-container mt-5`}
                          >
                            <InputField
                              className={`custom-input ${
                                error ? "validation-error" : ""
                              }`}
                              label={`Email Address`}
                              onBlur={(e) => {
                                setTouched({ ...touched, email: true });
                              }}
                              // hint={`${
                              //   error
                              //     ? "Sorry, this email is incorrect"
                              //     : "Required"
                              // }`}
                              placeholder={`Required`}
                              group
                              type="email"
                              validate
                              error="wrong"
                              value={email}
                              onChange={(e) => {
                                setError(false);
                                setEmail(e.target.value);
                              }}
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  handleLoginCheck(item, collectionProducts);
                                }
                              }}
                            />
                            <MDBTypography tag="p" className={`error-label`}>
                              {`${
                                error
                                  ? email.length === 0
                                    ? "Email is required"
                                    : "Your email is not on file, please register below"
                                  : ""
                              }`}
                            </MDBTypography>
                          </MDBBox>
                          <MDBBtn
                            color="primary"
                            disabled={loading}
                            className={`w-100 my-4 btn-without-icon ${
                              error ? "mt-5" : ""
                            }`}
                            onClick={(e) => {
                              validateInput(e);
                              handleLoginCheck(item, collectionProducts);
                            }}
                            children={
                              <>
                                <span>
                                  {loading ? "Processing..." : "Continue"}
                                </span>
                              </>
                            }
                          />
                          <p className="mt-5 mb-3 text-center">
                            Don’t have an account with us?
                          </p>
                          <p className="text-center">Please Register Below</p>
                          <div className="d-flex justify-content-between mt-4">
                            <Link
                              to="/quick-register"
                              onClick={modalToggle}
                              className="text-underline"
                            >
                              Guest Registration
                            </Link>
                            <Link
                              to="/register"
                              onClick={modalToggle}
                              className="text-underline"
                            >
                              Wholesale Customers
                            </Link>
                          </div>
                        </>
                      ) : (
                        <MDBBox className={`input-container`}>
                          <TogglePasswordContainer
                            show={togglePassword}
                            toggle={() => setTogglePassword(!togglePassword)}
                          >
                            <InputField
                              className={`custom-input ${
                                error ? "validation-error" : ""
                              }`}
                              label={`Password`}
                              onBlur={(e) => {
                                setTouched({ ...touched, password: true });
                              }}
                              placeholder="Required"
                              group
                              validate
                              error="wrong"
                              type={togglePassword ? "text" : `password`}
                              value={password}
                              onChange={(e) => {
                                setError(false);
                                setPassword(e.target.value);
                              }}
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  handleLoginCheck(item, collectionProducts);
                                }
                              }}
                            />
                          </TogglePasswordContainer>
                          <MDBTypography tag="p" className={`error-label`}>
                            {`${error ? "Password is required" : ""}`}
                          </MDBTypography>
                        </MDBBox>
                      )}
                    </MDBCol>
                  </MDBRow>
                  {username === false ? (
                    <></>
                  ) : (
                    <>
                      <MDBRow>
                        <MDBCol size="12">
                          <MDBLink
                            className={`modal-link ${error && "mt-3"}`}
                            to={`/forgot-password`}
                            onClick={(e) => {
                              modalToggle();
                            }}
                          >
                            <span>Forgot your password?</span>
                          </MDBLink>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol size="12">
                          <>
                            <MDBBtn
                              color="primary"
                              disabled={loading}
                              className={"btn-without-icon w-100 my-4"}
                              onClick={(e) => {
                                validateInput(e);
                                handleLoginCheck(item, collectionProducts);
                              }}
                              children={
                                <>
                                  <Icon icon="arrowRightWhite" />
                                  <span>
                                    {loading ? "Logging..." : "Login"}
                                  </span>
                                </>
                              }
                            />
                          </>
                        </MDBCol>
                      </MDBRow>
                    </>
                  )}
                  <SessionMessages target={`wishlist-login`} />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
        </MDBModal>
      </MDBContainer>
    </>
  );
};

export default LoginModal;
