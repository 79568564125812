import { memo, useEffect } from "react";
import { useHistory } from "react-router";
import { MDBModal, MDBModalBody } from "mdbreact";

const AddedMiddleModal = memo(
  ({
    modalToggleSecond,
    addedPopUp,
    setAddedPopUp,
    onMouseEnter,
    onMouseLeave,
    setAutoClose,
  }) => {
    const history = useHistory();

    return (
      <>
        <MDBModal
          isOpen={addedPopUp}
          className={"wishlist-modal modal-wrapper middle-modal"}
          centered
          toggle={(e) => setAddedPopUp(false)}
        >
          <MDBModalBody
            className="d-flex w-100 justify-content-between add-to-modal"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={(e) => e.stopPropagation()}
          >
            <p>Added to Favorites</p>
            <div className="d-flex justify-content-between ">
              <p
                className="font-weight-bold text-underline cursor-pointer"
                onClick={() => {
                  setAddedPopUp(false);
                  history.push("/customer/favorites");
                }}
              >
                View
              </p>
              <p
                className="font-weight-bold text-underline cursor-pointer"
                onClick={() => {
                  setAddedPopUp(false);
                  modalToggleSecond();
                }}
              >
                Add to projects
              </p>
            </div>
          </MDBModalBody>
        </MDBModal>
      </>
    );
  }
);

export default AddedMiddleModal;
