import { useMemo } from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import Image from "app/layout/image";
import Attributes from "../attributes";

const ProductDetailsMobile = ({
  data,
  storeCode,
  productDetailsImage,
  attributes,
  descriptionLength,
  setDescriptionLength,
  getExcerpt,
  updateArrow,
  context,
  selectedSwatch,
  selectedSize,
  galleries,
  toggleSidebarMenu,
  pdpSidebarImage,
  productVariant,
}) => {
  const webpImageDirectory = "catalog/product/webp/" + data.sku + "/";
  const explodedImageSource = productDetailsImage
    ? productDetailsImage?.url?.split(/\//g)
    : false;
  const {
    description: { html },
    media_gallery,
  } = data;

  const webpImage = explodedImageSource
    ? explodedImageSource[explodedImageSource.length - 1]
        .replace(".png", ".webp")
        .replace(".jpg", ".webp")
        .replace(".webp", ".webp")
    : false;

  const videoContent = useMemo(
    () => media_gallery.find((media) => media.video_content),
    [media_gallery]
  );

  return (
    <MDBContainer
      className={`page-full-width ${storeCode} product-details-container`}
    >
      {/* <HeaderSection heading={"Product details"} className={"py-5"} /> */}
      <MDBRow className="product-details-wrapper">
        <MDBCol size="12">
          <h3 className={`mb-5`}>Product Description</h3>
          <Image
            source={productDetailsImage?.url}
            webpImage={webpImageDirectory + webpImage}
            backend={true}
            backend_public={true}
            mediaLink={true}
          />
        </MDBCol>
        <MDBCol size="12">
          <MDBCol size="12" className="p-0">
            {html && (
              <>
                <p className="mb-3">{getExcerpt(html, descriptionLength)}</p>
                {descriptionLength === "short" ? (
                  <p
                    className="text-underline cursor-pointer"
                    onClick={() => setDescriptionLength("long")}
                  >
                    Read More
                  </p>
                ) : (
                  <p
                    className="text-underline cursor-pointer"
                    onClick={() => setDescriptionLength("short")}
                  >
                    Read Less
                  </p>
                )}
              </>
            )}
          </MDBCol>
          <Attributes
            attributes={attributes}
            data={data}
            selectedSwatch={selectedSwatch}
            selectedSize={selectedSize}
            pdpSidebarImage={pdpSidebarImage}
            productVariant={productVariant}
            context={context}
          />
        </MDBCol>
        <MDBCol size="12">
          {videoContent ? (
            <iframe
              src={videoContent?.video_content?.video_url}
              title={videoContent?.video_content?.video_title}
              width="100%"
              height="320"
            ></iframe>
          ) : (
            <></>
          )}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default ProductDetailsMobile;
