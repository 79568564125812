/**
 * Messages integrations with Redux following the ducks standard
 */

import applicationReducer from "./reducer";

export { default as applicationType } from "./types";
export { default as applicationActions } from "./actions";
export { default as applicationInitialState } from "./initialState";
export default applicationReducer;

// End
