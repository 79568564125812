import desktopLogoGatco from "app/assets/logo/GatcoLogo.svg";
import mobileLogo from "app/assets/logo/GatcoLogoBlue.svg";
import { useMediaQuery } from "react-responsive";

const Logo = ({ component, open }) => {
  const isMobile = useMediaQuery({ maxWidth: "992px" });

  // if component is not defined default logo is from header
  let isHeaderLogo = component
    ? component.includes("header")
      ? true
      : false
    : true;
  return (
    <div id="logo">
      {isHeaderLogo ? (
        isMobile ? (
          <div className="logo-mobile">
            <img
              src={!open ? desktopLogoGatco : mobileLogo}
              alt="gatco-logo"
              width={`176`}
              height={`34`}
            />
          </div>
        ) : (
          <div className="logo-desktop">
            <img
              src={desktopLogoGatco}
              alt="gatco-logo"
              width={`261`}
              height={`51`}
            />
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default Logo;
