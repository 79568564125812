import Icon from "app/assets/icon/icon";
import { MDBBox } from "mdbreact";
import React from "react";
import { useDownloader } from "../hooks/useDownloader";

/**
 * @param {*} image String, which is a url to the image
 * @param {string} sku Product sku, used for group_id
 */
export const ImageDownloadIcon = ({
  image = "https://via.placeholder.com/370x420",
  sku = false,
  type = false,
  is_primary = true,
  icon = "downloadWhite",
  addedIcon = "downloadDark",
  useWrapper = false,
  className = "",
  wrapperClass = "",
  selectYourImages = false,
  selectYourImagesToggle = () => {},
  role = `button`,
}) => {
  const { isAdded, addImage, removeImage } = useDownloader(image);

  return !useWrapper ? (
    <Icon
      role={role}
      onClick={() => {
        if (!selectYourImages) {
          if (isAdded) {
            removeImage();
          } else {
            addImage(sku, type, is_primary);
          }
        } else {
          selectYourImagesToggle();
        }
      }}
      className={`downloader downloader-icon ${className} ${
        isAdded ? "in" : "out"
      }`.trim()}
      icon={isAdded ? addedIcon : icon}
    />
  ) : (
    <MDBBox
      role={role}
      onClick={() => {
        if (!selectYourImages) {
          if (isAdded) {
            removeImage();
          } else {
            addImage(sku, type, is_primary);
          }
        } else {
          selectYourImagesToggle();
        }
      }}
      className={`downloader downloader-icon ${wrapperClass} ${className} ${
        isAdded ? "in" : "out"
      }`.trim()}
    >
      <Icon className={className} icon={isAdded ? addedIcon : icon} />
    </MDBBox>
  );
};
