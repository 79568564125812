import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import Image from "../image/image";
import useStore from "app/state/hooks/storeHooks/useStore";

const OverlapCardsComponentForCmsBlock = ({ data }) => {
  let position = "left";
  if (data?.props?.className && data.props.className.includes("right")) {
    position = "right";
  }
  //finding nodes with image
  let nodesWithImage = data.props.children.filter((item) => {
    return item.props.children.find((subitem) => {
      return subitem.props?.source;
    });
  });

  //removing node with image from data
  let images = [];
  nodesWithImage.map((node) => {
    return images.push(node.props.children[0].props.source);
  });

  const filteredData = data.props.children.filter((item) => {
    let count = 0;
    nodesWithImage.map((nodeWithImage) => {
      if (item === nodeWithImage) return count++;
      return count;
    });
    if (count === 0) return item;
    return null;
  });

  const { getStoreCode } = useStore();
  const store = getStoreCode();

  return (
    <MDBContainer className={`overlap-cards-component-for-cms `}>
      <MDBContainer
        className={`inner-content ${store + "-website"} ${
          position === "right" ? "top-right-icon" : "top-left-icon"
        }`}
      >
        <MDBRow className={`overlap-cards-row w-100 ${position}`}>
          <MDBCol lg="7" className={`text-container`}>
            <div className={`inner`}>{filteredData}</div>
          </MDBCol>
          <MDBCol lg={`8`}>
            <MDBRow>
              {images.map((image, index) => {
                return (
                  <MDBCol
                    key={`overlap-card-image-${index}`}
                    className={`image-container px-2`}
                  >
                    <div className={`image-overlay`}></div>
                    <Image
                      alt={`overlap-cards-image`}
                      source={image}
                      preload={false}
                    />
                  </MDBCol>
                );
              })}
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBContainer>
  );
};
export default OverlapCardsComponentForCmsBlock;
