import { MDBContainer, MDBRow, MDBCol, MDBBox, MDBTypography } from "mdbreact";
import { default as Pages } from "core/config/routing/pages/customerPages";
import { normalizeRoutes } from "./functions";
import CustomerSidebar from "./sidebar";
import CustomerPages from "./pages";

const Customer = (props) => {
  let PagesObjectMapper = normalizeRoutes(Pages);
  let hideSidebar = false;
  let isFavorites = false;

  Object.keys(PagesObjectMapper).map((value, index) => {
    let page = PagesObjectMapper[value];
    if (
      props.location.pathname === `/${page.url}` &&
      typeof page.showSidebar !== "undefined" &&
      page.showSidebar === false
    ) {
      hideSidebar = true;
      isFavorites = true;
    }
    return true;
  });
  let nameOfPage = props.currentLocation.split("/").pop();

  if (isFavorites) {
    return (
      <MDBBox className={`customer-account  page-main-content favorites`}>
        <MDBBox lg={`12`}>
          <CustomerPages {...props} />
        </MDBBox>
      </MDBBox>
    );
  } else {
    return (
      <>
        <MDBContainer fluid>
          <MDBRow>
            <MDBBox className="title-with-background w-100 mb-5">
              <MDBContainer>
                <MDBTypography tag="h1" className="page-title text-capitalize">
                  {(nameOfPage === "customer" && "My Account") ||
                    (nameOfPage === "information" && "Account Information") ||
                    ((nameOfPage === "addresses" || nameOfPage === "edit") &&
                      "Address Book") ||
                    nameOfPage}
                </MDBTypography>
              </MDBContainer>
            </MDBBox>
          </MDBRow>
        </MDBContainer>
        <MDBContainer className={`customer-account ${nameOfPage}`}>
          <MDBRow className={`pb-5`}>
            {hideSidebar === false ? (
              <MDBCol lg="3">
                <CustomerSidebar {...props} />
              </MDBCol>
            ) : (
              <></>
            )}
            <MDBCol lg={`${hideSidebar ? "12" : "9"}`}>
              <CustomerPages {...props} />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </>
    );
  }
};

export default Customer;
