export default (state, action) => {
  return Object.assign({}, state, {
    data: {
      ...state.data,
      compareItems: action.payload,
      listCleard: action.payload.listCleared
        ? action.payload.listCleared
        : null,
    },
    open: state.open,
    loading: false,
  });
};
