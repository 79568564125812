import { useDispatch, useSelector } from "react-redux";
import apolloClient from "app/graphql/apolloClient";
import compareActions from "../redux/data/compare/actions";
import queryLoader from "app/graphql/queryLoader";
import { isNull } from "lodash";
import { messagesActions } from "core/state/redux/data/messages";

function useCompare() {
  // return compare from state
  const compare = useSelector((state) => state.compare);
  const dispatch = useDispatch();

  /**
   * Adds product with id to compare list based on guestIndex
   * @param {productId: String} request
   */
  const addCompare = async (request) => {
    try {
      // let checkIfexists = checkIfProductIsInCompareById(request.products?.[0]);
      // if (checkIfexists) {
      //   dispatch(messagesActions.addMessage("Item already added to compare"));
      //   return;
      // }
      let variables = { products: request.products, uid: request.uid };

      const { data } = await apolloClient.mutate({
        mutation: queryLoader("addToCompare"),
        variables: { input: variables },
        fetchPolicy: "no-cache",
      });

      if (data.addProductsToCompareList !== null) {
        dispatch(compareActions.setCompare(data.addProductsToCompareList));
        dispatch(
          messagesActions.addMessage(
            "Item successfully added to compare",
            "success"
          )
        );
        return data.addProductsToCompareList;
      }
    } catch (error) {
      dispatch(messagesActions.addMessage(error.toString(), "danger"));
      return;
    }
  };

  const getCompareData = () => {
    return compare;
  };

  const getCompareItems = () => {
    return compare.data.compareItems.items;
  };

  const getCompareListId = async (uid) => {
    if (!uid) return false;
    try {
      const { data } = await apolloClient.query({
        query: queryLoader("compareList"),
        variables: { uid },
        fetchPolicy: "no-cache",
      });
      if (data.compareList !== null) {
        return data?.compareList;
      }
    } catch (error) {
      // dispatch(messagesActions.addMessage(error.toString(), "danger"));
      return;
    }
    // compare.data.compareItems.uid;
  };

  /**
   * Remove product with id from compare list based on guestIndex
   * @param {productId: String} request
   */
  const removeCompare = async (request) => {
    try {
      // let checkIfexists = checkIfProductIsInCompareById(request.products?.[0]);

      // if (!checkIfexists) {
      //   return;
      // }
      let variables = { products: request.products, uid: request.uid };

      const { data } = await apolloClient.mutate({
        mutation: queryLoader("removeCompare"),
        variables: { input: variables },
        fetchPolicy: "no-cache",
      });
      dispatch(compareActions.setCompare(data.removeProductsFromCompareList));
      dispatch(
        messagesActions.addMessage(
          "item successfully removed from compared list",
          "success"
        )
      );
      return data.removeProductsFromCompareList;
    } catch (error) {
      dispatch(messagesActions.addMessage(error.toString(), "danger"));
      return;
    }
  };

  const addCompareFunc = async (
    data,
    getCompareListId,
    setCompareData,
    setCompareMenu,
    customer
  ) => {
    const productId = data;
    const customerCompareData = await getCompareListId(
      customer?.compare_list?.uid
    );
    const compareData = getCompareData();

    if (!customer?.compare_list?.uid && !compareData?.data?.compareItems?.uid) {
      setCompareData([]);
      createCompareList({ products: [productId] }).then((response) => {
        addCompare({ products: [productId], uid: response?.uid }).then(
          (response) => setCompareData(response?.items)
        );
      });
    } else {
      addCompare({
        products: [productId],
        uid: customerCompareData?.uid
          ? customerCompareData?.uid
          : compareData?.data?.compareItems?.uid,
      }).then((response) => setCompareData(response?.items));
    }
    setCompareMenu(false);
  };

  const removeCompareFunc = async (
    data,
    getCompareListId,
    setCompareData,
    customer
  ) => {
    const productId = data;
    const customerCompareData = await getCompareListId(
      customer?.compare_list?.uid
    );

    const compareData = getCompareData();

    removeCompare({
      products: [productId],
      uid: customerCompareData?.uid
        ? customerCompareData?.uid
        : compareData?.data?.compareItems?.uid,
    }).then((response) => setCompareData(response?.items));
  };

  const clearCompareList = async (uid) => {
    let variables = {
      uid: uid,
    };

    const { data } = await apolloClient.mutate({
      mutation: queryLoader("clearCompare"),
      variables: variables,
      fetchPolicy: "no-cache",
    });
  };

  const checkIfProductIsInCompareById = (id) => {
    if (
      typeof compare.data.compareItems === "undefined" ||
      typeof compare.data.compareItems.items === "undefined" ||
      compare.data.compareItems.items === null ||
      compare.data.compareItems.items.length === 0
    ) {
      return false;
    }

    let findIfItemExistsInCompare = compare.data.compareItems.items.filter(
      (item) => {
        return parseInt(item.product.id) === parseInt(id);
      }
    );
    return findIfItemExistsInCompare.length === 1 ? true : false;
  };

  const createCompareList = async (request, target = "global") => {
    let variables = { products: request.products };
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("createCompareList"),
        variables: { input: variables },
        fetchPolicy: "no-cache",
      });
      if (data.createCompareList !== null) {
        dispatch(compareActions.setCompare(data.createCompareList));
        return data.createCompareList;
      }
    } catch (err) {
      dispatch(messagesActions.addMessage(err.toString(), "danger"));
      return;
    }
  };

  return {
    addCompare,
    removeCompare,
    getCompareData,
    getCompareItems,
    clearCompareList,
    checkIfProductIsInCompareById,
    createCompareList,
    getCompareListId,
    addCompareFunc,
    removeCompareFunc,
  };
}
export default useCompare;
