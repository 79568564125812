import { memo, lazy } from "react";
import { isEqual } from "lodash";
import SearchViewAll from "./viewAll";
import { useMediaQuery } from "react-responsive";
import ReactLoader from "app/layout/loader/loader";

const DesktopLayout = lazy(() => import("./desktopLayout"));
const MobileLayout = lazy(() => import("./mobileLayout"));

const SearchResults = memo(
  ({ data, search, loading, setDisplaySearch }) => {
    let searchResults = data?.products;
    let { items, total_count } = searchResults;
    const isMobile = useMediaQuery({ maxWidth: 992 });
    if (loading) {
      return (
        <div
          className={`h-100 p-5 d-flex align-items-center justify-content-center loading-container`}
        >
          <ReactLoader />
        </div>
      );
    }
    if (items.length === 0 && !loading)
      return (
        <div
          id={`search-results-container`}
          className={`no-results text-center`}
        >
          <h5>No Results Found</h5>
        </div>
      );

    return (
      <div fluid id={`search-results-container`}>
        <div className={"title-container text-center"}>
          <h5>Suggested Products</h5>
        </div>
        {isMobile ? (
          <MobileLayout items={items} setDisplaySearch={setDisplaySearch} />
        ) : (
          <DesktopLayout items={items} setDisplaySearch={setDisplaySearch} />
        )}
        <SearchViewAll
          total_count={total_count}
          search={search}
          setDisplaySearch={setDisplaySearch}
        />
      </div>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default SearchResults;
