import { MDBRow } from "mdbreact";
import React from "react";
import Image from "./image";

/**
 * Component used for retrieving product images based on a filter defined (right now the filter is by sku)
 * @param {*} images Product Images
 * @param {*} imageSelection State of image selection
 * @param {function} toggleImage Toggle function to set or unset image selection
 */
const ImagesNoFetch = ({ images, imageSelection, toggleImage }) => {
  const columnProps = { lg: `4`, md: `6`, xs: `12` };

  return (
    <MDBRow>
      {images !== undefined ? (
        images.map((image, index) => {
          let selected = imageSelection.find(
            (_image) => _image.url === image.url
          );
          return (
            <Image
              key={`download-image-${index}-${image.id} `}
              image={image}
              index={index}
              selected={selected}
              toggleImage={toggleImage}
              columnProps={columnProps}
            />
          );
        })
      ) : (
        <>
          We are unable to load the images. <br /> Please try again later.
        </>
      )}
    </MDBRow>
  );
};

export default ImagesNoFetch;
