import { Fragment } from "react";
import { MDBBox, MDBNavbarNav, MDBNavItem } from "mdbreact";
import ToggleMenu from "./toggle-menu";
import { Link } from "react-router-dom";
import Icon from "app/assets/icon/icon";

export const MobileNavigation = (
  menuItems,
  closeBurger,
  toggleHamburger,
  setOpen,
  open,
  collapseID,
  setCollapseID,
  props
) => {
  const navigation = [
    <MDBBox key="navigation-mobile-container">
      <MDBNavbarNav left className="mobile-menu-navbar">
        {Object.keys(menuItems).map((element, index) => {
          let currentMenuItem = menuItems[index];
          if (menuItems[index].include_in_menu === 0 || !open) {
            return <Fragment key={`menu-fragment-${index}`}></Fragment>;
          }

          return (
            (currentMenuItem.children?.length > 0 && (
              <MDBNavItem
                key={`uncontrolled-nav-dropdown-${index}`}
                className="px-0"
              >
                <MDBBox
                  className=" dropdown-toggle nav-link p-0 "
                  id={`nav-dropdown-${index}`}
                >
                  <MDBBox
                    className={`mobile-dropdown w-100`}
                    id={`dropdown-visible-${index}`}
                  >
                    <div display={`flex`} flex={`column`}>
                      <ToggleMenu
                        items={currentMenuItem.children}
                        sideItems={currentMenuItem.sideCategories}
                        menuItem={currentMenuItem}
                        toggleHamburger={toggleHamburger}
                        setOpen={setOpen}
                        open={open}
                        closeBurger={closeBurger}
                        collapseId={collapseID}
                        setCollapseId={setCollapseID}
                      />
                    </div>
                  </MDBBox>
                </MDBBox>
              </MDBNavItem>
            )) || (
              <MDBNavItem className="toggle-menu-item dropdown-toggle nav-link static-link w-100">
                {currentMenuItem.icon && (
                  <span className="menu-item-icon">
                    <Icon icon={currentMenuItem.icon} />
                  </span>
                )}
                <Link
                  to={`/${currentMenuItem.url_path}`}
                  className={`text-decoration-none link-mobile`}
                  key={`nav-item-not-uncontrolled-nav-${index}`}
                  onClick={(e) => {
                    if (!e.currentTarget.href) e.preventDefault();
                    toggleHamburger(props);
                    closeBurger();
                    setCollapseID([]);
                  }}
                >
                  <span>{currentMenuItem.name}</span>
                </Link>
              </MDBNavItem>
            )
          );
        })}
      </MDBNavbarNav>
    </MDBBox>,
  ];
  return navigation;
};
