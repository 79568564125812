import { useEffect } from "react";
import { useLocation } from "react-router-dom";
const ScrollToReset = () => {
  const { pathname, state } = useLocation();

  useEffect(() => {
    if (!pathname?.includes("customer-service")) {
      window.scrollTo({ top: 0 });
    } else if (
      typeof state !== "undefined" &&
      state !== null &&
      typeof state.scrollTo !== "undefined"
    ) {
      window.scrollTo({ top: 0 });
    }
  }, [pathname, state]);

  return null;
};
export default ScrollToReset;
