import { useMemo } from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import Attributes from "../attributes";

const ProductDetailsDesktop = ({
  data,
  storeCode,
  productDetailsImage,
  attributes,
  descriptionLength,
  setDescriptionLength,
  getExcerpt,
  context,
  selectedSwatch,
  selectedSize,
  pdpSidebarImage,
  productVariant,
}) => {
  const {
    description: { html },
    media_gallery,
  } = data;
  // const explodedImageSource = productDetailsImage
  //   ? productDetailsImage?.url?.split(/\//g)
  //   : false;

  // const webpImage = explodedImageSource
  //   ? explodedImageSource[explodedImageSource.length - 1]
  //       .replace(".png", ".webp")
  //       .replace(".jpg", ".webp")
  //       .replace(".webp", ".webp")
  //   : false;

  const videoContent = useMemo(
    () => media_gallery.find((media) => media.video_content),
    [media_gallery]
  );

  return (
    <MDBContainer
      className={`page-full-width  ${storeCode} product-details-container`}
    >
      <MDBRow className="product-details-wrapper d-flex flex-row justify-content-between">
        <MDBCol lg={videoContent ? "6" : "12"} className="pl-0">
          <h3 className={`mb-5`}>Product Description</h3>
          <MDBCol lg="10" className="p-0">
            {html && (
              <>
                <p className="mb-3">{getExcerpt(html, descriptionLength)}</p>
                {descriptionLength === "short" ? (
                  <p
                    className="text-underline cursor-pointer"
                    onClick={() => setDescriptionLength("long")}
                  >
                    Read More
                  </p>
                ) : (
                  <p
                    className="text-underline cursor-pointer"
                    onClick={() => setDescriptionLength("short")}
                  >
                    Read Less
                  </p>
                )}
              </>
            )}
          </MDBCol>
          <Attributes
            attributes={attributes}
            data={data}
            selectedSwatch={selectedSwatch}
            selectedSize={selectedSize}
            pdpSidebarImage={pdpSidebarImage}
            productVariant={productVariant}
            context={context}
          />
        </MDBCol>
        {videoContent ? (
          <MDBCol lg="6" className="pr-0">
            {videoContent ? (
              <iframe
                src={videoContent?.video_content?.video_url}
                title={videoContent?.video_content?.video_title}
                width="100%"
                height="320"
              ></iframe>
            ) : (
              <></>
            )}
          </MDBCol>
        ) : null}
      </MDBRow>
    </MDBContainer>
  );
};
export default ProductDetailsDesktop;
