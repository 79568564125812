import ContentQueryContainer from "./content-query-container";
import ContentContainer from "./content";

const ContentPreQueryContainer = (props) => {
  let { filterData } = props;

  if (filterData) {
    return <ContentQueryContainer {...props} />;
  }
  return <ContentContainer {...props} />;
};

export default ContentPreQueryContainer;
