import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { MDBCol, MDBRow } from "mdbreact";
import Slider from "react-slick";
import Image from "app/layout/image";
import ReactLoader from "app/layout/loader/loader";
import Icon from "app/assets/icon/icon";
import ModalGallery from "./modalPopupGallery";
import MediaGalleryForTwoImages from "./mediaGalleryForTwoImages";

const MediaSlider = ({
  productItem,
  mediaGallery,
  loading,
  activeGalleryImage,
  baseImage,
}) => {
  const isTablet = useMediaQuery({ maxWidth: 992 });
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [popupGallery, setPopupGallery] = useState();
  const [activeImage, setActiveImage] = useState();
  const [lifestyleImage, setLifestyleImage] = useState(null);
  const [filteredGallery, setFilteredGallery] = useState([]);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  // const [mobileGallery, setMobileGallery] = useState([]);

  const handleImageClick = (imageSource, index) => {
    if (!isTablet) {
      setPopupGallery(!popupGallery);
      setActiveImage(imageSource);
      setActiveImageIndex(index);
    }
  };

  const minimizedImge = (image) => {
    if (!image) return;
    const explodedImageSource = image?.url?.split(/\//g);
    const webpImage = explodedImageSource[explodedImageSource?.length - 1]
      .replace(/.png/, ".webp")
      .replace(/.jpg/, ".webp")
      .replace(/.webp/, ".webp");

    const webpImageDirectory = "catalog/product/webp/" + productItem?.sku + "/";
    return webpImage + webpImageDirectory;
  };

  useEffect(() => {
    let lifestyleImg =
      Array.isArray(mediaGallery) &&
      mediaGallery?.find((item) => item.url.toLowerCase().includes("ingr"));
    setLifestyleImage(lifestyleImg);
    if (mediaGallery?.length) {
      setFilteredGallery(
        mediaGallery?.filter(
          (item, index) => !item.url.toLowerCase().includes("ingr")
        )
      );
      // let baseImgIndex = mediaGallery.findIndex(
      //   (el) => el.url.toLowerCase() === baseImage.url.toLowerCase()
      // );
      // if (baseImgIndex === -1) {
      //   let newGallery = [baseImage];
      //   newGallery.push(mediaGallery);
      //   setMobileGallery(newGallery);
      // } else {
      //   let newGallery = mediaGallery;
      //   newGallery.splice(baseImgIndex, 1);
      //   newGallery.unshift(baseImage);
      //   setMobileGallery(newGallery);
      // }
    }
  }, [mediaGallery]);

  const showSlider = mediaGallery?.length > 1 && isMobile;

  return (
    <div className={`container media-gallery-slider pb-5`}>
      {loading && !mediaGallery ? (
        <div className={`d-flex flex-row w-100 justify-content-around`}>
          <ReactLoader />
          {!isTablet && <ReactLoader />}
        </div>
      ) : !showSlider ? (
        mediaGallery?.length > 2 ? (
          <MDBRow className={`w-100 m-0`}>
            <MDBCol size="6" className={`${!isTablet && "pl-0"}`}>
              {!activeGalleryImage && baseImage && (
                <div className={`product-image-container`}>
                  <Image
                    source={baseImage?.url}
                    wrapperClass={`imageloader`}
                    webpImage={minimizedImge(baseImage)}
                    alt={`product-image-${baseImage?.label}`}
                    backend={true}
                    backend_public={true}
                    mediaLink={true}
                  />
                  {!isTablet && (
                    <div
                      onClick={() =>
                        handleImageClick(
                          baseImage,
                          mediaGallery.findIndex(
                            (element) => element.url === baseImage.url
                          )
                        )
                      }
                      className={`enlarge-icon`}
                    >
                      <Icon icon={"enlarge_icon"} />
                    </div>
                  )}
                </div>
              )}
              {filteredGallery?.map((gallery, index) =>
                activeGalleryImage === index + 1 ? (
                  <div className={`product-image-container`} key={index}>
                    <Image
                      source={gallery?.url}
                      wrapperClass={`imageloader`}
                      webpImage={minimizedImge(gallery)}
                      alt={`product-image-${gallery?.label}`}
                      backend={true}
                      backend_public={true}
                      mediaLink={true}
                    />
                    {!isTablet && (
                      <div
                        onClick={() =>
                          handleImageClick(
                            gallery,
                            mediaGallery?.findIndex(
                              (img) => img?.url === gallery?.url
                            )
                          )
                        }
                        className={`enlarge-icon`}
                      >
                        <Icon icon={"enlarge_icon"} />
                      </div>
                    )}
                  </div>
                ) : null
              )}
            </MDBCol>
            <MDBCol size="6" className={`${!isTablet && "pr-0"}`}>
              <div className={`product-image-container`}>
                <Image
                  source={lifestyleImage?.url}
                  wrapperClass={`imageloader`}
                  webpImage={minimizedImge(lifestyleImage)}
                  alt={`product-image-${lifestyleImage?.label}`}
                  backend={true}
                  backend_public={true}
                  mediaLink={true}
                />
              </div>
            </MDBCol>
          </MDBRow>
        ) : (
          <MediaGalleryForTwoImages
            productItem={productItem}
            mediaGallery={mediaGallery}
            handleImageClick={handleImageClick}
            isMobile={isMobile}
            baseImage={baseImage}
            lifestyleImage={lifestyleImage}
            filteredGallery={filteredGallery}
            activeGalleryImage={activeGalleryImage}
          />
        )
      ) : (
        <div className="slider-holder">
          <Slider
            {...{
              dots: true,
              infinite: false,
              arrows: false,
              slidesToScroll: 1,
              initialSlide: activeImageIndex,
            }}
          >
            {/* <div>
                <Image
                  source={baseImage?.url}
                  wrapperClass={`imageloader`}
                  webpImage={minimizedImge(baseImage)}
                  alt={`product-image-${baseImage?.label}`}
                  backend={true}
                  backend_public={true}
                  mediaLink={true}
                />
              </div> */}
            {mediaGallery?.map((gallery, index) => (
              <div key={index}>
                <Image
                  source={gallery?.url}
                  wrapperClass={`imageloader`}
                  webpImage={minimizedImge(gallery)}
                  alt={`product-image-${gallery?.label}`}
                  backend={true}
                  backend_public={true}
                  mediaLink={true}
                />
              </div>
            ))}
          </Slider>
        </div>
      )}
      {!isTablet && (
        <ModalGallery
          productItem={productItem}
          popupGallery={popupGallery}
          setPopupGallery={setPopupGallery}
          activeImage={activeImage}
          setActiveImage={setActiveImage}
          image_gallery={mediaGallery}
          activeImageIndex={activeImageIndex}
          setActiveImageIndex={setActiveImageIndex}
        />
      )}
    </div>
  );
};

export default MediaSlider;
