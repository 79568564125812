import { useEffect } from "react";
import ReactGa from "react-ga";
import Layout from "./layout";
import apolloClient from "app/graphql/apolloClient";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "core/state/redux/store";
import history from "core/state/redux/history";
import ScrollToReset from "./layout/scrollToReset";
import StoreWrapper from "./layout/storeWrapper";
import "lazysizes";
import(`./${process.env.REACT_APP_MAIN_STORE_CODE}.scss`);

const App = () => {
  useEffect(() => {
    if (process.env.REACT_APP_MAIN_STORE === "magento.gatco-inc.com") {
      ReactGa.initialize("UA-97228225-1");
    }
    history.listen((location) => {
      if (location.pathname) {
        ReactGa.pageview(location.pathname + location.search);
      }
    });
  }, []);
  return (
    <>
      <ReduxProvider store={store}>
        <StoreWrapper>
          <ApolloProvider client={apolloClient}>
            <ApolloHooksProvider client={apolloClient}>
              <BrowserRouter history={history}>
                <ScrollToReset />
                <Layout />
              </BrowserRouter>
            </ApolloHooksProvider>
          </ApolloProvider>
        </StoreWrapper>
      </ReduxProvider>
    </>
  );
};

export default App;
