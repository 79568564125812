import { useState } from "react";
import { MDBContainer, MDBRow, MDBTypography, MDBBox, MDBLink } from "mdbreact";
import { useHistory } from "react-router-dom";
import { memo } from "react";
import InputField from "core/components/inputField";
import Button from "core/components/button";
import Icon from "app/assets/icon/icon";
import TogglePasswordContainer from "app/layout/togglePasswordContainer";

const SingIn = memo(
  ({
    onSubmit,
    handleSubmit,
    errors,
    register,
    setShowLoginRegister,
    showPasswordContent,
    setShowPasswordContent,
    storeCode,
    toggle,
    userName,
    setUserName,
    email,
    errorMessage,
    setErrorMessage,
    loading,
  }) => {
    const history = useHistory();
    const [togglePassword, setTogglePassword] = useState(false);
    return (
      <>
        <MDBContainer className={`singin-wrapper ${storeCode}`}>
          <MDBRow>
            <MDBBox className={`modal-inner-content w-100`}>
              <MDBBox className={`border-bottom block-with-border`}>
                <MDBTypography tag="p" className={`user-email f-16`}>
                  {email}
                </MDBTypography>
                <MDBTypography
                  tag="p"
                  className={`custom-link link-bottom-border in-the-middle link-small not-your-email f-16 text-underline`}
                  onClick={() => {
                    setUserName("");
                    setShowLoginRegister(true);
                    setShowPasswordContent(!showPasswordContent);
                  }}
                >
                  Not you? Go back
                </MDBTypography>
              </MDBBox>
              <MDBTypography tag="p" className={`modal-text`}>
                Great to see you again. Enter your account password to continue.
              </MDBTypography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <MDBBox className={`input-container`}>
                  <TogglePasswordContainer
                    show={togglePassword}
                    toggle={() => setTogglePassword(!togglePassword)}
                  >
                    <InputField
                      wrapperClass={`mb-5`}
                      placeholder={"Password*"}
                      className={`custom-input m-0 ${
                        (errors.password &&
                          errors.password.ref.value.length === 0 &&
                          errors.password.ref.value.length < 8) ||
                        typeof errorMessage.message !== "undefined"
                          ? "validation-error"
                          : ""
                      }`}
                      label={``}
                      inputRef={register({
                        required: true,
                      })}
                      group
                      name="password"
                      type={togglePassword ? "text" : `password`}
                      validate
                      error="wrong"
                      success="right"
                      onChange={(e) => {
                        setErrorMessage({
                          type: undefined,
                          message: undefined,
                        });
                      }}
                    />
                  </TogglePasswordContainer>
                  <MDBTypography tag="p" className={`error-label`}>
                    {`${
                      typeof errors.password !== "undefined"
                        ? errors.password.ref.value.length === 0
                          ? "Password is required*"
                          : errors.password.ref.value.length < 8
                          ? "Password is invalid*"
                          : ""
                        : typeof errorMessage.message !== "undefined"
                        ? "Password is invalid"
                        : ""
                    }`}
                  </MDBTypography>
                </MDBBox>
                <MDBRow
                  className={`mx-0 ${
                    (errors.password || errorMessage.message) && "mt-5"
                  }`}
                >
                  <Button
                    disabled={loading === "logging-in"}
                    color={"primary"}
                    className={`btn-with-icon mb-4`}
                    children={
                      <>
                        <Icon icon="arrowRightWhite" />
                        <MDBBox tag="span">Login</MDBBox>
                      </>
                    }
                    type="submit"
                  />
                </MDBRow>
                <MDBLink
                  to={"/forgot-password"}
                  onClick={toggle}
                  className={`custom-link link-bottom-border in-the-middle link-small forgot-password-link f-16 text-underline mb-5 p-0`}
                >
                  Forgot password?
                </MDBLink>
                <MDBRow className={`mx-0`}>
                  <p className={`font-weight-bold f-16 mb-3`}>
                    FIRST TIME LOGGING IN?
                  </p>
                  <Button
                    color={"primary"}
                    className={`btn-with-icon`}
                    onClick={() => {
                      document
                        .querySelector(".login-modal-wrapper .close-btn")
                        .click();
                      history.push("/forgot-password");
                    }}
                    children={
                      <>
                        <Icon icon="arrowRightWhite" />
                        <MDBBox tag="span">Reset Password</MDBBox>
                      </>
                    }
                  />
                </MDBRow>
              </form>
            </MDBBox>
          </MDBRow>
        </MDBContainer>
      </>
    );
  }
);
export default SingIn;
