// Main reducer
const TestReducer = (state, action) => {
  if (state === undefined) {
    return {};
  }

  return state;
};

export default TestReducer;
