//Please import icons in alphabetical order
import { ReactComponent as ArrowDownBlue } from "./arrowDownBlue.svg";
import { ReactComponent as ArrowUp } from "./whiteArrowUp.svg";
import { ReactComponent as AddQty } from "./addQty.svg";
import { ReactComponent as ArrowUpBlue } from "./ico-collapse.svg";
import { ReactComponent as ArrowLeftBlue } from "./arrowLeftBlue.svg"; //pagination arrow
import { ReactComponent as ArrowRightBlue } from "./arrowRightBlue.svg"; //pagination arrow
import { ReactComponent as ArrowLeftBlack } from "./arrowLeftBlack.svg"; //lifestyle gallery arrow
import { ReactComponent as ArrowRightBlack } from "./arrowRightBlack.svg"; //lifestyle gallery arrow
import { ReactComponent as AddressIcon } from "./addressIcon.svg"; //register icon
import { ReactComponent as AddressIconActive } from "./addressIconActive.svg"; //register icon
import { ReactComponent as BackArrowIcon } from "./back-arrow-icon.svg";
import { ReactComponent as EnlargeIcon } from "./enlargeIcon.svg";
import { ReactComponent as EmailIcon } from "./emailIcon.svg"; //contact us icon
import { ReactComponent as ErrorMessage } from "./error.svg";
import { ReactComponent as Calendar } from "./calendar.svg";
import { ReactComponent as CheckmarkIcon } from "./checkmarkIcon.svg"; //register icon
import { ReactComponent as Compare } from "./compare.svg";
import { ReactComponent as CompanyIcon } from "./companyIcon.svg"; //register icon
import { ReactComponent as CompanyIconActive } from "./companyIconActive.svg"; //register icon
import { ReactComponent as CloseIconBlue } from "./closeIconBlue.svg";
import { ReactComponent as CloseIconWhite } from "./closeIconWhite.svg"; // pdp fullscreen image
import { ReactComponent as CloseIconSmall } from "./ico-close.svg";
import { ReactComponent as CreateNewIcon } from "./create-new-icon.svg";
import { ReactComponent as DownloadPdfIcon } from "./download-pdf-icon.svg";
import { ReactComponent as DownloadSelectedIcon } from "./download-selected-icon.svg";
import { ReactComponent as FilterBlueIcon } from "./filter-blue-icon.svg";
import { ReactComponent as FileIcon } from "./file-icon.svg";
import { ReactComponent as IcoDesign } from "./ico-design.svg";
import { ReactComponent as LeftArrowHover } from "./ico-left-arrow-hover.svg";
import { ReactComponent as LeftArrow } from "./ico-left-arrow.svg";
import { ReactComponent as LoginIcon } from "./loginIcon.svg"; //register icon
import { ReactComponent as LoginIconActive } from "./loginIconActive.svg"; //register icon
import { ReactComponent as MinusQty } from "./minusQty.svg";
import { ReactComponent as MissionIcon } from "./mission-icon.svg";
import { ReactComponent as PassionIcon } from "./passion-icon.svg";
import { ReactComponent as MyAccount } from "./ico-myaccount.svg";
import { ReactComponent as PlayIcon } from "./playIcon.svg";
import { ReactComponent as PhoneIcon } from "./phoneIcon.svg"; //contact us icon
import { ReactComponent as RightArrow } from "./ico-right-arrow.svg";
import { ReactComponent as RemoveAllIcon } from "./remove-all-icon.svg";
import { ReactComponent as RightArrowHover } from "./ico-right-arrow-hover.svg";
import { ReactComponent as OneSelected } from "./1-selected.svg";
import { ReactComponent as PdfIcon } from "./fill-1.svg";
import { ReactComponent as TwoSelected } from "./2-selected.svg";
import { ReactComponent as FourSelected } from "./4-selected.svg";
import { ReactComponent as EightSelected } from "./8-selected.svg";
import { ReactComponent as OneDisabled } from "./1-disabled.svg";
import { ReactComponent as TwoDisabled } from "./2-disabled.svg";
import { ReactComponent as FourDisabled } from "./4-disabled.svg";
import { ReactComponent as EightDisabled } from "./8-disabled.svg";
import { ReactComponent as SuccessMessage } from "./success.svg";
import { ReactComponent as SearchIcon } from "./ico-search.svg";
import { ReactComponent as StorePin } from "./storePin.svg";
import { ReactComponent as TrashIcon } from "./trash.svg";
import { ReactComponent as UploadIcon } from "./uploadIcon.svg";
import { ReactComponent as VideoIcon } from "./video.svg";
import { ReactComponent as WishlistPdp } from "./wishlistPdp.svg";
import { ReactComponent as WishlistPlpEmpty } from "./card-fav-icon.svg";
import { ReactComponent as WishlistPlpFull } from "./fav-icon-active.svg";
import { ReactComponent as QualityIcon } from "./quality-icon.svg";
import { ReactComponent as QuoteIconWhite } from "./quoteIconWhite.svg";
import { ReactComponent as QuoteRequestIcon } from "./ico-quote-request.svg";
import { ReactComponent as FooterLogo } from "./footer_logo_dark.svg";
import { ReactComponent as PreviewInitial } from "./preview_initial.svg";
import { ReactComponent as PreviewHover } from "./preview_hover.svg";

// password icons
import { ReactComponent as VisibilityIcon } from "./visibility.svg";
import { ReactComponent as HiddenIcon } from "./hidden.svg";

const Icon = (props) => {
  const icons = {
    accountIcon: MyAccount,
    arrowLeftBlue: ArrowLeftBlue,
    arrowRightBlue: ArrowRightBlue,
    arrowLeftBlack: ArrowLeftBlack,
    arrowRightBlack: ArrowRightBlack,
    arrowUp: ArrowUp,
    addQty: AddQty,
    arrowUpBlue: ArrowUpBlue,
    addressIcon: AddressIcon,
    addressIconActive: AddressIconActive,
    arrowDownBlue: ArrowDownBlue,
    backArrowIcon: BackArrowIcon,
    enlarge_icon: EnlargeIcon,
    emailIcon: EmailIcon,
    error: ErrorMessage,
    calendar: Calendar,
    checkmarkIcon: CheckmarkIcon,
    compare: Compare,
    companyIcon: CompanyIcon,
    companyIconActive: CompanyIconActive,
    closeIconBlue: CloseIconBlue,
    closeIconWhite: CloseIconWhite,
    closeIconSmall: CloseIconSmall,
    createNewIcon: CreateNewIcon,
    downloadPdfIcon: DownloadPdfIcon,
    downloadSelectedIcon: DownloadSelectedIcon,
    filterBlueIcon: FilterBlueIcon,
    fileIcon: FileIcon,
    icoDesign: IcoDesign,
    leftArrow: LeftArrow,
    leftArrowHover: LeftArrowHover,
    loginIcon: LoginIcon,
    loginIconActive: LoginIconActive,
    minusQty: MinusQty,
    missionIcon: MissionIcon,
    passionIcon: PassionIcon,
    phoneIcon: PhoneIcon,
    playIcon: PlayIcon,
    removeAllIcon: RemoveAllIcon,
    rightArrow: RightArrow,
    rightArrowHover: RightArrowHover,
    oneSelected: OneSelected,
    pdfIcon: PdfIcon,
    twoSelected: TwoSelected,
    fourSelected: FourSelected,
    eightSelected: EightSelected,
    oneDisabled: OneDisabled,
    twoDisabled: TwoDisabled,
    fourDisabled: FourDisabled,
    eightDisabled: EightDisabled,
    searchIcon: SearchIcon,
    success: SuccessMessage,
    storePin: StorePin,
    trashIcon: TrashIcon,
    uploadIcon: UploadIcon,
    videoIcon: VideoIcon,
    wishlistPdp: WishlistPdp,
    wishlistPlpEmpty: WishlistPlpEmpty,
    wishlistPlpFull: WishlistPlpFull,
    qualityIcon: QualityIcon,
    quoteIconWhite: QuoteIconWhite,
    quoteRequestIcon: QuoteRequestIcon,
    footerLogo: FooterLogo,
    previewInitial: PreviewInitial,
    previewHover: PreviewHover,

    // password icons
    visibilty_icon: VisibilityIcon,
    hiddenIcon: HiddenIcon,
  };

  const ReturnIcon = icons[props.icon];
  return icons.hasOwnProperty(props.icon) ? <ReturnIcon {...props} /> : "";
};

export default Icon;
