import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MDBBox, MDBBtn, MDBCol } from "mdbreact";
import ReCAPTCHA from "react-google-recaptcha";
import { useMutation } from "react-apollo-hooks";
import useMessages from "app/state/hooks/messageHooks/useMessages";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import { HOSPITALITY_REQUEST } from "./gql";
import LeftSide from "./leftSide";
import RightSide from "./rightSide";


const ProjectForm = () => {
  const {
    register,
    handleSubmit,
    formState,
    control,
    trigger,
    getValues,
    setValue,
    reset,
  } = useForm();
  const [shipDate, setShipDate] = useState(false);
  const captchaOnChange = () => setIsCaptchaValid(true);
  const [isCaptchaValid, setIsCaptchaValid] = useState(null);
  //used for pre-populating forms
  const { getCustomer } = useCustomer();
  const customer = getCustomer();
  const customerBillingAddress = customer?.addresses?.find((address) => {
    return address.default_billing;
  });

  const { errors } = formState;
  const { addMessage } = useMessages();
  const [submitHospitalityRequest, { loading }] =
    useMutation(HOSPITALITY_REQUEST);

  const onProjectSubmit = (data) => {
    if (!isCaptchaValid || Object.keys(errors).length > 0) {
      return setIsCaptchaValid(false);
    }
    let input = {
      inquiry_type: data.type_of_inquiry?.value,
      industry: data.industry?.value,
      email: data.email,
      firstname: data.firstname,
      lastname: data.lastname,
      phone: data.phone,
      project_name: data.project_name,
      estimated_project_date: `${
        shipDate.getMonth() + 1
      }/${shipDate.getDate()}/${shipDate.getFullYear()}`,
      message: data.questions_or_comments,
    };
    try {
      submitHospitalityRequest({
        variables: {
          input,
        },
      }).then((data) => {
        addMessage(data.data.submitHospitalityRequest, "success");
        reset();
      });
    } catch (error) {
      addMessage(error, "error");
    }
  };
  const typesOfInquiry = [
    { label: "Customer Service", value: "1" },
    { label: "Hospitality", value: "3" },
  ];

  const industries = [
    { label: "Interior Design", value: "1" },
    { label: "Engineering", value: "2" },
    { label: "Hospitality", value: "3" },
    { label: "Construction", value: "4" },
    { label: "Remodeling", value: "5" },
    { label: "Other Personal Projects", value: "6" },
  ];

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setIsCaptchaValid(false);
    } else if (isCaptchaValid) {
      setIsCaptchaValid(true);
    }
  }, [errors]);

  //if customer gets logged out or logged in while on the page
  useEffect(() => {
    return reset();
  }, [customerBillingAddress, reset]);

  return (
    <form id={`project-form`} onSubmit={handleSubmit(onProjectSubmit)}>
      <div className={`row mx-0`}>
        <LeftSide
          control={control}
          trigger={trigger}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          customerBillingAddress={customerBillingAddress}
          typesOfInquiry={typesOfInquiry}
          industries={industries}
          customer={customer}
        />
        <RightSide
          register={register}
          control={control}
          trigger={trigger}
          errors={errors}
          shipDate={shipDate}
          setShipDate={setShipDate}
        />
        <div className="captcha mb-4 w-100">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={captchaOnChange}
            className={``}
          />
          {isCaptchaValid === false && (
            <div className="text-red">reCAPTCHA is required *</div>
          )}
        </div>
      </div>
      <MDBCol className={`submit-button-container`}>
        <MDBBtn
          type={`submit`}
          color="primary"
          className={"btn-with-icon btn-black-text mt-5"}
          outline
          disabled={loading}
          onClick={() => {
            if (shipDate === false) {
              setShipDate(null);
              return;
            }
          }}
          children={
            <>
              <MDBBox tag="span">Submit</MDBBox>
              <MDBBox className="circle-icon" />
            </>
          }
        />
      </MDBCol>
    </form>
  );
};

export default ProjectForm;
