import { MDBBox, MDBBtn } from "mdbreact";
import { memo } from "react";

const DiscoverItemInformation = ({ category }) => {
  return (
    <div className={"mask-container"}>
      <p className={`category-product-count`}>
        {category?.children?.length} Suites
      </p>
      <h5 className={`category-name`}>{category.name}</h5>
      <div className={"btn-container"}>
        <MDBBtn
          color="primary"
          outline
          className={"btn-with-icon btn-white-text w-100"}
          children={
            <>
              <MDBBox tag="span">Explore {category.name}</MDBBox>
              <MDBBox className="circle-icon" />
            </>
          }
        />
      </div>
    </div>
  );
};

export default memo(DiscoverItemInformation);
