import MiniCartItem from "./miniCartItem";
import { MDBRow, MDBCol, MDBBox, MDBTypography } from "mdbreact";
import MiniCartSubtotal from "./miniCartSubtotal";

const MiniCartRender = ({ cart, hideMiniCart, updateCart, lock, subtotal }) => {
  const cartItems = cart?.items;
  return (
    <MDBBox className={`p-4`}>
      <MDBCol size="12">
        <MDBRow className={`miniquote-top montserrat-bold border-bottom pb-4`}>
          <MDBCol size="12">
            <MDBTypography tag="p" className={`text-center montserrat-bold`}>
              Quote Request
            </MDBTypography>
          </MDBCol>
        </MDBRow>
      </MDBCol>
      <MDBCol size="12" className={`miniquote-body`}>
        {cartItems?.map((item) => (
          <MDBRow className="miniquote-item-wrapper border-bottom pt-3 pb-4">
            <MiniCartItem
              key={`miniquote-item-${item.id}`}
              item={item}
              hideMiniCart={hideMiniCart}
              updateCart={updateCart}
              lock={lock}
            />
          </MDBRow>
        ))}
      </MDBCol>
      <MDBCol size="12">
        <MiniCartSubtotal hideMiniCart={hideMiniCart} subtotal={subtotal} />
      </MDBCol>
    </MDBBox>
  );
};

export default MiniCartRender;
