import Header from "./header";
import Modal from "core/components/modal";
import { default as DynamicRouter } from "app/overrides/router";
import { ModalProvider } from "core/components/modal/ModalContext";
import {
  WishlistConsumer,
  WishlistProvider,
} from "./wishlistContext/WishlistContext";
import { DownloadProvider } from "core/components/imageDownloader";
import ModalWrapper from "app/layout/modal/ModalWrapper";
import { SearchSchema } from "./schemas/searchSchema";
import { Helmet } from "react-helmet";
// Global application style
// import "@fortawesome/fontawesome-free/css/all.min.css";

// Import css files;
import { withApollo } from "react-apollo";

import Wishlist from "./wishlist";
import useStore from "app/state/hooks/storeHooks/useStore";
import { MiniCartProvider } from "app/state/hooks/cartHooks/MinicartContext";
import { lazy, Suspense } from "react";
import OfflineBar from "./offline";
// library.add(fab, fas);

const Footer = lazy(() => import("./footer" /*webpackChunkName: "footer"*/));
const SessionMessages = lazy(() =>
  import("app/layout/sessionMessages" /*webpackChunkName: "sessionMessages"*/)
);

// --------------------------------------------------------------------------------------------------------------------------------------------------------
// This is the layout definition for every page on the website, it's content controls how any page will be rendered.
// It is used to bootstrap the header, footer, modals, and misc components, as well as provide a point of insertion for the content of any page based on
// the current URL.
// This file should not be edited unless you are making a change that should effect the layout of all pages
// Keep in mind that this is loaded on every page of the website, so try to keep it and it's children light
// --------------------------------------------------------------------------------------------------------------------------------------------------------

const Layout = (props) => {
  const { getStore, getStoreCode } = useStore();
  let store = getStore();
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {SearchSchema(getStoreCode())}
        </script>
      </Helmet>
      <div className={`${store.store_code} ${store.store_code}-skin`}>
        <ModalProvider {...props}>
          <DownloadProvider>
            <MiniCartProvider>
              <WishlistProvider>
                <OfflineBar />
                <Header />
                <div className={`session-container`}>
                  <Suspense fallback={<></>}>
                    <SessionMessages target="global" /> {/* SESSION MESSAGES */}
                  </Suspense>
                </div>
                <div id={`main`} className={"main"}>
                  <DynamicRouter /> {/* CONTENT */}
                </div>
                <Modal ModalWrapper={ModalWrapper} />
                <Suspense fallback={<></>}>
                  <Footer />
                </Suspense>
                <WishlistConsumer>
                  {(wishlistProps) => {
                    return <Wishlist {...wishlistProps} />;
                  }}
                </WishlistConsumer>
              </WishlistProvider>
              {/* <DownloadToolbar
            downloadBarContainerClass={`white py-4 border-top border-default z-index-1`}
            modalBodyClass={`col py-0 px-3`}
            downloadImageCancelButtonOutline={true}
            downloadImageCancelButtonColor={`primary`}
          /> */}
            </MiniCartProvider>
          </DownloadProvider>
        </ModalProvider>
      </div>
    </>
  );
};

export default withApollo(Layout);
