import { MDBContainer, MDBRow, MDBTypography, MDBBox, MDBBtn } from "mdbreact";
import { useCallback } from "react";
import CmsBlock from "core/components/cmsBlock";
import SeoHelmet from "app/layout/seoHelmet";

const OfflinePage = (props) => {
  const retry = useCallback(() => {
    props.refetch();
  }, [props]);
  return (
    <MDBBox className={`offline-page-wrapper`}>
      <SeoHelmet url={`/offline-page`} title={`Offline Page`} />
      <MDBContainer fluid>
        <MDBRow>
          <CmsBlock dangHtml={true} id="offline-page-banner" />
        </MDBRow>
      </MDBContainer>
      <MDBContainer fluid>
        <MDBRow className={`mb-40 offline-title-wrapper`}>
          <MDBBox className="title-with-background w-100">
            <MDBContainer>
              <MDBTypography tag="h1" className="page-title">
                Content Can’t be Loaded
              </MDBTypography>
            </MDBContainer>
          </MDBBox>
        </MDBRow>
        <MDBRow className={`mb-100 offline-text-wrapper`}>
          <MDBContainer>
            <MDBTypography tag="h3" className="mb-4">
              Sorry, you are not connected to internet and this page can not
              load.
            </MDBTypography>
            <MDBBox className="list-wrapper-err-page">
              <MDBTypography tag="p" className="mb-5">
                Let’s see if we can figure out what went wrong.
              </MDBTypography>
              <ul className={`mb-5 custom-list`}>
                <li className={`mb-3`}>
                  Check your connectivity and reconnect to the internet
                </li>
                <li>Select a different internet connection</li>
              </ul>
            </MDBBox>
            <MDBBtn
              color="primary"
              outline
              href="/"
              onClick={retry}
              className="btn-with-icon btn-black-text"
              children={
                <>
                  <MDBBox tag="span">Homepage</MDBBox>
                  <MDBBox className="circle-icon" />
                </>
              }
            />
          </MDBContainer>
        </MDBRow>
      </MDBContainer>
    </MDBBox>
  );
};

export default OfflinePage;
