import { memo } from "react";
import MenuStateContainer from "./menu-state-container";

const MenuDataContainer = memo((props) => {
  let data = props.menu ? props.menu : false;

  return <MenuStateContainer {...props} data={data} />;
});

export default MenuDataContainer;
