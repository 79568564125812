import useGetProductImagesByFilter from "core/components/imageDownloader/hooks/useGetProductImagesByFilter";
import { MDBRow } from "mdbreact";
import React from "react";
import Image from "./image";
import Skeleton from "./skeleton";

/**
 * Component used for retrieving product images based on a filter defined (right now the filter is by sku)
 * @param {*} sku Product sku used for retrieving product images
 * @param {*} imageSelection State of image selection
 * @param {function} toggleImage Toggle function to set or unset image selection
 */
const Images = ({ sku, imageSelection, toggleImage }) => {
  let filters = { sku: { eq: sku } };
  const { formattedData, error } = useGetProductImagesByFilter(filters);

  const columnProps = { lg: `4`, md: `6`, xs: `12` };

  return (
    <MDBRow>
      {formattedData !== undefined && !error ? (
        formattedData.map((image, index) => {
          let selected = imageSelection.find(
            (_image) => _image.url === image.url
          );
          return (
            <Image
              key={`download-image-${index}-${image.id} `}
              image={image}
              index={index}
              selected={selected}
              toggleImage={toggleImage}
              columnProps={columnProps}
            />
          );
        })
      ) : !error ? (
        <>
          <Skeleton columnProps={columnProps} />
        </>
      ) : (
        <>
          We are unable to load the images. <br /> Please try again later.
        </>
      )}
    </MDBRow>
  );
};

export default Images;
