import Icon from "app/assets/icon/icon";
import { generateRequestForAddingOrRemovingFileForDownload } from "core/components/imageDownloader/functions";
import useFilesForDownload from "core/components/imageDownloader/hooks/useFilesForDownload";
import { ImageDownloadIcon } from "core/components/imageDownloader/icon";
import { MDBBox, MDBCol, MDBRow, MDBTooltip } from "mdbreact";
import React, { useState } from "react";
import Modal from "./modal";

const Item = ({ thisKey, data, refetch }) => {
  const [modal, setModal] = useState(false);
  const { addFilesForDownload } = useFilesForDownload();
  const toggleModal = () => {
    setModal(!modal);
  };
  const group_id = JSON.parse(data.group_id);
  const sku = group_id.sku;
  const name = group_id.name;

  return (
    <MDBCol
      key={`listing-item-${thisKey}-${data.file_url}`}
      className={`my-downloads-listing-item`}
      lg={"3"}
      md={"6"}
      xs={"12"}
    >
      <MDBRow className={`flex-column`}>
        <MDBCol>
          <img
            src={`${data.file_url}`}
            alt={`Item ${data.file_url}`}
            style={{ minHeight: `265px` }}
          />

          <ImageDownloadIcon
            imageData={data.imageData}
            image={data.file_url}
            sku={sku}
            type={data.type}
            icon={"downloadPlp"}
            addedIcon={"downloadBlack"}
            useWrapper={true}
            selectYourImages={true}
            selectYourImagesToggle={toggleModal}
          />
          {data.type === "PRODUCT" ? (
            <Modal
              data={data}
              sku={sku}
              modal={modal}
              toggleModal={toggleModal}
              refetch={refetch}
            />
          ) : (
            <></>
          )}
        </MDBCol>
        <MDBCol className={`my-downloads-item-information pt-4`}>
          <MDBRow className={`justify-content-between`}>
            <MDBCol size={`9`}>{name}</MDBCol>
            <MDBCol size={`3`} className={`text-right`}>
              <MDBBox
                role={`button`}
                onClick={(e) => {
                  let removeFileData = generateRequestForAddingOrRemovingFileForDownload(
                    data,
                    "YES"
                  );
                  addFilesForDownload(removeFileData).then(() => {
                    refetch().then();
                  });
                }}
              >
                <MDBTooltip domElement tag="span" placement="bottom">
                  <div>
                    <Icon role={`button`} icon={`trashBig`} />
                  </div>
                  <span>Remove</span>
                </MDBTooltip>
              </MDBBox>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBCol>
  );
};

export default Item;
