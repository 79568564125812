export default (state, action) => {
  if (action.payload === null) {
    return state;
  }
  let wishlistItems = [...state.data.wishlistItems];

  wishlistItems.push(action.payload);

  return Object.assign({}, state, {
    ...state,
    data: {
      ...state.data,
      wishlistItems: [...wishlistItems],
    },
  });
};
