import { MDBBox } from "mdbreact";
// import "./imageContainer.scss";

const ImageContainer = (props) => {
  return (
    <>
      {(props.imgContainer && (
        <MDBBox className={props.imgContainer} src={props.src}>
          {props.children}
        </MDBBox>
      )) ||
        props.children}
    </>
  );
};

export default ImageContainer;
