import { isNaN } from "lodash";
export const updateCartItemFn = (
  item,
  value,
  updateCart,
  lock,
  quantity,
  setQuantity,
  setLoading
) => {
  if (value < 1 || value === "" || isNaN(value)) {
    lock({
      always: () => {},
      success: () => {
        setLoading(false);
        setQuantity(1);
      },
      fail: () => {
        setLoading(false);
      },
    });
    updateCart([
      {
        cart_item_id: item.id,
        customizable_options: [],
        quantity: 1,
      },
    ]);
    return;
  } else {
    lock({
      always: () => {},
      success: () => {
        setLoading && setLoading(false);
        setQuantity(value);
      },
      fail: () => {
        setLoading && setLoading(false);
      },
    });

    updateCart([
      {
        cart_item_id: item.id,
        customizable_options: [],
        quantity: value === 1 ? 1 : value,
      },
    ]);
  }
};
