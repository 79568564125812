import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import useGenerated from "app/state/hooks/generatedHooks";
import useStore from "app/state/hooks/storeHooks/useStore";
import SalesRepWidget from "app/layout/salesRepWidget";

const NoResults = () => {
  const { GetCmsBlocks } = useGenerated();
  const noResults = GetCmsBlocks("no_results_find_rep");
  const { store } = useStore();
  const accountSpecialist = store.default_rep_locator;

  return (
    <MDBContainer className={`find-rep-content find-rep-results no-results`}>
      <MDBRow
        className={`text-block  align-items-center justify-content-center mb-5 mx-0`}
      >
        <MDBCol>{noResults}</MDBCol>
      </MDBRow>
      <SalesRepWidget salesRep={accountSpecialist} />
    </MDBContainer>
  );
};

export default NoResults;
