import SeoHelmet from "app/layout/seoHelmet";
import AccountInformation from "./accountinformation";
import Addressbook from "../addressbook";

const Dashboard = (props) => {
  return (
    <div className="account-dashboard-container mb-5">
      <SeoHelmet url={`/customer`} title={`Account Dashboard`} />
      <div className="account-dashboard">
        <AccountInformation {...props} />
        <Addressbook dashboardLayout={true} />
      </div>
    </div>
  );
};

export default Dashboard;
