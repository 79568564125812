import { Link } from "react-router-dom";
import { ModalConsumer } from "core/components/modal/ModalContext";
import Login from "app/pages/customer/login";
import Icon from "app/assets/icon/icon";
// import useCustomer from "app/state/hooks/customerHooks/useCustomer";

const CustomerAccountActions = ({ closeBurger, token }) => {
  // const { logoutCustomer } = useCustomer();

  return (
    <>
      {token ? (
        <div className="logged-in d-flex flex-row">
          <Icon icon="account_icon_white" />
          <div className={`row`}>
            <div className={`col`}>
              <Link to={"/customer"} onClick={() => closeBurger()}>
                <Icon icon="accountIcon" />
              </Link>
            </div>
            {/* <div className="col d-flex justify-content-end">
              <span
                className="sign-out-btn"
                onClick={() => {
                  logoutCustomer();
                  closeBurger();
                }}
              >
                Sign Out
              </span>
            </div> */}
          </div>
        </div>
      ) : (
        <ModalConsumer value={{ useWrapper: false }}>
          {({ showModal, hideModal }) => (
            <div className="menu-toggle mobile-login">
              <p className="logged-out d-flex flex-row justify-content-center align-items-center">
                <span
                  onClick={() => {
                    showModal(() => {
                      return (
                        <Login
                          hideModal={hideModal}
                          closeBurger={closeBurger}
                        />
                      );
                    });
                    !token && closeBurger();
                  }}
                >
                  <div>
                    <Icon icon="accountIcon" />
                  </div>
                </span>
              </p>
            </div>
          )}
        </ModalConsumer>
      )}
    </>
  );
};
export default CustomerAccountActions;
