import { connect } from "react-redux";
import customerActions from "app/state/redux/data/customer/actions";
import { commonActions } from "app/state/redux/data/common";
import CustomerInformationFormStateContainer from "./information-state-container";

const CustomerLoginFormReduxContainer = (props) => {
  return <CustomerInformationFormStateContainer {...props} />;
};

const mapStateToProps = (state) => ({
  loading: state.customer.loading,
  locked: state.common.locked,
});

const mapDispatchToProps = (dispatch) => {
  return {
    login: (request) => {
      dispatch(customerActions.loginCustomer(request.email, request.password));
    },
    onSubmit: (request, callback) => {
      dispatch(customerActions.updateCustomerInformation(request, callback));
    },
    onSubmitCallback: () => {},
    unlock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerLoginFormReduxContainer);
