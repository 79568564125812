export default (state, action) => {
  let payload =
    action.payload === null
      ? typeof state.toggleMinicart !== "undefined"
        ? !state.toggleMinicart
        : true
      : action.payload;

  return Object.assign({}, state, {
    ...state,
    toggleMinicart:
      typeof state.toggleMinicart !== "undefined" && action.payload === null
        ? !state.toggleMinicart
        : payload,
  });
};
