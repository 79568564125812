// Middleware
import types from "./types";
import getCacheVersion from "./middleware/getCacheVersion";

const cacheMiddleware = (store) => {
  return (next) => {
    return async (action) => {
      next(action);
      switch (action.type) {
        // case types.GET_CACHE_VERSION:
        //   return getCacheVersion(store, action);

        default:
          return;
      }
    };
  };
};

export default cacheMiddleware;
