import { useState, useEffect, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import InputField from "core/components/inputField";
import Button from "core/components/button/button";
import CheckButton from "core/components/checkButton";
import Select2 from "app/layout/select";
import RadioButtons from "app/layout/radioButtons";
import { tearsheetMarkupOptions } from "config/data/customProjectData";

const TearsheetForm = ({
  setPdfData,
  generateDownloadLinkAndOpen,
  toggle,
  customer,
}) => {
  const { handleSubmit, formState, control, trigger } = useForm();
  const { errors } = formState;
  const [pricingOption, setPricingOption] = useState(tearsheetMarkupOptions[0]);

  const [count, setCount] = useState(0);

  useEffect(() => {
    if (pricingOption !== "custom_pricing") trigger("custom_pricing");
  }, [pricingOption]);

  const onHandleSubmit = useCallback((data) => {
    setPdfData(data);
    setTimeout(() => {
      generateDownloadLinkAndOpen();
    }, 500);
  }, []);

  return (
    <form id={`download-tearsheet`} onSubmit={handleSubmit(onHandleSubmit)}>
      <p className={`font-weight-bold`}>Select an option below</p>
      {customer?.show_price !== false && (
        <Controller
          control={control}
          name="pricing_option"
          defaultValue={"wholesale_pricing"}
          rules={{ required: true }}
          render={({ onChange, value, name, ref }) => (
            <RadioButtons
              innerRef={ref}
              groupName={name}
              checked={value}
              setChecked={(e) => {
                onChange(e);
                setPricingOption(e);
              }}
              options={[
                { value: "hide_pricing", label: "Display MSRP" },
                {
                  value: "wholesale_pricing",
                  label: "Display Wholesale Pricing",
                },
                {
                  value: "markup_pricing",
                  label: (
                    <div className={`label-container`}>
                      <p>Markup Wholesale Pricing</p>
                      <Controller
                        control={control}
                        name="markup_pricing"
                        defaultValue={tearsheetMarkupOptions[0]}
                        rules={{
                          required:
                            pricingOption === "markup_pricing" ? true : false,
                        }}
                        render={({ onChange, value, name, ref }) => (
                          <Select2
                            isSearchable={false}
                            wrapperClass={`inner-select`}
                            onBlur={() => trigger(name)}
                            innerRef={ref}
                            name={name}
                            value={value}
                            // menuIsOpen={true}
                            onChange={onChange}
                            filled={value && value.length !== 0 ? true : false}
                            options={tearsheetMarkupOptions}
                            placeholder={"Enter Markup"}
                          />
                        )}
                      />
                      <p> Times</p>
                    </div>
                  ),
                },
                {
                  value: "custom_pricing",
                  label: (
                    <div className={`label-container custom-pricng`}>
                      <p>Custom Pricing</p>
                      <Controller
                        control={control}
                        name="custom_pricing"
                        defaultValue={""}
                        rules={{
                          required:
                            pricingOption === "custom_pricing" ? true : false,
                        }}
                        render={({ onChange, value, name, ref }) => (
                          <div className="position-relative">
                            <InputField
                              type={"number"}
                              wrapperClass={`inner-input`}
                              onBlur={() => trigger(name)}
                              innerRef={ref}
                              name={name}
                              disabled={pricingOption !== "custom_pricing"}
                              value={value}
                              onChange={onChange}
                              style={{
                                opacity:
                                  pricingOption !== "custom_pricing" && 0.5,
                              }}
                              filled={
                                value && value.length !== 0 ? true : false
                              }
                              placeholder={"Enter Pricing"}
                              className={`custom-input ${
                                errors[name] ? "validation-error" : ""
                              }`}
                            />
                            <span className="dolar-sign">$</span>
                          </div>
                        )}
                      />
                    </div>
                  ),
                },
              ]}
            />
          )}
        />
      )}

      <Controller
        control={control}
        name="remarks"
        defaultValue={""}
        render={({ onChange, value, name, ref }) => (
          <div className="position-relative">
            <InputField
              type={`textarea`}
              onBlur={() => trigger(name)}
              innerRef={ref}
              name={name}
              label={`Notes`}
              value={value}
              onChange={(e) => {
                setCount(e.target.value.length);
                onChange(e);
              }}
              filled={value && value.length !== 0 ? true : false}
              placeholder={"Enter notes here"}
              maxLength={100}
            />
            <p className="limit">{`Characters: ${count}/150`}</p>
          </div>
        )}
      />
      <div className={`buttons-container`}>
        <Button children={`Download`} color={`primary`} type={`submit`} />
      </div>
    </form>
  );
};

export default TearsheetForm;
