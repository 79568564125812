// define project specific reducers
// import testReducer from "./data/test";
import commonReducer from "./data/common";
import filtersReducer from "./data/filters";
import customerReducer from "./data/customer";
import wishlistReducer from "./data/wishlist";
import cacheReducer from "./data/cache";
import storeReducer from "./data/store";
import applicationReducer from "./data/application";
import compare from "./data/compare/reducer";

const projectReducers = {
  // test: testReducer
  common: commonReducer,
  filters: filtersReducer,
  customer: customerReducer,
  wishlist: wishlistReducer,
  cache: cacheReducer,
  store: storeReducer,
  application: applicationReducer,
  compare: compare,
};

export default projectReducers;
