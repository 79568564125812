import Select, { components } from "react-select";
import Icon from "app/assets/icon/icon";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {(props.selectProps.menuIsOpen && <Icon icon={`arrowDown`} />) || (
        <Icon icon={`arrowDown`} />
      )}
    </components.DropdownIndicator>
  );
};
const indicatorSeparatorStyle = {
  width: 0,
};
const IndicatorSeparator = ({ innerProps }) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};
const Select2 = (props) => {
  return (
    <div
      className={`select2 ` + props.wrapperClass}
      title={props.disabled && "Field is disabled."}
    >
      {props.label && props.label !== `undefined` && (
        <p className={`select-label`}>{props.label}</p>
      )}
      <Select
        ref={props.innerRef}
        innerRef={props.innerRef}
        isMulti={props.isMulti}
        name={props.name}
        type={props.type}
        icon={props.icon}
        value={props.value}
        defaultInputValue={props.defaultInputValue}
        onChange={(event) => props.onChange(event)}
        onBlur={(event) => {
          if (typeof props.onBlur !== "undefined") {
            props.onBlur(event);
          }
        }}
        onClick={(event) => {
          if (typeof props.onClick !== "undefined") {
            props.onClick(event);
          }
        }}
        required={props.required}
        placeholder={props.placeholder}
        options={props.options}
        useCustom={props.useCustom}
        className={`react-select-container ${props.className} ${
          props.filled && "filled-select"
        }`}
        classNamePrefix={`react-select`}
        menuIsOpen={props.menuIsOpen}
        maxMenuHeight={230}
        isSearchable={props.isSearchable}
        components={{ DropdownIndicator, IndicatorSeparator }}
        isDisabled={props.isDisabled}
        styles={props.styles}
      />
    </div>
  );
};

export default Select2;
