import { useEffect } from "react";
import RightContainer from "./right";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";

const RightCartContainer = (props) => {
  let { getCartItems } = props;
  const { getCustomer, logoutCustomer } = useCustomer();
  const customer = getCustomer();
  const token = customer.token;
  useEffect(() => {
    getCartItems();
  }, [getCartItems, token]);
  return (
    <RightContainer
      {...props}
      customer={customer}
      token={token}
      logoutCustomer={logoutCustomer}
    />
  );
};

export default RightCartContainer;
