import { MDBCol, MDBTypography, MDBBox, MDBRow } from "mdbreact";
import ProductPrice from "../productPrice";

const Heading = ({
  data,
  isMobile,
  storeCode,
  isTablet,
  mediaGallery,
  customer,
  activeGalleryImage,
  setActiveGalleryImage,
  productVariant,
  loggedIn,
}) => {
  if (!mediaGallery) return <></>;
  const { name, sku, stock_status, overall_size } = data;

  return (
    <MDBRow className={`pdp-heading ${storeCode}`}>
      <MDBCol sm="6" className={`product-size`}>
        <MDBBox className={`product-sku-size`}>
          <MDBTypography
            tag="h3"
            className={`text-capitalize font-weight-bold mb-3
              ${(!isMobile && "pr-4") || "pr-2"}`}
          >
            {name}
          </MDBTypography>

          <MDBTypography
            tag="p"
            className={`text-uppercase
      ${(!isMobile && "pr-4") || "pr-2"}`}
          >
            ITEM# {productVariant?.product?.sku}
          </MDBTypography>
          <MDBTypography
            tag="p"
            className={`text-uppercase ${(!isMobile && "pl-4") || "pl-2"}`}
          >
            {overall_size}
          </MDBTypography>
        </MDBBox>
        <MDBBox className="d-flex align-items-center">
          {loggedIn && customer?.show_price !== false ? (
            <>
              <ProductPrice
                product={productVariant?.product}
                storeCode={storeCode}
              />
            </>
          ) : (
            <></>
          )}
          {loggedIn && productVariant?.product?.msrp?.value ? (
            <MDBTypography tag="p" className="msrp">
              ${productVariant?.product?.msrp?.value.toFixed(2)} MSRP
            </MDBTypography>
          ) : null}
        </MDBBox>
      </MDBCol>
      <MDBCol
        sm="6"
        className={`d-flex flex-column ${
          !isMobile && "justify-content-start"
        } ${!isMobile ? "align-items-end" : "align-items-start"} ${
          isMobile && "mt-4 mb-4"
        }`}
      >
        <div className="gallery-wrapper">
          {mediaGallery
            ?.filter((item) => !item.url.toLowerCase().includes("ingr"))
            ?.map((media, index) => (
              <img
                onClick={() => setActiveGalleryImage(index + 1)}
                src={media.url}
                alt={`media-galery-image-${index}`}
                className={`media-gallery-image-${index} ${
                  activeGalleryImage === index + 1 ? "active" : ""
                }  `}
              />
            ))}
        </div>

        {loggedIn && customer?.show_price !== false && (
          <MDBTypography tag="p">
            {stock_status === "IN_STOCK" ? "In Stock" : " Out of Stock"}
          </MDBTypography>
        )}
      </MDBCol>
    </MDBRow>
  );
};
export default Heading;
