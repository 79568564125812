import Price from "../price";

const PdfProductDetails = ({
  product,
  pricing,
  selectedSwatch,
  selectedSize,
  customer,
  productVariant,
  remarks,
}) => {
  const colors = product.variants.reduce((acc, cur) => {
    return [
      ...acc,
      cur.attributes.find((attribute) => attribute.code === "color")?.label,
    ];
  }, []);
  const sizes = product.variants.reduce((acc, cur) => {
    return [
      ...acc,
      cur.attributes.find((attribute) => attribute.code !== "color")?.label,
    ];
  }, []);

  return (
    <div className={`pdf-product-details`}>
      <div className={`image-container`}>
        <img src={product?.thumbnail?.url} alt={`product`} />
      </div>
      <div className={`info-container`} className={``}>
        <h3 className="pdf-product-heading mb-2">{product.name}</h3>
        <div className="d-flex justify-content-between flex-column mb-2">
          <div className={`sku-size-container d-flex`}>
            <p className={`sku mr-3 flex-1`}>
              ITEM# {productVariant?.product?.sku}
            </p>
            <div className={`d-flex align-items-center flex-1 ml-3 `}>
              {pricing?.pricing_option !== "hide_pricing" &&
              customer?.show_price !== false ? (
                <Price product={product} pricingOptions={pricing} />
              ) : productVariant?.product?.msrp?.value ? (
                <p
                  className={`ml-2 msrp`}
                >{`$${productVariant?.product?.msrp?.value?.toFixed(
                  2
                )} MSRP`}</p>
              ) : null}
            </div>
          </div>
          <div className={`d-flex justify-content-between mb-2`}>
            <p className={`text-capitalize flex-1`}>{selectedSwatch?.label}</p>
            <p className={`flex-1 ml-3 mt-2`}>
              {selectedSize ?? productVariant?.attributes?.[1]?.label}
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-between mb-2">
          <div className="flex-1">
            {product?.description?.html && (
              <div className={`overview mb-2`}>
                <h3 className="pdf-product-heading mb-2">
                  Product Description
                </h3>
                <p className={`mb-2`}>
                  {product.description.html.substring(0, 150)}
                </p>
                <span className={`read-more`}>Read More Online</span>
              </div>
            )}
          </div>
          <div className="flex-1 ml-3">
            {colors?.length || sizes.length ? (
              <div className={`overview mb-2 `}>
                <h3 className="pdf-product-heading mb-2">Also Available in</h3>
                <div className="d-flex mb-2">
                  {colors
                    ?.filter(function (value, index, array) {
                      return array.indexOf(value) === index;
                    })
                    .map(
                      (color) =>
                        color && (
                          <div
                            className="d-flex text-capitalize mr-2"
                            key={color}
                          >
                            {`${color},`}
                          </div>
                        )
                    )}
                </div>
                <div className="d-flex">
                  {sizes
                    ?.filter(function (value, index, array) {
                      return array.indexOf(value) === index;
                    })
                    .map(
                      (size, i) =>
                        size && (
                          <div
                            className="d-flex text-capitalize mr-2"
                            key={size}
                          >
                            {`${size}, `}
                          </div>
                        )
                    )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {remarks && (
          <div className={`mt-3 d-flex align-items-center remarks-section`}>
            <h3 className="pdf-product-heading mr-2 mb-0">Notes:</h3>{" "}
            <p className="mb-0">{remarks}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PdfProductDetails;
