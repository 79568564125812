import { gql } from "graphql.macro";

export const GET_CUSTOMER_IMAGES = gql`
  query getAllFilesForDownload {
    getAllFilesForDownload {
      file_url
      group_id
      customer_id
      is_primary
      created_at
      type
    }
  }
`;

export const ADD_FILES_FOR_DOWNLOAD = gql`
  mutation addFilesForDownload($input: [FilesForDownloadInput]) {
    addFilesForDownload(input: $input) {
      created_at
      customer_id
      file_url
      group_id
      is_primary
      type
    }
  }
`;

export const GET_PRODUCT_IMAGES = gql`
  query getProductImages($filter: ProductAttributeFilterInput) {
    products(filter: $filter) {
      items {
        id
        sku
        name
        media_gallery {
          disabled
          id
          url
          label
          position
          __typename
        }
      }
    }
  }
`;
