import SeoHelmet from "app/layout/seoHelmet";
import { isEqual } from "lodash";
import { memo } from "react";

const ProductSEO = memo(
  ({ product }) => {
    return (
      <SeoHelmet
        title={product?.name}
        url={product?.url_key}
        description={product?.description?.html}
        image={product?.image?.url}
      />
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }
    return false;
  }
);

export default ProductSEO;
