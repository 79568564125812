import { gql } from "graphql.macro";

export const SET_GUEST_EMAIL_ON_CART_MUTATION = gql`
  mutation SetGuestEmailOnCart($input: SetGuestEmailOnCartInput) {
    setGuestEmailOnCart(input: $input) {
      cart {
        id
        # ship_complete
        prices {
          subtotal_including_tax {
            currency
            value
          }
          subtotal_excluding_tax {
            currency
            value
          }
          fee {
            currency
            value
          }
          grand_total {
            currency
            value
          }
          applied_taxes {
            amount {
              currency
              value
            }
            label
          }
          discounts {
            amount {
              currency
              value
            }
            label
          }
        }
        available_payment_methods {
          code
          title
          terms
        }

        items {
          id
          prices {
            discounts {
              amount {
                currency
                value
              }
              label
            }
            price {
              currency
              value
            }
            row_total {
              currency
              value
            }
            row_total_including_tax {
              currency
              value
            }
            total_item_discount {
              currency
              value
            }
          }

          ... on SimpleCartItem {
            customizable_options {
              label
              values {
                label
                value
                price {
                  type
                  units
                  value
                }
              }
            }
          }
          product {
            url_key
            name
            sku
            small_image {
              url
            }
            size
            price_range {
              minimum_price {
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  currency
                  value
                }
                fixed_product_taxes {
                  amount {
                    currency
                    value
                  }
                  label
                }
                regular_price {
                  currency
                  value
                }
              }
              maximum_price {
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  currency
                  value
                }
                fixed_product_taxes {
                  amount {
                    currency
                    value
                  }
                  label
                }
                regular_price {
                  currency
                  value
                }
              }
            }
          }
          quantity
        }
        billing_address {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          street
          telephone
        }
        shipping_addresses {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          street
          telephone
          region {
            code
            label
            region_id
          }
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            carrier_code
            carrier_title
            error_message
            method_code
            method_title
            price_excl_tax {
              currency
              value
            }

            price_incl_tax {
              currency
              value
            }
          }
          selected_shipping_method {
            amount {
              currency
              value
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
        }
        selected_payment_method {
          title
          purchase_order_number
          code
        }
        billing_address {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          street
          telephone
        }
        email
        applied_coupons {
          code
        }
      }
    }
  }
`;

export const SET_SHIPPING_ADDRESSES_ON_CART = gql`
  mutation SetShippingAddressesOnCart($input: SetShippingAddressesOnCartInput) {
    setShippingAddressesOnCart(input: $input) {
      cart {
        id
        # ship_complete
        prices {
          subtotal_including_tax {
            currency
            value
          }
          subtotal_excluding_tax {
            currency
            value
          }
          fee {
            currency
            value
          }
          grand_total {
            currency
            value
          }
          applied_taxes {
            amount {
              currency
              value
            }
            label
          }
          discounts {
            amount {
              value
              currency
            }
            label
          }
        }
        available_payment_methods {
          code
          title
          # terms
        }

        items {
          id
          prices {
            discounts {
              amount {
                currency
                value
              }
              label
            }
            price {
              currency
              value
            }
            row_total {
              currency
              value
            }
            row_total_including_tax {
              currency
              value
            }
            total_item_discount {
              currency
              value
            }
          }
        }

        shipping_addresses {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          street
          telephone
          company
          region {
            code
            label
            region_id
          }
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            carrier_code
            carrier_title
            error_message
            method_code
            method_title
            price_excl_tax {
              currency
              value
            }

            price_incl_tax {
              currency
              value
            }
          }
          selected_shipping_method {
            amount {
              currency
              value
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
        }
      }
    }
  }
`;

export const SET_BILLING_ADDRESS_ON_CART = gql`
  mutation SetBillingAddressOnCart($input: SetBillingAddressOnCartInput) {
    setBillingAddressOnCart(input: $input) {
      cart {
        id
        # ship_complete
        prices {
          subtotal_including_tax {
            currency
            value
          }
          subtotal_excluding_tax {
            currency
            value
          }
          fee {
            currency
            value
          }
          grand_total {
            currency
            value
          }
          applied_taxes {
            amount {
              currency
              value
            }
            label
          }
          discounts {
            amount {
              currency
              value
            }
            label
          }
        }
        billing_address {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          street
          telephone
          company
          region {
            code
            label
            region_id
          }
        }
      }
    }
  }
`;
export const SET_BILLING_ADDRESS_ON_CART_WITH_SHIPPING = gql`
  mutation SetBillingAddressOnCart($input: SetBillingAddressOnCartInput) {
    setBillingAddressOnCart(input: $input) {
      cart {
        id
        # ship_complete
        prices {
          subtotal_including_tax {
            currency
            value
          }
          subtotal_excluding_tax {
            currency
            value
          }
          fee {
            currency
            value
          }
          grand_total {
            currency
            value
          }
          applied_taxes {
            amount {
              currency
              value
            }
            label
          }
          discounts {
            amount {
              currency
              value
            }
            label
          }
        }
        billing_address {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          street
          telephone
          company
          region {
            code
            label
            region_id
          }
        }
        shipping_addresses {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          street
          telephone
          company
          region {
            code
            label
            region_id
          }
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            carrier_code
            carrier_title
            error_message
            method_code
            method_title
            price_excl_tax {
              currency
              value
            }

            price_incl_tax {
              currency
              value
            }
          }
          selected_shipping_method {
            amount {
              currency
              value
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
        }
      }
    }
  }
`;

export const MINIMAL_ORDER = gql`
  query {
    isMinimalOrderFee {
      added
      response
      can_checkout
    }
  }
`;
