/**
 * Redux template file, used to export reducers, middlewares, inital state and action/creators.
 *
 * Compare Redux
 **/

import compareReducer from "./reducer";
export { default as compareTypes } from "./types";
export { default as compareActions } from "./actions";
export { default as compareInitialState } from "./initialState";
export default compareReducer;
