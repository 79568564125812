import { MDBNavLink, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { default as AccountLinks } from "app/config/routing/pages/customerPages";

const CustomerAccountDropdownMenu = (props) => {
  return (
    <MDBDropdownMenu basic className={`my-account-dropdown`}>
      {AccountLinks &&
        Object.keys(AccountLinks).map((value, index) => {
          let accountLink = AccountLinks[value];
          return (
            accountLink.inDropdown && (
              <MDBDropdownItem key={index}>
                {(accountLink.url !== "logout" && (
                  <MDBNavLink
                    className={`text-decoration-none`}
                    to={`${
                      (accountLink.url !== "logout" && "/" + accountLink.url) ||
                      ""
                    }`}
                  >
                    {accountLink.dropdownText}
                  </MDBNavLink>
                )) || (
                  <p
                    onClick={(e) => {
                      props.logoutCustomer();
                    }}
                    className={` sign-out `}
                  >
                    {accountLink.dropdownText}
                  </p>
                )}
              </MDBDropdownItem>
            )
          );
        })}
    </MDBDropdownMenu>
  );
};
export default CustomerAccountDropdownMenu;
