import { useState, useCallback, useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { ModalContext } from "core/components/modal/ModalContext";
import useGenerated from "app/state/hooks/generatedHooks";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import ProductDetailsDesktop from "./productDetailsDesktop";
import ProductDetailsMobile from "./productDetailsMobile";

const ProductDetails = ({
  data,
  storeCode,
  productDetailsImage,
  selectedSwatch,
  selectedSize,
  toggleSidebarMenu,
  productVariant,
}) => {
  let isMobile = useMediaQuery({ maxWidth: 992 });
  const pdpSidebarImage = productVariant?.product?.media_gallery.find((media) =>
    media.url.toLowerCase().includes(".topp")
  );
  const [descriptionLength, setDescriptionLength] = useState("short");
  const context = useContext(ModalContext);
  let { isLoggedIn } = useCustomer();
  let token = isLoggedIn();
  const [attributes, setAttributes] = useState([
    {
      title: "Specifications",
      icon: true,
      text: "View",
    },
    {
      title: "Tear Sheets",
      icon: true,
      text: "",
    },
    {
      title: "Installations",
      icon: false,
      text: "Visit our Resources Page for Installation Guides",
    },
  ]);

  useEffect(() => {
    let oldAttributes = [...attributes];
    if (!token) {
      setAttributes(
        oldAttributes.filter((attr) => attr.title !== "Tear Sheets")
      );
    } else if (!oldAttributes.find((attr) => attr.title === "Tear Sheets")) {
      oldAttributes.splice(1, 0, {
        title: "Tear Sheets",
        icon: true,
        text: "",
      });
      setAttributes(oldAttributes);
    }
  }, [token]);

  const getExcerpt = useCallback(
    (desc, size) => {
      if (size === "short" && desc) {
        return `${desc.substring(0, 100)}...`;
      }
      return desc;
    },
    [data, descriptionLength]
  );

  const { GetGalleries } = useGenerated();
  const galleries = GetGalleries();
  let productGalleries = [];

  Object.entries(galleries).forEach(([key, gallery]) => {
    if (gallery.name === data.sku) productGalleries.push(gallery);
  });

  return (
    <>
      {!isMobile ? (
        <ProductDetailsDesktop
          data={data}
          storeCode={storeCode}
          productDetailsImage={productDetailsImage}
          attributes={attributes}
          descriptionLength={descriptionLength}
          setDescriptionLength={setDescriptionLength}
          getExcerpt={getExcerpt}
          context={context}
          selectedSize={selectedSize}
          selectedSwatch={selectedSwatch}
          galleries={galleries}
          pdpSidebarImage={pdpSidebarImage}
          productVariant={productVariant}
        />
      ) : (
        <ProductDetailsMobile
          data={data}
          storeCode={storeCode}
          productDetailsImage={productDetailsImage}
          attributes={attributes}
          descriptionLength={descriptionLength}
          setDescriptionLength={setDescriptionLength}
          getExcerpt={getExcerpt}
          context={context}
          selectedSize={selectedSize}
          selectedSwatch={selectedSwatch}
          galleries={galleries}
          pdpSidebarImage={pdpSidebarImage}
          productVariant={productVariant}
        />
      )}
    </>
  );
};

export default ProductDetails;
