import { useRef } from "react";
import DesktopHeader from "./desktopHeader";
import MobileHeader from "./mobileHeader";
import PromoBanner from "app/layout/promoBanner/promoBanner";

import { useMediaQuery } from "react-responsive";
// --------------------------------------------------------------------------------------------------------------------------------------------------------
// This is the layout definition for the website header
// this file should only be used to bootstrap new header components and transfer props, it should not contain logic
// You can also include an scss file that would contain CSS for the header container
// Keep in mind that this is loaded on every page of the website, so try to keep it and it's children light
// --------------------------------------------------------------------------------------------------------------------------------------------------------

const Header = (props) => {
  let { menu } = props;
  const isMobile = useMediaQuery({ maxWidth: "992px" });
  let headerRef = useRef(null);

  return (
    <header ref={headerRef} id="header-navigation" className={`initial`}>
      <PromoBanner id={"promo-banner-header"} />
      {isMobile ? (
        <MobileHeader menu={menu} />
      ) : (
        <DesktopHeader menu={menu} headerRef={headerRef} isMobile={isMobile} />
      )}
    </header>
  );
};

export default Header;
