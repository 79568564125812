import { determineCurrency } from "config/currency";
import { isUndefined } from "core/helpers/functions";
import { MDBBox } from "mdbreact";
import PriceRender from "./render";

const Price = (props) => {
  let {
    currency,
    price,
    type,
    onlyValues = false,
    product = null,
    pricingOptions,
  } = props;
  if (product?.price_range === undefined) return <></>;

  if (isUndefined(currency)) {
    currency = product.price_range.minimum_price.final_price.currency;
  }
  if (isUndefined(type)) {
    type = "regular";
  }
  let symbol = determineCurrency(currency);
  if (type === "fixed") {
    price = number_format(price, 2);
  }
  if (type === "regular") {
    price = number_format(price);
  }
  if (onlyValues) {
    return `${symbol + price}`;
  }

  //logic for tearsheet
  let markupPrice = 1;
  let customPrice = 0;
  let isCustomerPricing = false;

  if (pricingOptions && pricingOptions.pricing_option === "markup_pricing") {
    markupPrice = pricingOptions.markup_pricing?.value;
  }

  if (pricingOptions && pricingOptions.pricing_option === "custom_pricing") {
    isCustomerPricing = true;
    customPrice = pricingOptions.custom_pricing;
  }

  const unitQty = parseInt(product.unit_qty ? product.unit_qty : 1);

  const formattedFinalPrice = (
    number_format(product.price_range.minimum_price.final_price.value, 2) *
    markupPrice
  ).toFixed(2);

  const formattedRegularPrice = isCustomerPricing
    ? customPrice
    : (
        number_format(
          product.price_range.minimum_price.regular_price.value,
          2
        ) * markupPrice
      ).toFixed(2);

  const formattedEachPrice = isCustomerPricing
    ? (customPrice / unitQty).toFixed(2)
    : product.each_price !== undefined &&
      product.each_price !== null &&
      product.each_price !== 0
    ? (number_format(product.each_price, 2) * markupPrice).toFixed(2)
    : false;

  const formattedSpecialEachPrice =
    product.each_special_price !== undefined &&
    product.each_special_price !== null &&
    product.each_special_price !== 0
      ? (number_format(product.each_special_price, 2) * markupPrice).toFixed(2)
      : false;
  const discountApplied = isCustomerPricing
    ? false
    : product?.price_range?.minimum_price?.discount?.amount_off !== 0;

  return (
    <MDBBox
      className={`d-flex price-component pdp-price ${
        discountApplied ? "flex-column" : "flex-row"
      }`}
    >
      <MDBBox className={`main-prices`}>
        {product.special_savings && !isCustomerPricing ? (
          <MDBBox className={`save-label  `}>{product.special_savings}</MDBBox>
        ) : (
          <MDBBox></MDBBox>
        )}
        <MDBBox className={`d-flex flex-row  pt-1`}>
          {discountApplied ? (
            <PriceRender
              discount={discountApplied}
              value={formattedFinalPrice}
              symbol={symbol}
              currency={currency}
              special={true}
            />
          ) : (
            <></>
          )}
          <PriceRender
            discount={discountApplied ? false : true}
            value={formattedRegularPrice}
            symbol={symbol}
            currency={currency}
          />
          {unitQty > 1 && <span> set of {unitQty}</span>}
        </MDBBox>
      </MDBBox>
      {unitQty > 1 ? (
        <MDBBox className={`pt-1 d-flex ${discountApplied ? "mt-3" : ""}`}>
          {formattedEachPrice ? (
            formattedSpecialEachPrice && !isCustomerPricing ? (
              <>
                <MDBBox className={`discount`}>
                  <span>{symbol}</span>
                  <span className={``}>
                    {formattedSpecialEachPrice !== null
                      ? formattedSpecialEachPrice
                      : formattedEachPrice}
                  </span>
                </MDBBox>
                <MDBBox className={`normal`}>
                  <del>
                    <span>{symbol}</span>
                    <span className={``}>{formattedEachPrice}</span>
                  </del>
                </MDBBox>
                <span>each</span>
              </>
            ) : (
              <MDBBox>
                <span>{symbol}</span>
                <span className={``}>{formattedEachPrice}</span>
                <span>each</span>
              </MDBBox>
            )
          ) : (
            <MDBBox></MDBBox>
          )}
        </MDBBox>
      ) : (
        <></>
      )}
    </MDBBox>
  );
};

function number_format(number, decimals, dec_point, thousands_sep) {
  // Strip all characters but numerical ones.
  number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
    dec = typeof dec_point === "undefined" ? "." : dec_point,
    s = "",
    toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec);
      return "" + Math.round(n * k) / k;
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
}

export default Price;
