import { withRouter } from "react-router-dom";
import { default as Pages } from "app/config/routing/pages/customerPages";
import { normalizeRoutes } from "../functions";
import { useMediaQuery } from "react-responsive";
import { lazy } from "react";

const SidebarMobile = lazy(() => import("./sidebarMobile"));
const SidebarDesktop = lazy(() => import("./sidebarDesktop"));

const CustomerSidebar = (props) => {
  let { selectedLink, setSelectedLink, logoutCustomer } = props;

  const screenWidth = useMediaQuery({ maxWidth: 992 });

  let PagesObjectMapper = normalizeRoutes(Pages);

  let dropdownPageName = `Account Dashboard`;

  Object.keys(PagesObjectMapper).map((value) => {
    let page = PagesObjectMapper[value];
    let pageParent = selectedLink.substring(0, selectedLink.lastIndexOf("/"));
    if (
      page.url === selectedLink ||
      (page.url !== "customer" && pageParent.indexOf(page.url) > -1)
    ) {
      dropdownPageName = page.menuText;
    }
    return value;
  });

  if (screenWidth) {
    return (
      <SidebarMobile
        PagesObjectMapper={PagesObjectMapper}
        selectedLink={selectedLink}
        setSelectedLink={setSelectedLink}
        logoutCustomer={logoutCustomer}
        dropdownPageName={dropdownPageName}
      />
    );
  }

  return (
    <SidebarDesktop
      PagesObjectMapper={PagesObjectMapper}
      selectedLink={selectedLink}
      setSelectedLink={setSelectedLink}
      logoutCustomer={logoutCustomer}
    />
  );
};

export default withRouter(CustomerSidebar);
