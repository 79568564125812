import { useEffect, useState } from "react";
import { MDBContainer } from "mdbreact";
import ComplexDropdownLayout from "./complexDropdownLayout";
import SimpleDropdownLayout from "./simpleDrodownLayout";
import useStore from "app/state/hooks/storeHooks/useStore";
/**Hovered dropdown is the dropdown that is currently hovered */
const HeaderDropdown = (props) => {
  let {
    hoveredDropdown,
    position,
    setPosition,
    showDropdown,
    setShowDropdown,
    activeMenuItems,
    activeCategory,
    setActiveCategory,
    subcategories,
    setSubcategories,
    activeCategoryImage,
    setActiveCategoryImage,
    complexMenuLayout,
  } = props;

  const [, setNavigationPosition] = useState(0);
  const { getStoreCode } = useStore();
  const store = getStoreCode();

  useEffect(() => {
    if (
      hoveredDropdown !== false &&
      document.getElementById("nav-dropdown-" + hoveredDropdown) !== null
    ) {
      let navigation = document
        .getElementById("main-navigation-header")
        .getBoundingClientRect();
      setNavigationPosition(navigation.left);

      let element = document.getElementById("nav-dropdown-" + hoveredDropdown);
      if (element) {
        element = element.getBoundingClientRect();
        setPosition(element.left);
      }
    }
  }, [position, hoveredDropdown, setNavigationPosition, setPosition]);

  return (
    <MDBContainer
      id={`navigation-dropdown`}
      className={`flex-row justify-content-start align-items-start ${
        (showDropdown && "show") || "hidden"
      } ${complexMenuLayout ? "complex-menu-layout" : "simple-menu-layout"}`}
      onMouseEnter={(e) => {
        e.preventDefault();
        setShowDropdown(true);
      }}
      onMouseLeave={(e) => {
        setShowDropdown(false);
      }}
    >
      {complexMenuLayout ? (
        <ComplexDropdownLayout
          activeMenuItems={activeMenuItems}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          subcategories={subcategories}
          setSubcategories={setSubcategories}
          activeCategoryImage={activeCategoryImage}
          setActiveCategoryImage={setActiveCategoryImage}
          setShowDropdown={setShowDropdown}
          store={store}
        />
      ) : (
        <SimpleDropdownLayout
          activeMenuItems={activeMenuItems}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          setShowDropdown={setShowDropdown}
          store={store}
        />
      )}
    </MDBContainer>
  );
};

export default HeaderDropdown;
