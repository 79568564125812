/**
 * This configuration file will override pageTypes located in core, this will give you flexibility to change components for the 3 standard Magento page types
 * Also, if you extend Magento and define a new page type, you can also add it here
 * This part of the configuration is used in dynamic url resolving, we match the page type retrieved and serve
 */

import CmsPage from "app/pages/cms";
import { Category } from "app/pages/catalog";
import { Product } from "app/pages/catalog";

const types = {
  CMS_PAGE: CmsPage,
  PRODUCT: Product,
  CATEGORY: Category,
};
export default types;
