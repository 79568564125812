import { MDBContainer, MDBCol, MDBRow } from "mdbreact";
import TestimonialItem from "./testimonialItem";
import { useMemo, memo } from "react";

const Testimonials = memo(({ data, testimonials }) => {
  const testimonialMap = useMemo(() => {
    return testimonials?.map(
      (item) => {
        return (
          <TestimonialItem
            key={`testimonials-item-${item.testimonial_id}`}
            item={item}
          />
        );
      },
      [testimonials]
    );
  });
  return (
    <MDBContainer className={`testimonials page-full-width`}>
      <MDBRow className={`text-center`}>
        <MDBCol>{data}</MDBCol>
      </MDBRow>
      <MDBContainer fluid className={`testimonials-list px-0`}>
        {testimonialMap}
      </MDBContainer>
    </MDBContainer>
  );
});

export default Testimonials;
