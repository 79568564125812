import {
  MDBBox,
  MDBDropdown,
  MDBDropdownToggle,
  MDBRow,
  MDBCol,
} from "mdbreact";
import { ModalConsumer } from "core/components/modal/ModalContext";
import Login from "app/pages/customer/login";
import CustomerAccountDropdownMenu from "./customerAccountDropdownMenu";
import Icon from "app/assets/icon/icon";
// import { Link } from "react-router-dom";

const CustomerAccountAction = ({ token, props, hideMiniCart, store }) => {
  return (
    <div className="customer-account-actions">
      {/* logged in */}
      {token && !props.customer.hide ? (
        <>
          <MDBBox
            onMouseEnter={hideMiniCart}
            className={`my-account ${store + "-website"}`}
          >
            <MDBDropdown>
              <MDBDropdownToggle
                caret
                onMouseEnter={hideMiniCart}
                color="my-account"
                className={`text-decoration-none mt-2 my-account-btn`}
              >
                <Icon icon="accountIcon" />
                {/* <MDBBox tag="p" className="my-account-label">
                  My Account
                </MDBBox> */}
                <Icon icon="arrow_down" />
              </MDBDropdownToggle>
              <CustomerAccountDropdownMenu {...props} store={store} />
            </MDBDropdown>
          </MDBBox>
        </>
      ) : (
        // logged out
        <ModalConsumer value={{ useWrapper: false }}>
          {({ showModal, hideModal }) => (
            <MDBRow className={`customer-logged-out mx-0 ${store}`}>
              <MDBCol className="d-flex flex-row pr-1 align-items-center pr-4">
                <div
                  className={`cursor-pointer`}
                  onClick={() => {
                    showModal(() => {
                      return <Login {...props} hideModal={hideModal} />;
                    });
                  }}
                >
                  <Icon icon="accountIcon" />
                </div>
              </MDBCol>
            </MDBRow>
          )}
        </ModalConsumer>
      )}
    </div>
  );
};

export default CustomerAccountAction;
