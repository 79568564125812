import {
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBTypography,
  MDBBox,
  MDBBtn,
} from "mdbreact";
import { useHistory } from "react-router-dom";
import { ModalConsumer } from "core/components/modal/ModalContext";
import Login from "app/pages/customer/login";

const SecondStep = ({ email, ...props }) => {
  const history = useHistory();
  return (
    <>
      <MDBContainer fluid>
        <MDBRow className={`mb-90`}>
          <MDBBox className="title-with-background w-100">
            <MDBContainer>
              <MDBTypography tag="h1" className="page-title">
                Reset your password
              </MDBTypography>
            </MDBContainer>
          </MDBBox>
        </MDBRow>
      </MDBContainer>
      <MDBContainer
        className={"page-main-content forgot-password second text-center"}
      >
        <MDBRow className="d-flex justify-content-center">
          <MDBCol sm="8" xl="6">
            <p className="text-left mb-5">
              You should receive a link in few moments. Please open that link to
              reset your password.
            </p>
            <h5 className="mt-3 mb-5">{email}</h5>
          </MDBCol>
        </MDBRow>
        <MDBRow className="input-btn-content d-flex justify-content-center">
          <MDBCol sm="8" xl="6">
            <ModalConsumer value={{ useWrapper: false }}>
              {({ showModal, hideModal }) => (
                <MDBBtn
                  color={"primary"}
                  outline
                  className={"btn-with-icon btn-black-text"}
                  onClick={() => {
                    history.push("/");
                    showModal(() => {
                      return <Login {...props} hideModal={hideModal} />;
                    });
                  }}
                  children={
                    <>
                      <MDBBox tag="span">{"Back to Login"}</MDBBox>
                      <MDBBox className="circle-icon" />
                    </>
                  }
                />
              )}
            </ModalConsumer>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBRow className={"w-100 spacer"}></MDBRow>
    </>
  );
};

export default SecondStep;
