import { useEffect, lazy } from "react";
import apolloClient from "app/graphql/apolloClient";
import queryLoader from "app/graphql/queryLoader";
import useMessages from "./messageHooks/useMessages";

const useUtility = () => {
  const { addMessage } = useMessages();

  const useOutsideClick = (ref, callback) => {
    const handleClick = (e) => {
      if (ref.current && !ref.current?.contains(e.target)) {
        callback();
      }
    };

    useEffect(() => {
      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    });
  };

  const sendEmail = async (input) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("sendEmail"),
        variables: input,
      });

      if (!data.sendEmail) {
        console.log("GraphQL response", data);
        throw new Error("No cache information received");
      }
      addMessage(data.sendEmail, "success");
    } catch (error) {
      addMessage("Something went wrong.", "error");
      return;
    }
  };

  const lazyWithPreload = (factory) => {
    const Component = lazy(factory);
    Component.preload = factory;
    return Component;
  };

  const getObjectWithoutAttribute = (object, attributesToRemove) => {
    const modifiedObject = Object.keys(object).reduce((item, key) => {
      let removeItem = attributesToRemove.find((attr) => {
        return attr === key;
      });
      if (removeItem === undefined) item[key] = object[key];
      return item;
    }, {});
    return modifiedObject;
  };

  const priceFormat = (number, decimals, dec_point, thousands_sep) => {
    // Strip all characters but numerical ones.
    number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
    var n = !isFinite(+number) ? 0 : +number,
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
      dec = typeof dec_point === "undefined" ? "." : dec_point,
      s = "",
      toFixedFix = function (n, prec) {
        var k = Math.pow(10, prec);
        return "" + Math.round(n * k) / k;
      };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || "").length < prec) {
      s[1] = s[1] || "";
      s[1] += new Array(prec - s[1].length + 1).join("0");
    }
    return s.join(dec);
  };

  const group = (keys) => (array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = keys.map((key) => obj[key]).join("-");
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);

      return objectsByKeyValue;
    }, {});

  const groupBy = (list, properties) => {
    let response = {};
    let newResponse = [];

    // const myGroupDataFn = () => {
    //   return [];
    // };

    // let myGroupData = myGroupDataFn();
    properties.map((x) => {
      response[x] = [];
      return x;
    });

    list.map((x, index) => {
      properties.map((property_key) => {
        let key =
          property_key === "order_number"
            ? "order-" + x[property_key]
            : property_key === "invoice_number"
            ? "invoice_number-" + x[property_key]
            : property_key === "sku"
            ? "sku-" + x[property_key]
            : x[property_key];
        if (typeof response[property_key][key] === "undefined") {
          response[property_key][key] = [];
        }

        response[property_key][key].push(x);

        return property_key;
      });

      return x;
    });

    return response;
  };

  return {
    useOutsideClick,
    sendEmail,
    lazyWithPreload,
    getObjectWithoutAttribute,
    priceFormat,
    groupBy,
  };
};

export const getSiblings = (element, type) => {
  var arraySib = [];
  if (type === "prev") {
    while ((element = element.previousSibling)) {
      arraySib.push(element);
    }
  } else if (type === "next") {
    while ((element = element.nextSibling)) {
      arraySib.push(element);
    }
  }
  return arraySib;
};

export default useUtility;
