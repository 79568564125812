import { MDBContainer, MDBRow, MDBTypography, MDBBox, MDBCol } from "mdbreact";
import CmsBlock from "core/components/cmsBlock";
import SeoHelmet from "app/layout/seoHelmet/seoHelmet";

const ComingSoon = () => {
  return (
    <MDBBox
      className={`coming-soon-page position-relative d-flex align-items-center justify-content-center`}
    >
      <SeoHelmet url={`/coming-soon`} title={`Coming Soon`} />
      <MDBContainer fluid>
        <MDBRow>
          <CmsBlock dangHtml={true} id="coming-soon-image" />
        </MDBRow>
      </MDBContainer>
      <MDBContainer fluid className={`position-absolute page-full-width`}>
        <MDBRow className={`coming-soon-text`}>
          <MDBCol size="12" sm="8" md="6">
            <MDBTypography tag="h1" className={`text-white mb-md-5 mb-3`}>
              Dear customers, we are working hard to create better online
              experience for you. We are soon back online.
            </MDBTypography>
            <MDBTypography tag="h3" className={`text-white`}>
              Sorry for the inconvenience and thank you for your patience
            </MDBTypography>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBBox>
  );
};

export default ComingSoon;
