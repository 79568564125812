import { useState, useEffect, useMemo } from "react";
// import Slider from "react-slick";
import { MDBCol, MDBTooltip, MDBRow } from "mdbreact";
// import Input from "core/components/input";
import { useHistory } from "react-router-dom";
import useGenerated from "app/state/hooks/generatedHooks";
import Icon from "app/assets/icon/icon";
import useProducts from "app/state/hooks/productsHooks/useProducts";
import Select2 from "app/layout/select";

const ActionsLeft = ({
  data,
  loggedIn,
  storeCode,
  setSelectedSwatch,
  setSelectedSize,
  selectedSwatch,
  selectedSize,
}) => {
  const history = useHistory();
  const { GetGeneratedStore } = useGenerated();
  const { getSwatchesAndSizes } = useProducts();
  const store = GetGeneratedStore();
  const [sizes, setSizes] = useState([]);
  const [swatches, setSwatches] = useState([]);
  const [initSwatch, setInitSwatch] = useState(false);
  const [swatchesLoaded, setSwatchesLoaded] = useState(false);
  const selectedSizeValue = useMemo(() => {
    if (selectedSize) {
      if (typeof selectedSize === "string") {
        return { value: selectedSize, label: selectedSize };
      } else {
        return selectedSize;
      }
    }
    if (history.location.search.includes("size")) {
      let size = history.location.search
        .split("size")?.[1]
        ?.split("=")?.[1]
        ?.split("%")?.[0];

      return { value: `${size}`, label: `${size}` };
    } else {
      return false;
    }
  }, [selectedSize, history]);

  const sizeOptions = useMemo(() => {
    if (sizes?.length) {
      let isSizeExist = sizes.some((size) => size?.includes(selectedSize));
      if (!isSizeExist) setSelectedSize(sizes[0]);
      return sizes.map((size) => ({ label: size, value: size }), []);
    }
    return [];
  }, [sizes, selectedSwatch]);

  useEffect(() => {
    let url = `https://${process.env.REACT_APP_MAIN_STORE}/media/${process.env.REACT_APP_ATTR_SWATCH_IMG_PLP_PATH}`;

    const { sizesArray, swatchesArray } = getSwatchesAndSizes(
      data,
      url,
      selectedSwatch,
      selectedSize
    );
    setSizes(sizesArray);
    setSwatches(swatchesArray);
  }, [selectedSwatch, selectedSize, data]);

  useEffect(() => {
    if (
      history.location.search.toLowerCase().includes("finish") &&
      !initSwatch
    ) {
      let finish = history.location.search.includes("%20")
        ? history.location.search.split("%20")[0].split("=")[1]
        : history.location.search.split("&")[0].split("=")[1];
      finish = finish.includes("&") ? finish.split("&")[0] : finish;
      let swatchCircles = document.querySelectorAll(".swatch-holder .outer");
      if (finish && !selectedSwatch?.label?.includes(finish)) {
        setSelectedSwatch(
          swatches.find((swatch) => swatch.label.includes(finish))
        );

        Array.from(swatchCircles).map((size) => {
          size.classList.remove("active");
        });
        Array.from(swatchCircles)
          .find((swatch) => swatch.outerHTML.includes(finish))
          ?.classList.add("active");
      }
    }
  }, [history, swatches]);

  useEffect(() => {
    if (swatches.length && !swatchesLoaded)
      setTimeout(() => {
        document.querySelectorAll(".swatch-holder .outer")?.[0]?.click();
        document.querySelectorAll(".slick-slide .size")?.[0]?.click();
        setSwatchesLoaded(true);
      }, 0);
  }, [swatches]);

  useEffect(() => {
    history.listen(() => {
      setSwatchesLoaded(false);
    });
  }, [history]);

  const handleSizeSelect = (event) => {
    setSelectedSize(event.label);
  };

  const handleSelectSwatch = (event, swatch) => {
    setSelectedSwatch(swatch);
    if (!event.target.outerHTML.includes("div")) {
      setInitSwatch(true);
    }
    Array.from(document.querySelectorAll(".swatch-holder .outer")).map(
      (size) => {
        size.classList.remove("active");
      }
    );
    event.currentTarget.classList.add("active");
  };

  return (
    <div className={`pdp-actions-left ${storeCode}`}>
      <MDBRow>
        <MDBCol sm={`6`}>
          <div className="swatch-holder">
            {swatches?.map((swatch, i) => (
              <MDBTooltip domElement placement="top" tag="span" key={i}>
                <div
                  className="outer cursor-pointer"
                  key={i}
                  onClick={(e) => handleSelectSwatch(e, swatch)}
                >
                  <div className="inner">
                    <img src={swatch?.image} alt="swatch" />
                  </div>
                </div>
                <span>{swatch?.label}</span>
              </MDBTooltip>
            ))}
          </div>
        </MDBCol>
        <MDBCol sm={`6`}>
          {sizeOptions?.length ? (
            <div className="sizes">
              <Select2
                className="browser-default"
                isSearchable={false}
                onChange={(e) => handleSizeSelect(e)}
                placeholder={"Select a Size"}
                options={sizeOptions}
                openIndicator="caret-up"
                closedIndicator="caret-down"
                value={
                  !selectedSizeValue ? sizeOptions?.[0] : selectedSizeValue
                }
              />
            </div>
          ) : null}
        </MDBCol>
      </MDBRow>
    </div>
  );
};
export default ActionsLeft;
