import { useEffect, useState } from "react";
import AddressBook from "./addressbook";
import { isUndefined } from "core/helpers/functions";
import { Redirect } from "react-router-dom";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import { apolloClient } from "app/graphql/apolloClient";
import queryLoader from "app/graphql/queryLoader";

const AddressbookStateContainer = ({ dashboardLayout }) => {
  const { isLoggedIn, getCustomer } = useCustomer();
  const customer = getCustomer();
  const [addresses, setAddresses] = useState(customer?.addresses);
  // const addresses = useMemo(() => {
  //   return customer?.addresses;
  // }, [customer]);

  const fetchAddress = async () => {
    const { data } = await apolloClient.query({
      query: queryLoader("getCustomer"),
      fetchPolicy: "cache-network",
    });
    try {
      setAddresses(data.customer.addresses);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAddress();
  }, [customer]);

  if (!isLoggedIn()) return <Redirect to={`/`} />;

  let doesHaveAddresses = isUndefined(addresses)
    ? 0
    : customer.addresses.length;

  let defaultShipping = false;
  let defaultBilling = false;

  if (doesHaveAddresses) {
    defaultBilling = addresses.find((address) => {
      return address.default_billing === true;
    });
    defaultShipping = addresses.find((address) => {
      return address.default_shipping === true;
    });
  }

  return (
    <AddressBook
      dashboardLayout={dashboardLayout}
      addresses={addresses}
      defaultBilling={defaultBilling}
      defaultShipping={defaultShipping}
      doesHaveAddresses={doesHaveAddresses}
    />
  );
};

export default AddressbookStateContainer;
