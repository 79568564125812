import { MDBCol, MDBContainer } from "mdbreact";
import useGenerated from "app/state/hooks/generatedHooks";
import Logo from "app/layout/logo";

const InitialBlock = () => {
  const { GetCmsBlocks } = useGenerated();
  const contactSalesRep = GetCmsBlocks(
    "contact_a_territory_sales_representative"
  );
  return (
    <MDBContainer
      className={`initial-block align-items-center justify-content-center`}
    >
      <MDBCol className={`logo-container`}>
        <Logo />
      </MDBCol>
      <MDBCol className={`contact-sales-rep text-block`}>
        {contactSalesRep}
      </MDBCol>
    </MDBContainer>
  );
};
export default InitialBlock;
