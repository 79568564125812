import { MDBContainer, MDBRow, MDBCol, MDBBox, MDBTypography } from "mdbreact";
import CmsPage from "app/pages/cms/";
import SeoHelmet from "app/layout/seoHelmet/seoHelmet";
import Breadcrumbs from "app/layout/breadcrumbs";

const TermsOfUse = (props) => {
  return (
    <div id={`terms-of-use`} fluid className={"static-cms-page"}>
      <MDBContainer fluid>
        <SeoHelmet url={`/terms-of-use`} title={`Terms Of Use`} />
        <MDBRow className="mb-90">
          <MDBBox className="title-with-background w-100">
            <MDBContainer>
              <MDBTypography tag="h1" className="page-title">
                Terms Of Use
              </MDBTypography>
            </MDBContainer>
          </MDBBox>
        </MDBRow>
        <MDBRow className={``}>
          <MDBContainer>
            <Breadcrumbs />
          </MDBContainer>
        </MDBRow>
        <MDBRow className={``}>
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <CmsPage
                  {...props}
                  dangHtml={true}
                  id={4}
                  variables={{ ...props.variables }}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

export default TermsOfUse;
