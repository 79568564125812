const { useQuery } = require("react-apollo-hooks");
const { GET_CUSTOMER_IMAGES } = require("../queries");

const useDownloads = () => {
  let formattedData = undefined;
  const { data, loading, error, refetch } = useQuery(GET_CUSTOMER_IMAGES, {
    fetchPolicy: "cache-and-network",
  });

  if (data !== undefined) {
    formattedData = data.getAllFilesForDownload;
  }
  return { formattedData, data, loading, error, refetch };
};

export default useDownloads;
