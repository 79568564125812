import Testimonials from "./render";
import useGenerated from "app/state/hooks/generatedHooks";
import { isEmpty } from "lodash";
const TestimonialsQueryContainer = (props) => {
  const { GetTestimonials } = useGenerated();
  const data = GetTestimonials();

  if (isEmpty(data)) return <></>;
  return <Testimonials {...props} testimonials={data} />;
};

export default TestimonialsQueryContainer;
