import { MDBCol, MDBTypography } from "mdbreact";
import { Link } from "react-router-dom";

const CategoriesList = ({
  complexMenuLayout,
  activeMenuItems,
  activeCategory,
  setActiveCategory,
  setActiveCategoryImage,
  setSubcategories,
  setShowDropdown,
  store,
}) => {
  let categories =
    activeMenuItems && activeMenuItems.children ? activeMenuItems.children : {};

  return (
    <MDBCol
      size={complexMenuLayout ? "2" : "12"}
      className={`categories-container ${
        complexMenuLayout ? "complex" : "simple"
      }`}
    >
      <ul
        className={`${
          categories.includes(categories.find((cat) => cat.child_categories))
            ? "dropdown-with-subchildren"
            : "dropdown-only-children"
        }`}
      >
        {categories &&
          categories.length > 0 &&
          categories.map((category, index) => {
            if (
              (complexMenuLayout && category?.children?.length > 0) ||
              !complexMenuLayout
            ) {
              let urlPath = category.url_path
                ? category.url_path
                : category.url_key
                ? category.url_key
                : "";
              urlPath = urlPath.includes("/") ? urlPath : "/" + urlPath;
              if (complexMenuLayout) {
                return (
                  // remove comment if client wants to change categories on hover
                  // <Link
                  //   to={category.url_path}
                  //   onClick={() => setShowDropdown(false)}
                  // >
                  <li
                    key={`dropdown-menu-item-${category.name}-${index}`}
                    className={`dropdown-menu-item ${store} ${
                      activeCategory === index && "active"
                    }`}
                    onClick={() => {
                      setActiveCategory(index);
                      setActiveCategoryImage(
                        category.menu_image ? category.menu_image : false
                      );
                      setSubcategories(
                        category.children ? category.children : []
                      );
                    }}
                  >
                    {category.name && category.name}
                  </li>
                  // </Link>
                );
              }
              return (
                <li>
                  <Link
                    to={
                      activeMenuItems.name.toLowerCase() === "styles"
                        ? `/collections?index=${index}`
                        : urlPath
                    }
                    key={`dropdown-menu-item-${category.name}-link`}
                  >
                    <p
                      key={`dropdown-menu-item-${category.name}`}
                      className={`dropdown-menu-item ${store}  ${
                        activeCategory === index && "active"
                      }`}
                      onMouseEnter={() => {
                        setActiveCategory(index);
                      }}
                      onMouseLeave={() => setActiveCategory(false)}
                    >
                      {category.name && category.name}
                    </p>
                  </Link>
                  {category?.child_categories !== null
                    ? category?.child_categories?.map((child) => (
                        <Link
                          key={child.id}
                          to={`/${child.url_key}`}
                          className={`text-default cat-subchildren`}
                        >
                          <MDBTypography
                            tag="p"
                            className={`montserrat-regular`}
                          >
                            {child.name}
                          </MDBTypography>
                        </Link>
                      ))
                    : ""}
                </li>
              );
            }
          })}
      </ul>
      {/* {complexMenuLayout && (
        <Link
          className={`view-all ${store + "-website"}`}
          to={`/` + categories[activeCategory].url_path}
          onClick={() => setShowDropdown(false)}
        >
          <span className="hover">View All</span>
        </Link>
      )} */}
    </MDBCol>
  );
};

export default CategoriesList;
