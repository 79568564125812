import { Link } from "react-router-dom";
import Image from "app/layout/image";
import Icon from "app/assets/icon/icon";
import Price from "app/layout/price";

const CompareItem = ({
  productItem,
  loggedIn,
  customer,
  removeCompareMethod,
}) => {
  if (!productItem) return <></>;
  const { product, attributes } = productItem;
  const webpImageDirectory = "catalog/product/webp/" + product.sku + "/";

  const smallImage = product?.small_image?.url
    ? product.small_image.url
    : product?.image?.url
    ? product.image.url
    : "";

  const imageSource = smallImage;

  const explodedImageSource = imageSource.split(/\//g);

  const webpImage = explodedImageSource[explodedImageSource.length - 1]
    .replace(/.png/, ".webp")
    .replace(/.jpg/, ".webp")
    .replace(/.webp/, ".webp");

  const imageSourceWebp = webpImageDirectory + webpImage;
  return (
    <div key={product.uid} className="compare-item">
      <Link to={`/${product?.url_key}`} className={`p-0`}>
        <Icon
          icon={`closeIconBlue`}
          onClick={(e) => {
            e.preventDefault();
            removeCompareMethod(product?.id);
          }}
        />
        <Image
          key={`imagesource-${product.uid}`}
          source={imageSource}
          webpImage={imageSourceWebp}
          alt={product?.thumbnail?.label}
          wrapperClass={`product-image thumbnail-image d-flex justify-content-center`}
          backend={true}
          backend_public={true}
          mediaLink={true}
        />
      </Link>
      <div className="info">
        <h5 className="mt-3 mb-3">{product?.name}</h5>
        {loggedIn && customer?.show_price !== false ? (
          <p className={`product-price`}>
            <Price product={product} layout={`widget`} />
          </p>
        ) : (
          <></>
        )}
      </div>
      <div className="border-bottom mt-3"></div>
      <div className="attributes-info">
        {attributes
          .filter(
            (attribute) =>
              attribute.code !== "short_description" &&
              attribute.code !== "manufacturer"
          )
          .map((attribute) => (
            <div
              className="d-flex justify-content-between"
              key={attribute.code}
            >
              <p className="font-weight-bold text-capitalize">
                {attribute.code === "color" ? "Finish" : attribute.code}
              </p>
              <p>{`${attribute.value.substring(0, 100)}...`}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CompareItem;
