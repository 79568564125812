import { useMemo } from "react";
import { MDBRow, MDBCol, MDBContainer, MDBTypography, MDBBox } from "mdbreact";
import { Link } from "react-router-dom";

const Accountinformation = (props) => {
  let isNewsletter = useMemo(() => {
    return props.customer.is_subscribed;
  }, [props]);

  return (
    <MDBContainer fluid className={`account-information-container`}>
      <MDBRow>
        <MDBCol>
          <MDBBox className="mb-md-5 mb-sm-5 mb-xs-5 ">
            <MDBBox>
              <MDBTypography tag="h3" className="border-bottom pb-2 mb-5">
                Account Information
              </MDBTypography>
            </MDBBox>
            <div className="account-information">
              <div>
                <div>
                  <div className="my-3">
                    <MDBTypography tag="p" className={`montserrat-bold`}>
                      Contact Information
                    </MDBTypography>
                  </div>
                  <div className="text-account-info">
                    <MDBTypography tag="p" className="mb-3">
                      {props.customer.firstname} {props.customer.lastname}
                    </MDBTypography>
                    <MDBTypography tag="p">
                      {props.customer.email}
                    </MDBTypography>
                  </div>
                  <div className={`d-flex flex-row`}>
                    <Link
                      to="/customer/information"
                      className={"link blue-link mr-5"}
                    >
                      Edit
                    </Link>
                    <Link
                      className={"link blue-link"}
                      to={{
                        pathname: "/customer/information",
                        state: { changePassword: true },
                      }}
                    >
                      Change Password
                    </Link>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className="mb-lg-3 mt-xs-5 mt-sm-5 mt-md-0">
                    <MDBTypography tag="p" className="my-3 montserrat-bold">
                      Subscription
                    </MDBTypography>
                  </div>
                </div>
                <MDBTypography tag="p" className="text-newsletter">
                  {isNewsletter
                    ? "You are currently subscribed to our newsletter"
                    : "You aren’t subscribed for our updates"}
                </MDBTypography>
                <Link to="/customer/newsletter" className={"link blue-link"}>
                  Edit
                </Link>
              </div>
            </div>
          </MDBBox>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Accountinformation;
