import apolloClient from "app/graphql/apolloClient";
import customerActions from "../actions";
import commonActions from "../../common/actions";
import queryLoader from "app/graphql/queryLoader";
import messagesActions from "core/state/redux/data/messages/actions";
import queryActions from "core/state/redux/data/queries/actions";

export default async (store, action) => {
  try {
    let cart_id = store.getState().customer.data.cartToken;
    let cart = { ...store.getState().customer.data.cart };
    const { data } = await apolloClient.mutate({
      mutation: queryLoader("removeProduct"),
      variables: {
        cart_item_id: action.item_id,
        cart_id,
      },
    });

    if (data.removeItemFromCart !== null) {
      // merge(cart, data.removeItemFromCart.cart);

      let newCart = {
        ...cart,
        items: [...data.removeItemFromCart.cart.items],
        prices: { ...data.removeItemFromCart.cart.prices },
        shipping_addresses: [
          ...data.removeItemFromCart.cart.shipping_addresses,
        ],
      };
      store.dispatch(customerActions.setCartInformation(newCart));
      store.dispatch(
        messagesActions.addMessage(
          "Successfully removed item from cart",
          "success"
        )
      );
    }
  } catch (error) {
    if (error.message.includes("Could not find a cart with ID")) {
      store.dispatch(customerActions.createEmptyCart());
      store.dispatch(customerActions.removeProductFromCart(action.item_id));
    } else {
      store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    }
    store.dispatch(commonActions.unlock());
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
