import { useContext } from "react";
import Left from "./left";
import Menu from "./menu";
import Right from "./right";
import { MDBNavbar, MDBContainer } from "mdbreact";
import { debounce } from "lodash";
import useStore from "app/state/hooks/storeHooks/useStore";
import { useCart } from "app/state/hooks/cartHooks/useCart";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { logicallyHandleHeaderOnScroll } from "../handleHeaderScroll";
import { MiniCartContext } from "app/state/hooks/cartHooks/MinicartContext";

const DesktopHeader = ({ menu, headerRef, isMobile }) => {
  let { getCartItems } = useCart();
  const { getStoreCode } = useStore();
  const store = getStoreCode();
  const { showMiniCart, hideMiniCart } = useContext(MiniCartContext);
  useScrollPosition(
    debounce(({ prevPos, currPos }) => {
      logicallyHandleHeaderOnScroll(
        prevPos,
        currPos,
        headerRef,
        hideMiniCart,
        showMiniCart,
        isMobile
      );
    }, 0)
  );

  return (
    <MDBNavbar
      id="navigation"
      expand="lg"
      className={`white z-index-overwrite p-0`}
    >
      <MDBContainer
        id={`main-navigation-header`}
        className={`d-flex navbar-container position-relative ${store}`}
      >
        <Left />
        <Menu menu={menu} />
        <Right getCartItems={getCartItems} store={store} />
      </MDBContainer>
    </MDBNavbar>
  );
};

export default DesktopHeader;
