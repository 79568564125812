import { useState, useEffect, useRef, Fragment, useCallback } from "react";
import { MDBNavbar } from "mdbreact";
import { toggleHamburger, useOutsideAlerter } from "./functions";
import Search from "../search";
import MobileHeaderTop from "./mobileHeaderTop";
import MobileMenu from "./mobileMenu";
import useStore from "app/state/hooks/storeHooks/useStore";

const Mobile = (props) => {
  let {
    open,
    setOpen,
    menuItems,
    setCollapseID,
    collapseID,
    menuToggle,
    setMenuToggle,
  } = props;

  let [searchVal, setSearchVal] = useState("");
  let [displaySearch, setDisplaySearch] = useState(false);

  useEffect(() => {
    if (open) {
      document.body.classList.add("scroll-lock");
    } else {
      document.body.classList.remove("scroll-lock");
    }
  }, [open]);

  const closeBurger = useCallback(
    function closeBurgerfn() {
      if (open) document.getElementById("hamburger1").click();
    },
    [open]
  );

  let searchRef = useRef(null);
  useOutsideAlerter(searchRef, displaySearch, setDisplaySearch);

  const { getStoreCode } = useStore();
  const store = getStoreCode();
  return (
    <>
      <MDBNavbar
        id={`mobile-header`}
        expand="lg"
        scrolling
        className={`align-items-start ${store} ${open && "collapsed"}`}
      >
        <div className={`container-fluid h-100 px-0`}>
          <MobileHeaderTop
            props={props}
            searchRef={searchRef}
            setOpen={setOpen}
            toggleHamburger={toggleHamburger}
            setMenuToggle={setMenuToggle}
            closeBurger={closeBurger}
            setSearchVal={setSearchVal}
            displaySearch={displaySearch}
            setDisplaySearch={setDisplaySearch}
            store={store}
            open={open}
          />
          <MobileMenu
            open={open}
            setOpen={setOpen}
            menuItems={menuItems}
            props={props}
            toggleHamburger={toggleHamburger}
            closeBurger={closeBurger}
            setCollapseID={setCollapseID}
            collapseID={collapseID}
            menuToggle={menuToggle}
            setMenuToggle={setMenuToggle}
            store={store}
          />
        </div>
      </MDBNavbar>
    </>
  );
};

export default Mobile;
