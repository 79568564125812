import { useSelector, useDispatch } from "react-redux";
import { filterActions } from "app/state/redux/data/filters";

const useFilters = () => {
  const filters = useSelector((state) => state.filters);
  let dispatch = useDispatch();

  const getFilters = () => {
    return filters;
  };

  const getIsEmpty = () => {
    return typeof filters !== "undefined" &&
      typeof filters.data !== "undefined" &&
      typeof filters.data.empty !== "undefined"
      ? filters.data.empty
      : false;
  };

  const getIsLoading = () => {
    return typeof filters !== "undefined" &&
      typeof filters.data !== "undefined" &&
      typeof filters.data.loading !== "undefined"
      ? filters.data.loading
      : false;
  };

  const getData = () => {
    return typeof filters !== "undefined" &&
      typeof filters.data !== "undefined" &&
      typeof filters.data.items !== "undefined"
      ? filters.data.items
      : [];
  };

  const getFilterStateByKey = (key) => {
    return typeof filters[key] !== "undefined" ? filters[key] : false;
  };

  const dispatchLoading = (payload) => {
    dispatch(filterActions.setLoading(payload));
  };

  return {
    getFilters,
    getIsEmpty,
    getData,
    getIsLoading,
    getFilterStateByKey,
    dispatchLoading,
  };
};

export default useFilters;
