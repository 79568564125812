import Login from "./login";
import { useState } from "react";

const LoginStateContainer = (props) => {
  const [email, setEmail] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [showLoginRegister, setShowLoginRegister] = useState(true);
  const [showPasswordContent, setShowPasswordContent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toggleView, setToggleView] = useState(true);
  const [errorMessage, setErrorMessage] = useState({
    type: undefined,
    message: undefined,
  });
  return (
    <>
      <Login
        email={email}
        setEmail={setEmail}
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        showLoginRegister={showLoginRegister}
        setShowLoginRegister={setShowLoginRegister}
        showPasswordContent={showPasswordContent}
        setShowPasswordContent={setShowPasswordContent}
        loading={loading}
        setLoading={setLoading}
        toggleView={toggleView}
        setToggleView={setToggleView}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        {...props}
      />
    </>
  );
};

export default LoginStateContainer;
