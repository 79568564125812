import FindRep from "./findRep";
import { uniqBy } from "lodash";
import useStore from "app/state/hooks/storeHooks/useStore";

const FindRepDataContainer = ({ data }) => {
  const { store } = useStore();
  let industriesData = store.rep_locator_industries;
  let repLocators = data?.getAllRepLocators;
  let allIndustries = [];

  if (typeof industriesData === "undefined") {
    industriesData = [];
  } else {
    industriesData?.map((industry) => {
      allIndustries.push({
        label: industry,
        value: industry,
        regions: null,
      });
      return "";
    });
  }

  allIndustries = uniqBy(allIndustries, (industry) => {
    return industry.label;
  });
  allIndustries.sort();

  return <FindRep repLocators={repLocators} industries={allIndustries} />;
};
export default FindRepDataContainer;
