/**
 * This file has only one function, to create a list of which Redux state belonging to the project needs to be persisted
 */
import { isEmpty } from "core/helpers/functions";

export const persistList = {
  // test: true
  store: true,
  customer: true,
  application: true,
};

export const getStateByKey = (store, key, projectStates) => {
  let thisState = {};
  if (!isEmpty(store) && !isEmpty(store[key])) {
    thisState = store[key];
  } else {
    thisState = projectStates[key]();
  }
  return thisState;
};
