import { Controller } from "react-hook-form";
import { MDBBox, MDBTypography } from "mdbreact";
import InputField from "core/components/inputField/inputField";

const LeftSide = ({ control, trigger, errors }) => {
  return (
    <div className={`d-flex flex-column col-lg-6`}>
      <Controller
        control={control}
        name="firstname"
        defaultValue={""}
        rules={{ required: true }}
        render={({ onChange, value, name, ref }) => (
          <MDBBox className={`input-container`}>
            <InputField
              // onBlur={() => trigger(name)}
              innerRef={ref}
              placeholder={"Required"}
              name={name}
              value={value}
              onChange={onChange}
              className={`custom-input ${
                errors[name] ? "validation-error" : ""
              }`}
              label={`First Name *`}
              filled={value && value.length !== 0 ? true : false}
            />
            {errors[name] && (
              <MDBTypography tag="p" className={`error-label`}>
                First Name is required
              </MDBTypography>
            )}
          </MDBBox>
        )}
      />
      <Controller
        control={control}
        name="lastname"
        defaultValue={""}
        rules={{ required: true }}
        render={({ onChange, value, name, ref }) => (
          <MDBBox className={`input-container`}>
            <InputField
              // onBlur={() => trigger(name)}
              innerRef={ref}
              placeholder={"Required"}
              name={name}
              value={value}
              onChange={onChange}
              className={`custom-input ${
                errors[name] ? "validation-error" : ""
              }`}
              label={`Last Name *`}
              filled={value && value.length !== 0 ? true : false}
            />
            {errors[name] && (
              <MDBTypography tag="p" className={`error-label`}>
                Last Name is required
              </MDBTypography>
            )}
          </MDBBox>
        )}
      />
      <Controller
        control={control}
        name="company"
        defaultValue={""}
        render={({ onChange, value, name, ref }) => (
          <MDBBox className={`input-container`}>
            <InputField
              // onBlur={() => trigger(name)}
              innerRef={ref}
              placeholder={"Company"}
              name={name}
              value={value}
              onChange={onChange}
              className={`custom-input`}
              label={`Company`}
              filled={value && value.length !== 0 ? true : false}
            />
          </MDBBox>
        )}
      />
      <Controller
        control={control}
        name="telephone"
        defaultValue={""}
        rules={{ required: true }}
        render={({ onChange, value, name, ref }) => (
          <MDBBox className={`input-container`}>
            <InputField
              // onBlur={() => trigger(name)}
              innerRef={ref}
              className={`custom-input ${
                errors[name] ? "validation-error" : ""
              }`}
              placeholder={"Required"}
              name={name}
              value={value}
              onChange={onChange}
              label={`Phone *`}
              type={"number"}
              filled={value && value.length !== 0 ? true : false}
            />
            {errors[name] && (
              <MDBTypography tag="p" className={`error-label`}>
                Phone is required
              </MDBTypography>
            )}
          </MDBBox>
        )}
      />
    </div>
  );
};

export default LeftSide;
