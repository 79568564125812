import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardText,
  MDBBox,
  MDBRow,
  MDBCol,
} from "mdbreact";
import Price from "app/layout/price";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import { Link } from "react-router-dom";

const ProductCard = ({ dot, containerClassName }) => {
  const { isLoggedIn } = useCustomer();
  const loggedIn = isLoggedIn();
  return (
    <MDBCard
      className={`${containerClassName} hotspot-card card-with-price-${loggedIn}`}
    >
      {/* <WishlistIcon
        product={{ sku: dot.sku, image: { url: dot.image } }}
        label={false}
        className={`wishlist-box`}
      /> */}
      <MDBBox className={`product-card-img`}>
        {dot.url ? (
          <Link to={dot.url}>
            <MDBCardImage
              className="img-hotspot-detail"
              src={dot.image}
              alt="hot spot picture"
            />
          </Link>
        ) : (
          <MDBCardImage
            className="img-hotspot-detail"
            src={dot.image}
            alt="hot spot picture"
          />
        )}
      </MDBBox>
      <MDBCardBody>
        <MDBCardText className="product-details ">
          <MDBRow className={`details-row`}>
            <MDBCol size={`12`}>
              <p className={`text-start`}>{dot?.name}</p>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="12">
              {loggedIn && (
                <MDBBox className={`product-price`}>
                  <p>
                    <Price
                      product={dot.product}
                      price={dot.price}
                      currency={dot.priceCurrency}
                      type={`fixed`}
                    />
                  </p>
                </MDBBox>
              )}
            </MDBCol>
          </MDBRow>
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>
  );
};

export default ProductCard;
