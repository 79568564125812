import { Fragment, useMemo } from "react";
import { MDBRow, MDBNavbarNav, MDBCollapse } from "mdbreact";
import HeaderDropdown from "../dropdown";
import MenuItem from "./menuItem";

const Menu = (props) => {
  let {
    menuItems,
    position,
    setPosition,
    hoveredDropdown,
    setHoveredDropdown,
    showDropdown,
    setShowDropdown,
    hoveredDropdownId,
    setHoveredDropdownId,
  } = props;
  let activeMenuItems =
    typeof menuItems[hoveredDropdown] !== "undefined"
      ? menuItems[hoveredDropdown]
      : {};
  const memoizedMenuItems = useMemo(() => {
    if (!menuItems) return false;
    let activeMenuItems =
      typeof menuItems[hoveredDropdown] !== "undefined"
        ? menuItems[hoveredDropdown]
        : {};
    return Object.keys(menuItems).map((index, key) => {
      let currentMenuItem = menuItems[index];
      if (menuItems[index].include_in_menu === 0) {
        return <></>;
      }
      const isHovered = hoveredDropdown === index ? true : false;

      let hasDropdownMenu =
        typeof currentMenuItem.children !== "undefined" &&
        currentMenuItem.children.length > 0;
      const onMouseOverFn = (e) => {
        if (hasDropdownMenu) {
          e.preventDefault();
          setHoveredDropdown(index);
          setHoveredDropdownId(currentMenuItem);
          setShowDropdown(true);
        } else {
          e.preventDefault();
          setHoveredDropdown(false);
          setHoveredDropdownId(false);
          setShowDropdown(false);
        }
      };
      return (
        <MenuItem
          currentMenuItem={currentMenuItem}
          index={index}
          key={key}
          setHoveredDropdown={setHoveredDropdown}
          setShowDropdown={setShowDropdown}
          hoveredDropdown={isHovered}
          showDropdown={showDropdown}
          activeMenuItems={activeMenuItems}
          position={position}
          setPosition={setPosition}
          hoveredDropdownId={isHovered}
          hasDropdownMenu={hasDropdownMenu}
          onMouseOverFn={onMouseOverFn}
        />
      );
    });
  }, [
    hoveredDropdown,
    position,
    menuItems,
    setPosition,
    setShowDropdown,
    showDropdown,
    setHoveredDropdown,
    setHoveredDropdownId,
  ]);

  return (
    <Fragment key={`main-navigation`}>
      <div
        className={`d-flex flex-column position-relative nav-items`}
        onMouseLeave={(e) => {
          e.preventDefault();
          setShowDropdown(false);
        }}
      >
        <MDBRow
          className="menu-items"
          onMouseLeave={(e) => {
            e.preventDefault();
            setShowDropdown(false);
          }}
        >
          <MDBCollapse isOpen={props.collapseID} navbar>
            <MDBNavbarNav className={`align-items-center`}>
              {memoizedMenuItems ? memoizedMenuItems : <></>}
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBRow>
      </div>
      <MDBRow className="dropdown-items">
        {/* only for complex menu layout */}
        {activeMenuItems && activeMenuItems.complex_menu && (
          <HeaderDropdown
            hoveredDropdown={hoveredDropdown}
            setHoveredDropdown={setHoveredDropdown}
            activeMenuItems={activeMenuItems}
            position={position}
            setPosition={setPosition}
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
            hoveredDropdownId={hoveredDropdownId}
          />
        )}
      </MDBRow>
    </Fragment>
  );
};

export default Menu;
