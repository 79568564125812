import gql from "graphql-tag";
export const PRODUCTS = gql`
  query Products(
    $search: String
    $sort: ProductAttributeSortInput
    $pageSize: Int
    $currentPage: Int
  ) {
    products(
      search: $search
      sort: $sort
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      total_count
      items {
        id
        sku
        name
        canonical_url
        url_key
        parent_url_key
        small_image {
          disabled
          label
          position
          url
        }
        thumbnail {
          url
          label
        }
        url_rewrites {
          parameters {
            name
            value
          }
          url
        }
      }
    }
  }
`;

export const PRODUCTS_CUSTOMER = gql`
  query ProductsCustomer(
    $search: String
    $sort: ProductAttributeSortInput
    $pageSize: Int
    $currentPage: Int
  ) {
    products(
      search: $search
      sort: $sort
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      total_count
      items {
        id
        sku
        name
        canonical_url
        url_key
        parent_url_key
        small_image {
          disabled
          label
          position
          url
        }
        thumbnail {
          url
          label
        }
        url_rewrites {
          parameters {
            name
            value
          }
          url
        }
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            fixed_product_taxes {
              amount {
                currency
                value
              }
              label
            }
            regular_price {
              currency
              value
            }
          }
        }
      }
      page_info {
        page_size
        total_pages
        current_page
      }
    }
  }
`;
