import { MDBRow } from "mdbreact";
import CategoriesList from "../dropdownLayoutComponents/categoriesList";
import SubcategoriesList from "../dropdownLayoutComponents/subcategoriesList";
import CategoryImage from "../dropdownLayoutComponents/categoryImage";

const ComplexDropdownLayout = (props) => {
  let {
    activeMenuItems,
    activeCategory,
    setActiveCategory,
    subcategories,
    setSubcategories,
    activeCategoryImage,
    setActiveCategoryImage,
    setShowDropdown,
    store,
  } = props;

  return (
    <MDBRow className="w-100 mx-0">
      <CategoriesList
        complexMenuLayout={true}
        activeMenuItems={activeMenuItems}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        setSubcategories={setSubcategories}
        setShowDropdown={setShowDropdown}
        setActiveCategoryImage={setActiveCategoryImage}
        store={store}
      />
      <SubcategoriesList
        activeCategory={activeCategory}
        activeCategoryImage={activeCategoryImage}
        activeMenuItems={activeMenuItems}
        subcategories={subcategories}
        setShowDropdown={setShowDropdown}
        setActiveCategoryImage={setActiveCategoryImage}
        store={store}
      />
      <CategoryImage
        activeCategory={activeCategory}
        activeMenuItems={activeMenuItems}
        image={activeCategoryImage}
        setShowDropdown={setShowDropdown}
      />
    </MDBRow>
  );
};

export default ComplexDropdownLayout;
