import { lazy } from "react";
import { useMediaQuery } from "react-responsive";

const DesktopLayout = lazy(() => import("./desktopLayout"));
const MobileLayout = lazy(() => import("./mobileLayout"));

const TestimonialItem = ({ item }) => {
  const isMobile = useMediaQuery({ maxWidth: 992 });
  return isMobile ? (
    <MobileLayout item={item} />
  ) : (
    <DesktopLayout item={item} />
  );
};

export default TestimonialItem;
