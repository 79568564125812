import { useState } from "react";
import { MDBRow, MDBCol, MDBContainer, MDBBox, MDBTypography } from "mdbreact";
import Button from "core/components/button";
import InputField from "core/components/inputField";
import { shouldMarkError } from "../validation";
import TogglePasswordContainer from "app/layout/togglePasswordContainer";

const ThirdStep = ({
  errors,
  touched,
  setTouched,
  email,
  setEmailFn,
  loading,
  passwordCheck,
  setPasswordFn,
  password,
  validateInput,
  confirmPassword,
  setConfirmPasswordFn,
}) => {
  const [togglePassword, setTogglePassword] = useState({
    password: false,
    confirmPassword: false,
  });
  return (
    <>
      <MDBContainer fluid>
        <MDBRow className={`mb-90`}>
          <MDBBox className="title-with-background w-100">
            <MDBContainer>
              <MDBTypography tag="h1" className="page-title">
                Update password
              </MDBTypography>
            </MDBContainer>
          </MDBBox>
        </MDBRow>
      </MDBContainer>
      <MDBContainer className={"page-main-content forgot-password"}>
        <MDBRow className="d-flex justify-content-center text-center">
          <MDBCol sm="8" xl="6">
            <p className="mb-3">Please enter your new password.</p>
          </MDBCol>
        </MDBRow>
        <MDBRow className="input-btn-content">
          <MDBCol sm="12" className="input-field-content">
            <MDBRow>
              <MDBCol size="12">
                <MDBBox className={`input-container`}>
                  <InputField
                    placeholder={"Required"}
                    className={`custom-input ${
                      shouldMarkError("email", errors, touched)
                        ? "validation-error"
                        : ""
                    }`}
                    style={{
                      marginBottom:
                        shouldMarkError("email", errors, touched) && "3.5rem",
                    }}
                    label={`Email *`}
                    onBlur={(e) => {
                      setTouched({ ...touched, email: true });
                    }}
                    group
                    type="email"
                    validate
                    error="wrong"
                    success="right"
                    value={email !== undefined ? email : ""}
                    onChange={setEmailFn}
                  />
                  <MDBTypography tag="p" className={`error-label`}>
                    {shouldMarkError("email", errors, touched)
                      ? email.length === 0
                        ? "Email is required *"
                        : "Email is invalid *"
                      : ""}
                  </MDBTypography>
                </MDBBox>
                <MDBBox className={`input-container`}>
                  <TogglePasswordContainer
                    show={togglePassword.password}
                    toggle={() =>
                      setTogglePassword((prevProps) => ({
                        ...prevProps,
                        password: !togglePassword.password,
                      }))
                    }
                  >
                    <InputField
                      placeholder={"Required"}
                      className={`custom-input ${
                        shouldMarkError("password", errors, touched) ||
                        passwordCheck
                          ? "validation-error"
                          : ""
                      }`}
                      label={`New Password`}
                      group
                      type={togglePassword.password ? "text" : `password`}
                      validate
                      error="wrong"
                      success="right"
                      onChange={setPasswordFn}
                      onBlur={(e) => {
                        setTouched({ ...touched, password: true });
                      }}
                      value={password !== undefined ? password : ""}
                    />
                  </TogglePasswordContainer>
                  <MDBTypography tag="p" className={`error-label`}>
                    {`${
                      shouldMarkError("password", errors, touched) ||
                      passwordCheck
                        ? "Password is required *"
                        : ""
                    }`}
                  </MDBTypography>
                </MDBBox>
                <div
                  className={`password-note ${
                    shouldMarkError("password", errors, touched) ? "" : "d-none"
                  }`}
                >
                  Password must be a minimum of 8 characters in length and some
                  special characters
                </div>
                <MDBBox className={`input-container`}>
                  <TogglePasswordContainer
                    show={togglePassword.confirmPassword}
                    toggle={() =>
                      setTogglePassword((prevProps) => ({
                        ...prevProps,
                        confirmPassword: !togglePassword.confirmPassword,
                      }))
                    }
                  >
                    <InputField
                      placeholder={"Required"}
                      className={`custom-input ${
                        shouldMarkError("confirm_password", errors, touched)
                          ? "validation-error"
                          : ""
                      }`}
                      label={`Confirm Password *`}
                      onBlur={(e) => {
                        setTouched({ ...touched, confirm_password: true });
                      }}
                      group
                      type={
                        togglePassword.confirmPassword ? "text" : `password`
                      }
                      validate
                      error="wrong"
                      success="right"
                      value={
                        confirmPassword !== undefined ? confirmPassword : ""
                      }
                      onChange={setConfirmPasswordFn}
                    />
                  </TogglePasswordContainer>
                  <MDBTypography tag="p" className={`error-label`}>
                    {`${
                      shouldMarkError("confirm_password", errors, touched)
                        ? "confirm password is required*"
                        : ""
                    }`}
                  </MDBTypography>
                </MDBBox>
                <Button
                  disabled={loading}
                  className={"big w-100 mt-5"}
                  children={
                    <span tag="span">{`${
                      loading ? "resetting..." : "Update password"
                    }`}</span>
                  }
                  onClick={(e) => {
                    validateInput();
                  }}
                />
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBRow className={"w-100 spacer"}></MDBRow>
    </>
  );
};

export default ThirdStep;
