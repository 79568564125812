export default (state, action) => {
  let modifiedVisited =
    typeof state.visited !== "undefined" ? [...state.visited] : [];

  // check if sku exists
  let foundSku = modifiedVisited.find((x) => x === action.payload);

  if (!foundSku) {
    modifiedVisited.push(action.payload);
  }

  return Object.assign({}, state, {
    ...state,
    visited: [...modifiedVisited],
  });
};
