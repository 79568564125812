import types from "./types";
import setIsOnline from "./reducers/setIsOnline";

// Main reducer
const applicationReducer = (state, action) => {
  if (state === undefined) {
    return {};
  }

  if (action.type === types.SET_IS_ONLINE) {
    return setIsOnline(state, action);
  }

  return state;
};

export default applicationReducer;
