/**
 * Redux template file, used to export reducers, middlewares, inital state and action/creators.
 *
 * Wishlist Redux
 **/
import wishlistReducer from "./reducer";
export { default as wishlistTypes } from "./types";
export { default as wishlistActions } from "./actions";
export { default as wishlistInitialState } from "./initialState";
export default wishlistReducer;
