import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { MDBBox, MDBBtn } from "mdbreact";
import { useMutation } from "react-apollo-hooks";
import ReCAPTCHA from "react-google-recaptcha";
import ReactLoader from "app/layout/loader";
import useMessages from "app/state/hooks/messageHooks/useMessages";
import { gql } from "graphql.macro";
import { handleContactUsSubmit } from "./handleSubmit";
import LeftSide from "./leftSide";
import RightSide from "./rightSide";

const CONTACT_US = gql`
  mutation SubmitContactUs($input: ContactusInput!) {
    submitContactUs(input: $input)
  }
`;

const ContactUsForm = () => {
  const { handleSubmit, formState, control, trigger, reset } = useForm();
  const { errors } = formState;

  const { addMessage } = useMessages();
  const [submitContactUs, { loading }] = useMutation(CONTACT_US);

  const onContactUsSubmit = (data) => {
    if (!isCaptchaValid || Object.keys(errors).length > 0) {
      return setIsCaptchaValid(false);
    }
    handleContactUsSubmit(data, reset, submitContactUs, addMessage);
  };
  const [isCaptchaValid, setIsCaptchaValid] = useState(null);

  const captchaOnChange = () => setIsCaptchaValid(true);

  useEffect(() => {
    if (Object.keys(errors).length > 0 && !isCaptchaValid) {
      setIsCaptchaValid(false);
    } else if (isCaptchaValid) {
      setIsCaptchaValid(true);
    }
  }, [errors]);

  return (
    <form id={`contact-us-form`} onSubmit={handleSubmit(onContactUsSubmit)}>
      <div className={`row`}>
        <LeftSide control={control} errors={errors} trigger={trigger} />
        <RightSide control={control} errors={errors} trigger={trigger} />
      </div>
      <div className="captcha mb-4">
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
          onChange={captchaOnChange}
          className={``}
        />
        {isCaptchaValid === false && (
          <div className="text-red">reCAPTCHA is required *</div>
        )}
      </div>
      <div
        className={`submit-button-container pt-5 d-flex justify-content-end`}
      >
        <MDBBtn
          type={`submit`}
          color={`primary`}
          className={`btn-with-icon btn-black-text`}
          outline
          disabled={loading}
          children={
            (loading && (
              <ReactLoader
                loading={loading}
                type={`ThreeDots`}
                height={`35`}
                width={`35`}
                size={20}
                color={`#fff`}
              />
            )) || (
              <>
                <MDBBox tag="span">Submit</MDBBox>
                <MDBBox className="circle-icon" />
              </>
            )
          }
        ></MDBBtn>
      </div>
    </form>
  );
};

export default ContactUsForm;
