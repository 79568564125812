import { memo, useEffect } from "react";
import { MDBBtn, MDBInput } from "mdbreact";
import useDebounce from "app/state/hooks/coreHooks/useDebounce";
import { isEqual } from "lodash";

const SearchInput = memo(
  ({ changeSearch, searchVal, setSearchVal }) => {
    useEffect(() => {
      document.getElementById("search-input").focus();
    }, []);
    const debouncedSearchVal = useDebounce(searchVal, 1500);
    useEffect(() => {
      if (debouncedSearchVal) {
        changeSearch(debouncedSearchVal);
      }
    }, [debouncedSearchVal, changeSearch]);
    return (
      <div className={`search-input-wrapper`}>
        <MDBInput
          className="m-0"
          id={`search-input`}
          placeholder={`Enter keyword or SKU`}
          value={searchVal}
          onChange={(e) => {
            setSearchVal(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.keyCode === "Enter" || e.which === 13) {
              changeSearch(e.target.value);
            }
          }}
        >
          <MDBBtn
            onClick={(e) => {
              setSearchVal("");
            }}
            color={`primary`}
            type="submit"
            className={`w-100 btn-without-icon text-center d-inline-block`}
          >
            {searchVal.length > 0 ? "Clear" : "Submit"}
          </MDBBtn>
        </MDBInput>
      </div>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default SearchInput;
