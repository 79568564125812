export default (state, action) => {
  let payload = { ...action.payload };
  let findWishlist = state.data.wishlistItems.findIndex(
    (wishlist) => wishlist.name === payload.name
  );

  let newState = { ...state };

  if (typeof newState.data.wishlistItems[findWishlist] !== "undefined") {
    newState.data.wishlistItems[findWishlist] = {
      ...payload,
      id: newState.data.wishlistItems[findWishlist].id,
    };
  } else {
    newState.data.wishlistItems.push({
      ...payload,
    });
  }

  return Object.assign({}, state, { ...newState });
};
