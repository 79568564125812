import { useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import useStore from "app/state/hooks/storeHooks/useStore";
import { MDBModal, MDBModalHeader, MDBModalBody } from "mdbreact";
import Icon from "app/assets/icon/icon";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import TearsheetForm from "./tearsheetForm";
import Pdf from "./print/pdf";

const DownloadTearsheet = ({
  product,
  selectedSwatch,
  selectedSize,
  pdpSidebarImage,
  productVariant,
}) => {
  const { getStoreCode } = useStore();
  const store = "gatco";
  const { getCustomer } = useCustomer();

  const customer = getCustomer();

  const [pdfData, setPdfData] = useState();
  const [toggleModal, setToggleModal] = useState(true);
  const toggle = () => {
    setToggleModal(!toggleModal);
  };

  const pdfComponent = useRef();

  const handlePrint = useReactToPrint({
    content: () => pdfComponent.current,
  });
  const generateDownloadLinkAndOpen = () => {
    handlePrint();
  };
  let pdfOptions = {
    remarks: pdfData?.remarks,
    pricing: pdfData,
  };

  return (
    <>
      <MDBModal
        wrapClassName={"tearsheet-modal"}
        className={`download-tearsheet-modal ${
          customer?.show_price === false && "quick-register-customer"
        }`}
        isOpen={toggleModal}
        toggle={toggle}
      >
        <MDBModalHeader>
          Download Tearsheet
          <div onClick={toggle} className={`icon-container`}>
            <Icon icon="closeIconBlue" />
          </div>
        </MDBModalHeader>
        <MDBModalBody>
          <TearsheetForm
            setPdfData={setPdfData}
            generateDownloadLinkAndOpen={generateDownloadLinkAndOpen}
            toggle={toggle}
            customer={customer}
          />
        </MDBModalBody>
      </MDBModal>
      <div className={`d-none`}>
        <Pdf
          ref={pdfComponent}
          product={product}
          {...pdfOptions}
          store={store}
          selectedSwatch={selectedSwatch}
          selectedSize={selectedSize}
          customer={customer}
          pdpSidebarImage={pdpSidebarImage}
          productVariant={productVariant}
        />
      </div>
    </>
  );
};

export default DownloadTearsheet;
