import { useState, useRef } from "react";
import { validate } from "./validation";
import FirstStep from "./firstStep";
import SecondStep from "./secondStep";
import ThirdStep from "./thirdStep";
import ResetPassSuccess from "./success";
import { MDBContainer } from "mdbreact";

const ForgotPassword = (props) => {
  let { location } = props;
  const [loading, setLoading] = useState(false);
  let token =
    location.search !== "" && location.search.includes("token")
      ? location.search.split("=")[1]
      : "";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [touched, setTouched] = useState({
    email: false,
    password: false,
  });

  // this will determine which step is selected
  const [passwordResetSent, setPasswordResetSent] = useState(false);
  const [passwordUpdated, setPasswordUpdated] = useState(false);

  let errors = validate(email, password, confirmPassword);

  const setEmailFn = (e) => {
    setEmail(e.target.value);
  };
  const setPasswordFn = (e) => {
    setPassword(e.target.value);
  };
  const setConfirmPasswordFn = (e) => {
    setConfirmPassword(e.target.value);
  };
  const [passwordCheck] = useState(false);

  const recaptchaRef = useRef(null);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  function onChangeRecaptcha(value) {
    setRecaptchaValue(value);
  }
  const [siteKeyVal] = useState(process.env.REACT_APP_RECAPTCHA_KEY);
  const validateInput = () => {
    let isTrue = true;
    setTouched({
      email: true,
      password: true,
      confirm_password: true,
    });
    Object.keys(errors).map((item) => {
      let error = errors[item];
      if (item === "email") {
        error = false;
      }
      if (isTrue) {
        if (error === true) {
          isTrue = false;
        }
      }
      return item;
    });

    if (isTrue) {
      setLoading(true);
      props.lock({
        always: () => {
          setLoading(true);
        },
        success: () => {
          setLoading(false);
          setPasswordUpdated(true);
        },
        fail: () => {
          setLoading(false);
          //change this when we fix BE issue to false
          setPasswordUpdated(false);
        },
      });
      props.resetPassword(token, password, confirmPassword, email);
    }
  };

  const forgotPassword = (email) => {
    let isTrue = true;
    Object.keys(errors).map((item) => {
      setTouched({ ...touched, email: true });
      let error = errors[item];
      if (item === "email") {
        if (error) {
          isTrue = false;
        }
      }
      return item;
    });
    setLoading(true);
    props.lock({
      always: () => {},
      success: () => {
        setLoading(false);
        setPasswordResetSent(true);
      },
      fail: () => {
        setLoading(false);
        setPasswordResetSent(false);
      },
    });
    if (recaptchaValue === null) {
      isTrue = false;
    }
    if (isTrue) {
      props.onForgotPassword(email);
    }
    // setLoading(false);
  };

  return (
    <>
      {!token && !passwordResetSent ? (
        <FirstStep
          errors={errors}
          touched={touched}
          setTouched={setTouched}
          email={email}
          setEmailFn={setEmailFn}
          forgotPassword={forgotPassword}
          loading={loading}
          setLoading={setLoading}
          recaptchaRef={recaptchaRef}
          siteKeyVal={siteKeyVal}
          onChangeRecaptcha={onChangeRecaptcha}
          recaptchaValue={recaptchaValue}
        />
      ) : !token && passwordResetSent ? (
        <SecondStep email={email} {...props} />
      ) : token && !passwordUpdated ? (
        <ThirdStep
          password={password}
          setPasswordFn={setPasswordFn}
          passwordCheck={passwordCheck}
          errors={errors}
          touched={touched}
          setTouched={setTouched}
          email={email}
          setEmailFn={setEmailFn}
          loading={loading}
          validateInput={validateInput}
          confirmPassword={confirmPassword}
          setConfirmPasswordFn={setConfirmPasswordFn}
        />
      ) : (
        token && passwordUpdated && <ResetPassSuccess {...props} />
      )}
    </>
  );
};

export default ForgotPassword;
