import { Suspense, Component } from "react";

const Pages = ({ customer, selectedPage, page, history, orderData }) => {
  let SelectedPage = selectedPage;

  return (
    <ErrorBoundary>
      <Suspense fallback={<></>}>
        <SelectedPage
          customer={customer}
          history={history}
          page={page}
          orderData={orderData}
        />
      </Suspense>
    </ErrorBoundary>
  );
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong:( </h1>;
    }

    return this.props.children;
  }
}

export default Pages;
