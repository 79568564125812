/**
 * Messages integrations with Redux following the ducks standard
 */

import storeReducer from "./reducer";

export { default as storeType } from "./types";
export { default as storeActions } from "./actions";
export { default as storeInitialState } from "./initialState";
export { default as storeMiddleware } from "./middleware";
export default storeReducer;

// End
