import { useEffect, useState } from "react";
import {
  categories,
  storeConfig,
  cmsBlocks,
  galleries,
  testimonials,
  catalogsdata,
  careers,
  cmsPages,
} from "generation";
import { useSelector, useDispatch } from "react-redux";
import { isEqual } from "lodash";
import { storeActions } from "../redux/data/store";
import { CMSBlockParser } from "app/layout/cmsBlock/Parser";

const mainStoreCode = process.env.REACT_APP_MAIN_STORE_CODE;
const mainStore = process.env.REACT_APP_MAIN_STORE;

const useGenerated = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.store);

  const [allStores, setAllStores] = useState(storeConfig);
  const [currentStore, setCurrentStore] = useState({
    store_code: mainStoreCode,
    url: mainStore,
  });

  storeConfig.then((store) => {
    setAllStores(store.default);
  });

  const GetGeneratedStore = () => {
    const [data, setData] = useState({});

    useEffect(() => {
      storeConfig.then((res) => {
        getDataByCurrentStore(res, store, data, setData);
      });
    }, [data]);

    return data;
  };
  const GetCategories = () => {
    const [data, setData] = useState({});

    useEffect(() => {
      categories.then((res) => {
        getDataByCurrentStore(res, store, data, setData, false);
      });
    }, [data]);
    return data;
  };

  const GetCmsBlocks = (key = false) => {
    const [data, setData] = useState({});

    useEffect(() => {
      cmsBlocks.then((res) => {
        getDataByCurrentStore(res, store, data, setData, false);
      });
    }, [data]);

    if (key) {
      return data && data[key] ? (
        <CMSBlockParser cmsBlock={data[key]} />
      ) : (
        <></>
      );
    }
    return data;
  };

  const GetCmsPages = (key = false) => {
    const [data, setData] = useState({});

    useEffect(() => {
      cmsPages.then((res) => {
        getDataByCurrentStore(res, store, data, setData, false);
      });
    }, [data]);

    if (key) {
      return data && data[key] ? data[key] : <></>;
    }

    return data;
  };

  const GetGalleries = () => {
    const [data, setData] = useState({});

    useEffect(() => {
      galleries.then((res) => {
        getDataByCurrentStore(res, store, data, setData, false);
      });
    }, [data]);
    return data;
  };

  const GetTestimonials = () => {
    const [data, setData] = useState({});

    useEffect(() => {
      testimonials.then((res) => {
        getDataByCurrentStore(res, store, data, setData, false);
      });
    }, [data]);
    return data;
  };

  const GetCatalogs = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
      catalogsdata.then((res) => {
        getDataByCurrentStore(res, store, data, setData, false);
      });
    }, [data]);
    return data;
  };

  const GetCareers = () => {
    const [data, setData] = useState([]);
    careers.then((res) => {
      getDataByCurrentStore(res, store, data, setData, false);
    });

    return data;
  };

  const determineStore = async () => {
    let FLAG = process.env.REACT_APP_FLAG;
    let mainPWAUrl = process.env.REACT_APP_URL;

    // FLAG = "production";
    // local development is going to use .env prop for this to simulate current store, while production will use window location
    let currentHostUrl =
      FLAG === "local" ? mainPWAUrl : window.location.host?.replace("www.", "");

    let newStore = { ...currentStore };

    let storeCode = store.store_code + "-" + sanitizeUrl(store.base_link_url);

    if (FLAG === "production") {
      if (store?.store_code) {
        if (!store.react_url.includes(currentHostUrl)) {
          if (!allStores.then) {
            Object.keys(allStores).map((key) => {
              let store = allStores[key];
              if (store.react_url.includes(currentHostUrl)) {
                newStore = {
                  store_code: store.store_code,
                  url: sanitizeUrl(store.base_link_url),
                };
                setCurrentStore({ ...newStore });
                dispatch(
                  storeActions.setStore({
                    ...store,
                  })
                );
              }
              return key;
            });
          }
        }
      } else {
        if (!allStores.then) {
          Object.keys(allStores).map((key) => {
            let store = allStores[key];
            if (store.react_url.includes(currentHostUrl)) {
              newStore = {
                store_code: store.store_code,
                url: sanitizeUrl(store.base_link_url),
              };
              setCurrentStore({ ...newStore });
              dispatch(
                storeActions.setStore({
                  ...store,
                })
              );
            }
            return key;
          });
        }
      }
    }
    if (FLAG === "local") {
      if (store?.store_code && store.store_code !== currentStore.store_code) {
        newStore = {
          store_code: store.store_code,
          url: sanitizeUrl(store.base_link_url),
        };
        if (!isEqual(allStores[storeCode], store)) {
          dispatch(
            storeActions.setStore({
              ...allStores[storeCode],
            })
          ).then(() => {
            setCurrentStore({ ...newStore });
          });
        }

        return newStore;
      }

      newStore = { store_code: mainStoreCode, url: mainStore };
      if (!allStores.then) {
        if (
          !isEqual(
            allStores[currentStore.store_code + "-" + currentStore.url],
            store
          )
        ) {
          setCurrentStore({ ...newStore });
          dispatch(
            storeActions.setStore({
              ...allStores[mainStoreCode + "-" + mainStore],
            })
          );
        }
      }
    }

    return newStore;
  };

  const getDataByCurrentStore = (res, store, data, setData, useUrl = true) => {
    if (store.store_code === undefined) {
      setData(res[useUrl ? mainStoreCode + "-" + mainStore : mainStoreCode]);
    } else if (
      store.store_code !== undefined &&
      mainStoreCode !== store.store_code &&
      isEqual(
        res[
          useUrl
            ? store.store_code + "-" + sanitizeUrl(store.base_link_url)
            : store.store_code
        ],
        store
      )
    ) {
      setData(
        res[
          useUrl
            ? store.store_code + "-" + sanitizeUrl(store.base_link_url)
            : store.store_code
        ]
      );
    } else {
      let finalStore = useUrl
        ? store.store_code + "-" + sanitizeUrl(store.base_link_url)
        : store.store_code;
      if (!isEqual(res[finalStore], data)) {
        setData(res[finalStore]);
      }
    }

    return res;
  };

  const sanitizeUrl = (baseLink) => {
    let sanitizedBaseLink = baseLink
      ? baseLink.replace("https://", "").replace("/", "")
      : mainStore;

    return sanitizedBaseLink;
  };

  const changeCurrentStore = (payload) => {
    dispatch(storeActions.setStore(payload));
  };

  return {
    GetCategories,
    GetGeneratedStore,
    determineStore,
    GetCmsBlocks,
    GetGalleries,
    GetTestimonials,
    GetCatalogs,
    GetCareers,
    GetCmsPages,
    allStores,
    changeCurrentStore,
  };
};

export default useGenerated;
