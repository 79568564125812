import apolloClient from "app/graphql/apolloClient";
import customerActions from "../../actions";
import commonActions from "../../../common/actions";
import messagesActions from "core/state/redux/data/messages/actions";
import queryActions from "core/state/redux/data/queries/actions";
import { gql } from "graphql.macro";
import { merge } from "lodash";

const SET_SHIPPING_ADDRESSES_ON_CART = gql`
  mutation SetShippingAddressesOnCart($input: SetShippingAddressesOnCartInput) {
    setShippingAddressesOnCart(input: $input) {
      cart {
        id
        # ship_complete
        prices {
          subtotal_including_tax {
            currency
            value
          }
          subtotal_excluding_tax {
            currency
            value
          }
          fee {
            currency
            value
          }
          grand_total {
            currency
            value
          }
          applied_taxes {
            amount {
              currency
              value
            }
            label
          }
          discounts {
            amount {
              value
              currency
            }
            label
          }
        }
        available_payment_methods {
          code
          title
          # terms
        }

        items {
          id
          prices {
            discounts {
              amount {
                currency
                value
              }
              label
            }
            price {
              currency
              value
            }
            row_total {
              currency
              value
            }
            row_total_including_tax {
              currency
              value
            }
            total_item_discount {
              currency
              value
            }
          }
        }

        shipping_addresses {
          city
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          street
          telephone
          region {
            code
            label
          }
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            carrier_code
            carrier_title
            error_message
            method_code
            method_title
            price_excl_tax {
              currency
              value
            }

            price_incl_tax {
              currency
              value
            }
          }
          selected_shipping_method {
            amount {
              currency
              value
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
        }
      }
    }
  }
`;
export default async (store, action) => {
  try {
    let cart_id = store.getState().customer.data.cartToken;
    let cartInformation = store.getState().customer.data.cart;
    let input = {
      cart_id: cart_id,
      shipping_addresses: [{ ...action.shipping_address }],
    };

    const { data } = await apolloClient.mutate({
      mutation: SET_SHIPPING_ADDRESSES_ON_CART,
      variables: { input: input },
      fetchPolicy: "no-cache",
    });
    if (data) {
      merge(cartInformation, data.setShippingAddressesOnCart.cart);

      cartInformation = {
        ...cartInformation,
        shipping_addresses: [
          ...data.setShippingAddressesOnCart.cart.shipping_addresses,
        ],
      };
      store.dispatch(customerActions.setCartInformation(cartInformation));
      store.dispatch(
        messagesActions.addMessage("Updated cart information", "success")
      );
      store.dispatch(commonActions.unlock("success"));
    }
  } catch (error) {
    if (error.message.includes("Could not find a cart with ID")) {
      store.dispatch(customerActions.createEmptyCart());
    } else {
      store.dispatch(messagesActions.addMessage(error.toString(), "danger"));
    }
    store.dispatch(commonActions.unlock("fail"));
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
