import ReactLoader from "../loader/loader";
import useStore from "app/state/hooks/storeHooks/useStore";

const CustomLoader = () => {
  const { getStoreCode } = useStore();
  const store = getStoreCode();

  return (
    <div className={`custom-loader`}>
      <div className={`logo`}>
        <img
          src={`/manifests/${store}/GatcoLogoBlue.svg`}
          alt={`loader-logo`}
        />
      </div>
      <ReactLoader size={`60px`} color={"#004C7E"} />
    </div>
  );
};

export default CustomLoader;
