import configData from "config/schemaConfiguration.json";
export const OrganizationSchema = (props) => {
  const { store = "gatco" } = props;
  const schemaConfiguration = configData[store];

  let breadcrumbs = undefined;
  if (typeof props !== "undefined") {
    breadcrumbs = props.breadcrumbs;
  }

  const data = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": "#organization",
        name: schemaConfiguration.name,
        legalName: schemaConfiguration.legalName,
        url: schemaConfiguration.url,
        logo: schemaConfiguration.logo,
        foundingDate: schemaConfiguration.foundingDate,
        address: {
          "@type": "PostalAddress",
          streetAddress: schemaConfiguration.address.streetAddress,
          addressLocality: schemaConfiguration.address.addressLocality,
          addressRegion: schemaConfiguration.address.addressRegion,
          postalCode: schemaConfiguration.address.postalCode,
          addressCountry: schemaConfiguration.address.addressCountry,
        },
        contactPoint: {
          "@type": "ContactPoint",
          contactType: "customer service",
          telephone: schemaConfiguration.contactPoint.telephone,
          email: schemaConfiguration.contactPoint.email,
          hoursAvailable: [
            {
              "@type": "OpeningHoursSpecification",
              dayOfWeek:
                schemaConfiguration.contactPoint.hoursAvailable.dayOfWeek,
              opens: schemaConfiguration.contactPoint.hoursAvailable.opens,
              closes: schemaConfiguration.contactPoint.hoursAvailable.closes,
            },
          ],
          sameAs: schemaConfiguration.contactPoint.socialNetworks,
        },
      },
      {
        "@type": "WebSite",
        "@id": "#website",
        name: schemaConfiguration.name,
        url: schemaConfiguration.url,
        potentialAction: {
          "@type": "SearchAction",
          target: `${schemaConfiguration.url}/search-results?search={search_term_string}`,
          "query-input": "required name=search_term_string",
        },
      },
      typeof breadcrumbs !== "undefined" && breadcrumbs.length > 0
        ? {
            "@type": "BreadcrumbList",
            "@id": "#breadcrumbs",
            itemListElement: breadcrumbs.map((item, index) => {
              let url = schemaConfiguration.url + item.item;
              return {
                "@type": "ListItem",
                "@id": `#breadcrumb-${index + 1}`,
                position: index + 1,
                name: item.name,
                item: url,
              };
            }),
          }
        : {},
    ],
  };
  return JSON.stringify(data);
};
