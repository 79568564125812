import { memo } from "react";
import Icon from "app/assets/icon/icon";
import SearchResults from "./searchResults";
import { isEqual } from "lodash";
import SearchToolbar from "./searchToolbar";

const Search = memo(
  ({ open, searchVal, setSearchVal, setDisplaySearch, toggleSearch }) => {
    return (
      <div
        fluid
        id={`search-container`}
        className={`container-fluid ${open ? "show" : "hide"}`}
      >
        {open ? (
          <>
            <Icon icon={`closeIconBlue`} onClick={toggleSearch} />
            <div className={`content-wrapper container `}>
              <SearchToolbar
                searchVal={searchVal}
                setSearchVal={setSearchVal}
              />
              <div className={`content w-100 justify-content-center  row mx-0`}>
                {searchVal ? (
                  <div className={`col-lg-9 px-0`}>
                    <SearchResults
                      setDisplaySearch={setDisplaySearch}
                      searchVal={searchVal}
                      setSearchVal={setSearchVal}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default Search;
