import Images from "./images";
import {
  MDBBtn,
  MDBCloseIcon,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import React, { useState } from "react";
import {
  downloadImages,
  generateRequestForAddingOrRemovingFileForDownload,
} from "core/components/imageDownloader/functions";
import useCustomer from "core/state/hooks/customerHooks/useCustomer";
import useFilesForDownload from "core/components/imageDownloader/hooks/useFilesForDownload";
import ImagesNoFetch from "./imagesNoFetch";

/**
 * @param {*} sku Product sku used to retrieve product images
 * @param {*} modal Modal status, used for isOpen prop
 * @param {*} toggleModal Toggle function for opening and closing modal
 */
const Modal = ({
  data,
  sku,
  modal,
  toggleModal,
  refetch = () => {},
  images = undefined,
}) => {
  const [imageSelection, setImageSelection] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, isLoggedIn] = useCustomer();
  const { addFilesForDownload } = useFilesForDownload();
  let customerToken = isLoggedIn();

  /**
   * @param {*} image Expects image object
   */
  const toggleImage = (image) => {
    const isAdded = imageSelection.find((_image) => _image.id === image.id);
    if (!isAdded) {
      setImageSelection((_images) => [..._images, { ...image }]);
    } else {
      setImageSelection((_images) =>
        _images.filter((_image) => _image.id !== image.id)
      );
    }
  };

  /**
   * Function used to reset modal and selections
   */
  const toggleModalFn = () => {
    setImageSelection([]);
    toggleModal();
  };

  return (
    <MDBModal
      isOpen={modal}
      toggle={toggleModalFn}
      animation={`right`}
      contentClassName={`my-downloads-item-modal-content rounded`}
      wrapClassName={`my-downloads-item-modal d-flex align-items-center justify-content-center`}
    >
      <MDBModalHeader
        className={`my-downloads-item-modal-content-header p-4 text-center`}
      >
        Select Your Images
        <MDBCloseIcon onClick={toggleModalFn} />
      </MDBModalHeader>
      <MDBModalBody>
        {images === undefined ? (
          <Images
            sku={sku}
            imageSelection={imageSelection}
            toggleImage={toggleImage}
          />
        ) : (
          <ImagesNoFetch
            images={images}
            imageSelection={imageSelection}
            toggleImage={toggleImage}
          />
        )}
      </MDBModalBody>
      <MDBModalFooter
        className={`my-downloads-item-modal-content-footer p-4 text-center`}
      >
        <MDBCol>
          <MDBBtn disabled={loading} onClick={toggleModalFn}>
            Cancel
          </MDBBtn>
        </MDBCol>
        <MDBCol>
          <MDBBtn
            disabled={loading || imageSelection.length === 0}
            onClick={() => {
              let downloadImagesArray = imageSelection.map((_image) => ({
                file_url: _image.url,
              }));
              downloadImages(
                downloadImagesArray,
                customerToken,
                true,
                setLoading,
                () => {
                  if (images === undefined) {
                    let removeFileData = generateRequestForAddingOrRemovingFileForDownload(
                      data,
                      "YES"
                    );
                    addFilesForDownload(removeFileData).then(() => {
                      refetch().then(() => {
                        toggleModalFn();
                      });
                    });
                  } else {
                    toggleModalFn();
                  }
                }
              );
            }}
          >
            {loading ? "Downloading..." : "Download Selected"}
          </MDBBtn>
        </MDBCol>
      </MDBModalFooter>
    </MDBModal>
  );
};

export default Modal;
