import types from "./types";
import getCacheVersion from "./reducers/getCacheVersion";
import setCacheVersion from "./reducers/setCacheVersion";
import setFlag from "./reducers/setFlag";
import addDataToCache from "./reducers/addDataToCache";
import clearDataFromCache from "./reducers/clearDataFromCache";

// Main reducer
const cacheReducer = (state, action) => {
  if (state === undefined) {
    return {};
  }

  if (action.type === types.GET_CACHE_VERSION) {
    return getCacheVersion(state, action);
  }

  if (action.type === types.SET_CACHE_VERSION) {
    return setCacheVersion(state, action);
  }

  if (action.type === types.SET_FLAG) {
    return setFlag(state, action);
  }

  if (action.type === types.ADD_DATA_TO_CACHE) {
    return addDataToCache(state, action);
  }

  if (action.type === types.CLEAR_DATA_FROM_CACHE) {
    return clearDataFromCache(state);
  }

  return state;
};

export default cacheReducer;
