import React, { useState } from "react";
import { createContext } from "react";

/**
 * Image download context
 */
export const DownloadContext = createContext({});

/**
 * Image download provider
 * @param {*} props
 */
export const DownloadProvider = (props) => {
  const [images, setImages] = useState([]);

  return (
    <DownloadContext.Provider value={[images, setImages]}>
      {props.children}
    </DownloadContext.Provider>
  );
};
