import { useMemo } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBBtn,
  MDBBox,
  MDBTypography,
  MDBCol,
} from "mdbreact";
import { useHistory } from "react-router";
import MiniCartRender from "./render";

const MiniCart = ({
  cart,
  hideMiniCart,
  updateCart,
  lock,
  showMiniCart,
  numberOfItems,
}) => {
  const history = useHistory();
  // const subtotal = cart?.prices?.subtotal_excluding_tax?.value;
  const subtotal = useMemo(() => {
    let total = cart?.items.reduce((acc, curr) => {
      return (
        acc +
        curr.product.price_range?.minimum_price?.final_price?.value *
          curr.quantity
      );
    }, 0);
    return total;
  }, [cart]);
  return (
    <MDBContainer>
      <MDBRow
        id="miniquote"
        className={`miniquote-wrapper border ${
          showMiniCart ? "active fadeIn" : "inactive"
        }`}
      >
        {numberOfItems !== 0 ? (
          <>
            <MiniCartRender
              cart={cart}
              hideMiniCart={hideMiniCart}
              updateCart={updateCart}
              lock={lock}
              numberOfItems={numberOfItems}
              subtotal={subtotal}
            />
            <MDBBtn
              onClick={() => {
                history.push("/cart");
                hideMiniCart();
              }}
              color="primary"
              className={
                "btn-without-icon miniquote-btn w-100 d-flex justify-content-center"
              }
              children={
                <>
                  <MDBBox tag="span">View Quote Request</MDBBox>
                </>
              }
            />
          </>
        ) : (
          <MDBCol className={`quote-no-item py-4`}>
            <MDBTypography tag="p">
              No items in your quote request
            </MDBTypography>
          </MDBCol>
        )}
      </MDBRow>
    </MDBContainer>
  );
};

export default MiniCart;
