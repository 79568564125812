import OfflinePage from "app/layout/OfflinePage/OfflinePage";
import { ErrorBoundary as ErrorBoundaryNpm } from "react-error-boundary";
import { useHistory } from "react-router";

const ErrorBoundary = (props) => {
  const history = useHistory();
  return (
    <ErrorBoundaryNpm
      fallbackRender={() => (
        <OfflinePage
          refetch={() => {
            history.go(0);
          }}
        />
      )}
    >
      {props.children}
    </ErrorBoundaryNpm>
  );
};

export default ErrorBoundary;
