import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";
import { LocalStorageWrapper, persistCacheSync } from "apollo3-cache-persist";
// react used for redirections
import { isUndefined } from "core/helpers/functions";
import {
  customerActions,
  customerInitialState,
} from "core/state/redux/data/customer";
import { messagesActions } from "core/state/redux/data/messages";
// fetches local storage state and theme configuration
import { loadState } from "core/state/redux/localStorage";
// redux store
import store from "core/state/redux/store";
import introspectionQueryResultData from "./fragmentTypes.json";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});
const cache = new InMemoryCache({ fragmentMatcher });

// TODO Migrate to async api
// difference is summarized here: https://github.com/apollographql/apollo-cache-persist#using-synchronous-storage-api
persistCacheSync({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
});

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  let loadedState = loadState();

  let validToken = window.localStorage.getItem("validToken");

  const token = loadedState?.customer?.data?.token
    ? loadedState.customer.data.token
    : validToken
    ? validToken
    : false;

  let siteCode = loadedState?.site?.siteCode
    ? loadedState.site.siteCode
    : undefined;
  if (siteCode !== undefined) {
    let context = operation.getContext();
    if (
      typeof context.headers !== "undefined" &&
      typeof context.headers.store !== "undefined"
    ) {
      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : "",
          store: context.headers.store,
        },
      });
    }
    // Use the setContext method to set the HTTP headers.
    else {
      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : "",
          store: siteCode,
        },
      });
    }
  } else {
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    });
  }
  return forward(operation);
});

const resetToken = onError(({ graphQLErrors, networkError }) => {
  if (networkError?.bodyText === "TypeError: Failed to fetch") {
    console.log("we should try offline mode");
  }
  if (!isUndefined(graphQLErrors)) {
    let error = graphQLErrors[0];
    if (error.extensions.category === "graphql-authorization") {
      store.dispatch(customerActions._reduceCustomer(customerInitialState));
      store.dispatch(
        messagesActions.addMessage(
          "Your session has expired. Please login to resume.",
          "danger"
        )
      );
    }
  }
});

// implement customerTokenValidation here, in case there is no graphql error
export const apolloClient = new ApolloClient({
  link: authLink.concat(
    ApolloLink.from([
      resetToken,
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path, debugMessage }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, DebugMessage: ${debugMessage}, Location: ${JSON.stringify(
                locations
              )}, Path: ${path}`
            )
          );
        }
        if (networkError) console.log(`[Network error]: ${networkError}`);
      }),

      createHttpLink({
        uri: process.env.REACT_APP_MAIN_STORE + '/graphql',
        useGETForQueries: false,
      }),
    ])
  ),

  cache: cache,
});

export default apolloClient;
